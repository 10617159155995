import React from 'react';
import {
  Grid, Typography, makeStyles, Button, CircularProgress, Box,
} from '@material-ui/core';
import {
  head, groupBy, get, flatMap, isEmpty, sortBy,
} from 'lodash';
import { ThumbUp, ThumbDown, Check } from '@material-ui/icons';
import { useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
import Alert from '@material-ui/lab/Alert';
import { repairDecisionCustomer, confirmEstimateApproval } from '../../../../../utils/GraphQL';
import BrazillianCurrency from '../../../../../utils/NationalCurrency';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  button: {
    margin: theme.spacing(1),
  },
}));
const AwaitingEstimateRevision = (props) => {
  const { order } = props;
  const { enqueueSnackbar } = useSnackbar();
  const lastEstimate = head(order.estimates);
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [loadingApprove, setLoadingApprove] = React.useState(false);
  const [loadingReject, setLoadingReject] = React.useState(false);
  const [defectsAvailable, setDefectsAvailable] = React.useState({});
  const [updateRevision, { loading }] = useMutation(repairDecisionCustomer);
  React.useEffect(() => {
    const groupedRevisionsByDefect = groupBy(lastEstimate.revisions, (v) => v.defect.defect_name);
    setDefectsAvailable(groupedRevisionsByDefect);
  }, []);
  const handleNext = (response, defect) => () => {
    response ? setLoadingApprove(true) : setLoadingReject(true);
    const promises = defectsAvailable[defect].map((r) => updateRevision({
      variables:
      { revisionId: r.id, estimate_status: response ? 'APPROVED' : 'REJECTED' },
    }));
    Promise.all(promises)
      .then(() => {
        setDefectsAvailable((currentState) => (
          {
            ...currentState,
            [defect]: currentState[defect].map((r) => ({ ...r, estimate_status: response ? 'approved' : 'rejected' })),
          }));
        setActiveStep((currentStep) => currentStep + 1);
        response ? setLoadingApprove(false) : setLoadingReject(false);
      })
      .catch((_e) => enqueueSnackbar('Houve um erro ao salvar os dados.', { variant: 'error' }));
  };
  const handleBack = () => {
    setActiveStep((currentStep) => currentStep - 1);
  };
  const getStepContent = (step) => {
    const defectStep = sortBy(Object.values(defectsAvailable), (item) => item[0].phone_status)[step];
    const defectObj = get(head(defectStep), 'defect');
    return (
      <>
        <Box>
          <Grid container direction="column" spacing={3}>
            <Grid item>
              <Alert severity="info">
                {head(defectStep).phone_status === 'APPOINTED' && 'Este defeito foi apontado por você durante a criação da ordem.'}
                {head(defectStep).phone_status === 'OPTIONAL' && 'Este defeito não foi apontado por você durante a criação da ordem mas o encontramos durante a revisão do aparelho.'}
              </Alert>
            </Grid>
            <Grid item>
              <Typography variant="h6" align="center">
                {`Para o defeito ${defectObj.lay_name || defectObj.defect_name} será necessário utilizar as peças ${flatMap(defectStep, (v) => v.parts.part_name).join(', ').replace(/, ([^,]*)$/, ' e $1')}.`}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h6" align="center">
                {`O custo para reparo deste defeito será de ${
                  BrazillianCurrency(defectStep.map((r) => r.total_price / 100)
                    .reduce((a, v) => a + v))
                    .add(
                      BrazillianCurrency(lastEstimate.labor / 100).distribute(Object.values(defectsAvailable).length)[step]
                    ).format()}.`}
              </Typography>
            </Grid>
            <Grid item>
              <Grid container direction="row" justify="center">
                <Grid item xs="auto">
                  <Button disabled={loading} onClick={handleNext(false, defectObj.defect_name)} className={classes.button} color="secondary" variant="contained" startIcon={loadingReject ? <CircularProgress size={20} /> : <ThumbDown />}>Rejeitar</Button>
                </Grid>
                <Grid item xs="auto">
                  <Button disabled={loading} onClick={handleNext(true, defectObj.defect_name)} className={classes.button} color="primary" variant="contained" startIcon={loadingApprove ? <CircularProgress size={20} /> : <ThumbUp />}>Aprovar</Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </>
    );
  };
  const getRepairTotalPrice = () => {
    const prices = Object.values(defectsAvailable)
      .map((r) => r.filter((f) => f.estimate_status === 'approved').map((v) => v.total_price))
      .flat();
    if (prices.length < 1) return false;
    return prices.reduce((a, v) => a + v) + parseInt(lastEstimate.labor, 10);
  };
  const finishedContent = () => (
    <>
      <Typography variant="h6" align="center">Deseja confirmar a revisão de orçamento?</Typography>
      <Typography variant="body2" align="center">Esta ação não poderá ser desfeita. Ao confirmar, o aparelho será enviado para reparação de acordo com os defeitos que você aprovou.</Typography>
      <Typography variant="subtitle1" align="center">
        {`O reparo do seu aparelho custará ${(getRepairTotalPrice() / 100).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}.`}
      </Typography>
    </>
  );
  const [confirmEstimate, {
    loading: confirmLoading, error: confirmError, called: confirmCalled,
  }] = useMutation(confirmEstimateApproval);
  const handleConfirmEstimate = () => {
    const decision = Object.values(defectsAvailable).flatMap((r) => r.filter((s) => {
      if (s.estimate_status === 'approved') return true;
      return false;
    }));
    confirmEstimate({
      variables: { orderId: order.id, estimate_decision: !isEmpty(decision) ? 'ACCEPTED' : 'REJECTED' },
    }).catch(
      () => enqueueSnackbar('Houve um erro ao salvar os dados.', { variant: 'error' }),
    );
  };
  if (isEmpty(defectsAvailable) || lastEstimate.revisions.length === 0) return 'Não há orçamento a ser aprovado.';
  return (
    <div className={classes.root}>
      {Object.values(defectsAvailable).length === activeStep ? finishedContent() : getStepContent(activeStep)}
      <Grid container direction="row" justify="center" spacing={2}>
        {activeStep > 0 && !confirmCalled && (
        <Grid item>
          <Button disabled={loading} variant="outlined" onClick={handleBack}>Voltar</Button>
        </Grid>
        )}
        {
          Object.values(defectsAvailable).length === activeStep && (
            <Grid item>
              <Button disabled={confirmLoading} onClick={handleConfirmEstimate} variant="contained" color="primary" startIcon={confirmLoading ? <CircularProgress size={20} /> : <Check />}>Confirmar</Button>
            </Grid>
          )
        }
      </Grid>
      {confirmCalled && !confirmError && (
      <Alert severity="success">
        A sua revisão de orçamento foi salva com sucesso!
      </Alert>
      )}
    </div>
  );
};

export default AwaitingEstimateRevision;
