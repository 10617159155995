import React from 'react';
import * as P from 'prop-types';
import { Box, makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';
import BrazillianCurrency from '@utils/NationalCurrency';

const useStyle = makeStyles({
  budget: {
    fontWeight: 500,
    wordSpacing: '-4px',
  },
  budgetCents: {
    fontWeight: 500,
    wordSpacing: '-4px',
    marginTop: 8,
    marginLeft: 2,
  },
});

export default function LabelBudget({ budget }) {
  const totalBudget = BrazillianCurrency(budget / 100);
  const partBudget = totalBudget.divide(10);
  const classes = useStyle();

  return (
    <Box
      display="flex"
      maxWidth={260}
      mx="auto"
      justifyContent="center"
      flexDirection="column"
    >
      <Box textAlign="center">
        <Typography variant="body1">
          Seu reparo a partir de
          { ' ' }
          <Box fontWeight="500" component="span">10x</Box>
          { ' ' }
          de
        </Typography>
      </Box>
      <Box display="flex" justifyContent="center">
        <Typography variant="h2" component="span">
          <div className={classes.budget}>
            { `R$ ${partBudget.dollars()}` }
          </div>
        </Typography>
        <Typography variant="body1" component="span">
          <div className={clsx(classes.budget, classes.budgetCents)}>
            { String(partBudget.cents()).padEnd(2, '0') }
          </div>
        </Typography>
      </Box>
      <Box textAlign="right" px={4}>
        <Typography variant="caption" color="textSecondary">
          ou
          { ' ' }
          { totalBudget.format() }
          { ' ' }
          à vista
        </Typography>
      </Box>
    </Box>
  );
}

LabelBudget.propTypes = {
  budget: P.number.isRequired,
};
