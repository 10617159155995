import { connect } from 'react-redux';

const mapStateToProps = ({ NewOrderReducer, PartnerStoreReducer }) => ({
  $state: {
    orderData: { ...NewOrderReducer.orderData, conditions: NewOrderReducer.device.conditions },
    customer: {
      ...NewOrderReducer.customer,
      billing: NewOrderReducer.billingAddress,
    },
    payment: NewOrderReducer.payment,
    partner: {
      ...PartnerStoreReducer.branch,
    },
  },
});

export default connect(mapStateToProps);
