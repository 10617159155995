import * as P from 'prop-types';

export const AddressProps = {
  zipCode: P.string,
  state: P.string,
  city: P.string,
  neighborhood: P.string,
  street: P.string,
  number: P.string,
  complement: P.string,
};

export const EmployeeProps = {
  id: P.string,
  name: P.string,
};
export const BranchProps = {
  id: P.string,
  branch_name: P.string,
  branch_slug: P.string,
  branch_logo: P.string,
};

export const PropTypes = {
  id: P.string,
  name: P.string,
  address: P.shape(AddressProps),
  employee: P.shape(EmployeeProps),
  branch: P.shape(BranchProps),
};

export default PropTypes;
