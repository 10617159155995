import React from 'react';
import * as P from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { getLabelAddress } from '../Common';

export default function AddressShortName({ data }) {
  const labelAddress = getLabelAddress(data);
  return (
    <Typography variant="caption" component="div">
      {labelAddress ? `End.: ${labelAddress}` : '-'}
    </Typography>
  );
}

AddressShortName.propTypes = {
  data: P.shape({
    zipCode: P.string,
    street: P.string,
    number: P.string,
    neighborhood: P.string,
    city: P.string,
    state: P.string,
  }).isRequired,
};
