import React from 'react';
import {
  Box, Step, Stepper, StepLabel, Typography,
} from '@material-ui/core';
// REDUX
import { useSelector } from 'react-redux';
import StepConnector from '../StepConnector';
import StepIcon from '../StepIcon';
import steps from '../../../../utils/steps';
// STYLES
import makeStyles from './style';

const mapActiveStepToIconStep = [0, 1, 2, 3];
function StepperHeader() {
  const classes = makeStyles();
  const customerData = useSelector((state) => state.NewRepairReducer);
  const { activeStep } = customerData;

  return (
    <Stepper
      alternativeLabel
      activeStep={mapActiveStepToIconStep[activeStep]}
      connector={<StepConnector />}
      className={classes.stepper}
    >
      {steps.map((label) => (
        <Step key={label}>
          <StepLabel StepIconComponent={StepIcon}>
            <Typography component={Box} variant="caption" m={0}>
              {label}
            </Typography>
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  );
}

export default StepperHeader;
