import React from 'react';

import { Header, List } from 'semantic-ui-react';

const Contato = () => (
  <div className="footer-institucional">
    <Header as="h3" content="Institucional" />
    <List animated verticalAlign="middle">
    <List animated verticalAlign="middle">
  </List>
        <List.Item
            content={(
            <a
                href="https://www.trocafone.com.br/politica-de-cookies"
                rel="noreferrer"
                target="_blank"
              >
                Política de cookies
              </a>
            )}
          />
        <List.Item
          content={(
                <a
                    href="https://www.trocafone.com.br/politica-de-privacidade"
                    rel="noreferrer"
                    target="_blank"
                  >
                    Política de Privacidade
                  </a>
                )}
        />
        <List.Item
            content={(
                <a
                    href="https://www.trocafone.com.br/termos-e-condicoes"
                    rel="noreferrer"
                    target="_blank"
                >
                Termos e condições
              </a>
            )}
        />
    </List>
    
  </div>
);

export default Contato;
