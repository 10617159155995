import * as Yup from 'yup';

Yup.addMethod(Yup.string, 'cpf', function checkCpf() {
  return this.test({
    name: 'name',
    message: 'CPF inválido',
    test: (cpfWithNotation) => {
      const cpf = String(cpfWithNotation).replace(/[^0-9]/gm, '');
      let numbers;
      let digits;
      let sum;
      let result;
      let equalsDigits = 1;

      if (cpf.length < 11) return false;

      for (let i = 0; i < cpf.length - 1; i += 1) {
        if (cpf.charAt(i) !== cpf.charAt(i + 1)) {
          equalsDigits = 0;
          break;
        }
      }

      if (!equalsDigits) {
        numbers = cpf.substring(0, 9);
        digits = cpf.substring(9);
        sum = 0;

        for (let i = 10; i > 1; i -= 1) {
          sum += numbers.charAt(10 - i) * i;
        }

        result = sum % 11 < 2 ? 0 : 11 - (sum % 11);

        if (String(result) !== digits.charAt(0)) return false;

        numbers = cpf.substring(0, 10);
        sum = 0;

        for (let i = 11; i > 1; i -= 1) sum += numbers.charAt(11 - i) * i;

        result = sum % 11 < 2 ? 0 : 11 - (sum % 11);

        if (String(result) !== digits.charAt(1)) return false;

        return true;
      }
      return false;
    },
  });
});

export default Yup
  .string()
  .trim()
  .cpf()
  .required('Este campo é obrigatório');
