import React from 'react';
import ReactDOM from 'react-dom';
import { ApolloProvider } from '@apollo/react-hooks';
import './layout/css/Main.css';
// REDUX
import thunkMiddleware from 'redux-thunk';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, combineReducers } from 'redux';

import { client } from './Apollo';
// REPARAFONE REDUX
import { AuthReducer } from './redux/reducers/AuthReducer';
import AllStoresReducer from './redux/reducers/AllStoresReducer';
import NewOrderReducer from './redux/reducers/NewOrderReducer';
import NewRepairReducer from './redux/reducers/NewRepairReducer';
import VivoStoreReducer from './redux/reducers/VivoStoreReducer';
import PartnerStoreReducer from './redux/reducers/PartnerStoreReducer';
import MapBoxReducer from './redux/reducers/MapBoxReducer';

import App from './App';
// import * as serviceWorker from './serviceWorker';

const reduxapp = combineReducers({
  AuthReducer,
  NewOrderReducer,
  VivoStoreReducer,
  PartnerStoreReducer,
  AllStoresReducer,
  NewRepairReducer,
  MapBoxReducer,
});

const createStoreWithMiddleware = applyMiddleware(thunkMiddleware)(createStore);

const store = createStoreWithMiddleware(
  reduxapp,
  // eslint-disable-next-line no-underscore-dangle
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ApolloProvider client={client}>
        <App />
      </ApolloProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
