import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles(({ breakpoints }) => ({
  imgBannerCarrefour: {
    width: '100%',
    maxWidth: '100%',
    height: 'auto',
    display: 'block',
  },
  bannerCarrefour: {
    width: '100%',
    height: 'auto',
    marginTop: '65px',
    [breakpoints.down('xs')]: {
      marginTop: '56px',
    },
  },
}));

export default styles;
