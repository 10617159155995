import React from 'react';
import { Box, Divider } from '@material-ui/core';
// COMPONENTS
import LabelItem from './Components/LabelItem';
import LabelTotal from './Components/LabelTotal';
import LabelCoupon from './Components/LabelCoupon';
import Coupon from './Components/Coupon';
// FUNCTIONS
import connect from './ConnectComponentRedux';
import { propTypes, defaultProps } from './PropTypes';

function BoxCheckout({ $state }) {
  const {
    taxCollect,
    taxDelivery,
    budgetRepair,
    totalBudgetRepair = budgetRepair,
  } = $state.transactionAmount;
  const { coupons } = $state;

  return (
    <Box py={0.85}>
      <Divider />
      <Box mx="auto" width="100%" px={1} py={1} maxWidth={400}>
        <LabelItem title="Valor do reparo" value={budgetRepair / 100} />
        <LabelItem title="Frete da coleta" value={taxCollect / 100} />
        <LabelItem title="Frete da entrega" value={taxDelivery / 100} />
        <Box py={0.4}>
          <Divider />
        </Box>
        <LabelTotal
          subtotal
          value={(taxCollect + taxDelivery + budgetRepair) / 100}
        />
        <LabelCoupon value={coupons.length > 0 ? coupons[0].discount / 100 : 'Sem Cupom'} />
        <LabelTotal
          value={
            totalBudgetRepair
              ? (taxCollect + taxDelivery + totalBudgetRepair) / 100
              : (taxCollect + taxDelivery + budgetRepair) / 100
          }
        />
        <Coupon />
      </Box>
      <Divider />
    </Box>
  );
}

BoxCheckout.propTypes = propTypes;
BoxCheckout.defaultProps = defaultProps;

export default connect(BoxCheckout);
