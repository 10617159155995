import React from 'react';
import {
  Typography, Grid, makeStyles,
} from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import ModalStepper from './ModalStepper';
import { mapStatusSlugToComponent } from './MapComponentsToStatusSlug';

const useStyles = makeStyles((theme) => ({
  coloredPaper: {
    background: theme.palette.secondary.main,
  },
  alertClass: {
    '& .MuiAlert-message': {
      width: '100%',
    },
    padding: '16px 30px',
    marginBottom: '50px',
  },
}));
const StatusPanel = (props) => {
  const classes = useStyles();
  const { slug, order } = props;
  const mappedStatus = mapStatusSlugToComponent[slug];
  if (mappedStatus.shouldShow && !mappedStatus.shouldShow(order)) return null;
  return (
    <>
      <Alert icon={false} className={classes.alertClass} style={{ backgroundColor: '#ffa84a' }} severity="warning">
        <AlertTitle style={{ textAlign: 'center' }}>Seu aparelho está te aguardando!</AlertTitle>
        <Grid container direction="column" justify="center" alignItems="center" alignContent="center" spacing={3}>
          <Grid item xs>
            <Typography variant="body1" align="center">{mappedStatus.statusText}</Typography>
          </Grid>
          <Grid item xs>
            {
              mappedStatus.isModal ? <ModalStepper status={slug} order={order} />
                : React.createElement(mappedStatus.component, { order })
              }
          </Grid>
        </Grid>
      </Alert>
    </>
  );
};
// fazer a amostragem dos status com ações, tipo aprovar orçamento, fazer apgamento etc
export default StatusPanel;
