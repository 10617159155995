import React from 'react';
// MATERIAL-UI
import {
  Box, Button, Grid, CircularProgress,
} from '@material-ui/core';
// MATERIAL ICONS
import Icon from '@material-ui/core/Icon';
// REDUX
import { useSelector } from 'react-redux';
// CLSX
import clsx from 'clsx';
// PROP-TYPES
import {
  StepperButtonsPropTypes,
  StepperButtonsDefaultProp,
  SUBMITTING,
} from './PropTypes';
// STYLES
import styles from './style';

const LoadingCircularProgress = () => <CircularProgress size="1.2rem" />;

function StepperButtons(props) {
  const {
    backStep, nextStep, nextText, isSubmitting,
  } = props;
  const classes = styles();
  const blankFunc = () => null;
  const customerData = useSelector((state) => state.NewRepairReducer);
  const { activeStep } = customerData;
  const partnerData = useSelector((state) => state.PartnerStoreReducer);
  const { branch } = partnerData;
  const { branch_name } = branch;

  return (
    <Box className={classes.stepperButtons}>
      <Grid container position="relative">
        {activeStep > 0 && (
        <Button
          id="button-back"
          className={clsx(classes.backButton, {
            [classes.backButtonCarrefour]: branch_name === 'Carrefour',
            [classes.backButtonReparofone]: branch_name === 'Limelocker',
          })}
          variant="outlined"
          onClick={typeof backStep === 'function' ? backStep : blankFunc}
          disabled={!(typeof backStep === 'function') || !!isSubmitting}
          startIcon={
            isSubmitting === SUBMITTING.BACK
              ? <LoadingCircularProgress />
              : <Icon>arrow_back</Icon>
          }
        >
          Voltar
        </Button>
        )}
        <Button
          id="button-next"
          className={clsx(classes.nextButton, {
            [classes.nextButtonCarrefour]: branch_name === 'Carrefour',
            [classes.nextButtonReparofone]: branch_name === 'Limelocker',
          })}
          color="primary"
          variant="contained"
          onClick={typeof nextStep === 'function' ? nextStep : blankFunc}
          disabled={!(typeof nextStep === 'function') || !!isSubmitting}
          endIcon={
            isSubmitting === SUBMITTING.NEXT
              ? <LoadingCircularProgress />
              : <Icon>arrow_forward</Icon>
          }
        >
          { nextText }
        </Button>
      </Grid>
    </Box>
  );
}

StepperButtons.propTypes = StepperButtonsPropTypes;
StepperButtons.defaultProps = StepperButtonsDefaultProp;
StepperButtons.SUBMITTING = SUBMITTING;

export default StepperButtons;
