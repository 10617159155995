import * as P from 'prop-types';
import { DefectsProps } from '@redux/reducers/NewOrderReducer/PropTypes';

const propTypes = {
  defects: P.arrayOf(P.shape(DefectsProps)),
  hookCalcBudget: P.func.isRequired,
  selectedDefects: P.arrayOf(P.shape(DefectsProps)),
  deliveryZipcode: P.string.isRequired,
  collectZipcode: P.string.isRequired,
};

export default {
  propTypes,
  defaultProps: {},
};
