import React from 'react';
import {
  Box,
  Step, StepContent, StepLabel, Stepper, Typography,
} from '@material-ui/core';
// COMPONENTS
import ButtonDeviceStep from '../ButtonDeviceStep';
import LabelModelSelected from '../LabelModelSelected';
import LoadingContent from '../../../LoadingContent';
// FUNCTIONS
import {
  StepperSelectDeviceDefaultProps, StepperSelectDevicePropTypes,
} from './PropTypes';
import ConnectComponentRedux from './ConnectComponentRedux';
import getStepContent from './getStepContent';
import steps from './steps';
import useStyle from './style';

function StepperSelectDevice({
  $state, $actions, step, setStep,
}) {
  const [isLoading, setLoading] = React.useState(false);
  const {
    device: {
      model = {}, brand = '', color = '',
    },
  } = $state;
  const classes = useStyle();

  const advanceSteps = (num = 1) => setTimeout(() => setStep(step + num), 0);
  const returnSteps = (num = 1) => advanceSteps(num * -1);

  const stepperData = {
    /** Brand-step */
    brand: {
      handleNext: (value) => {
        $actions.setDevice({ brand: value });
        advanceSteps();
      },
      data: {
        brand,
      },
    },

    /** Model-Step */
    model: {
      handleNext: (value) => {
        $actions.setDevice({ model: value });
        advanceSteps();
      },
      handleBack: () => {
        $actions.setDevice({ brand: undefined });
        returnSteps();
      },
      data: {
        brand,
        model,
      },
    },

    /** Color-Step */
    color: {
      handleNext: (value) => {
        $actions.setDevice({ color: value });
        setLoading(true);
        $actions.searchProduct()
          .then(advanceSteps)
          .finally(() => setLoading(false));
      },
      handleBack: () => {
        $actions.setDevice({ color: undefined });
        returnSteps();
      },
      data: {
        model,
      },
    },
  };

  const getStepHandleClick = (index) => {
    if (index < step) {
      return () => setStep(index);
    }
    return () => null;
  };

  React.useEffect(() => {
    if (step < 3 && $state.product?.name) {
      $actions.resetProduct();
    }
  }, [step]);

  return (
    <Box>
      <Typography variant="h6">Selecione o aparelho</Typography>
      <LoadingContent isLoading={isLoading}>
        <Box py={1}>
          <Stepper activeStep={step} orientation="vertical">
            {steps.map(({ slug, label, concludedLabel }, index) => (
              <Step key={label}>
                <StepLabel>
                  <ButtonDeviceStep
                    handleClick={getStepHandleClick(index)}
                    isActive={index < step}
                    label={index < step ? concludedLabel($state.device) : label}
                  />
                </StepLabel>
                <StepContent className={classes.stepperContent}>
                  {getStepContent(
                    index,
                    stepperData[slug]?.data,
                    stepperData[slug]?.handleNext,
                    stepperData[slug]?.handleBack,
                  )}
                </StepContent>
              </Step>
            ))}
          </Stepper>
          <Box>
            {
              step < 3 || !$state.product?.name ? (
                <Typography variant="body2">
                  <i>Selecione o dispositivo para prosseguir</i>
                </Typography>
              ) : (
                <LabelModelSelected model={`${brand} ${model.name} ${color}`.trim()} />
              )
            }
          </Box>
        </Box>
      </LoadingContent>
    </Box>
  );
}

StepperSelectDevice.propTypes = StepperSelectDevicePropTypes;
StepperSelectDevice.defaultProps = StepperSelectDeviceDefaultProps;

export default ConnectComponentRedux(StepperSelectDevice);
