import { makeStyles } from '@material-ui/core/styles';

const useStyle = makeStyles(() => ({
  inputIcon: {
    fontSize: '1.2rem',
    color: '#6c687d',
    textAlign: 'center',
  },
  textField: {
    margin: '10px 0',
  },
}));

export default useStyle;
