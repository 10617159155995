/* eslint-disable import/prefer-default-export */
import { createAction } from '@reduxjs/toolkit';
import { NewOrderTypes } from '../../reducers/NewOrderReducer';

export const setCustomer = createAction(NewOrderTypes.SET_CUSTOMER_DATA);

export const setCreditCard = createAction(NewOrderTypes.SET_CREDIT_CARD);

export const setLaterPayment = createAction(NewOrderTypes.SET_LATER_PAYMENT);

export const setAfterRepairPayment = createAction(NewOrderTypes.SET_AFTER_REPAIR_PAYMENT);

export const setCollect = createAction(NewOrderTypes.SET_CUSTOMER_COLLECT);

export const setDelivery = createAction(NewOrderTypes.SET_CUSTOMER_DELIVERY);

export const setDevice = createAction(NewOrderTypes.SET_DEVICE);

export const setProduct = createAction(NewOrderTypes.SET_PRODUCT);

export const setStep = createAction(NewOrderTypes.SET_STEP);

export const resetProduct = createAction(NewOrderTypes.RESET_PRODUCT);

export const setSelectedDefect = createAction(NewOrderTypes.SET_SELECTED_DEFECTS);

export const resetSelectedDefect = createAction(NewOrderTypes.RESET_SELECTED_DEFECTS);

export const setBillingAddress = createAction(NewOrderTypes.SET_BILLING_ADDRESS);

export const setTransactionAmount = createAction(NewOrderTypes.SET_TRANSACTION_AMOUNT);

export const setOrderData = createAction(NewOrderTypes.SET_ORDER_DATA);

export const setTradeInUser = createAction(NewOrderTypes.SET_TRADE_IN_USER);

export const setCouponData = createAction(NewOrderTypes.ADD_COUPON);

export const removeCouponData = createAction(NewOrderTypes.REMOVE_COUPON);

export const updateCouponData = createAction(NewOrderTypes.UPDATE_COUPON);

export const resetOrder = createAction(NewOrderTypes.RESET_ORDER);

export const setLead = createAction(NewOrderTypes.SET_LEAD);

export const setDeliveryData = createAction(NewOrderTypes.DELIVERY_DATA_INSERT);

export const setCollectModalities = createAction(NewOrderTypes.SET_COLLECT_MODALITIES);

export const setFluxType = createAction(NewOrderTypes.SET_FLUX_TYPE);

export const setWarranty = createAction(NewOrderTypes.SET_WARRANTY);

export const setAllRefusedBudgetReasons = createAction(NewOrderTypes.REFUSED_BUDGET_REASONS);

export const setRefusedBudgetReason = createAction(NewOrderTypes.SET_REFUSED_BUDGET_REASON);
