import * as P from 'prop-types';

export const propTypes = {
  $state: P.shape({
    step: P.number.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    orderData: P.any,
    customer: P.any,
  }),
  $actions: P.shape({
    setStep: P.func.isRequired,
    setCollectModalities: P.func.isRequired,
    setDeliveryData: P.func.isRequired,
  }),
};

export const defaultProps = {

};

export default {
  propTypes, defaultProps,
};
