import React from 'react';
// COMPONENTS
import {
  Grid, Button, CircularProgress,
} from '@material-ui/core';
// FORMIK && YUP
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';
// REDUX
import { useSelector } from 'react-redux';
// REACT ROUTER
import { useHistory } from 'react-router-dom';
// GRAPHQL
import requestTokenEmail from '../graphql/schema/RequestChangeEmail';

const ChangeEmailSchema = Yup.object().shape({
  customer_email: Yup.string()
    .email('Digite um e-mail válido!')
    .required('Por favor, insira seu novo E-mail!'),
  customer_email_confirmation: Yup.string()
    .email('Digite um e-mail válido!')
    .oneOf(
      [Yup.ref('customer_email'), null],
      'Os dois e-mails digitados devem ser iguais!',
    )
    .required('Por favor, confirme seu novo E-mail!'),
});

const ChangeEmail = ({ onClose, handleAlert, setSubmittingForm }) => {
  const history = useHistory();
  const customerData = useSelector((state) => state.NewRepairReducer);
  const { customer } = customerData;
  const { email } = customer;
  const onSubmitForm = ({ customer_email }, { setSubmitting }) => {
    setSubmittingForm(true);
    if (customer_email === customer.email) {
      handleAlert('Oops, você já utiliza esse e-mail!', 'info', true, 3000);
      setSubmittingForm(false);
      return setSubmitting(false);
    }
    return requestTokenEmail({ email, newEmail: customer_email })
      .then((r) => {
        if (!r.errors) {
          onClose();
          history.push(`/customer/change/email/${r.data.CustomerRequestChangeEmail.new_email_bcrypt}`, { alertSuccessToken: true });
        }
        if (r.errors) handleAlert('Esse e-mail já está cadastrado!', 'warning', true, 3000);
      })
      .catch(() => handleAlert('Houve um erro ao atualizar as informações!', 'error', true, 3000))
      .finally(() => { setSubmitting(false); setSubmittingForm(false); });
  };
  return (
    <>
      <Formik
        initialValues={{
          customer_email: '',
          customer_email_confirmation: '',
        }}
        validationSchema={ChangeEmailSchema}
        onSubmit={onSubmitForm}
      >
        {({
          errors, touched, isSubmitting, dirty,
        }) => (
          <Form>
            <Grid container direction="column" spacing={4}>
              <Grid item xs={12}>
                <Field
                  type="email"
                  disabled={isSubmitting}
                  component={TextField}
                  fullWidth
                  label="Novo E-mail"
                  name="customer_email"
                  error={!!touched.customer_email && !!errors.customer_email}
                  helperText={touched.customer_email && errors.customer_email}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  type="email"
                  disabled={isSubmitting}
                  component={TextField}
                  fullWidth
                  label="Confirmar E-mail"
                  name="customer_email_confirmation"
                  error={
                    !!touched.customer_email_confirmation
                    && !!errors.customer_email_confirmation
                  }
                  helperText={
                    touched.customer_email_confirmation
                    && errors.customer_email_confirmation
                  }
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  disabled={isSubmitting || !dirty}
                  type="submit"
                  color="secondary"
                  variant="contained"
                  fullWidth
                  title="Confirmar"
                  endIcon={isSubmitting && <CircularProgress size="1.2rem" />}
                >
                  Confirmar
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default ChangeEmail;
