import React from 'react';

import { Header } from 'semantic-ui-react';

const Sobre = () => (
  <div className="sobre-reparofone">
    <Header as="h3" content="Reparofone" />

    <p className="texto-sobre">
      Não atendemos neste endereço. Atendemos e comercializamos reparos
      exclusivamente por meio do nosso site.
    </p>
    <p className="texto-cnpj">CNPJ: 20.553.221/0001-02</p>
  </div>
);

export default Sobre;
