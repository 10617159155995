import React from 'react';
import {
  Box, Grid, IconButton, Tooltip,
} from '@material-ui/core';
import { Add, PlaylistAddCheck } from '@material-ui/icons';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { Link } from 'react-router-dom';
import logo from '@images/trocafone-reparos -white-desktop.png';
import style from './style';

export default function HeaderPartner() {
  const classes = style();
  const ordersPageUrl = '/partners/servicerepair/orders';
  const newRepairUrl = '/partners/servicerepair';
  const exitApp = '/partners/logout';
  return (
    <Box position="fixed" width="100%" zIndex={9}>
      <Box className={classes.navbar} m={0} py={2} px={3}>
        <Grid container direction="row" justify="space-between" spacing={2}>
          <Grid item xs={3}>
            <Box alignItems="flex-end" display="flex" height="100%">
              <a href={newRepairUrl} style={{ display: 'flex' }}>
                <img src={logo} alt="logo" style={{ height: '3vh' }} title="Voltar ao ínicio" />
              </a>
            </Box>
          </Grid>
          <Grid item style={{ position: 'relative' }}>
            <Grid container direction="row">
              <Grid item>
                <Tooltip title="Sair">
                  <IconButton href={exitApp} style={{ position: 'absolute', right: '1px', padding: 0 }}>
                    <ExitToAppIcon color="secondary" />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item>
                <Tooltip title="Criar uma ordem de serviço">
                  <IconButton href={newRepairUrl} style={{ position: 'absolute', right: '3rem', padding: 0 }}>
                    <Add color="secondary" />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item>
                <Tooltip title="Ver ordens de serviço">
                  <IconButton href={ordersPageUrl} style={{ position: 'absolute', right: '6rem', padding: 0 }}>
                    <PlaylistAddCheck color="secondary" />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
