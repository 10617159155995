import { makeStyles } from '@material-ui/core/styles';

const useStyle = makeStyles((theme) => ({
  root: {
    margin: '20px 0',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    maxWidth: '450px',
  },
  title: {
    fontSize: '1.5rem',
  },
  textField: {
    margin: '10px 0',
  },
  skeleton: {
    maxWidth: '400px',
    minWidth: '400px',
  },
}));

export default useStyle;
