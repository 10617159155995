import React from 'react';
import * as P from 'prop-types';
import BrazillianCurrency from '@/utils/NationalCurrency';
import useStyle from '../Utils/Style';

export default function LabelTotal({
  value, subtotal,
}) {
  const valueParsed = BrazillianCurrency(value);
  const classes = useStyle();

  return (
    <>
      {subtotal ? (
        <div className={classes.containerLabel}>
          <div className={classes.labelTotal}>
            <div className={classes.containerTitle}>Subtotal</div>
            <div className={classes.containerValue}>{valueParsed.format()}</div>
          </div>
        </div>
      ) : (
        <div className={classes.containerLabel}>
          <div className={classes.labelTotal}>
            <div className={classes.containerTitle}>Total</div>
            <div className={classes.containerValue}>{valueParsed.format()}</div>
          </div>
        </div>
      )}
    </>
  );
}

LabelTotal.propTypes = {
  value: P.number.isRequired,
};
