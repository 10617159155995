import React from 'react';
// MATERIAL-UI
import { Box, Divider } from '@material-ui/core';
// COMPONENTS
import Customer from './components/Customer';
import Addresses from './components/Addresses';
import Order from './components/Order';
import Revision from './components/Revision';
import Repair from './components/Repair';
import Payment from './components/Payment';
// PROP-TYPES
import PropTypes from './PropTypes';
// STYLES
import styles from './styles';

const OrderDetails = ({ order }) => {
  const classes = styles();
  return (
    <Box className={classes.box}>
      <Customer customer={order.customer} />
      <Divider className={classes.divider} variant="middle" />
      <Addresses addresses={order.addresses} />
      <Divider className={classes.divider} variant="middle" />
      <Order order={order} />
      <Divider className={classes.divider} variant="middle" />
      <Revision order={order} />
      <Divider className={classes.divider} variant="middle" />
      <Repair order={order} />
      <Divider className={classes.divider} variant="middle" />
      <Payment order={order} />
    </Box>
  );
};

export default OrderDetails;

OrderDetails.propTypes = PropTypes.propTypes;
OrderDetails.defaultProps = PropTypes.defaultProps;
