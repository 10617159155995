/* eslint-disable import/prefer-default-export */
import { createAction } from '@reduxjs/toolkit';
import { NewRepairTypes } from '../../reducers/NewRepairReducer';

export const setCustomer = createAction(NewRepairTypes.SET_CUSTOMER_DATA);

export const setCreditCard = createAction(NewRepairTypes.SET_CREDIT_CARD);

export const setLaterPayment = createAction(NewRepairTypes.SET_LATER_PAYMENT);

export const setCollect = createAction(NewRepairTypes.SET_CUSTOMER_COLLECT);

export const setDelivery = createAction(NewRepairTypes.SET_CUSTOMER_DELIVERY);

export const setDevice = createAction(NewRepairTypes.SET_DEVICE);

export const setProduct = createAction(NewRepairTypes.SET_PRODUCT);

export const setStep = createAction(NewRepairTypes.SET_STEP);

export const resetProduct = createAction(NewRepairTypes.RESET_PRODUCT);

export const setSelectedDefect = createAction(NewRepairTypes.SET_SELECTED_DEFECTS);

export const setBillingAddress = createAction(NewRepairTypes.SET_BILLING_ADDRESS);

export const setTransactionAmount = createAction(NewRepairTypes.SET_TRANSACTION_AMOUNT);

export const setOrderData = createAction(NewRepairTypes.SET_ORDER_DATA);

export const setCouponData = createAction(NewRepairTypes.ADD_COUPON);

export const removeCouponData = createAction(NewRepairTypes.REMOVE_COUPON);

export const updateCouponData = createAction(NewRepairTypes.UPDATE_COUPON);

export const resetOrder = createAction(NewRepairTypes.RESET_ORDER);

export const setLead = createAction(NewRepairTypes.SET_LEAD);

export const setOriginUser = createAction(NewRepairTypes.SET_ORIGIN_USER);

export const setOriginStore = createAction(NewRepairTypes.SET_ORIGIN_STORE);

export const setCollectData = createAction(NewRepairTypes.COLLECT_DATA_INSERT);

export const setCollectModality = createAction(NewRepairTypes.CHOOSE_COLLECT_MODALITY);

export const setCollectModalities = createAction(NewRepairTypes.SET_COLLECT_MODALITIES);

export const resetCollectModalities = createAction(NewRepairTypes.RESET_COLLECT_MODALITIES);

export const setDeliveryModalities = createAction(NewRepairTypes.SET_DELIVERY_MODALITIES);

export const resetDeliveryModalities = createAction(NewRepairTypes.RESET_DELIVERY_MODALITIES);

export const setDeliveryData = createAction(NewRepairTypes.DELIVERY_DATA_INSERT);

export const setAllRefusedBudgetReasons = createAction(NewRepairTypes.REFUSED_BUDGET_REASONS);

export const setRefusedBudgetReason = createAction(NewRepairTypes.SET_REFUSED_BUDGET_REASON);
