import React from 'react';
import clsx from 'clsx';
import {
  Box, Button, Popover, Typography,
} from '@material-ui/core';
import CreateIcon from '@material-ui/icons/Create';
import { ButtonDeviceStepPropTypes } from './PropTypes';
import useStyles from './style';

export default function ButtonDeviceStep({
  handleClick, isActive, label,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const classes = useStyles();
  const open = ({ currentTarget }) => isActive && setAnchorEl(currentTarget);
  const handleClose = () => setAnchorEl(null);
  const handleConfirm = () => { setAnchorEl(null); handleClick(); };

  return (
    <div>
      <button
        type="button"
        onClick={open}
        className={clsx(classes.buttonStep, { active: isActive })}
      >
        <span>{ label }</span>
        {
          isActive
            ? (
              <span>
                <CreateIcon className={classes.icon} />
              </span>
            )
            : null
        }
      </button>
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Box p={1}>
          <Typography variant="caption">Deseja voltar este passo? Não é possível desfazer</Typography>
          <Box display="flex" justifyContent="flex-end">
            <Button onClick={handleConfirm} size="small">Sim</Button>
            <Button onClick={handleClose} size="small">Não</Button>
          </Box>
        </Box>
      </Popover>
    </div>
  );
}

ButtonDeviceStep.propTypes = ButtonDeviceStepPropTypes;
