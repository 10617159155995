import gql from 'graphql-tag';

export const GetCustomer = gql`
  query{
    MeCustomer{
      id
      customer_first_name
      customer_last_name
      customer_email
      customer_cpf
      customer_rg
      customer_phone
      customer_landline
      customer_zip_code
      customer_state
      customer_city
      customer_neighborhood
      customer_street
      customer_street_number
      customer_address_complement
      password_changed
    }
  }
`;

export default GetCustomer;
