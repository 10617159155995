import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles(({ typography, breakpoints }) => ({
  sobreReparofone: {
    width: '50%',
    [breakpoints.down('sm')]: {
      width: '100%',
      marginBottom: '70px',
    },
  },
  textoSobre: {
    fontSize: '1.3em',
    [breakpoints.down('xs')]: {
      fontSize: '1.2em',
    },
  },
  title: {
    fontSize: '2em',
    fontWeight: 400,
    marginBottom: '30px',
    [breakpoints.down('xs')]: {
      fontSize: '1.5em',
    },
  },
  titleCarrefour: {
    fontFamily: 'Milliard Book',
  },
  titleReparofone: {
    fontFamily: 'Roboto',
  },
}));

export default styles;
