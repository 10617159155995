import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles(({ typography, breakpoints }) => ({
  mobileMenu: {
    position: 'fixed',
    bottom: 0,
    right: 0,
    left: 0,
    zIndex: 999999,
    [breakpoints.up('md')]: {
      display: 'none',
    },
  },
  mobileMenuCarrefour: {
    '& a.MuiBottomNavigationAction-root.Mui-selected': {
      color: '#1752CC',
    },
  },
  mobileMenuReparofone: {
    '& a.MuiBottomNavigationAction-root.Mui-selected': {
      color: '#393285',
    },
  },
  bottomItems: {},
  bottomItemsCarrefour: {
    '& span span.MuiBottomNavigationAction-label': {
      fontFamily: 'Milliard Book',
    },
  },
  bottomItemsReparofone: {
    '& span span.MuiBottomNavigationAction-label': {
      fontFamily: 'Roboto',
    },
  },
}));

export default styles;
