import gql from 'graphql-tag';

const GetEmployeeById = gql`
  query GetEmployeeById($id: ID!) {
    ReparafoneGetTradeInUserById(id: $id) {
      id
      name
      email
      password
      branch_id
      enabled
    }
  }
`;

export const GetEmployeeByIdWithStoreById = gql`
  query GetEmployeeByIdWithStoreById($employeeId: ID!, $storeId: ID!) {
    ReparafoneGetTradeInUserById(id: $employeeId) {
      id
      name
      email
      password
      branch_id
      enabled
    }
    ReparafoneGetStoreById(id: $storeId) {
      id
      name
      enabled
      addresses {
        id
        zipCode: zip_code
        state
        city
        neighborhood
        street
        number: street_number
        complement
      }
    }
  }
`;

export default GetEmployeeById;
