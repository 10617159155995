import { gql } from '@apollo/client';

const validateRecoverPasswordTokenPartner = gql`
  mutation ($email:String!, $token:String!){
    ValidateRecoverPasswordTokenPartner(email:$email, token:$token){
      id
      email
    }
  }
`;

export default validateRecoverPasswordTokenPartner;
