import { groupBy as rGroupBy } from 'ramda';

export const curryGroupByCount = (num) => {
  let index = 0;
  // eslint-disable-next-line no-plusplus
  return () => Math.floor(index++ / num);
};

export const groupBy = (models, quantity) => (
  Object.values(rGroupBy(curryGroupByCount(quantity), models))
);

export default {
  curryGroupByCount,
};
