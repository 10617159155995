import initialState from './initialState';

const PREFIX = 'VIVO_STORE';

export const StoreVivoTypes = {
  SET_STORE: `${PREFIX}.SET_STORE`,
  SET_EMPLOYEE: `${PREFIX}.SET_EMPLOYEE`,
};

export default function VivoStoreReducer(state = initialState, action) {
  const { type, payload } = action;
  const finalState = (() => {
    switch (type) {
      case StoreVivoTypes.SET_STORE:
        return {
          ...state,
          ...payload,
        };
      case StoreVivoTypes.SET_EMPLOYEE:
        if (payload.token) {
          localStorage.setItem('apiToken', `Bearer ${payload.token}`);
        }
        return {
          ...state,
          employee: { ...state.employee, ...payload },
        };
      default:
        return { ...state };
    }
  })();
  return finalState;
}
