export default function getInstallments(paymentMethodId, transactionAmount) {
  return new Promise((resolve, reject) => {
    window.Mercadopago.getInstallments({
      payment_method_id: paymentMethodId,
      amount: parseFloat(transactionAmount),
    }, (status, response) => {
      if (status === 200) {
        const [{ payer_costs: payerCosts }] = response;
        resolve(payerCosts);
      }
      reject(response);
    });
  });
}
