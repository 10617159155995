import React from 'react';
import * as P from 'prop-types';
import { loadCSS } from 'fg-loadcss';
import { connect } from 'react-redux';
import { setCollect as actSetCollect } from '@redux/actions/NewOrderActions/index';
import { AddressProps } from '@redux/reducers/VivoStoreReducer/PropTypes';
import { Fab, styled } from '@material-ui/core';
import { PlaylistAddCheck } from '@material-ui/icons';
import Header from '../../layout/partner/Header';
import FormStepper from './components/FormStepper';
function App({ setCollect, storeAddress, showHeader = true }) {
  React.useEffect(() => {
    const node = loadCSS(
      'https://use.fontawesome.com/releases/v5.12.0/css/all.css',
      document.querySelector('#font-awesome-css'),
    );

    return () => {
      node.parentNode.removeChild(node);
    };
  }, []);

  React.useEffect(() => {
    setCollect({ ...storeAddress, isChartered: true });
  }, []);

  return (
    <>
      {showHeader && <Header />}
      <FormStepper />
    </>
  );
}

App.propTypes = {
  setCollect: P.func.isRequired,
  storeAddress: P.shape(AddressProps).isRequired,
  showHeader: P.bool,
};
App.defaultProps = {
  showHeader: true,
}
const mapStateToProps = ({ VivoStoreReducer }) => ({
  storeAddress: VivoStoreReducer.address,
});

const mapDispatchToProps = (dispatch) => ({
  setCollect: (payload) => dispatch(actSetCollect(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
