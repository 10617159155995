import React from 'react';
import {
  Box, Button as MuiButton, Typography, withStyles,
} from '@material-ui/core';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import PropTypes from './PropTypes';
import useStyles from './style';

const Button = withStyles(() => ({
  root: {
    height: 56,
    fontSize: 13,
    borderRadius: 8,
    display: 'flex',
    textAlign: 'start',
    padding: '0px 20px',
    margin: 0,
    justifyContent: 'flex-start',
  },
}))(MuiButton);

export default function ButtonsMainDefects({ defects, handleSelect }) {
  const handleClick = ({ currentTarget }) => {
    const defect = defects[currentTarget.value];
    delete defect.icon;
    handleSelect(defect);
  };

  const classes = useStyles();

  return (
    <Box py={1}>
      <TransitionGroup>
        { defects.map(({ name, desc, icon }, index) => (
          <CSSTransition
            key={name}
            appear
            timeout={350}
            unmountOnExit
            classNames={classes.buttonDefect}
          >
            <div>
              <Button
                key={name}
                fullWidth
                size="large"
                value={index}
                variant="outlined"
                onClick={handleClick}
                startIcon={icon}
              >
                <Box>
                  <div>{name}</div>
                  {desc
                    ? (
                      <Typography variant="caption" className={classes.labelDesc}>
                        {desc}
                      </Typography>
                    ) : null}
                </Box>
              </Button>
            </div>
          </CSSTransition>
        )) }
      </TransitionGroup>
    </Box>
  );
}

ButtonsMainDefects.propTypes = PropTypes.propTypes;
ButtonsMainDefects.defaultProps = PropTypes.defaultProps;
