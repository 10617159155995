import { gql } from '@apollo/client';

export const requestRecoverPasswordCode = gql`
  mutation ($email: String!){
    PartnerRequestRecoverPassword(email:$email){
      id
      email
    }
  }
`;

export const validateRecoverPasswordTokenPartner = gql`
  mutation ($email:String!, $token:String!){
    ValidateRecoverPasswordTokenPartner(email:$email, token:$token){
      id
      email
    }
  }
`;

export const GetPartnerInformation = gql`
  query{
    MeTradeInUser {
      id
      name
      email
      branch_id
    }
  }
`;

export const ChangePasswordMutation = gql`
  mutation ChangePassword($email:String, $newPassword:String!, $token:String){
    PartnerChangePassword(email:$email, newPassword:$newPassword, token:$token){
      id
      password_changed
    }
  }
`;
