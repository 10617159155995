import Header from '@/layout/partner/HeaderPartner';
import GetPartnerUser from '@/pages/partner/graphql/GetPartnerUser';
import {
  makeStyles, CircularProgress,
} from '@material-ui/core/';
import Home from '@/pages/partner/homePartner';
import { useQuery } from '@apollo/client';
import React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';

const useStyles = makeStyles(({ spacing, breakpoints }) => ({
  backgroundForm: {
    backgroundImage: 'url(//theme.zdassets.com/theme_assets/9251552/e26e16acf092dce541a9f56d8f7d89f6a5ad8c39.png)',
    padding: `${spacing(15)}px ${spacing(6)}px ${spacing(6)}px`,
    display: 'flex',
    minHeight: '650px',
    justifyContent: 'center',
    [breakpoints.down('xs')]: {
      padding: `${spacing(15)}px 0 ${spacing(6)}px`,
    },
  },
  root: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '30px',
  },
}));

const PartnerRoutes = withRouter(({ location }) => {
  const classes = useStyles();
  const { data, loading, error } = useQuery(GetPartnerUser);
  if (loading) {
    return (
      <div className={classes.root}>
        <CircularProgress />
      </div>
    );
  }
  if (error) return 'Houve um erro ao autenticar. Tente novamente mais tarde';
  if (!data.ReparafonePartnerUser?.enabled) return 'Você não possui acesso a esta página.';
  return (
    <>
      <Header />
      <div className={classes.backgroundForm}>
        <Switch location={location}>
          <Route exact path="/partners/servicerepair/orders" component={Home} />
        </Switch>
      </div>
    </>
  );
});

export default PartnerRoutes;
