import { client } from '../../../../Apollo';
import { GetBranches } from '../query/GetBranches';

const getBranches = () => new Promise((resolve, reject) => {
  client
    .query({
      query: GetBranches,
      fetchPolicy: 'network-only',
    })
    .then((r) => {
      resolve(r.data.ReparafoneGetBranchesAll);
    })
    .catch((e) => {
      console.log(e.message, 'erro ao buscar');
      reject(e.message);
    });
});

export default getBranches;
