import React from 'react';
// PROP-TYPES
import P from 'prop-types';
// CLSX
import clsx from 'clsx';
// REDUX
import { useSelector } from 'react-redux';
// ICONS
import DeviceUnknownRoundedIcon from '@material-ui/icons/DeviceUnknownRounded';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import AssignmentIcon from '@material-ui/icons/Assignment';
// STYLE
import style from './style';

export default function StepIcon(props) {
  const classes = style();
  const { active, completed, icon } = props;
  const partnerData = useSelector((state) => state.PartnerStoreReducer);
  const { branch } = partnerData;
  const { branch_name } = branch;
  const icons = [
    null,
    <DeviceUnknownRoundedIcon fontSize="small" />,
    <LocalShippingIcon fontSize="small" />,
    <AssignmentIndIcon fontSize="small" />,
    <AssignmentIcon fontSize="small" />,
  ];

  return (
    <div
      className={clsx(classes.root, 'stepIcon', {
        [classes.activeCarrefour]: active && branch_name === 'Carrefour',
        [classes.activeReparofone]: active && branch_name === 'Limelocker',
        [classes.completedCarrefour]: completed && branch_name === 'Carrefour',
        [classes.completedReparofone]: completed && branch_name === 'Limelocker',
      })}
    >
      {icons[icon]}
    </div>
  );
}

StepIcon.propTypes = {
  active: P.bool.isRequired,
  completed: P.bool.isRequired,
  icon: P.number.isRequired,
};
