import React, { useState } from 'react';
import * as P from 'prop-types';
import { Box, Checkbox, FormControlLabel } from '@material-ui/core';

export default function TermsOfService({ handleChange }) {
  const [termsChecked, setTermsChecked] = useState(false);
  const [customerAgreed, setCustomerAgreed] = useState(false);
  const handleCheckBoxChange = ({ target }) => {
    setTermsChecked(target.checked);
  };
  const handleCustomerCheckBoxChange = ({ target }) => {
    setCustomerAgreed(target.checked);
  };
  React.useEffect(() => {
    handleChange(termsChecked && customerAgreed);
  }, [termsChecked, customerAgreed]);

  return (
    <Box py={1}>
      <FormControlLabel
        control={<Checkbox checked={termsChecked} onChange={handleCheckBoxChange} />}
        label={(
          <span>
            Confirmo que li e concordo com os
            {' '}
            <a href="/termsofservice/vivo" target="_blank">Termos de Serviços</a>
          </span>
        )}
      />
      <FormControlLabel
        control={<Checkbox checked={customerAgreed} onChange={handleCustomerCheckBoxChange} />}
        label={(
          <span>
            O Cliente está ciente que os dados poderão ser perdidos, caso seja necessário, para o reparo ser realizado
          </span>
        )}
      />
    </Box>
  );
}

TermsOfService.propTypes = {
  handleChange: P.func.isRequired,
};
