import React from 'react';

import { connect } from 'react-redux';
import {
  MemoryRouter, Route, useHistory, useLocation,
} from 'react-router-dom';

import CenteredPanel from '../../components/modal/CenteredPanel';

import { requestLoginPartner } from '../../redux/actions/auth/AuthActions';
import Login from './auth/Login';
import Header from './components/Header';
import RequestPasswordRecover from './recover/RequestPasswordRecover';
import RequestToken from './recover/RequestToken';
import ChangePassword from './auth/ChangePassword';

const LoginPage = (props) => {
  const history = useHistory();
  const location = useLocation();
  return (
    <CenteredPanel>
      <MemoryRouter>
        <>
          <Route path="/" component={Header} />
          <Route exact path="/">
            <Login outerHistory={history} outerLocation={location} />
          </Route>
          <Route exact path="/partners/recover/password" component={RequestPasswordRecover} />
          <Route exact path="/partners/recover/password/token" component={RequestToken} />
          <Route exact path="/partners/recover/password/change" component={ChangePassword} />
        </>
      </MemoryRouter>
    </CenteredPanel>
  );
};

const mapStateToProps = (state) => {
  const { AuthReducer } = state;
  const { isFetching } = AuthReducer;
  return {
    isFetching,
  };
};
export default connect(mapStateToProps, { requestLoginPartner })(LoginPage);
