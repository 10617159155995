import AwaitingEstimateRevision from './status/AwaitingEstimateRevision';
import WaitingPayment from './status/WaitingPayment';

export const mapStatusSlugToComponent = {
  'pendente-aprovacao-ct': {
    buttonTitle: 'Visualizar orçamento',
    statusText: 'O orçamento realizado para o reparo durante a revisão do aparelho ficou diferente do total estimado inicialmente, é necessário a sua aprovação para continuarmos!',
    isModal: true,
    component: AwaitingEstimateRevision,
  },
  'pending-processing': {
    shouldShow: (order) => !order.isPaid,
    buttonTitle: 'Realizar pagamento',
    statusText: 'Nós recebemos sua ordem de serviço e estamos aguardando o pagamento, clique no botão abaixo para prosseguir com o pagamento!',
    component: WaitingPayment,
  },
  'aguardando-pag': {
    shouldShow: (order) => !order.isPaid,
    buttonTitle: 'Realizar pagamento',
    statusText: 'O reparo do seu aparelho foi realizado e está aguardando pagamento, clique no botão abaixo para prosseguir com o pagamento!',
    component: WaitingPayment,
  },
};

export default mapStatusSlugToComponent;
