import React from 'react';
// MATERIAL-UI
import { Box, Typography, List } from '@material-ui/core';
// ICONS
import AssignmentIcon from '@material-ui/icons/Assignment';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import EventIcon from '@material-ui/icons/Event';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
// PROP-TYPES
import PropTypes from './PropTypes';
// COMPONENTS
import { InfoData } from '../Common';
// UTILS
import {
  dateFormatter,
} from './utils';
// STYLES
import styles from './styles';

const Order = ({ order }) => {
  const classes = styles();
  const [createdAt, setCreatedAt] = React.useState(null);
  const [deliveryDate, setDeliveryDate] = React.useState(null);
  React.useEffect(() => {
    setCreatedAt(dateFormatter(order.order_created_at));
    setDeliveryDate(dateFormatter(order.delivery_date_limit));
  }, []);
  return (
    <Box className={classes.box}>
      <Typography className={classes.title} variant="h2" align="left">
        Dados da Ordem
      </Typography>
      <List className={classes.list}>
        <InfoData
          title="Número da Ordem"
          info={order.id}
          icon={<AssignmentIcon className={classes.icon} />}
        />
        <InfoData
          title="Data de Criação"
          info={createdAt}
          icon={<EventAvailableIcon className={classes.icon} />}
        />
        <InfoData
          title="Status"
          info={order.status.status_name}
          icon={<LocalOfferIcon className={classes.icon} />}
        />
        <InfoData
          title="Data Agendada para Entrega"
          info={deliveryDate}
          icon={<EventIcon className={classes.icon} />}
        />
      </List>
    </Box>
  );
};

export default Order;

Order.propTypes = PropTypes.propTypes;
Order.defaultProps = PropTypes.defaultProps;
