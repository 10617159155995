import React from 'react';
import {
  Route,
  Redirect,
} from 'react-router-dom';
import { connect } from 'react-redux';

function LoggedOutRoutePartner({ children, ...rest }) {
  const { isAuthenticated } = rest;
  return (
    <Route
      {...rest}
      render={
        ({ location }) => (!isAuthenticated ? (children) : (<Redirect to={{ pathname: '/partners/servicerepair', state: { from: location } }} />))
}
    />
  );
}
function mapStateToProps(state) {
  const { AuthReducer } = state;
  const { isAuthenticated } = AuthReducer;
  return {
    isAuthenticated,
  };
}
export default connect(mapStateToProps)(LoggedOutRoutePartner);
