import React from 'react';
// SLICK SLIDER
import Slider from 'react-slick';
// IMAGE
import comment from '@images/Depoimentos/comment.png';
// MATERIAL-UI
import {
  CircularProgress,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';
// GRAPHQL
import getNpsFeedbacks from '../../graphql/schemas/GetNpsFeedbacks';
// STYLES
import useStyles from './style';

const SimpleSlider = () => {
  const classes = useStyles();
  const theme = useTheme();
  const mediaQuerySM = useMediaQuery(theme.breakpoints.down('sm'));
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
  };
  const [infos, setInfos] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  const loadFeedbacks = () => {
    getNpsFeedbacks()
      .then((r) => setInfos(r.data))
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  };

  React.useEffect(() => {
    loadFeedbacks();
  });

  return (
    <>
      <div
        className={
          mediaQuerySM ? classes.testimonialsMobile : classes.testimonials
        }
      >
        <p className={classes.title}>Depoimentos</p>
        { loading && (
          <CircularProgress color="inherit" size={30} />
        )}
        { !loading && (
          <Slider className={classes.slider} {...settings}>
            {infos.map((info) => (
              <div className={mediaQuerySM ? classes.testimonialsData : null}>
                <img
                  src={comment}
                  alt="Depoimentos Reparofone"
                  className={classes.image}
                />

                <div className={classes.comment}>
                  <blockquote
                    className={
                    mediaQuerySM ? classes.blockquoteMobile : classes.blockquote
                  }
                  >
                    {info.message}
                  </blockquote>
                  <h2 className={classes.name}>
                    {info.order.customer.customer_first_name}
                    {' '}
                    -
                    {' '}
                    <span className={classes.city}>
                      {info.order.customer.customer_city}
                      /
                      {info.order.customer.customer_state}
                    </span>
                  </h2>
                </div>
              </div>
            ))}
          </Slider>
        )}
      </div>
    </>
  );
};

export default SimpleSlider;
