import React from 'react';
import * as P from 'prop-types';
import Collapse from '@material-ui/core/Collapse';
import Alert from '@material-ui/lab/Alert';

export default function InlineAlertCollapse({
  show, message, severity, button,
}) {
  const collapseRef = React.useRef();
  const id = React.useMemo(() => `alert-${Math.floor(Math.random() * 100)}`);

  React.useEffect(() => {
    const { current } = collapseRef;
    if (show && current) {
      document.scrollingElement.scrollTo({
        top: current.offsetTop - 140,
        behavior: 'smooth',
      });
    }
  }, [show]);

  return (
    <Collapse id={id} in={show} ref={collapseRef}>
      <Alert severity={severity || undefined} action={button}>{message ?? ''}</Alert>
    </Collapse>
  );
}

InlineAlertCollapse.propTypes = {
  show: P.bool.isRequired,
  message: P.string,
  severity: P.string,
  button: P.node,
};

InlineAlertCollapse.defaultProps = {
  button: null,
  message: null,
  severity: null,
};
