import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles(({ palette: { primary, secondary } }) => ({
  root: {
    backgroundImage: 'linear-gradient( 136deg, #eee, #ccc )',
    zIndex: 1,
    color: '#fff',
    width: 35,
    height: 35,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  active: {
    backgroundImage: `linear-gradient(136deg,
        ${secondary.light} -28%,
        ${primary.light} 30%,
        ${primary.main} 60%
      )`,
    boxShadow: '0 0 2px 2px rgba(0,0,0,.25)',
  },
  completed: {
    backgroundImage:
        `linear-gradient(136deg,
          ${secondary.main} -28%,
          ${primary.light} 30%,
          ${primary.main} 60%
        )`,
  },
}));
