import {
  Box, IconButton, Paper, Tooltip, Typography, Zoom,
} from '@material-ui/core';
import React from 'react';
import { Clear, Replay } from '@material-ui/icons';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Alert, AlertTitle } from '@material-ui/lab';
import style from './style';
import StepperContent from '../StepperContent';
import StepperHeader from '../StepperHeader';
import { resetOrder, setStep } from '../../../../redux/actions/NewOrderActions/actions';

function FormStepper({ $actions, $state }) {
  const { vivo: { name: storeName, employee: { name: employeeName } } } = $state;
  const classes = style();
  const handleClearClick = () => {
    $actions.resetOrder();
    $actions.setStep(0);
  };
  return (
    <div className={classes.backgroundForm}>
      <Paper className="form" elevation={0}>
        <div id="topContent" className={classes.topContent}>
          <div className={classes.clearButton}>
            <Tooltip title="Refazer ordem" TransitionComponent={Zoom}>
              <IconButton onClick={handleClearClick} aria-label="Refazer Ordem">
                <Replay />
              </IconButton>
            </Tooltip>
          </div>
          <Typography variant="h5" component={Box} px={6} textAlign="center">
            Cadastro de Reparo
          </Typography>
        </div>
        <Typography variant="body2" align="center">
          {storeName}
        </Typography>
        <StepperHeader />
        <StepperContent />
      </Paper>
    </div>
  );
}
const mapDispatchToProps = (dispatch) => ({
  $actions: bindActionCreators(
    { resetOrder, setStep },
    dispatch,
  ),
});
const mapStateToProps = ({ VivoStoreReducer }) => ({
  $state: {
    vivo: VivoStoreReducer,
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(FormStepper);
