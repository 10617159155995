import React from 'react';
import Contato from './Contato';

import Copyright from './Copyright';
import Sobre from './Sobre';
import WhatsAppPulse from './WhatsappPulse';

import Holidays from '../../../utils/Holidays';

import './Footer.css';

const toggleViewWhatsApp = () => {
  const date = new Date();

  const diasDaSemana = date.getDay();
  const horas = date.getHours();
  const isHoliday = Holidays.isHoliday(date);

  if (!isHoliday && (diasDaSemana !== 0 && diasDaSemana !== 6) && (horas > 8 && horas < 17)) {
    return true;
  }
  return false;
};

const Footer = () => (
  <>
    <div id="footer-franch" className="footer">
      <div className="footer-interno">
        <Sobre />
        <Contato />
      </div>
    </div>
    <Copyright />
    <WhatsAppPulse show={toggleViewWhatsApp()} />
  </>
);

export default Footer;
