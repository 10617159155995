export const geolocateStyle = {
  top: 0,
  left: 0,
  padding: '10px',
};

export const fullscreenControlStyle = {
  top: 36,
  left: 0,
  padding: '10px',
};

export const navStyle = {
  top: 0,
  left: 0,
  padding: '10px',
};

export const scaleControlStyle = {
  bottom: 36,
  left: 0,
  padding: '10px',
};
