import { gql } from '@apollo/client';

const requestRecoverPasswordCode = gql`
  mutation ($email: String!){
    PartnerRequestRecoverPassword(email:$email){
      id
      email
    }
  }
`;

export default requestRecoverPasswordCode;
