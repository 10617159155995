import * as P from 'prop-types';
import { BillingAddressProps } from '@redux/reducers/NewOrderReducer/PropTypes';

export const propTypes = {
  $state: P.shape({
    step: P.number.isRequired,
    billingAddress: P.shape(BillingAddressProps),
  }),
  $actions: P.shape({
    setStep: P.func.isRequired,
    setBillingAddress: P.func.isRequired,
    setDeliveryData: P.func.isRequired,
  }),
};

export const defaultProps = {};

export default {
  propTypes,
  defaultProps,
};
