import * as P from 'prop-types';

const propTypes = {
  order: P.arrayOf(P.shape({
    id: P.string.isRequired,
  })).isRequired,
};

export default {
  propTypes,
  defaultProps: {},
};
