import React from 'react';
import * as P from 'prop-types';
import {
  Backdrop, CircularProgress, Icon, IconButton,
  InputAdornment, makeStyles, TextField,
} from '@material-ui/core';
import ReactInputMask from 'react-input-mask';
import searchZipCodeInfo from '../../../../../utils/SearchZipCodeInfo';
import { textFieldType, textFieldSize } from '../Common';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

export default function ZipCode({
  value, handleChange, handleBlur, setValues, disabled, prefix, error,
}) {
  const [isLoading, setLoading] = React.useState(false);
  const classes = useStyles();

  const searchZipCode = (zipCode = value) => {
    setLoading(true);
    setTimeout(() => {
      searchZipCodeInfo(zipCode)
        .then(({
          state, city, neighborhood, street,
        }) => {
          setValues({
            state, city, neighborhood, street,
          });
        })
        .catch(() => null)
        .finally(() => setLoading(false));
    }, 200);
  };

  const handleSearchZipCode = () => {
    searchZipCode();
  };

  const handleBlurInput = (ev) => {
    const { currentTarget } = ev;
    handleSearchZipCode(currentTarget.value);
    handleBlur(ev);
  };

  return (
    <>
      <ReactInputMask
        mask="99999-999"
        onChange={handleChange}
        onBlur={handleBlurInput}
        disabled={disabled}
        value={value}
        maskPlaceholder={null}
      >
        <TextField
          fullWidth
          size={textFieldSize}
          label="CEP"
          error={!!error}
          helperText={error}
          variant={textFieldType}
          name={`${prefix}[zipCode]`}
          InputProps={{
            endAdornment: (
              <InputAdornment>
                <IconButton
                  size="small"
                  title="Pesquisar CEP"
                  edge="end"
                  onClick={handleSearchZipCode}
                >
                  <Icon>search</Icon>
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </ReactInputMask>
      <Backdrop className={classes.backdrop} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}

ZipCode.propTypes = {
  value: P.string,
  handleChange: P.func.isRequired,
  handleBlur: P.func.isRequired,
  setValues: P.func.isRequired,
  disabled: P.bool,
  prefix: P.string.isRequired,
  error: P.string,
};

ZipCode.defaultProps = {
  value: '',
  error: '',
  disabled: false,
};
