const amountFormatter = (value) => {
  const amount = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(value / 100);
  return `${amount}`;
};

export const paymentFormatter = (value) => amountFormatter(value);

export const paymentResultFormatter = (value) => {
  if (value) return 'Pago';
  return 'Pendente de Pagamento';
};
