import client from '@/Apollo';
import {
  GetCepAvailability,
  GetDeliveryCepAvailability,
} from '../graphql/query/GetCepAvailability';

export function checkZipCode(zipCode) {
  return new Promise((resolve, reject) => {
    const clearZipCode = zipCode.replace(/[^0-9]/gm, '');
    client.query({ query: GetCepAvailability, variables: { cep: clearZipCode } })
      .then(({ data: { ReparafoneValidateCepAvailability } }) => {
        const collect = ReparafoneValidateCepAvailability.slice().pop();
        if (!collect) {
          return resolve(null);
        }
        return resolve(collect);
      });
  });
}
export function checkDeliveryCep(zipCode) {
  const clearedZipCode = String(zipCode).replace(/[^0-9]/gm, '');
  return new Promise((resolve, reject) => {
    client
      .query({ query: GetDeliveryCepAvailability, variables: { cep: clearedZipCode } })
      .then(({ data: { ReparafoneValidateDeliveryCepAvailability } }) => {
        const delivery = ReparafoneValidateDeliveryCepAvailability.slice().pop();
        resolve(!!delivery);
      })
      .catch((e) => reject(e));
  });
}
