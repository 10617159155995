/* eslint-disable camelcase */
import * as React from 'react';
// REDUX
import { useSelector, useDispatch } from 'react-redux';
import {
  setBranches,
} from '@/redux/actions/MapBoxActions';
// LODASH
import { isEmpty } from 'lodash';
// PROP-TYPES
import PropTypes from './PropTypes';
// GRAPHQL
import getBranches from '../../../../graphql/schemas/GetBranches';
// COMPONENTS
import StoreInfo from './components/StoreInfo';
// STYLES
import styles from './styles';

function StoresPanel({ onSelectStore, setPopupInfo, geocoderContainerRef }) {
  const classes = styles();
  const dispatch = useDispatch();
  const mapBoxData = useSelector((state) => state.MapBoxReducer);
  const { branches } = mapBoxData;

  const loadBranches = () => {
    getBranches()
      .then((r) => dispatch(setBranches(r)))
      .catch(({ response }) => console.log(response));
  };
  React.useEffect(() => {
    if (isEmpty(branches)) return loadBranches();
  }, []);
  return (
    <div className={classes.storesPanel}>
      <div
        ref={geocoderContainerRef}
        className={classes.geocoder}
      />
      <hr />
      <StoreInfo onSelectStore={onSelectStore} setPopupInfo={setPopupInfo} />
    </div>
  );
}

export default React.memo(StoresPanel);

StoresPanel.propTypes = PropTypes.propTypes;
StoresPanel.defaultProps = PropTypes.defaultProps;
