import gql from 'graphql-tag';

export const GetOrderInfoByIdQuery = gql`
  query GetOrderInfoByIdQuery($orderId: ID!){
    customerRepairOrderByIdPayment(id: $orderId){
      id
      informedDefects
      repairedDefects
      isPaid
      debt
      totalPaid
      isRepaired
      order_price
      product{
        model{
          designName
        }
      }
    }
}
`;

export default GetOrderInfoByIdQuery;
