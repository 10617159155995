import {
  Box, Container, Paper, Typography,
} from '@material-ui/core';
import React from 'react';

const ReparafoneTOS = () => (
  <Container maxWidth="md">
    <Paper elevation={1}>
      <Box p={3}>
        <Typography variant="body1" align="center">
          A REPAROFONE é um serviço prestado pela Trocafone – Comercialização de
          Aparelhos Eletrônicos Ltda., CNPJ n. 20.553.221/0001-02, e este
          documento contém as condições e regulamentos aos quais as Partes se
          submetem quando da contratação dos serviços de reparo.
        </Typography>
        <Typography variant="body1" align="center">
          1. Prazos para coleta e entrega. A coleta, pela Reparofone, será
          realizada em data agendada pelo Cliente e o reparo será realizado após
          a aprovação do orçamento final. A partir dessa data, a Reparofone tem
          um dia útil para realizar o reparo e enviar o link de pagamento. A
          devolução do aparelho está condicionada à confirmação de pagamento.
        </Typography>
        <Typography variant="body1" align="center">
          2. Da coleta O aparelho será verificado no ato da coleta para termos
          certeza de suas condições. A Reparofone não poderá ser
          responsabilizada por reparos de outras peças ou defeitos
          pré-existentes, além do que efetivamente foram contratados.
        </Typography>
        <Typography variant="body1" align="center">
          3. Da Garantia Nossa garantia é de 90 (noventa) dias, especificamente,
          sobre o reparo realizado, devendo ser apresentada a nota fiscal do
          serviço para aplicabilidade.
        </Typography>
        <Typography variant="body1" align="center">
          4. Do orçamento O orçamento inicial é uma previsão e pode ser alterado
          conforme diagnóstico posterior à coleta. Em caso de alteração no valor
          inicialmente previsto, este será novamente informado ao Cliente e
          seguiremos com o reparo apenas após a sua aprovação.
        </Typography>
        <Typography variant="body1" align="center">
          5. Do pagamento O pagamento do serviço será realizado pelo Cliente em
          link encaminhado pela Reparofone, após aprovação do orçamento final, e
          poderá ser feito via boleto ou cartão de crédito.
        </Typography>
        <Typography variant="body1" align="center">
          6. Privacidade dos Dados A Reparofone declara que cumpre todas as
          formalidades necessárias para manter em sigilo eventuais dados
          pessoais contidos em seu Aparelho e que os dados pessoais do Cliente,
          coletados quando da solicitação dos serviços, serão armazenados,
          exclusivamente, para histórico da operação. Em alguns reparos, há
          riscos de perder toda a informação contida no aparelho, portanto é
          recomendado fazer um backup na nuvem dos arquivos principais antes do
          seu envio.
        </Typography>
        <Typography variant="body1" align="center">
          7. Condições Gerais O Cliente se declara ciente de que alguns tipos de
          reparo possuem risco de danificar permanentemente o aparelho. Nessa
          hipótese, caso o Cliente decida seguir com o reparo, ele se declara
          ciente de que não nos responsabilizaremos em caso de dano permanente
          no aparelho.
        </Typography>
        <Typography variant="body1" align="center">
          Caso não seja possível o reparo ou ocorra algum problema que danifique
          o aparelho, ele será devolvido totalmente sem custo.
        </Typography>
        <Typography variant="body1" align="center">
          Não é necessário desbloquear o aparelho, entretanto dependendo da peça
          a ser reparada, será necessário a senha de tela para o teste. O
          Cliente se declara ciente e de acordo de que, caso necessário,
          fornecerá a senha para desbloqueio do Aparelho.
        </Typography>
      </Box>
    </Paper>
  </Container>
);
export default ReparafoneTOS;
