import * as P from 'prop-types';
import { ModelProps } from '@redux/reducers/NewOrderReducer/PropTypes';

export const StepSelectColorPropTypes = {
  model: P.shape(ModelProps),
  handleNextStep: P.func.isRequired,
  handleBackStep: P.func.isRequired,
};

export const StepSelectColorDefaultProps = {};

export default { StepSelectColorPropTypes, StepSelectColorDefaultProps };
