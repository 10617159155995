import { sum } from 'ramda';
import { connect } from 'react-redux';

const mapStateToProps = ({ NewOrderReducer }) => ({
  $state: {
    transactionAmount: sum(Object.values(NewOrderReducer.transactionAmount)),
  },
});

const mapDispatchToProps = null;

export default connect(mapStateToProps, mapDispatchToProps);
