import React from 'react';
import * as P from 'prop-types';
import {
  Checkbox, FormControlLabel, Typography,
} from '@material-ui/core';

function CheckboxAutocomplete({
  toggleAutoComplete, autoFillLabel, isEqualCollect,
}) {
  return (
    <FormControlLabel
      control={(
        <Checkbox
          size="small"
          color="primary"
          checked={isEqualCollect}
          onChange={toggleAutoComplete}
        />
)}
      label={<Typography variant="caption">{ autoFillLabel }</Typography>}
    />
  );
}

CheckboxAutocomplete.propTypes = {
  autoCompleted: P.bool.isRequired,
  toggleAutoComplete: P.func.isRequired,
  autoFillLabel: P.string.isRequired,
  isEqualCollect: P.bool.isRequired,
};

export default CheckboxAutocomplete;
