import { makeStyles } from '@material-ui/core';
import { green } from '@material-ui/core/colors';

export const useStyles = makeStyles((themeStyle) => ({
  paper: {
    padding: themeStyle.spacing(2),
    textAlign: 'center',
    color: themeStyle.palette.text.secondary,
    width: '60%',
    margin: `${themeStyle.spacing(3)}px auto`,
    [themeStyle.breakpoints.down('sm')]: {
      marginBottom: '80px',
      width: '100%',
    },
  },
  divider: { margin: '16px 0' },
  totalLabel: {
    color: '#383838',
    maxWidth: '216px',
    minWidth: '216px',
    textAlign: 'center',
  },
  totalPriceLabel: { textAlign: 'end' },
  defectLabel: {
    color: '#383838',
    maxWidth: '216px',
    minWidth: '216px',
    textAlign: 'center',
  },
}));

export default useStyles;
