import {
  Button, Grid, Typography,
} from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import React from 'react';
import { Link } from 'react-router-dom';
import Header from './components/Header';
import CardContent from './components/CardContent';

const Failed = () => (
  <>
    <Header color={red[500]} title="Pagamento rejeitado!" description="O seu pagamento foi rejeitado pelo meio de pagamento, por favor, tente novamente." />
    <CardContent>
      <Grid container direction="column" alignItems="center" spacing={2}>
        <Grid item>
          <Typography variant="body1">
            O seu pagamento foi rejeitado e não gerou nenhuma cobrança, você pode tentar novamente.
          </Typography>
        </Grid>
        <Grid item>
          <Button component={Link} to="/customer/repairs" color="primary" variant="contained">Ver ordens de serviço</Button>
        </Grid>
      </Grid>
    </CardContent>
  </>
);

export default Failed;
