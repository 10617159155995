import { makeStyles } from '@material-ui/core/';

const styles = makeStyles(({ spacing, breakpoints }) => ({
  backgroundForm: {
    backgroundImage: 'url(//theme.zdassets.com/theme_assets/9251552/e26e16acf092dce541a9f56d8f7d89f6a5ad8c39.png)',
    padding: `${spacing(15)}px ${spacing(6)}px ${spacing(6)}px`,
    display: 'flex',
    minHeight: '650px',
    justifyContent: 'center',
    [breakpoints.down('xs')]: {
      padding: `${spacing(15)}px 0 ${spacing(6)}px`,
    },
  },
  root: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '30px',
  },
}));

export default styles;
