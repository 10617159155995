import React from 'react';
import {
  Container,
  Grid,
  DialogTitle,
  DialogContent,
  Dialog,
  IconButton,
  Typography,
  makeStyles,
  Slide,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ChangePassword from './ChangePassword';
import ReparofoneLogo from '../../../images/Reparafone-logo.png';

const CustomerFirstPasswordChange = (
  <Grid container direction="column" spacing={2} alignItems="center">
    <Grid item>
      <img src={ReparofoneLogo} alt="Reparofone" style={{ marginTop: '1rem' }} />
    </Grid>
    <Grid item>
      <Typography variant="body1">Por razões de segurança nós solicitamos que você altere a sua senha no primeiro acesso, escolha uma senha segura!</Typography>
    </Grid>
  </Grid>
);
// const ModalChangePassword = (props) => (
//   <FullWidthModal title="Trocar senha" {...props}>
//     <Container maxWidth="xs">
//       {props.passwordChanged && CustomerFirstPasswordChange}
//       <ChangePassword showTitle={false} />
//     </Container>
//   </FullWidthModal>
// );

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(0.25),
    top: theme.spacing(0.25),
    color: theme.palette.grey[500],
  },
  dialogTitleText: {
    fontSize: '1.7rem',
    fontWeight: 500,
    textAlign: 'center',
  },
  dialogTitle: {
    padding: '16px 60px 16px 16px',
  },
  dialogContent: {
    padding: '24px 0',
  },
}));
const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const ModalChangePassword = ({ passwordChanged, isOpen, handleModalState }) => {
  const classes = useStyles();
  return (
    <Dialog
      open={isOpen}
      aria-labelledby="customized-dialog-title"
      fullWidth
      TransitionComponent={Transition}
      keepMounted
      maxWidth="sm"
    >
      <DialogTitle
        id="alert-dialog-slide-title"
        className={classes.dialogTitle}
      >
        <Typography variant="h2" className={classes.dialogTitleText}>
          Trocar Senha
        </Typography>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handleModalState}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Container maxWidth="xs">
          {passwordChanged && CustomerFirstPasswordChange}
          <ChangePassword showTitle={false} />
        </Container>
      </DialogContent>
    </Dialog>
  );
};

export default ModalChangePassword;
