import { Box, Typography } from '@material-ui/core';
import React from 'react';
import connect from './ConnectComponentRedux';

const LaterPaymentMethod = React.forwardRef(({ $state, $actions }, ref) => {
  React.useImperativeHandle(
    ref,
    () => ({
      backStep: () => {
        $actions.setStep($state.step - 1);
      },
      nextStep: () => {
        $actions.setAfterRepairPayment();
        $actions.setStep($state.step + 1);
      },
    }),
    []
  );
  return (
    <Box p={3}>
      <Typography variant="body1">
        Você pagará pelo reparo apenas após a finalização do serviço.
      </Typography>
    </Box>
  );
});

export default connect(LaterPaymentMethod);
