import React from 'react';
// REDUX
import { useSelector } from 'react-redux';
// MATERIAL-UI
import { useMediaQuery, useTheme } from '@material-ui/core';
// STYLES
import styles from './style';

const Banner = () => {
  const classes = styles();
  const partnerData = useSelector((state) => state.PartnerStoreReducer);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'), { noSsr: true });
  return (
    <div id="home" className={classes.bannerCarrefour}>
      <img
        className={classes.imgBannerCarrefour}
        src={
          isMobile
            ? require(`@/images/Banner/banner-${partnerData.branch.branch_slug}-mobile.webp`)
            : require(`@/images/Banner/banner-${partnerData.branch.branch_slug}.webp`)
        }
        alt={partnerData.branch.branch_slug}
      />
    </div>
  );
};

export default Banner;
