import React, { useState } from 'react';
import { Box, Collapse } from '@material-ui/core';
// REDUX
import { useSelector, useDispatch } from 'react-redux';
import {
  setStep,
  middlewares,
  setDeliveryData,
  setDevice,
} from '@redux/actions/NewRepairActions';
// DATE FORMATTERS
import {
  format, parse, add, isWeekend, isFriday,
} from 'date-fns';
import Holidays from '@/utils/Holidays';
// COMPONENTS
import StepperButtons from '../StepperButtons';
import PreviewCard from './Components/PreviewCard';
import PrintServiceOrder from './Components/PrintServiceOrder/index';
import InlineAlertCollapse from '../InlineAlertCollapse';
import LoadingContent from '../LoadingContent';
import Title from './Components/Title';
import BoxCheckout from './Components/BoxCheckout';
import InputDefectComment from './Components/InputDefectComment';
// FUNCTIONS
import handleResponse from './Components/PrintServiceOrder/Utils/ResponseHelper';
// STYLES
import style from './style';

function ConcludedOrder() {
  const classes = style();
  const dispatch = useDispatch();
  const customerData = useSelector((state) => state.NewRepairReducer);
  const {
    orderData, activeStep, collect, transactionAmount,
  } = customerData;
  const { budgetRepair, taxCollect, taxDelivery } = transactionAmount;
  const totalBudget = transactionAmount.totalBudgetRepair
    ? transactionAmount.totalBudgetRepair + taxCollect + taxDelivery
    : budgetRepair + taxCollect + taxDelivery;
  const { schedule_collect } = collect;
  const [isSubmitting, setSubmitting] = useState(false);
  const [termsAgreem, setTermsAgree] = useState(false);
  const [defectComment, setDefectComment] = useState(null);
  const [alert, setAlert] = useState({
    show: false,
    message: null,
    severity: null,
  });
  React.useEffect(() => {
    document
      .querySelector('#top-content')
      .scrollIntoView({ behavior: 'smooth' });
  }, [orderData]);

  const showAlert = (options) => setAlert({
    ...alert,
    ...options,
    show: true,
  });

  const closeAlert = () => setAlert({
    ...alert,
    show: false,
  });

  const handleBackStep = () => {
    dispatch(setStep(activeStep - 1));
  };

  const handleConfirm = () => {
    setSubmitting(true);
    closeAlert();
    dispatch(setDevice({ defectComment }));
    dispatch(middlewares.createOrder(showAlert, setSubmitting, handleResponse, totalBudget));
  };

  const handleDefectComment = (e) => {
    if (e.target.value) setDefectComment(e.target.value);
  };

  const parsedDate = parse(schedule_collect, 'dd-MM-yyyy', new Date());

  const initialDeliveryDate = () => {
    if (isWeekend(add(parsedDate, { days: 3 }))) { return add(parsedDate, { days: 5 }); }
    if (Holidays.isHoliday(add(parsedDate, { days: 3 }))) {
      if (isFriday(add(parsedDate, { days: 3 }))) { return add(parsedDate, { days: 6 }); }
      return add(parsedDate, { days: 4 });
    }
    if (isFriday(parsedDate)) return add(parsedDate, { days: 5 });
    return add(parsedDate, { days: 3 });
  };

  const [deliveryDate, setDeliveryDate] = React.useState(initialDeliveryDate);

  React.useEffect(() => {
    dispatch(
      setDeliveryData({ schedule_delivery: format(deliveryDate, 'dd-MM-yyyy') }),
    );
  }, [schedule_collect, dispatch]);

  return (
    <>
      <Box maxWidth={668} width="100%">
        <InlineAlertCollapse
          show={alert.show}
          message={alert.message}
          severity={alert.severity}
        />
        <Box py={0.5}>
          <LoadingContent isLoading={isSubmitting}>
            <Collapse
              in={!orderData}
              unmountOnExit
              className={classes.couponBox}
            >
              <Title label="Informações financeiras do reparo" />
              <BoxCheckout />
            </Collapse>
            <Collapse
              in={!orderData}
              unmountOnExit
            >
              <InputDefectComment
                handleChange={handleDefectComment}
                value={defectComment}
              />
            </Collapse>
            <PreviewCard
              in={!orderData}
              handleTermsAgree={setTermsAgree}
              handleDefectComment={handleDefectComment}
              defectComment={defectComment}
            />
            <PrintServiceOrder in={!!orderData} data={orderData} />
          </LoadingContent>
        </Box>
      </Box>
      <StepperButtons
        backStep={!orderData ? handleBackStep : null}
        nextStep={termsAgreem && !orderData ? handleConfirm : null}
        isSubmitting={isSubmitting ? StepperButtons.SUBMITTING.NEXT : null}
        nextText={!orderData ? 'Confirmar e criar ordem' : 'Próximo'}
      />
    </>
  );
}

export default ConcludedOrder;
