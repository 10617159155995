import trocafone from '@images/trocafone-logo.png';

const domEle = document.createElement('div');
const checkList = (conditions) => {
  const mapCondition = { Y: 'Sim', N: 'Não', NA: 'Não se aplica' };
  return `
    <div class="row mx-1 my-n1">
      ${conditions.map(
    ({ question, condition }) => `
          <div class="col-auto col-sm-3">
            <p style="font-weight: 600">${question}</p>
          </div>
          <div class="col-auto col-sm-3">${mapCondition[condition]}</div>
        `,
  )}
    </div>`;
};
const shtml = (value) => {
  domEle.innerText = value ?? '';
  return domEle.innerHTML;
};

const getPageHtml = (customer, order, partner, pageName) => {
  const { billing, delivery } = customer;
  return `<div class="container pt-3">
	<div class="row">
		<div class="col-12 text-center">
			<h4>Ordem de Serviço</h4>
		</div>
	</div>
	<div class="row justify-content-between text-center">
		<div class="col-4" style="text-align:left">
			<h3>${shtml(partner?.branch_name)}</h3>
		</div>
		<div class="col-4" style="display:grid; place-items: center;">
			<!--?xml version="1.0" standalone="no" ?-->
			
      <div style="font-family: 'Libre Barcode 128';font-size: 65px;" class="mb-n4">
        ${shtml(order?.id)}
      </div>
			<label>${shtml(order?.id)}</label>
		</div>
		<div class="col-4">
			<!-- <h2>Trocafone Reparo</h2> -->
			<div class="align-items-center d-flex flex-column text-center">
				<img src="${shtml(trocafone)}" height="35rem">
				<label class="logoLabel">Reparo</label>
			</div>
		</div>
	</div>
	<hr>
	<h4>Dados do cliente</h4>
	<fieldset id="customer_data" class="customer_data mb-2">
		<div class="row mx-1 my-n1">
			<div class="col-auto col-sm-1">
				<p style="font-weight: 600">Nome:</p>
			</div>
			<div class="col-auto col-sm-4">
        ${shtml(customer?.name)}
			</div>
			<div class="col-auto col-sm-1">
				<p style="font-weight: 600">Email:</p>
			</div>
			<div class="col-auto col-sm-3">
				${shtml(customer?.email)}
      </div>
			<div class="col-auto col-sm-1">
				<p style="font-weight: 600">Celular:</p>
			</div>
			<div class="col-auto col-sm-2">
        ${shtml(customer?.phone)}
      </div>
		</div>

		<div class="row mx-1 my-n1">

	</div></fieldset>
	<fieldset>
		<h5>Endereço de cobrança</h5>
		<div class="row mx-1 my-n1">
			<div class="col-auto col-sm-1">
				<p style="font-weight: 600">CEP:</p>
			</div>
			<div class="col-auto col-sm-2">
				${shtml(billing?.zipCode)}
      </div>
			<div class="col-auto col-sm-1">
				<p style="font-weight: 600">Estado:</p>
			</div>
			<div class="col-auto col-sm-3">
        ${shtml(billing?.state)}
      </div>
			<div class="col-auto col-sm-1">
				<p style="font-weight: 600">Cidade:</p>
			</div>
			<div class="col-auto col-sm-2">
        ${shtml(billing?.city)}
      </div>
		</div>
		<div class="row mx-1 my-n1">
			<div class="col-auto col-sm-1">
				<p style="font-weight: 600">Bairro:</p>
			</div>
			<div class="col-auto col-sm-2">
        ${shtml(billing?.neighborhood)}
			</div>
			<div class="col-auto col-sm-1">
				<p style="font-weight: 600">Rua:</p>
			</div>
			<div class="col-auto col-sm-5">
        ${shtml(billing?.street)}
      </div>
		</div>
		<div class="row mx-1 my-n1">

			<div class="col-auto col-sm-1">
				<p style="font-weight: 600">Número:</p>
			</div>
			<div class="col-auto col-sm-2">
				${shtml(billing?.number)}
      </div>
			<div class="col-auto col-sm-1">
				<p style="font-weight: 600">Compl.:</p>
			</div>
			<div class="col-auto">
        ${shtml(billing?.complement)}
			</div>
		</div>
	</fieldset>
	<fieldset>
		<h5>Endereço de entrega</h5>
		<div class="row mx-1 my-n1">
			<div class="col-auto col-sm-1">
				<p style="font-weight: 600">CEP:</p>
			</div>
			<div class="col-auto col-sm-2">
				${shtml(delivery?.zipCode)}
      </div>
			<div class="col-auto col-sm-1">
				<p style="font-weight: 600">Estado:</p>
			</div>
			<div class="col-auto col-sm-3">
        ${shtml(delivery?.state)}
      </div>
			<div class="col-auto col-sm-1">
				<p style="font-weight: 600">Cidade:</p>
			</div>
			<div class="col-auto col-sm-2">
        ${shtml(delivery?.city)}
      </div>
		</div>
		<div class="row mx-1 my-n1">
			<div class="col-auto col-sm-1">
				<p style="font-weight: 600">Bairro:</p>
			</div>
			<div class="col-auto col-sm-2">
        ${shtml(delivery?.neighborhood)}
			</div>
			<div class="col-auto col-sm-1">
				<p style="font-weight: 600">Rua:</p>
			</div>
			<div class="col-auto col-sm-5">
        ${shtml(delivery?.street)}
      </div>
		</div>
		<div class="row mx-1 my-n1">

			<div class="col-auto col-sm-1">
				<p style="font-weight: 600">Número:</p>
			</div>
			<div class="col-auto col-sm-2">
				${shtml(delivery?.number)}
      </div>
			<div class="col-auto col-sm-1">
				<p style="font-weight: 600">Compl.:</p>
			</div>
			<div class="col-auto">
        ${shtml(delivery?.complement)}
			</div>
		</div>
	</fieldset>
	<hr>
	<h4>Dados da ordem</h4>
	<fieldset id="order_data" class="py-2 order_data mb-2">
		<div class="row mx-1 my-n1">
			<div class="col-auto col-sm-3">
				<p style="font-weight: 600">Número da ordem:</p>
			</div>
			<div class="col-auto col-sm-3">
        ${shtml(order?.id)}
      </div>
			<div class="col-auto col-sm-2">
				<p style="font-weight: 600">Data de criação:</p>
			</div>
			<div class="col-auto">
        ${shtml(order?.createdAt || new Date().toLocaleDateString())}
      </div>
		</div>
		<div class="row mx-1 my-n1">
			<div class="col-auto col-sm-3">
				<p style="font-weight: 600">Defeitos informados:</p>
			</div>
			<div class="col-auto col-sm-3">
        ${shtml(JSON.parse(order?.defects).join(', '))}
      </div>
			<div class="col-auto col-sm-2">
				<p style="font-weight: 600">Imei informado:</p>
			</div>
			<div class="col-auto d-flex">
        ${shtml(order?.imei ?? 'Não informado')}
      </div>
		</div>
		<div class="row mx-1 my-n1">
			<div class="col-auto col-sm-3">
				<p style="font-weight: 600">Produto informado:</p>
			</div>
			<div class="col-auto col-sm-3">
        ${shtml(order?.product.name)}
      </div>
			<div class="col-sm-2">
				<p style="font-weight: 600">Valor do reparo:</p>
			</div>
			<div class="col-auto">
        R$ ${shtml(order?.budget / 100)}
      </div>
		</div>
		<div class="row mx-1 my-n1">
			<div class="col-auto col-sm-3">
				<p style="font-weight: 600">Observações do aparelho:</p>
			</div>
			<div class="col-auto col-sm-3">
        ${shtml(order?.commentDefects)}
			</div>
			<div class="col-auto col-sm-2">
				<p style="font-weight: 600">Autorizada:</p>
			</div>
			<div class="col-auto">
		${shtml(partner?.branch_name)}
      </div>
		</div>
		${checkList(order.conditions)}
		<div class="row mx-1 my-n1">
			<div class="col-auto col-sm-3">
				<p style="font-weight: 600">Prazo de Reparo:</p>
			</div>
			<div class="col-auto col-sm-3">3 dias úteis</div>
		</div>
		<div class="row mx-1 my-n1">
			<div class="col-auto col-sm-3">
				<p style="font-weight: 600">Termos de serviço:</p>
			</div>
			<div class="col-auto">
			${shtml(partner?.service_terms)}
			</div>
		</div>
	</fieldset>
	<div class="row justify-content-between text-center mt-5">
		<div class="col-4">
			<hr>
			<label>${customer.name}</label>
		</div>
		<div class="col-4">
			<hr>
			<label style="text-transform: uppercase;">${shtml(partner?.branch_name)}</label>
		</div>
	</div>
	<div class="row justify-content-center text-center">
		<div class="col">
		<label>${pageName}</label>
		</div>
	</div>
</div>
<p style="page-break-after: always;">&nbsp;</p>`;
};
export default function getPrintView(customer, order, partner) {
  const pages = [partner?.branch_name, 'Cliente', 'Trocafone'].map((page) => getPageHtml(customer, order, partner, page));
  return `
<html><head>

	<link rel="preconnect" href="https://fonts.gstatic.com">
	<link href="https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&amp;display=swap" rel="stylesheet">

	<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.6.0/dist/css/bootstrap.min.css" integrity="sha384-B0vP5xmATw1+K9KRQjQERJvTumQW0nPEzvF6L/Z6nronJ3oUOFUFpCjEUQouq2+l" crossorigin="anonymous">
	<script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.14.3/umd/popper.min.js" integrity="sha384-ZMP7rVo3mIykV+2+9J3UJ46jBk0WLaUAdn689aCwoqbBJiSnjAK/l8WvCWPIPm49" crossorigin="anonymous"></script>
	<script src="https://code.jquery.com/jquery-3.5.1.slim.min.js" integrity="sha384-DfXdz2htPH0lsSSs5nCTpuj/zy4C+OGpamoFVy38MVBnE+IbbVYUew+OrCXaRkfj" crossorigin="anonymous"></script>
	<script src="https://cdn.jsdelivr.net/npm/bootstrap@4.6.0/dist/js/bootstrap.bundle.min.js" integrity="sha384-Piv4xVNRyMGpqkS2by6br4gNJ7DXjqk09RmUpJ8jgGtD7zP9yug3goQfGII0yAns" crossorigin="anonymous"></script>
  <link href='https://fonts.googleapis.com/css?family=Libre+Barcode+128' rel='stylesheet'>
	<style>		
		@media only screen {
			html {
				overflow: auto;
			}
			body{
				height: 100%;
				overflow: scroll;
			}
		}
    body {
      height: 100%;
    }
		.logoLabel {
			color: #808083;
			font-size: 1.5rem;
			font-weight: 500;
			font-family: 'Poppins';
		}
	</style>
</head>
<body>${pages}</body>
<script type="text/javascript">
	const waitUntilImageIsLoaded = () => {
		return new Promise((resolve) => {
			let imagesLoaded = false;
			setInterval(() => {
				imagesLoaded = !Array.from($('img')).map(img => img.complete).includes(false);
				if(imagesLoaded)
					resolve(imagesLoaded);
			},500);
		})
	}
	waitUntilImageIsLoaded().then(() => {
		window.print();
	});
</script>
</html>`;
}
