import React from 'react';
import { Icon, InputAdornment, TextField } from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from './PropTypes';
import InputImeiRules from './Rules';
import useStyle from '../../../../style';

export default function InputImei({
  handleChange, handleBlur, errors, value, helperText, ...rest
}) {
  const classes = useStyle();
  return (
    <TextField
      fullWidth
      name="imei"
      variant="outlined"
      label="Imei do aparelho"
      placeholder="356000000000000"
      value={value}
      error={!!errors}
      onBlur={handleBlur}
      onChange={handleChange}
      helperText={errors || helperText}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Icon className={clsx(['fas', 'fa-mobile-alt', classes.inputIcon])} color="action" />
          </InputAdornment>
        ),
      }}
      {...rest}
    />
  );
}

InputImei.propTypes = PropTypes.propTypes;
InputImei.defaultProps = PropTypes.defaultProps;
InputImei.rules = InputImeiRules;
