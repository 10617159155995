export const scrollView = (offset, querySelector) => {
  const yOffset = offset;
  const element = document.querySelector(querySelector);
  const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
  return window.scrollTo({ top: y, behavior: 'smooth' });
};
export const attributionStyle = {
  right: 0,
  bottom: 0,
};
export const getBbox = (sortedStores, storeIdentifier, searchResult) => {
  const lats = [
    sortedStores[storeIdentifier].addresses[0].latitude,
    searchResult.coordinates[1],
  ];
  const lons = [
    sortedStores[storeIdentifier].addresses[0].longitude,
    searchResult.coordinates[0],
  ];
  const sortedLons = lons.sort((a, b) => {
    if (a > b) return 1;
    if (a.distance < b.distance) return -1;
    return 0;
  });
  const sortedLats = lats.sort((a, b) => {
    if (a > b) return 1;
    if (a.distance < b.distance) return -1;
    return 0;
  });
  return [
    [Number(sortedLons[0]), Number(sortedLats[0])],
    [sortedLons[1], sortedLats[1]],
  ];
};
