import gql from 'graphql-tag';

export const GetOrders = gql`
  query GetOrders(
    $id: ID
    $page: Int!
    $first: Int!
    $searchLike: Mixed
    $search: Mixed
  ) {
    ReparafoneGetOrders(
      orderBy: [{ column: ID, order: DESC }]
      id: $id
      page: $page
      first: $first
      where: {
        OR: [
          { column: ID, operator: EQ, value: $search }
          {
            HAS: {
              relation: "branch"
              condition: {
                column: BRANCH_NAME
                operator: LIKE
                value: $searchLike
              }
            }
          }
          {
            HAS: {
              relation: "customer"
              condition: {
                column: CUSTOMER_FIRST_NAME
                operator: LIKE
                value: $searchLike
              }
            }
          }
          {
            HAS: {
              relation: "customer"
              condition: {
                column: CUSTOMER_EMAIL
                operator: LIKE
                value: $searchLike
              }
            }
          }
          {
            HAS: {
              relation: "customer"
              condition: {
                column: CUSTOMER_CPF
                operator: LIKE
                value: $searchLike
              }
            }
          }
          {
            HAS: {
              relation: "status"
              condition: {
                column: STATUS_NAME
                operator: LIKE
                value: $searchLike
              }
            }
          }
          {
            HAS: {
              relation: "store"
              condition: {
                column: NAME
                operator: LIKE
                value: $searchLike
              }
            }
          }
        ]
      }
    ) {
      paginatorInfo {
        count
        currentPage
        total
        lastPage
        perPage
      }
      data {
        id
        order_imei
        order_created_at
        product {
          product_name
          color: product_color
          brand: product_brand
          model {
            id
            name
            brand
          }
          defects {
            id
            name
            description
            maxPrice
            isAble
            parts {
              part_name
              part_type
            }
          }
        }
        informedDefects
        repairedDefects
        status {
          status_name
        }
        estimates {
          revision_obs
          repair_obs
          labor
          created_at
          imei: phone_imei
          price: estimate_price
          revisions {
            defect {
              defect_name
            }
            parts {
              part_name
              part_type
              part_price_selling
            }
          }
          rootCauses {
            cause_name
          }
          repairResult {
            result_name
          }
        }
        warranty {
          warranty_name
        }
        isOutOfWarranty
        order_bko_id
        customer {
          fullName
          email: customer_email
          phone: customer_phone
          cpf: customer_cpf
          landline: customer_landline
        }
        collect {
          collect_name
          collect_slug
        }
        collectPrice
        deliveryPrice
        addresses {
          zipCode: zip_code
          state
          city
          neighborhood
          street
          number: street_number
          complement
          addressType: address_type
        }
        branch {
          branch_name
        }
        store {
          name
        }
        isCreatedWarranty: is_created_warranty
        delivery_date_limit
        isPaid
        totalPaid
        discountValue
        statusTransaction
      }
    }
  }
`;

export default GetOrders;
