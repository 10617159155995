import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Container } from '@material-ui/core';

const CenteredPanel = (props) => {
  const { children, superGridProps, childGridProps } = props;
  return (
    <Container>
      <Grid
        container
        alignItems="center"
        justify="center"
        style={{ minHeight: '80vh' }}
      // eslint-disable-next-line react/jsx-props-no-spreading
        {...superGridProps}
      >

        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <Grid item xs={12} sm={3} {...childGridProps}>
          {children}
        </Grid>
      </Grid>
    </Container>
  );
};

export default CenteredPanel;
CenteredPanel.propTypes = {
  children: PropTypes.node.isRequired,
};
