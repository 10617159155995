import React from 'react';
import { Box, Step, Stepper, StepLabel, Typography } from '@material-ui/core';
import StepConnector from '../StepConnector';
import StepIcon from '../StepIcon';
import steps from '../../utils/steps';
import ConnectComponentRedux from './ConnectComponentRedux';
import { StepperHeaderPropTypes } from './PropTypes';

const mapActiveStepToIconStep = [0, 1, 1, 2, 3, 4];
function StepperHeader({ $state }) {
  const { activeStep } = $state;

  return (
    <Stepper
      alternativeLabel
      activeStep={mapActiveStepToIconStep[activeStep]}
      connector={<StepConnector />}
    >
      {steps.map((label) => (
        <Step key={label}>
          <StepLabel StepIconComponent={StepIcon}>
            <Typography component={Box} variant="caption" m={0}>
              {label}
            </Typography>
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  );
}

StepperHeader.propTypes = StepperHeaderPropTypes;

export default ConnectComponentRedux(StepperHeader);
