import React from 'react';
import { Box, Button, Grid } from '@material-ui/core';
import Icon from '@material-ui/core/Icon';
import CircularProgress from '@material-ui/core/CircularProgress';
import styles from './style';
import {
  StepperButtonsPropTypes,
  StepperButtonsDefaultProp,
  SUBMITTING,
} from './PropTypes';

const LoadingCircularProgress = () => <CircularProgress size="1.2rem" />;

function StepperButtons(props) {
  const {
    backStep, nextStep, nextText, isSubmitting,
  } = props;
  const classes = styles();
  const blankFunc = () => null;

  return (
    <Box className={classes.stepperButtons}>
      <Grid container position="relative">
        <Button
          id="button-back"
          color="primary"
          variant="outlined"
          onClick={typeof backStep === 'function' ? backStep : blankFunc}
          disabled={!(typeof backStep === 'function') || !!isSubmitting}
          startIcon={
            isSubmitting === SUBMITTING.BACK
              ? <LoadingCircularProgress />
              : <Icon>arrow_back</Icon>
          }
        >
          Voltar
        </Button>
        <Button
          id="button-next"
          color="primary"
          variant="contained"
          onClick={typeof nextStep === 'function' ? nextStep : blankFunc}
          disabled={!(typeof nextStep === 'function') || !!isSubmitting}
          endIcon={
            isSubmitting === SUBMITTING.NEXT
              ? <LoadingCircularProgress />
              : <Icon>arrow_forward</Icon>
          }
        >
          { nextText }
        </Button>
      </Grid>
    </Box>
  );
}

StepperButtons.propTypes = StepperButtonsPropTypes;
StepperButtons.defaultProps = StepperButtonsDefaultProp;
StepperButtons.SUBMITTING = SUBMITTING;

export default StepperButtons;
