import React from 'react';
// MATERIAL-UI
import {
  Box, Typography, List,
} from '@material-ui/core';
// ICONS
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import EmailIcon from '@material-ui/icons/Email';
import PaymentIcon from '@material-ui/icons/Payment';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import LocalPhoneIcon from '@material-ui/icons/LocalPhone';
// PROP-TYPES
import PropTypes from './PropTypes';
// COMPONENTS
import { InfoData } from '../Common';
// UTILS
import {
  cpfFormatter,
  phoneFormatter,
  landlineFormatter,
} from './utils';
// STYLES
import styles from './styles';

const Customer = ({ customer }) => {
  const classes = styles();
  const [cpf, setCPF] = React.useState(null);
  const [phone, setPhone] = React.useState(null);
  const [landline, setLandline] = React.useState(null);
  React.useEffect(() => {
    setCPF(cpfFormatter(customer.cpf));
    setPhone(phoneFormatter(customer.phone));
    setLandline(phoneFormatter(customer.landline));
  }, []);
  return (
    <Box className={classes.box}>
      <Typography className={classes.title} variant="h2" align="left">
        Dados do Cliente
      </Typography>
      <List className={classes.list}>
        <InfoData
          title="Nome"
          info={customer.fullName}
          icon={<AccountBoxIcon className={classes.icon} />}
        />
        <InfoData
          title="E-mail"
          info={customer.email}
          icon={<EmailIcon className={classes.icon} />}
        />
        <InfoData
          title="CPF"
          info={cpf}
          icon={<PaymentIcon className={classes.icon} />}
        />
        <InfoData
          title="Telefone Fixo"
          info={landline}
          icon={<LocalPhoneIcon className={classes.icon} />}
        />
        <InfoData
          title="Celular"
          info={phone}
          icon={<PhoneIphoneIcon className={classes.icon} />}
        />
      </List>
    </Box>
  );
};

export default Customer;

Customer.propTypes = PropTypes.propTypes;
Customer.defaultProps = PropTypes.defaultProps;
