import React from 'react';
import {
  Grid,
  Tabs,
  Tab,
  withStyles,
  Card,
  makeStyles,
  IconButton,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Drawer,
  Icon,
} from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import MenuIcon from '@material-ui/icons/Menu';
import { indexOf, snakeCase } from 'lodash';
import ModalNeoAssist from './ModalNeoAssist';
import MenuItems from './MenuItems';

import ReparofoneLogo from '../../images/Reparafone-logo.png';

const useStyles = makeStyles((theme) => ({
  desktopMenu: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  mobileMenu: {
    display: 'block',
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  wrapper: {
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'space-between',
    },
  },
  listMenu: {
    '& .MuiListItem-gutters': {
      paddingRight: '3rem',
    },
  },
}));

let GMTLoaded = false;
let GMTNoScriptLoaded = false;

const Navbar = (props) => {
  const classes = useStyles();
  const location = window.location.pathname;
  const [activeTab, setActiveTab] = React.useState(
    location === '/customer/repairs' ? 0 : 1,
  );
  const [popup, setPopup] = React.useState(false);
  const handleChange = (e, value) => {
    setActiveTab(value);
  };
  const openPopUpNeoAssist = () => {
    setPopup(true);
  };
  const closePopUpNeoAssist = () => {
    setPopup(false);
  };
  const [drawerState, setDrawerState] = React.useState(false);
  const toggleDrawer = (state) => () => {
    setDrawerState(state);
  };
  const drawerItems = () => (
    <div onClick={toggleDrawer(false)}>
      <List className={classes.listMenu}>
        {MenuItems.filter((r) => !!~indexOf(r.appearIn, 'drawer')).map(
          (item) => (
            <ListItem
              button
              component={item.to ? NavLink : ListItem}
              to={{ pathname: item.to }}
              onClick={item.openPopupAssist ? openPopUpNeoAssist : () => {}}
              key={item.label}
            >
              <ListItemIcon>
                <Icon>{snakeCase(item.icon)}</Icon>
              </ListItemIcon>
              <ListItemText primary={item.label} />
            </ListItem>
          ),
        )}
      </List>
    </div>
  );

  React.useEffect(() => {
    if (GMTLoaded === false) {
      GMTLoaded = true;
      // Google Tag Manager
      (function (w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({
          'gtm.start': new Date().getTime(),
          event: 'gtm.js',
        });
        const f = d.getElementsByTagName(s)[0];
        const j = d.createElement(s);
        const dl = l !== 'dataLayer' ? `&l=${l}` : '';
        j.async = true;
        j.src = `https://www.googletagmanager.com/gtm.js?id=${i}${dl}`;
        f.parentNode.insertBefore(j, f);
      }(window, document, 'script', 'dataLayer', 'GTM-597BDG5'));
      // End Google Tag Manager
    }
  }, []);
  return (
    <>
      {/* Google Tag Manager (noscript) */}
      {!GMTNoScriptLoaded && (
      <noscript>
        <iframe
          title="GTM-597BDG5"
          src="https://www.googletagmanager.com/ns.html?id=GTM-597BDG5"
          height="0"
          width="0"
          style={{ display: 'none', visibility: 'hidden' }}
        />
      </noscript>
      )}
      {/* End Google Tag Manager (noscript) */}
      <ModalNeoAssist open={popup} handleClose={closePopUpNeoAssist} />
      {props.children && (
        <Card>
          <Grid
            container
            className={classes.wrapper}
            direction="row"
            alignItems="center"
            spacing={2}
            style={{ paddingLeft: '0.5rem', paddingTop: '0.7rem' }}
          >
            <Grid item>
              <a href="/novaordem">
                <img
                  src={ReparofoneLogo}
                  alt="Reparofone"
                  style={{ width: '150px' }}
                />
              </a>
            </Grid>
            <Grid item sm>
              <MenuTabs
                className={classes.desktopMenu}
                value={activeTab}
                onChange={handleChange}
              >
                {MenuItems.filter((r) => !!~indexOf(r.appearIn, 'main')).map(
                  ({ label, to }) => (
                    <Tab
                      key={label}
                      label={label}
                      to={{ pathname: to }}
                      component={NavLink}
                    />
                  ),
                )}
              </MenuTabs>
            </Grid>
            <Grid item>
              <Button
                className={classes.desktopMenu}
                onClick={openPopUpNeoAssist}
                size="small"
              >
                Precisa de ajuda?
              </Button>
            </Grid>
            <Grid item>
              <IconButton onClick={toggleDrawer(true)}>
                <MenuIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Card>
      )}
      <Drawer anchor="right" open={drawerState} onClose={toggleDrawer(false)}>
        {drawerItems()}
      </Drawer>
      {props.children}
    </>
  );
};

export default Navbar;
GMTNoScriptLoaded = true;
const MenuTabs = withStyles({
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    '& > span': {
      maxWidth: 40,
      width: '100%',
      backgroundColor: '#3c3584',
    },
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);
