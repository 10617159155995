import { client } from '../../../../Apollo';
import { GetNpsFeedbacks } from '../query/GetNpsFeedbacks';

const getNpsFeedbacks = () => new Promise((resolve, reject) => {
  client
    .query({
      query: GetNpsFeedbacks,
      fetchPolicy: 'network-only',
    })
    .then((r) => {
      resolve(r.data.ReparafoneGetNpsFeedbacks);
    })
    .catch((e) => {
      console.log(e.message, 'erro ao buscar');
      reject(e.message);
    });
});

export default getNpsFeedbacks;
