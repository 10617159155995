/* eslint-disable react/jsx-no-duplicate-props */
import React from 'react';
import * as Yup from 'yup';
import { Icon, InputAdornment, TextField } from '@material-ui/core';
import clsx from 'clsx';
import { propTypes, defaultProps } from '../Utils/TextFieldPropTypes';
import useStyle from '../../../../../style';

export default function InputName({
  handleChange, handleBlur, handleFocus, value, error, typeInput, disabled,
}) {
  const classes = useStyle();

  return (
    <TextField
      fullWidth
      size="small"
      autoComplete="off"
      label="Nome do titular"
      value={value}
      variant={typeInput}
      onBlur={handleBlur}
      disabled={disabled}
      onFocus={handleFocus}
      onChange={handleChange}
      error={!!error}
      helperText={error || null}
      inputProps={{
        id: 'cardholderName',
        'data-checkout': 'cardholderName',
        style: {
          textTransform: 'uppercase',
        },
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Icon className={clsx(classes.inputIcon, 'fas', 'fa-user')} />
          </InputAdornment>
        ),
      }}
    />
  );
}

InputName.propTypes = propTypes;
InputName.defaultProps = defaultProps;
InputName.rules = Yup
  .string()
  .matches(/^[A-Za-z ]+$/gm, 'Apenas letras')
  .required('Este campo é obrigatório');
