import * as P from 'prop-types';

const propTypes = {
  order: P.arrayOf(P.shape({
    id: P.string.isRequired,
    order_created_at: P.string.isRequired,
    status: P.shape({
      status_name: P.string.isRequired,
    }).isRequired,
    delivery_date_limit: P.string.isRequired,
  })).isRequired,
};

export default {
  propTypes,
  defaultProps: {},
};
