import React from 'react';
// MATERIAL-UI
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core/';
// COMPONENTS
import DialogRefused from './components/DialogRefused';
// PropTypes
import PropTypes from './PropTypes';
// STYLES
import styles from './style';

export default function Form({
  open, clickToClose, handleAlert, setStep,
}) {
  const classes = styles();
  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="refuse-budget"
      >
        <DialogTitle id="refuse-budget">
          <Typography className={classes.title} variant="h1" component="h2">
            Poderia nos informar o motivo da recusa do Orçamento?
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogRefused
            setProductStep={setStep}
            clickToClose={clickToClose}
            handleAlert={handleAlert}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}

Form.propTypes = PropTypes.propTypes;
Form.defaultProps = PropTypes.defaultProps;
