import React from 'react';
import {
  Grid,
  Typography,
  useTheme,
  useMediaQuery,
  Paper,
} from '@material-ui/core';
import { useStyles } from './style';
import OrderInfo from '../../OrderInfo';

const Addresses = ({
  title,
  zipCode,
  state,
  city,
  neighborhood,
  street,
  streetNumber,
  complement,
  reference,
}) => {
  const theme = useTheme();
  const classes = useStyles();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Grid
      item
      container
      direction="column"
      lg={4}
      md={6}
      xs={12}
      justify="center"
      className={classes.marginBt}
    >
      <Paper className={classes.paper}>
        <Grid
          item
          container
          direction="row"
          spacing={isMobile ? 4 : 2}
          style={{ marginBottom: '10px' }}
        >
          <Grid item xs={12}>
            <Typography variant="h6" className={classes.title}>{title}</Typography>
          </Grid>
        </Grid>
        <Grid item container direction="row" spacing={isMobile ? 4 : 2}>
          <Grid item xs={12}>
            <OrderInfo label="CEP" value={zipCode} />
          </Grid>
        </Grid>
        <Grid item container direction="row" spacing={isMobile ? 4 : 2}>
          <Grid item xs={12}>
            <OrderInfo label="Estado" value={state} />
          </Grid>
        </Grid>
        <Grid item container direction="row" spacing={isMobile ? 4 : 2}>
          <Grid item xs={12}>
            <OrderInfo label="Cidade" value={city} />
          </Grid>
        </Grid>
        <Grid item container direction="row" spacing={isMobile ? 4 : 2}>
          <Grid item xs={12}>
            <OrderInfo label="Bairro" value={neighborhood} />
          </Grid>
        </Grid>
        <Grid item container direction="row" spacing={isMobile ? 4 : 2}>
          <Grid item xs={12}>
            <OrderInfo label="Rua" value={`${street}, ${streetNumber}`} />
          </Grid>
        </Grid>
        <Grid item container direction="row" spacing={isMobile ? 4 : 2}>
          <Grid item xs={12}>
            <OrderInfo label="Complemento" value={complement} />
          </Grid>
        </Grid>
        <Grid item container direction="row" spacing={isMobile ? 4 : 2}>
          <Grid item xs={12}>
            <OrderInfo label="Referência" value={reference} />
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

export default Addresses;
