import { loadCSS } from 'fg-loadcss';
import React from 'react';
import ReactGA from 'react-ga';
import jQuery from 'jquery';
// REDUX
import { useSelector } from 'react-redux';
import FormStepper from './components/FormStepper';

function Form() {
  const customerData = useSelector((state) => state.NewRepairReducer);
  const { activeStep } = customerData;
  const partnerData = useSelector((state) => state.PartnerStoreReducer);
  const { branch } = partnerData;
  React.useEffect(() => {
    const node = loadCSS(
      'https://use.fontawesome.com/releases/v5.12.0/css/all.css',
      document.querySelector('#font-awesome-css'),
    );
    return () => {
      node.parentNode.removeChild(node);
    };
  }, []);
  React.useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      switch (activeStep) {
        case 1:
          return ReactGA.event({
            category: `createOrder${branch.branch_name}`,
            action: 'Step 3',
            label: 'Entrega e Coleta',
            value: 3,
          });
        case 2:
          return ReactGA.event({
            category: `createOrder${branch.branch_name}`,
            action: 'Step 4',
            label: 'Sobre Você',
            value: 4,
          });
        case 3:
          return ReactGA.event({
            category: `createOrder${branch.branch_name}`,
            action: 'Step 5',
            label: 'Ordem de Serviço',
            value: 5,
          });
        default:
          return console.log('Não há evento');
      }
    }
  }, [activeStep]);
  function handleCTA() {
    if (process.env.NODE_ENV === 'production') {
      ReactGA.event({
        category: `createOrder${branch.branch_name}`,
        action: 'Step 1',
        label: 'Foi até o Formulário',
        value: 1,
      });
    }
  }
  let scrolled = false;
  jQuery(window).scroll(() => {
    const { pathname } = window.location;
    if (pathname !== `/onlinepartner/${branch.id}`) return;
    if (
      jQuery('#do-estimate').offset().top <= jQuery(window).scrollTop()
      && scrolled === false
    ) {
      scrolled = true;
      handleCTA();
    }
  });
  return (
    <div id="do-estimate">
      <FormStepper />
    </div>
  );
}

export default Form;
