import React from 'react';
// YUP
import * as Yup from 'yup';
// MATERIAL-UI
import {
  Box, Icon, IconButton, Typography,
  Collapse, FormControlLabel, Checkbox,
  Grid, Button,
} from '@material-ui/core';
// ICONS
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
// FORMIK
import { useFormik } from 'formik';
// REDUX
import { useSelector, useDispatch } from 'react-redux';
import {
  setCollect,
  setDelivery,
  setBillingAddress,
  setStep,
  middlewares,
  setCollectData,
  resetDeliveryModalities,
  resetCollectModalities,
  setCollectModality,
} from '@redux/actions/NewRepairActions';
// DATE FORMATTERS
import {
  format, add, isWeekend, isFriday, parse,
} from 'date-fns';
import Holidays from '@/utils/Holidays';
// COMPONENTS
import FocusError from '@/utils/FocusError';
import FormAddress from '../FormAddress';
import Modality from './Components/InputDate';
import StepperButtons from '../StepperButtons';
import InputZipCode from './Components/InputZipCode';
// FUNCTIONS
import {
  checkZipCode,
  checkDeliveryCep,
} from '../../../../utils/GetShipping';
import searchZipCodeInfo from '../../../../utils/SearchZipCodeInfo';
import { factoryAddressFields } from './Common';
import InlineAlertCollapse from '../InlineAlertCollapse';
import ScheduleCollect from './Components/InputDate/ScheduleCollect';
// STYLES
import useStyles from './style';

function MoreAboutCustomer() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const customerData = useSelector((state) => state.NewRepairReducer);
  const { customer, activeStep } = customerData;
  const { collect, delivery } = customer;
  const [selectedMethod, setSelectedMethod] = React.useState({});
  const [isEqualCollect, setEqualCollect] = React.useState(true);
  const [collectData, setCollectZipCode] = React.useState(null);
  const [isLoading, setLoading] = React.useState(false);
  const [disabledButton, setDisabledButton] = React.useState(true);
  const [alert, setAlert] = React.useState({
    show: false,
    message: null,
    severity: null,
  });

  const showAlert = ({ message, severity }) => setAlert({
    message,
    severity,
    show: true,
  });
  const closeAlert = () => setAlert({
    ...alert,
    show: false,
  });

  const initialCollectDate = () => {
    if (isWeekend(add(new Date(), { days: 1 }))) {
      return add(new Date(), { days: 3 });
    }
    if (Holidays.isHoliday(add(new Date(), { days: 1 }))) {
      if (isFriday(add(new Date(), { days: 1 }))) return add(new Date(), { days: 4 });
      return add(new Date(), { days: 2 });
    }
    return add(new Date(), { days: 1 });
  };
  const [selectedDate, setDate] = React.useState(initialCollectDate);

  const formik = useFormik({
    initialValues: {
      zipCode: collect.zipCode,
      collect: factoryAddressFields(collect),
      delivery: isEqualCollect ? factoryAddressFields(collect) : factoryAddressFields(delivery),
      chooseCollect: customerData.collect.chosenModality.collect_slug,
      collectDate: customerData.collect?.schedule_collect
        ? parse(customerData.collect?.schedule_collect, 'dd-MM-yyyy', new Date())
        : selectedDate,
    },

    validationSchema: Yup.object({
      zipCode: InputZipCode.rules,
      collect: Yup.object(FormAddress.rules),
      delivery: isEqualCollect ? null : Yup.object(FormAddress.rules),
      chooseCollect: Yup.string().required(
        'Você deve selecionar uma modalidade de coleta',
      ),
      collectDate: Yup.date().required(
        'Selecione uma data válida para coleta do aparelho',
      ),
    }),

    enableReinitialize: true,

    onSubmit: async (values) => {
      const formattedDate = format(values.collectDate, 'dd-MM-yyyy');
      if (values.chooseCollect) {
        dispatch(setCollectData({ schedule_collect: formattedDate }));
      }

      if (!collect.isChartered) {
        const response = await checkZipCode(values.collect.zipCode);
        if (response) {
          dispatch(setCollect(values.collect));
          dispatch(setBillingAddress(values.collect));
        } else {
          return setAlert({
            show: true,
            severity: 'error',
            message:
              'Ainda não temos abrangência para o CEP de coleta, sentimos muito 😢',
          });
        }
      }

      if (!delivery.isChartered) {
        if (!isEqualCollect) {
          const response = await checkDeliveryCep(values.delivery.zipCode);
          if (response) dispatch(setDelivery(values.delivery));
          else {
            return setAlert({
              show: true,
              severity: 'error',
              message:
                'Ainda não temos abrangência para este CEP de entrega, sentimos muito 😢',
            });
          }
        } else {
          const response = await checkDeliveryCep(values.collect.zipCode);
          if (response) dispatch(setDelivery(values.collect));
          else {
            return setAlert({
              show: true,
              severity: 'error',
              message:
                'Ainda não temos abrangência para este CEP de entrega, sentimos muito 😢',
            });
          }
        }
      }

      try {
        dispatch(middlewares.calculateTotalBudgetAmount());
        return dispatch(setStep(activeStep + 1));
      } catch (error) {
        setAlert({
          show: true,
          severity: 'error',
          message:
            'Não foi possível estimar o frete, estamos passando por problemas, tente novamente mais tarde, sentimos muito 😢',
        });
      }
    },
  });

  const isSubmitting = formik.isSubmitting
    ? StepperButtons.SUBMITTING.NEXT
    : null;

  const backStep = () => {
    dispatch(
      setCollect({
        ...collectData,
        zipCode: formik.values.zipCode ?? '',
        isChartered: false,
        blockZipCodeEdit: true,
      }),
    );
    dispatch(
      setDelivery({
        ...collectData,
        zipCode: formik.values.zipCode ?? '',
        isChartered: false,
        blockZipCodeEdit: false,
      }),
    );
    if (formik.values.chooseCollect) {
      dispatch(setCollectData(formik.values.collectDate));
    }
    dispatch(setStep(activeStep - 1));
  };

  const isDisabledField = (typeAddress) => (field) => field === 'zipCode' && typeAddress.blockZipCodeEdit;

  const setValuesAddress = (field) => (newValues) => formik.setValues((values) => ({
    ...values,
    [field]: {
      ...values[field],
      ...newValues,
    },
  }));

  React.useEffect(() => {
    document
      .querySelector('#top-content')
      .scrollIntoView({ behavior: 'smooth' });
  }, []);

  const autoFill = () => {
    if (collect.isChartered) return null;
    return formik.setFieldValue('delivery', {
      zipCode: collect.zipCode,
      state: collect.state,
      city: collect.city,
      neighborhood: collect.neighborhood,
      street: collect.street,
      number: collect.number,
      complement: collect.complement,
    });
  };

  const toggleEqualCollect = () => setEqualCollect(!isEqualCollect);

  const calculateShipping = () => {
    if (String(formik.values.zipCode ?? '').length === 9) {
      setLoading(true);
      setDisabledButton(true);
      dispatch(resetCollectModalities());
      dispatch(resetDeliveryModalities());
      dispatch(setCollectModality({ collect_slug: undefined }));
      if (collect.zipCode) {
        dispatch(
          setCollect({
            city: undefined,
            complement: undefined,
            neighborhood: undefined,
            number: undefined,
            state: undefined,
            street: undefined,
            zipCode: undefined,
            isChartered: false,
            blockZipCodeEdit: false,
          }),
        );
        dispatch(
          setDelivery({
            city: undefined,
            complement: undefined,
            neighborhood: undefined,
            number: undefined,
            state: undefined,
            street: undefined,
            zipCode: undefined,
            isChartered: false,
            blockZipCodeEdit: false,
          }),
        );
      }
      (async () => {
        try {
          const response = await searchZipCodeInfo(formik.values.zipCode);
          if (response) setCollectZipCode(response);
          const responseZipCode = await checkZipCode(formik.values.zipCode);
          if (responseZipCode) {
            dispatch(
              setCollect({
                ...response,
                zipCode: formik.values.zipCode ?? '',
                isChartered: false,
                blockZipCodeEdit: true,
              }),
            );
            dispatch(
              setDelivery({
                ...response,
                zipCode: formik.values.zipCode ?? '',
                isChartered: false,
                blockZipCodeEdit: false,
              }),
            );
          } else {
            showAlert({
              message:
                'Ainda não temos abrangência para o CEP selecionado, para prosseguir é necessário inserir um CEP válido do estado de São Paulo.',
              severity: 'error',
            });
          }
        } catch (e) {
          showAlert({
            message:
              'Não foi possível encontrar as informações deste CEP, mas não se preocupe, você pode nos fornecer mais detalhes nos próximo passos.',
            severity: 'warning',
          });
        } finally {
          setLoading(false);
        }
      })();
    }
  };

  return (
    <>
      <Box maxWidth={668}>
        <form onSubmit={formik.handleSubmit}>
          <Box py={1}>
            <Box py={2}>
              <Typography variant="h6">Local de Coleta do Aparelho</Typography>
            </Box>
            <Grid container>
              <Grid item xs={12} md={8} className={classes.cepAvailable}>
                <InputZipCode
                  disabled={formik.isSubmitting}
                  typeInput="outlined"
                  value={formik.values.zipCode}
                  errors={formik.touched.zipCode ? formik.errors.zipCode : null}
                  handleChange={formik.handleChange}
                  handleBlur={formik.handleBlur}
                  isLoading={isLoading}
                  setFieldValue={formik.setFieldValue}
                  setDisabledButton={setDisabledButton}
                />
              </Grid>
              <Grid item xs={12} md={4} className={classes.cepAvailable}>
                <Button
                  fullWidth
                  size="large"
                  color="secondary"
                  variant="contained"
                  onClick={calculateShipping}
                  disabled={isLoading || disabledButton}
                  endIcon={<LocalShippingIcon fontSize="small" />}
                >
                  Calcular Frete
                </Button>
              </Grid>
            </Grid>
          </Box>
          <Box width="100%" py={1} display="flex" />
          <Box py={1}>
            <Collapse in={collect.zipCode}>
              <Box py={1}>
                <Typography variant="h6">Data de Coleta</Typography>
              </Box>
              <Box
                display="flex"
                style={{ flexWrap: 'wrap', justifyContent: 'space-between' }}
                width="100%"
              >
                <Modality
                  disabled={formik.isSubmitting}
                  value={formik.values.chooseCollect}
                  errors={
                  formik.touched.chooseCollect
                    ? formik.errors.chooseCollect
                    : null
                }
                  handleChange={formik.handleChange}
                  handleBlur={formik.handleBlur}
                  setSelectedMethod={setSelectedMethod}
                  selectedMethod={selectedMethod}
                  setDate={formik.setFieldValue}
                />
                <Collapse in={customerData.collect.chosenModality?.collect_slug}>
                  <>
                    <ScheduleCollect
                      collectId={formik.values.chooseCollect}
                      disabled={formik.isSubmitting}
                      value={formik.values.collectDate}
                      errors={
                      formik.touched.collectDate
                        ? formik.errors.collectDate
                        : null
                    }
                      handleChangeDate={formik.handleChange}
                      selectedMethod={selectedMethod}
                      setDate={setDate}
                      setFieldValue={formik.setFieldValue}
                    />
                  </>
                </Collapse>
              </Box>
            </Collapse>
          </Box>
          <Box width="100%" py={1} display="flex" />
          <Box py={1}>
            <Typography variant="h6">Endereço</Typography>
            <Box py={1} mx="auto" width="100%" maxWidth={560}>
              <Box py={1}>
                <InlineAlertCollapse
                  show={alert.show}
                  message={alert.message}
                  severity={alert.severity}
                  button={(
                    <IconButton onClick={closeAlert}>
                      <Icon>close</Icon>
                    </IconButton>
                  )}
                />
              </Box>
              <FormAddress
                isEditable={!collect.isChartered && !formik.isSubmitting}
                prefix="collect"
                title="Coleta"
                desc="Endereço no qual iremos coletar seu aparelho"
                handleBlur={formik.handleBlur}
                handleChange={formik.handleChange}
                values={formik.values.collect}
                touched={formik.touched.collect}
                errors={formik.errors.collect}
                isDisabledField={isDisabledField(collect)}
                setValues={setValuesAddress('collect')}
                defaultShow
              />
              <Box display="flex" py={1} />
              <Collapse in={isEqualCollect}>
                <FormControlLabel
                  control={(
                    <Checkbox
                      checked={isEqualCollect}
                      onChange={toggleEqualCollect}
                      name="equalCollect"
                      color="primary"
                    />
                      )}
                  label="Usar mesmo endereço de coleta para entrega"
                />
              </Collapse>
              <Collapse in={!isEqualCollect}>
                <FormAddress
                  isEditable={!delivery.isChartered && !formik.isSubmitting}
                  prefix="delivery"
                  title="Entrega"
                  autoFillLabel="Preencher com endereço de coleta"
                  desc="Endereço no qual iremos entregar seu aparelho"
                  autoFill={autoFill}
                  handleBlur={formik.handleBlur}
                  handleChange={formik.handleChange}
                  values={formik.values.delivery}
                  touched={formik.touched.delivery}
                  errors={formik.errors.delivery}
                  isDisabledField={isDisabledField(delivery)}
                  setValues={
                    isEqualCollect
                      ? setValuesAddress('collect')
                      : setValuesAddress('delivery')
                  }
                  isEqualCollect={isEqualCollect}
                  setEqualCollect={toggleEqualCollect}
                />
              </Collapse>
            </Box>
          </Box>
          <FocusError
            errors={formik.errors}
            isSubmitting={formik.isSubmitting}
            isValidating={formik.isValidating}
          />
        </form>
      </Box>
      <StepperButtons
        backStep={backStep}
        nextStep={formik.submitForm}
        isSubmitting={isSubmitting}
      />
    </>
  );
}

export default MoreAboutCustomer;
