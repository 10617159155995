import { createAction } from '@reduxjs/toolkit';
import { StorePartnerTypes } from '../reducers/PartnerStoreReducer';

export const setEmployee = createAction(StorePartnerTypes.SET_EMPLOYEE);
export const setStore = createAction(StorePartnerTypes.SET_STORE);
export const setBranch = createAction(StorePartnerTypes.SET_BRANCH);

export default {
  setEmployee, setStore, setBranch,
};
