import gql from 'graphql-tag';

export const GetBranches = gql`
  query GetBranches {
    ReparafoneGetBranchesAll(
      appear_site: 1
    ) {
      id
      branch_name
      branch_address
      branch_slug
      service_terms
      created_at
      appear_site
    }
  }
`;

export default GetBranches;
