import { client } from '../../../../Apollo';
import { GetPartnerUser } from '../query/GetPartnerUser';

const getPartnerUser = () => new Promise((resolve, reject) => {
  client
    .query({
      query: GetPartnerUser,
      fetchPolicy: 'network-only',
    })
    .then((r) => {
      resolve(r.data.ReparafonePartnerUser);
    })
    .catch((e) => {
      console.log(e.message, 'erro ao buscar');
      reject(e.message);
    });
});

export default getPartnerUser;
