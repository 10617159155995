import * as P from 'prop-types';
import { CustomerProps, BillingAddressProps, OrderDataProps } from '@redux/reducers/NewOrderReducer/PropTypes';

export const propTypes = {
  $state: P.shape({
    customer: P.shape({
      ...CustomerProps,
      billing: P.shape(BillingAddressProps),
    }).isRequired,
    orderData: P.shape(OrderDataProps),
  }),
};

export const defaultProps = {
  orderData: {},
};

export default {
  propTypes, defaultProps,
};
