import * as React from 'react';
// MATERIAL-UI
import { Button } from '@material-ui/core';
import { Marker } from 'react-map-gl';
// IMAGES
import ImageMarker from '@/images/Map/shop.png';
// PROP-TYPES
import PropTypes from './PropTypes';

function Pins({ data, onClick }) {
  return Object.values(data).map((store) => {
    const { addresses } = store;
    const { latitude, longitude } = addresses[0];
    return (
      <Marker key={`marker-${store.id}`} longitude={Number(longitude) || 0} latitude={Number(latitude) || 0}>
        <Button
          onClick={() => onClick(store)}
          variant="text"
          style={{
            transform: `translate(${-56 / 2}px,${-56}px)`,
          }}
        >
          <img
            style={{
              cursor: 'pointer',
              fill: '#d00',
              stroke: 'none',
            }}
            alt="marker-reparofone"
            src={ImageMarker}
          />
        </Button>
      </Marker>
    );
  });
}

export default React.memo(Pins);
Pins.propTypes = PropTypes.propTypes;
Pins.defaultProps = PropTypes.defaultProps;
