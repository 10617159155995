import React from 'react';
// MATERIAL UI
import {
  CircularProgress,
  TextField,
  Typography,
  Box,
  Divider,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
// REDUX
import { useDispatch, useSelector } from 'react-redux';
import {
  setAllRefusedBudgetReasons,
  setRefusedBudgetReason,
} from '@/redux/actions/NewRepairActions';
// LODASH
import { isEmpty } from 'lodash';
// GRAPHQL
import getReasons from '../../../../../../../../graphql/schemas/GetRefusedBudgetReasons';
// PROP-TYPES
import { propTypes, defaultProps } from './PropTypes';
// STYLES
import styles from './styles';

const InputReasons = ({
  setFieldValue,
  handleBlur,
  errors,
  value,
  typeInput,
  disabled,
  name,
}) => {
  const classes = styles();
  const [openReasons, setOpenReasons] = React.useState(false);
  const [loadingReasons, setLoadingReasons] = React.useState(false);
  const dispatch = useDispatch();
  const customerData = useSelector((state) => state.NewRepairReducer);
  const { allRefusedBudgetReasons } = customerData;
  const loadReasons = () => {
    setLoadingReasons(true);
    getReasons()
      .then((r) => dispatch(setAllRefusedBudgetReasons(r)))
      .catch(({ response }) => console.log(response))
      .finally(() => setLoadingReasons(false));
  };

  return (
    <Autocomplete
      id={name}
      name={name}
      blurOnSelect
      disabled={disabled || loadingReasons}
      onBlur={handleBlur}
      open={openReasons}
      value={value}
      onOpen={() => {
        if (isEmpty(allRefusedBudgetReasons)) {
          loadReasons();
          return setOpenReasons(true);
        }
        return setOpenReasons(true);
      }}
      onChange={(event, refusedBudgetReason) => {
        setFieldValue(name, refusedBudgetReason);
        dispatch(setRefusedBudgetReason(refusedBudgetReason));
      }}
      onClose={() => setOpenReasons(false)}
      getOptionLabel={(option) => option.lay_name}
      renderOption={(option, { selected }) => (
        <Box className={classes.optionBox}>
          <Typography variant="h3" className={classes.optionTitle}>
            {option.lay_name}
          </Typography>
          <span className={classes.optionDescription}>{option.description}</span>
          <Divider light className={classes.divider} />
        </Box>
      )}
      options={allRefusedBudgetReasons}
      loading={loadingReasons}
      variant={typeInput}
      size="small"
      renderInput={(params) => (
        <TextField
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...params}
          label="Escolha um Motivo"
          variant={typeInput}
          disabled={disabled || loadingReasons}
          size="small"
          className={classes.textField}
          helperText={errors}
          onBlur={handleBlur}
          error={!!errors}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loadingReasons ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};

export default InputReasons;

InputReasons.propTypes = propTypes;
InputReasons.defaultProps = defaultProps;
