import React from 'react';
import {
  FormControlLabel, Radio, RadioGroup, Typography,
} from '@material-ui/core';
import { propTypes, defaultProps } from './PropTypes';
import rules from './Rules';

export default function RadioInputStoreDelivery({
  handleChange, value, disabled,
}) {
  const { chooses } = RadioInputStoreDelivery;
  return (
    <div>
      <Typography variant="subtitle1">Local de devolução do aparelho</Typography>
      <RadioGroup name="storeDelivery" value={value} onChange={handleChange}>
        <FormControlLabel
          value={chooses.STORE}
          disabled={disabled}
          control={(<Radio size="small" />)}
          label={<Typography variant="body2">No endereço da loja</Typography>}
        />
        <FormControlLabel
          value={chooses.ANOTHER}
          disabled={disabled}
          control={<Radio size="small" />}
          label={<Typography variant="body2">Em outro endereço</Typography>}
        />
      </RadioGroup>
    </div>
  );
}

RadioInputStoreDelivery.chooses = {
  STORE: 'STORE',
  ANOTHER: 'ANOTHER',
};

RadioInputStoreDelivery.propTypes = propTypes;
RadioInputStoreDelivery.defaultProps = defaultProps;
RadioInputStoreDelivery.rules = rules;
