import React from 'react';
// REDUX
import { useDispatch, useSelector } from 'react-redux';
import {
  setDeliveryModalities,
  setCollectModalities,
  setCollectModality,
} from '@redux/actions/NewRepairActions';
// DATE FORMATTERS
import { add } from 'date-fns';
// MATERIALS
import {
  Grid,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Typography,
  LinearProgress,
} from '@material-ui/core';
// AXIOS
import axios from 'axios';

function CollectStep({ handleChange, errors, value, disabled, setDate, disabledButton, }) {
  const dispatch = useDispatch();
  const customerData = useSelector((state) => state.NewRepairReducer);
  const { customer } = customerData;
  const { collect } = customer;
  const [isFetching, setIsFetching] = React.useState(false);
  const deliveryType = () => {
    return customerData.collect?.modalities?.find(
      (r) => r.allowed_collect_id == value
    )?.collect?.collect_slug;
  };
  const deliveriesDeadline = () => {
    if (customerData.delivery?.modalities.length > 0)
      return customerData.delivery?.modalities?.map((del, i) => del.deadline);
    return [0];
  };
  const isCollectMtBoy = (modality) => modality.collect.collect_slug === 'mtboy';
  React.useEffect(() => {
    if (customerData.collect.modalities.length === 0 && collect.zipCode) {
      setIsFetching(true);
      let cepCleaned = collect.zipCode?.replace(/[^0-9]/gm, '');
      axios({
        url:
          process.env.REACT_APP_CI_URL +
          `/reparafone/api-new/range/delivery/get/${cepCleaned}`,
      }).then(({ data: r }) => {
        return dispatch(setDeliveryModalities(r));
      });
      axios({
        url:
          process.env.REACT_APP_CI_URL +
          `/reparafone/api-new/range/collect/get/${cepCleaned}`,
      }).then(({ data: r }) => {
        dispatch(setCollectModalities(r));
        return setIsFetching(false);
      });
    }
    return;
  }, [collect.zipCode, dispatch]);
  return (
    <>
      <FormControl component="fieldset" error={errors}>
        <RadioGroup
          aria-label="modalidade"
          name="chooseCollect"
          value={value}
          disabled={disabled}
          onChange={(e, value) => {
            dispatch(setCollectModality({ collect_slug: value }));
            handleChange(e);
            if (value !== 'mtboy')
              setDate('collectDate', add(new Date(), { days: 1 }));
          }}
        >
          {customerData.collect?.modalities?.map((col, i) => {
            const index = customerData.collect?.modalities?.find(isCollectMtBoy) ? --i : i;
              return (
                <Grid
                  key={i}
                  container
                  direction="row"
                  alignItems="center"
                  alignContent="center"
                >
                  <Grid item>
                    <FormControlLabel
                      value={col.collect.collect_slug}
                      control={<Radio />}
                      label={col.collect.collect_name}
                      disabled={disabledButton}
                      checked={
                        customerData.collect.chosenModality.collect_slug ===
                          col.collect.collect_slug || false
                      }
                    />
                  </Grid>
                  <Grid item>
                    <Typography variant="caption">
                      {col.price > 0
                        ? '+' +
                          (col.price / 100).toLocaleString('pt-BR', {
                            style: 'currency',
                            currency: 'BRL',
                          }) +
                        ` (${col.deadline + deliveriesDeadline()[index] + 1} dias)`
                      : 'Gratuito (3 dias)'}
                    </Typography>
                  </Grid>
                </Grid>
              );
          })}
        </RadioGroup>
        <FormHelperText style={{ color: 'red' }}>
          {errors ? 'Você deve selecionar uma modalidade de coleta' : null}
        </FormHelperText>
      </FormControl>
      <input type="hidden" value={deliveryType() || ''} name="delivery_type" />
      {isFetching ? (
        <Grid container justify="center" alignItems="center" item sm={12}>
          <LinearProgress style={{ width: '100%' }} />
        </Grid>
      ) : null}
    </>
  );
}

export default CollectStep;
