import React from 'react';
import {
  Grid, Typography, Divider, Button,
} from '@material-ui/core';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import { connect } from 'react-redux';
import Alert from '@material-ui/lab/Alert';
import { Link } from 'react-router-dom';
import changePassword from '../graphql/schemas/ChangePassword';
import { updateUserData, startFetching, stopFetching } from '../../../redux/actions/auth/AuthActions';

const ChangePasswordSchema = Yup.object().shape({
  newPassword: Yup.string()
    .required('Digite a nova senha.')
    .min(6, 'Sua senha deve possuir no mínimo 6 carácteres.')
    .max(16, 'Sua senha deve possuir no máximo 16 carácteres.'),
  confirmPassword: Yup.string()
    .required('Digite a mesma senha.')
    .oneOf(
      [Yup.ref('newPassword'), null],
      'As duas senhas digitadas devem ser iguais!',
    ),
});

const ChangePassword = ({
  history,
  location,
  updateUserData,
  showTitle = true,
  startFetching,
  stopFetching,
}) => {
  const [state, setState] = React.useState(false);
  const onSubmitForm = ({ newPassword }, { setSubmitting }) => {
    const { state: { token, email } = {} } = location || {};
    startFetching();
    changePassword({ email, newPassword, token })
      .then((r) => {
        if (history) {
          history.location.state = {
            ...history.location.state,
            passwordChanged: true,
          };
        }
        setState(true);
        updateUserData(r);
      })
      .catch((e) => console.log(e))
      .finally(() => { setSubmitting(false); stopFetching(); });
  };
  return (
    <>
      {showTitle && (
        <Grid container direction="row">
          <Grid item xs={12}>
            <Typography variant="subtitle1">Alterar senha</Typography>
          </Grid>
        </Grid>
      )}
      <Divider style={{ marginTop: '0.5rem', marginBottom: '0.5rem' }} />
      <Formik
        initialValues={{
          newPassword: '',
          confirmPassword: '',
        }}
        validationSchema={ChangePasswordSchema}
        onSubmit={onSubmitForm}
      >
        {({
          errors, touched, isSubmitting, dirty,
        }) => (
          <Form>
            <Grid container direction="column" spacing={4}>
              <Grid item xs={12}>
                <Field
                  type="password"
                  disabled={isSubmitting || state}
                  component={TextField}
                  fullWidth
                  label="Nova Senha"
                  name="newPassword"
                  variant="outlined"
                  error={!!touched.newPassword && !!errors.newPassword}
                  helperText={touched.newPassword && errors.newPassword}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  type="password"
                  disabled={isSubmitting || state}
                  component={TextField}
                  fullWidth
                  label="Confirmar senha"
                  variant="outlined"
                  name="confirmPassword"
                  error={!!touched.confirmPassword && !!errors.confirmPassword}
                  helperText={touched.confirmPassword && errors.confirmPassword}
                />
              </Grid>
              {state && (
              <Grid item xs={12}>
                <Alert severity="success">
                  Sua senha foi alterada com sucesso!
                  {' '}
                  {history && (
                  <Link to="/">
                    Clique aqui para voltar à pagina inicial!
                  </Link>
                  )}
                </Alert>
              </Grid>
              )}
              <Grid item xs={12}>
                <Button
                  disabled={isSubmitting || state || !dirty}
                  type="submit"
                  color="secondary"
                  variant="contained"
                  fullWidth
                  title="Confirmar"
                >
                  Confirmar
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default connect(null, { updateUserData, startFetching, stopFetching })(ChangePassword);
