import React from 'react';
import {
  Box, Button, Collapse, Typography,
} from '@material-ui/core';
// REDUX
import { useSelector } from 'react-redux';

function PrintServiceOrder({ in: show }) {
  const customerData = useSelector((state) => state.NewRepairReducer);
  const { orderData } = customerData;
  const OrderInfo = `/customer/repairs/${orderData?.id}`;

  React.useEffect(() => {
    document.querySelector('#top-content').scrollIntoView({ behavior: 'smooth' });
  }, []);

  return (
    <Collapse in={show} unmountOnExit>
      <Box height="100%" py={5} px={4} style={{ textAlign: 'center' }}>
        <Typography variant="h6">Recebemos sua ordem de serviço!</Typography>
        <Button
          fullWidth
          size="medium"
          color="primary"
          variant="outlined"
          href={OrderInfo}
          style={{ margin: '10px 0' }}
        >
          Ver ordem
        </Button>
        <Typography variant="body1" style={{ marginBottom: '15px' }}>
          Clique no botão acima para ver sua ordem. Caso seja seu primeiro
          acesso, a <b>senha será os 6 primeiros dígitos de seu CPF!</b>
        </Typography>
      </Box>
    </Collapse>
  );
}

export default PrintServiceOrder;
