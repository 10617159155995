/* eslint-disable jsx-a11y/anchor-has-content */
import React from 'react';

const WhatsAppPulse = (props) => {
  const { show } = props;

  return (
    <>
      {show && (
        <div className="wc_whatsapp_app right">
          <a
            href="/atendimento-wpp"
            target="_blank"
            className="wc_whatsapp"
            alt="whatsapp reparofone"
          />
        </div>
      )}
    </>

  );
};

export default WhatsAppPulse;
