import * as P from 'prop-types';

const propTypes = {
  title: P.string.isRequired,
  info: P.string.isRequired,
  icon: P.elementType.isRequired,
};

export default {
  propTypes,
  defaultProps: {},
};
