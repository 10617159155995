import React from 'react';
import {
  Box, Grid, IconButton, Tooltip,
} from '@material-ui/core';
import { Add, PlaylistAddCheck } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import style from './style';
import logo from '../../../images/trocafone-reparos -white-desktop.png';

export default function Header() {
  const classes = style();
  const urlParams = new URLSearchParams(window.location.search);
  const ordersPageUrl = `/partner/vivo?user=${urlParams.get('user')}&store=${urlParams.get('store')}`;
  const newRepairUrl = `/neworder/vivo?user=${urlParams.get('user')}&store=${urlParams.get('store')}`;
  return (
    <Box position="fixed" width="100%" zIndex={9}>
      <Box className={classes.navbar} m={0} py={2} px={3}>
        <Grid container direction="row" justify="space-between" spacing={2}>
          <Grid item xs={3}>
            <Box alignItems="flex-end" display="flex" height="100%">
              <a href={newRepairUrl} style={{ display: 'flex' }}>
                <img src={logo} alt="logo" style={{ height: '3vh' }} title="Voltar ao ínicio" />
              </a>
            </Box>
          </Grid>
          <Grid item style={{ position: 'relative' }}>
            <Grid container direction="row">
              <Grid item>
                <Tooltip title="Criar uma ordem de serviço">
                  <IconButton component={Link} to={newRepairUrl} style={{ position: 'absolute', right: '1px', padding: 0 }}>
                    <Add color="secondary" />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item>
                <Tooltip title="Ver ordens de serviço">
                  <IconButton component={Link} to={ordersPageUrl} style={{ position: 'absolute', right: '3rem', padding: 0 }}>
                    <PlaylistAddCheck color="secondary" />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
