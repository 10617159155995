import Header from '@/layout/partner/Header';
import { GetEmployeeByIdWithStoreById } from '@/pages/neworder/graphql/query/GetEmployee';
import Home from '@/pages/partner/home';
import { useLazyQuery, useQuery } from '@apollo/client';
import { makeStyles } from '@material-ui/core';
import React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { setStore, setEmployee } from '@redux/actions/VivoStoreActions';
import { omit } from 'lodash';
import { connect } from 'react-redux';

const useStyles = makeStyles(({ spacing, breakpoints }) => ({
  backgroundForm: {
    backgroundImage:
      'url(//theme.zdassets.com/theme_assets/9251552/e26e16acf092dce541a9f56d8f7d89f6a5ad8c39.png)',
    padding: `${spacing(15)}px ${spacing(6)}px ${spacing(6)}px`,
    display: 'flex',
    minHeight: '650px',
    justifyContent: 'center',
    [breakpoints.down('xs')]: {
      padding: `${spacing(15)}px 0 ${spacing(6)}px`,
    },
  },
}));

const VivoRoutes = withRouter(({ location, $actions, ...rest }) => {
  const classes = useStyles();
  const {
    dispatch,
    VivoStoreReducer: { encryptedId: storeId, employee, address },
  } = rest;
  const [isAuthenticated, setAuthenticated] = React.useState(false);
  const [getData, { data, error, loading = true }] = useLazyQuery(
    GetEmployeeByIdWithStoreById,
  );
  React.useEffect(() => {
    if (employee.encryptedId || storeId) {
      getData({ variables: { employeeId: employee.encryptedId, storeId } });
    }
  }, [employee.id, storeId]);
  React.useEffect(() => {
    if (data) {
      const { ReparofoneGetEncryptedUser, ReparofoneGetEncryptedStore } = data;
      if (
        ReparofoneGetEncryptedUser === null
        || ReparofoneGetEncryptedStore === null
      ) return;
      setAuthenticated(true);
      const storeAddress = { ...ReparofoneGetEncryptedStore.addresses[0] };
      storeAddress.zipCode = `${storeAddress.zipCode.substr(
        0,
        5,
      )}-${storeAddress.zipCode.substr(5, 3)}`;
      storeAddress.complement = storeAddress.complement ?? '';
      $actions.setStore({
        ...omit(ReparofoneGetEncryptedStore, ['addresses']),
        address: storeAddress,
      });
      $actions.setEmployee(ReparofoneGetEncryptedUser);
    }
  }, [data]);
  return (
    <>
      {isAuthenticated ? (
        <>
          <Header />
          <div className={classes.backgroundForm}>
            <Switch location={location}>
              <Route exact path="/partner/vivo/" component={Home} />
            </Switch>
          </div>
        </>
      ) : (
        <FallBack loading={loading} error={error} address={address} />
      )}
    </>
  );
});
const FallBack = ({ loading, error, address }) => {
  if (loading) return <div>Carregando...</div>;
  if (error) return <div>Ocorreu um erro, tente novamente mais tarde.</div>;
  if (!address) {
    return (
      <div>
        Esta loja não possui endereço cadastrado, entre em contato com o
        suporte.
      </div>
    );
  }
  return <div>Acesso não autorizado.</div>;
};
function mapStateToProps(state) {
  const { VivoStoreReducer } = state;
  return {
    VivoStoreReducer,
  };
}
const mapDispatchToProps = (dispatch) => ({
  $actions: bindActionCreators({ setStore, setEmployee }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(VivoRoutes);
