import initialState from './initialState';

const PREFIX = 'PARTNER_STORE';

export const StorePartnerTypes = {
  SET_STORE: `${PREFIX}.SET_STORE`,
  SET_EMPLOYEE: `${PREFIX}.SET_EMPLOYEE`,
  SET_BRANCH: `${PREFIX}.SET_BRANCH`,
};

export default function PartnerStoreReducer(state = initialState, action) {
  const { type, payload } = action;
  const finalState = (() => {
    switch (type) {
      case StorePartnerTypes.SET_STORE:
        return {
          ...state,
          ...payload,
        };
      case StorePartnerTypes.SET_EMPLOYEE:
        return {
          ...state,
          employee: { ...state.employee, ...payload },
        };
      case StorePartnerTypes.SET_BRANCH:
        return {
          ...state,
          branch: { ...state.branch, ...payload },
        };
      default:
        return { ...state };
    }
  })();
  return finalState;
}
