import React from 'react';
import { FixedSizeList } from 'react-window';
import { Box, Grid } from '@material-ui/core';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
// COMPONENT
import ModelCard from '../ModelCard';
// FUNCTIONS
import { ListModelsPropTypes } from './PropTypes';
import { groupBy } from './Common';
import style from './style';

function ListModels({ models, handleSelect, width }) {
  const classes = style();

  let modelsGrouped;
  let listWidth;
  switch (true) {
    case isWidthDown('xs', width):
      modelsGrouped = groupBy(models, 2);
      listWidth = 275;
      break;
    default:
      modelsGrouped = groupBy(models, 3);
      listWidth = 460;
      break;
  }

  return (
    <Box py={2} minWidth="100%">
      <FixedSizeList
        height={modelsGrouped.length < 2 ? 190 : 370}
        width={listWidth}
        itemSize={180}
        itemCount={modelsGrouped.length}
        className={classes.listModels}
      >
        {({ index, style: renderStyle }) => (
          <Grid container spacing={1} style={renderStyle} justify="center" className={classes.gridGroup}>
            { modelsGrouped[index].map((model) => (
              <Grid item key={model.name}>
                <ModelCard model={model} handleClick={() => handleSelect(model)} />
              </Grid>
            )) }
          </Grid>
        )}
      </FixedSizeList>
    </Box>
  );
}

ListModels.propTypes = ListModelsPropTypes;

const preventRerender = (prevProps, nextProps) => (
  prevProps.models === nextProps.models
    && prevProps.handleSelect === nextProps.handleSelect
    && prevProps.width === nextProps.width
);

export default withWidth()(React.memo(ListModels, preventRerender));
