import * as P from 'prop-types';
import { DefectsProps } from '@redux/reducers/NewOrderReducer/PropTypes';

const propTypes = {
  defects: P.arrayOf(P.shape({
    ...DefectsProps,
    icon: P.node.isRequired,
  })).isRequired,
  handleSelect: P.func.isRequired,
};

export default {
  propTypes,
  defaultProps: {},
};
