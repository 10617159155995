import {
  Box, Container, Paper, Typography,
} from '@material-ui/core';
import React from 'react';

const VivoTOS = () => (
  <Container maxWidth="md">
    <Paper elevation={1}>
      <Box p={3}>
        <Typography variant="h6" align="center">
          Termos de Serviço
        </Typography>
        <Typography variant="body1" align="center">
          Este documento contém as condições e regulamentos aos quais o Cliente se submetem quando da contratação dos serviços de reparo, prestados por TROCAFONE – COMERCIALIZAÇÃO DE APARELHOS ELETRÔNICOS LTDA.
        </Typography>
        <Typography variant="body1" align="center">
          1. Como funciona?
          O Cliente interessado deverá procurar uma das lojas Vivo participantes, relatando o defeito apresentado no Aparelho ao Vendedor. Este, por sua vez, confirmando a existência do defeito, gerará um orçamento prévio que, caso aprovado pelo Cliente, deverá ser pago, por meio de cartão de crédito, no ato da emissão da Ordem de Serviço.
        </Typography>
        <Typography variant="body1" align="center">
          2. Da coleta
          A TROCAFONE, então, realiza a coleta do Aparelho, na loja Vivo participante, encaminhando-o ao centro de reparo para a prestação do serviço e, posteriormente, entrega na loja ou em outro endereço, conforme opção do Cliente na Ordem de Serviço, bem como de acordo com a abrangência da TROCAFONE.
        </Typography>
        <Typography variant="body1" align="center">
          3. Da Garantia
          A TROCAFONE oferece garantia de 90 (noventa) dias, especificamente, sobre o reparo realizado, devendo ser apresentada a nota fiscal do serviço para aplicabilidade.
        </Typography>
        <Typography variant="body1" align="center">
          A TROCAFONE não poderá ser responsabilizada por reparos de outras peças ou defeitos pré-existentes, além do que efetivamente foram contratados.
        </Typography>
        <Typography variant="body1" align="center">
          4. Do pagamento
          O Pagamento é realizado pelo Cliente quando da emissão da Ordem de Serviço pela Loja Participante. Caso não seja possível realizar o reparo solicitado, a TROCAFONE realizará o procedimento de estorno do pagamento, conforme condições da operadora do cartão.
        </Typography>
        <Typography variant="body1" align="center">
          Nesse caso, o estorno será realizado de forma integral, sendo o Aparelho devolvido sem custos para o Cliente.
        </Typography>
        <Typography variant="body1" align="center">
          5. Privacidade dos Dados
          A TROCAFONE declara que cumpre todas as formalidades necessárias para manter em sigilo eventuais dados pessoais contidos em seu Aparelho e que os dados pessoais do Cliente, coletados quando da solicitação dos serviços, serão armazenados, exclusivamente, para histórico da operação.
        </Typography>
        <Typography variant="body1" align="center">
          Em alguns reparos, há riscos de perder toda a informação contida no aparelho, portanto, é recomendado fazer um backup na nuvem dos arquivos principais antes do seu envio.
        </Typography>
        <Typography variant="body1" align="center">
          6. Condições Gerais
          O Cliente se declara ciente de que alguns tipos de reparo possuem risco de danificar permanentemente o aparelho. Nessa hipótese, caso o Cliente decida seguir com o reparo, ele se declara ciente de que a TROCAFONE não poderá ser responsabilizada em caso de dano permanente no aparelho.
        </Typography>
        <Typography variant="body1" align="center">
          Não é necessário desbloquear o aparelho, entretanto dependendo da peça a ser reparada, será necessário a senha de tela para o teste. O Cliente se declara ciente e de acordo de que, caso necessário, fornecerá a senha para desbloqueio do Aparelho.
        </Typography>

      </Box>
    </Paper>
  </Container>
);
export default VivoTOS;
