import React from 'react';

import { HashLink as Link } from 'react-router-hash-link';

const Buttons = (props) => {
  const { label, url, onClick, component, ButtonProps, ...otherProps } = props;

  return (
    <Link
      smooth
      className="button-menu"
      to={url}
      label={label}
      onClick={onClick}
      scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: 'start' })}
      {...otherProps}
    >
      {component ? React.createElement(component, ButtonProps) : label}
    </Link>
  );
};

export default Buttons;
