import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles(({ spacing }) => ({
  stepperButtons: {
    padding: `${spacing(2)}px 0`,
    margin: 'auto 0 0',
    '& button': {
      display: 'flex',
      '&#button-back': {
        margin: '0 auto 0 0',
      },
      '&#button-next': {
        margin: '0 0 0 auto',
      },
    },
  },
  backButton: {},
  nextButton: {},
  backButtonCarrefour: {
    backgroundColor: '#ffffff',
    fontFamily: 'Milliard Book',
  },
  backButtonReparofone: {
    backgroundColor: '#ffffff',
    fontFamily: 'Roboto',
  },
  nextButtonCarrefour: {
    backgroundColor: '#1752CC',
    fontFamily: 'Milliard Book',
    '&:hover': {
      backgroundColor: '#0b317f',
    },
  },
  nextButtonReparofone: {
    backgroundColor: '#5146A2',
    fontFamily: 'Roboto',
  },
}));
