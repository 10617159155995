import React from 'react';
import { Box, Typography } from '@material-ui/core';

export default function Title() {
  return (
    <Box textAlign="center" py={0.4}>
      <Typography variant="h5">Confirme os dados da ordem de serviço</Typography>
    </Box>
  );
}
