import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setLead, setSelectedDefect } from '@redux/actions/NewOrderActions';

const mapStateToProps = ({ NewOrderReducer, PartnerStoreReducer }) => ({
  $state: {
    customer: NewOrderReducer.customer,
    estimate: NewOrderReducer.transactionAmount,
    product: NewOrderReducer.device.product,
    device: NewOrderReducer.device,
    selectedDefects: NewOrderReducer.device.selectedDefects,
    deliveryZipcode: NewOrderReducer.customer.delivery.zipCode,
    collectZipcode: NewOrderReducer.customer.collect.zipCode,
    partner: PartnerStoreReducer,
  },
});

const mapDispatchToProps = (dispatch) => ({
  $actions: bindActionCreators({ setSelectedDefect, setLead }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
