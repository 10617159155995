import { useMutation } from '@apollo/client';
import { styled, Typography } from '@material-ui/core';
import React from 'react';
import CreateMercadoPagoPreferenceMutation from '../../graphql/MercadoPago';

function createCheckoutButton(preference) {
  const script = document.createElement('script');
  script.src =
    'https://www.mercadopago.com.br/integrations/v1/web-payment-checkout.js';
  script.type = 'text/javascript';
  script.dataset.preferenceId = preference;
  document.getElementById('button-checkout').innerHTML = '';
  document.querySelector('#button-checkout').appendChild(script);
}

const MercadoPagoPro = ({ order, className }) => {
  const [createPreference, { data, loading, error }] = useMutation(
    CreateMercadoPagoPreferenceMutation,
    {
      variables: { orderId: order.id },
    }
  );
  React.useEffect(() => {
    createPreference();
  }, []);
  React.useEffect(() => {
    if (data?.ReparafoneCreateMercadopagoPreference) {
      createCheckoutButton(data.ReparafoneCreateMercadopagoPreference);
    }
  }, [data]);
  if (loading || !data) return 'Preparando o pagamento...';
  return (
    <>
      <Typography variant="body1">
        A página de pagamento será carregada automaticamente, se não carregar,
        clique no botão abaixo!
      </Typography>
      <div id="button-checkout" className={className} />
    </>
  );
};

export default styled((props) => <MercadoPagoPro {...props} />)({
  '& .mercadopago-button': {
    width: '100%',
  },
});
