import * as P from 'prop-types';

const propTypes = {
  handleChange: P.func.isRequired,
  handleBlur: P.func.isRequired,
  value: P.string,
  errors: P.string,
};

const defaultProps = {
  value: '',
  errors: '',
};

export default {
  propTypes, defaultProps,
};
