import gql from 'graphql-tag';

export const ValidateCoupons = gql`
  query ValidateCoupons($coupon: String!, $parameters: Mixed) {
    ReparafoneValidateCoupon(coupon: $coupon, parameters: $parameters)
  }
`;

export const ValidateCouponsAndGetDiscount = gql`
  query ValidateCouponsAndGetDiscount($coupon: String!, $parameters: Mixed, $input: ReparofoneCouponDiscount!) {
    ReparafoneValidateCoupon(coupon: $coupon, parameters: $parameters)

    ReparafoneCouponDiscount(input: $input) {
      code
      discount
    }
  }
`;

export default ValidateCoupons;
