import React from 'react';
import { Box, Grid } from '@material-ui/core';
// REDUX
import { useSelector } from 'react-redux';
// COMPONENTS
import InlineGridInfo from './Components/InlineGridInfo';
import Title from './Components/Title';
// FUNCTIONS
import getLabelAddress from './Utils/GetLabelAddress';

function PreviewOrder() {
  const customerData = useSelector((state) => state.NewRepairReducer);
  const { customer } = customerData;
  const { collect, delivery } = customer;

  return (
    <Box py={1}>
      <Title />
      <Grid container spacing={1}>
        <InlineGridInfo title="Nome:" value={customer.name} />
        <InlineGridInfo title="E-mail:" value={customer.email} />
        <InlineGridInfo title="Telefone:" value={customer.phone} />
        <InlineGridInfo title="CPF:" value={customer.cpf} />
        <InlineGridInfo title="Endereço de coleta:" value={getLabelAddress(collect)} />
        <InlineGridInfo title="Endereço de entrega:" value={getLabelAddress(delivery)} />
      </Grid>
    </Box>
  );
}

export default PreviewOrder;
