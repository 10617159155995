import React from 'react';
// ROUTERS
import { HashLink as Link } from 'react-router-hash-link';
// REDUX
import { useSelector } from 'react-redux';
// CLSX
import clsx from 'clsx';
// PROP-TYPES
import PropTypes from './PropTypes';
// STYLES
import styles from './style';

const Buttons = ({
  label, url, onClick, component, ButtonProps, ...otherProps
}) => {
  const classes = styles();
  const partnerData = useSelector((state) => state.PartnerStoreReducer);
  const { branch } = partnerData;
  const { branch_name } = branch;
  return (
    <Link
      smooth
      className={clsx(classes.buttonMenu, {
        [classes.buttonMenuCarrefour]: branch_name === 'Carrefour',
        [classes.buttonMenuReparofone]: branch_name === 'Limelocker',
      })}
      to={url}
      label={label}
      onClick={onClick}
      scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: 'start' })}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...otherProps}
    >
      {component ? React.createElement(component, ButtonProps) : label}
    </Link>
  );
};

export default Buttons;

Buttons.propTypes = PropTypes.propTypes;
Buttons.defaultProps = PropTypes.defaultProps;
