import React from 'react';
// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { setCustomer } from '@/redux/actions/NewRepairActions';
// FORMIK
import { useFormik } from 'formik';
// MATERIAL UI
import {
  Button,
  TextField,
  Grid,
  Paper,
  Typography,
  InputAdornment,
  Grow,
  Slide,
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
// ICONS
import ContactsIcon from '@material-ui/icons/Contacts';
import ContactMailIcon from '@material-ui/icons/ContactMail';
// YUP
import Yup from '../../../utils/CustomYup';
// GRAPHQL
import getCustomerInfo from '../graphql/schema/GetCustomerInfo';
import editCustomerInfo from '../graphql/schema/EditCustomerInfo';
// STYLES
import style from './style';
// COMPONENTS
import InputCpf from './components/InputCpf';
import InputPhone from './components/InputPhone';
import Alerts from '../components/Alert';
import Breadcrumb from './components/Breadcrumb';
import ModalChangePassword from '../auth/ModalChangePassword';
import ModalChangeEmail from '../auth/ModalChangeEmail';

const Profile2 = () => {
  const dispatch = useDispatch();
  const classes = style();
  const dataCustomer = useSelector((state) => state.NewRepairReducer);
  const { customer } = dataCustomer;
  const [isEditing, setEditing] = React.useState(false);
  const [reinitialize, setReinitialize] = React.useState(false);
  const [loadingData, setLoadingData] = React.useState(false);
  const [showAlert, setShowAlert] = React.useState(null);
  const [loadBackdrop, setLoadBackdrop] = React.useState(false);
  const [alertMessage, setMessageAlert] = React.useState(false);
  const [alertSeverity, setSeverityAlert] = React.useState(false);
  const [alertDuration, setDurationAlert] = React.useState(false);
  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowAlert(null);
  };
  const handleCancelEdit = () => {
    setEditing(false);
  };
  const handleEditInfo = () => {
    setEditing(true);
  };
  const handleAlert = (message, severity, openAlert, duration) => {
    setMessageAlert(message);
    setSeverityAlert(severity);
    setShowAlert(openAlert);
    setDurationAlert(duration);
  };
  const formik = useFormik({
    enableReinitialize: reinitialize,
    initialValues: {
      customer_first_name: customer.name,
      customer_last_name: customer.lastName,
      customer_email: customer.email,
      customer_cpf: customer?.cpf || '',
      customer_phone: customer?.phone || '',
    },

    validationSchema: Yup.object().shape({
      customer_first_name: Yup.string().required('Por favor, insira seu Nome!'),
      customer_last_name: Yup.string().required(
        'Por favor, insira seu Sobrenome!',
      ),
      customer_cpf: Yup.string()
        .min(11, 'Epa, CPF está inválido')
        .CPF('Você deve inserir um CPF válido')
        .required('Por favor, insira seu CPF!'),
      customer_phone: Yup.string()
        .min(11, 'Oops, Celular inválido')
        .required('Por favor, insira seu Celular!'),
    }),

    onSubmit: async (data) => {
      const editedData = {
        customer_first_name:
          data.customer_first_name === customer.name
            ? null
            : data.customer_first_name,
        customer_last_name:
          data.customer_last_name === customer.lastName
            ? null
            : data.customer_last_name,
        customer_cpf:
          data.customer_cpf.replace(/[^0-9]/gm, '') === customer.cpf
            ? null
            : data.customer_cpf.replace(/[^0-9]/gm, ''),
        customer_phone:
          data.customer_phone.replace(/[^0-9]/gm, '') === customer.phone
            ? null
            : data.customer_phone.replace(/[^0-9]/gm, ''),
      };
      if (Object.values(editedData).every((elem) => elem === null)) {
        handleCancelEdit();
        return handleAlert(
          'Não há nada para atualizar!',
          'warning',
          true,
          3000,
        );
      }
      if (editedData.customer_first_name === null) delete editedData.customer_first_name;
      if (editedData.customer_last_name === null) delete editedData.customer_last_name;
      if (editedData.customer_cpf === null) delete editedData.customer_cpf;
      if (editedData.customer_phone === null) delete editedData.customer_phone;

      delete editedData.customer_email;
      setShowAlert(false);
      if (Object.keys(editedData).length > 0) {
        setLoadBackdrop(true);
        return editCustomerInfo({ input: { ...editedData, id: customer.id } })
          .then((response) => {
            const customerData = {
              email: response.customer_email,
              name: response.customer_first_name,
              lastName: response.customer_last_name,
              phone: response.customer_phone,
              cpf: response.customer_cpf,
            };
            dispatch(setCustomer(customerData));
            handleAlert(
              'Informações atualizadas com sucesso!',
              'success',
              true,
              3000,
            );
          })
          .catch(() => handleAlert(
            'Houve um erro ao atualizar as informações!',
            'error',
            true,
            3000,
          ))
          .finally(() => {
            handleCancelEdit();
            setLoadBackdrop(false);
          });
      }
      handleCancelEdit();
      return handleAlert('Não há nada para atualizar!', 'warning', true, 3000);
    },
  });
  React.useEffect(() => {
    setLoadingData(true);
    getCustomerInfo()
      .then((data) => {
        const customerData = {
          id: data.id,
          email: data.customer_email,
          name: data.customer_first_name,
          lastName: data.customer_last_name,
          phone: data.customer_phone,
          cpf: data.customer_cpf,
        };
        setReinitialize(true);
        dispatch(setCustomer(customerData));
      })
      .finally(() => {
        setLoadingData(false);
      });
  }, [dispatch]);
  const [modalEmail, setModalEmail] = React.useState(false);
  const handleModalEmailState = (state) => () => {
    setModalEmail(state);
  };
  const [modal, setModal] = React.useState(false);
  const handleCloseModalPassword = () => {
    setModal(false);
  };
  const handleModalPasswordState = (state) => () => {
    setModal(state);
  };
  return (
    <div>
      <Breadcrumb title="Minha conta" />
      <Alerts
        open={showAlert}
        loadBackdrop={loadBackdrop}
        handleClose={handleCloseAlert}
        message={alertMessage}
        severity={alertSeverity}
        duration={alertDuration}
      />
      <form onSubmit={formik.handleSubmit}>
        <Grid
          container
          justify="center"
          alignItems="center"
          className={classes.root}
        >
          <Paper className={classes.paper}>
            <Typography variant="h2" gutterBottom className={classes.title}>
              Dados da sua Conta
            </Typography>
            {!customer.name ? (
              <Skeleton height={50} className={classes.skeleton} />
            ) : (
              <TextField
                fullWidth
                className={classes.textField}
                disabled={formik.isSubmitting || !isEditing}
                name="customer_first_name"
                label="Nome"
                variant="outlined"
                size="small"
                value={formik.values.customer_first_name}
                onChange={(event, valueName) => {
                  if (event) {
                    formik.setFieldValue(
                      'customer_first_name',
                      event.target.value,
                    );
                  }
                }}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.customer_first_name
                  && Boolean(formik.errors.customer_first_name)
                }
                helperText={
                  formik.touched.customer_first_name
                  && formik.errors.customer_first_name
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <ContactsIcon
                        color={
                          !formik.isSubmitting && isEditing
                            ? 'primary'
                            : 'action'
                        }
                      />
                    </InputAdornment>
                  ),
                }}
              />
            )}
            {!customer.lastName ? (
              <Skeleton height={50} className={classes.skeleton} />
            ) : (
              <TextField
                fullWidth
                className={classes.textField}
                disabled={formik.isSubmitting || !isEditing}
                id="customer_last_name"
                name="customer_last_name"
                label="Sobrenome"
                type="text"
                variant="outlined"
                size="small"
                value={formik.values.customer_last_name}
                onChange={(event, valueLastName) => {
                  if (event) {
                    formik.setFieldValue(
                      'customer_last_name',
                      event.target.value,
                    );
                  }
                }}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.customer_last_name
                  && Boolean(formik.errors.customer_last_name)
                }
                helperText={
                  formik.touched.customer_last_name
                  && formik.errors.customer_last_name
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <ContactsIcon
                        color={
                          !formik.isSubmitting && isEditing
                            ? 'primary'
                            : 'action'
                        }
                      />
                    </InputAdornment>
                  ),
                }}
              />
            )}
            {!customer.email ? (
              <Skeleton height={50} className={classes.skeleton} />
            ) : (
              <Grid
                container
                row
                justify="space-between"
                alignItems="center"
                className={classes.buttonsGrid}
              >
                <TextField
                  style={{ width: '70%' }}
                  className={classes.textField}
                  disabled
                  id="customer_email"
                  name="customer_email"
                  label="E-mail"
                  type="text"
                  variant="outlined"
                  size="small"
                  value={formik.values.customer_email}
                  onChange={(event, valueEmail) => {
                    if (event) {
                      formik.setFieldValue(
                        'customer_email',
                        event.target.value,
                      );
                    }
                  }}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.customer_email
                    && Boolean(formik.errors.customer_email)
                  }
                  helperText={
                    formik.touched.customer_email
                    && formik.errors.customer_email
                  }
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <ContactMailIcon
                          color={
                            !formik.isSubmitting && isEditing
                              ? 'primary'
                              : 'action'
                          }
                        />
                      </InputAdornment>
                    ),
                  }}
                />
                <Button
                  variant="outlined"
                  size="small"
                  onClick={handleModalEmailState(true)}
                  disabled={formik.isSubmitting || !isEditing}
                >
                  Editar E-mail
                </Button>
              </Grid>
            )}
            {!customer.cpf ? (
              <Skeleton height={50} className={classes.skeleton} />
            ) : (
              <InputCpf
                disabled={formik.isSubmitting || !isEditing}
                typeInput="outlined"
                value={formik.values.customer_cpf}
                errors={
                  formik.touched.customer_cpf
                    ? formik.errors.customer_cpf
                    : null
                }
                handleChange={formik.setFieldValue}
                handleBlur={formik.handleBlur}
              />
            )}
            {!customer.phone ? (
              <Skeleton height={50} className={classes.skeleton} />
            ) : (
              <InputPhone
                disabled={formik.isSubmitting || !isEditing}
                typeInput="outlined"
                value={formik.values.customer_phone}
                errors={
                  formik.touched.customer_phone
                    ? formik.errors.customer_phone
                    : null
                }
                handleChange={formik.setFieldValue}
                handleBlur={formik.handleBlur}
              />
            )}
            {!customer.id ? (
              <Skeleton
                height={50}
                className={classes.skeleton}
                style={{ marginBottom: '15px' }}
              />
            ) : (
              <Grid
                container
                row
                justify="flex-start"
                alignItems="center"
                className={classes.buttonsGrid}
                style={{ marginBottom: '15px' }}
              >
                <Typography variant="body1" style={{ marginRight: '15px' }}>
                  Senha:
                </Typography>
                <Button
                  disabled={formik.isSubmitting || !isEditing}
                  variant="outlined"
                  size="small"
                  onClick={handleModalPasswordState(true)}
                >
                  Alterar senha
                </Button>
              </Grid>
            )}
            {isEditing ? (
              <Grow in={isEditing}>
                <Grid
                  container
                  row
                  justify="space-between"
                  alignItems="center"
                  className={classes.buttonsGrid}
                >
                  <Button
                    disabled={formik.isSubmitting}
                    color="secondary"
                    variant="contained"
                    size="large"
                    onClick={handleCancelEdit}
                  >
                    Cancelar
                  </Button>
                  <Button
                    disabled={formik.isSubmitting}
                    color="primary"
                    variant="contained"
                    size="large"
                    type="submit"
                  >
                    Salvar
                  </Button>
                </Grid>
              </Grow>
            ) : (
              <Slide direction="up" in={!isEditing} mountOnEnter unmountOnExit>
                <Button
                  color="primary"
                  variant="contained"
                  size="large"
                  disabled={loadingData}
                  onClick={handleEditInfo}
                >
                  Editar Informações
                </Button>
              </Slide>
            )}
          </Paper>
        </Grid>
      </form>
      <ModalChangePassword
        isOpen={modal}
        handleModalState={handleCloseModalPassword}
      />
      <ModalChangeEmail
        isOpen={modalEmail}
        handleModalState={handleModalEmailState}
        handleAlert={handleAlert}
      />
    </div>
  );
};

export default Profile2;
