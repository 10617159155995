import gql from 'graphql-tag';

const getScheduleCollect = gql`
  query getScheduleCollect($getValidFutureDates: Boolean = true) {
    getScheduledCollectsByDate(getValidFutureDates: $getValidFutureDates) {
      scheduled_date
      count
    }
    getReparofoneCollectTypes {
      id
      collect_slug
      need_schedule
      max_schedules
    }
  }
`;

export default getScheduleCollect;
