import React from 'react';

import { CssBaseline, AppBar, Toolbar } from '@material-ui/core';

import Logo from './Logo';
import Menu from './Menu';
import MenuMobile from './MenuMobile';

import './Header.css';

const Header = () => (
  <div id="header-top" className="header-top">
    <MenuMobile />

    <CssBaseline />
    <AppBar position="fixed" style={{ zIndex: '9999' }}>
      <Toolbar>
        <Logo />
        <Menu />
      </Toolbar>
    </AppBar>
  </div>
);

export default Header;
