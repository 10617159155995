import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setStep, setCreditCard, middlewares } from '@redux/actions/NewOrderActions';

const mapStateToProps = ({ NewOrderReducer }) => {
  const { payment } = NewOrderReducer;
  return {
    $state: {
      step: NewOrderReducer.activeStep,
      cpf: payment.data.cpf,
      number: payment.data.number,
      name: payment.data.name,
      cvc: payment.data.cvc,
      expiry: payment.data.expiry,
    },
  };
};

const mapDispatchToProps = (dispatch) => ({
  $actions: bindActionCreators({
    setStep,
    setCreditCard,
    checkCreditCard: middlewares.checkCreditCard,
  }, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { forwardRef: true },
);
