import React from 'react';
// MATERIAL-UI
import { Box, Typography, List } from '@material-ui/core';
// ICONS
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
// PROP-TYPES
import PropTypes from './PropTypes';
// COMPONENTS
import { InfoData } from '../Common';
// UTILS
import {
  addressFormatter,
} from './utils';
// STYLES
import styles from './styles';

const Address = ({ addresses }) => {
  const classes = styles();
  const [addressCollect, setAddressCollect] = React.useState(null);
  const [addressDelivery, setAddressDelivery] = React.useState(null);
  const [addressCharge, setAddressCharge] = React.useState(null);
  React.useEffect(() => {
    addressFormatter(
      addresses,
      setAddressCollect,
      setAddressDelivery,
      setAddressCharge,
    );
  }, []);
  return (
    <Box className={classes.box}>
      <Typography className={classes.title} variant="h2" align="left">
        Dados dos Endereços
      </Typography>
      <List className={classes.list}>
        <InfoData
          title="Coleta"
          info={addressCollect}
          icon={<LocalShippingIcon className={classes.icon} />}
        />
        <InfoData
          title="Entrega"
          info={addressDelivery}
          icon={<LocalShippingIcon className={classes.icon} />}
        />
        <InfoData
          title="Cobrança"
          info={addressCharge}
          icon={<MonetizationOnIcon className={classes.icon} />}
        />
      </List>
    </Box>
  );
};

export default Address;

Address.propTypes = PropTypes.propTypes;
Address.defaultProps = PropTypes.defaultProps;
