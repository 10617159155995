import React from 'react';
import {
  Box, Collapse, Divider, Typography,
} from '@material-ui/core';
import { useFormik } from 'formik';
import * as Yup from 'yup';
// COMPONENTS
import InputImei from './Components/InputImei';
import SelectDefects from './Components/SelectDefects';
import StepperButtons from '../StepperButtons';
import InputDefectComment from './Components/InputDefectComment';
import StepperSelectDevice from './Components/StepperSelectDevice';
// FUNCTIONS
import { SelectDevicePropTypes, SelectDeviceDefaultProps } from './PropTypes';
import ConnectComponentRedux from './ConnectComponentRedux';

function SelectDevice({ $state, $actions }) {
  const [disabledBtnNext, setDisabledBtnNext] = React.useState(true);

  const formik = useFormik({
    initialValues: {
      imei: $state.imei,
      defectComment: $state.defectComment,
    },

    validationSchema: Yup.object({
      imei: InputImei.rules,
      defectComment: InputDefectComment.rules,
    }),

    onSubmit: ({ imei, defectComment }) => {
      $actions.setDevice({ imei, defectComment });
      $actions.setStep($state.step + 1);
    },
  });

  const backStep = () => {
    const { values } = formik;
    $actions.setDevice({
      imei: values.imei,
      defectComment: values.defectComment,
    });
    $actions.setStep($state.step + 1);
    $actions.setStep($state.step - 1);
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Typography variant="h6">Identificador do aparelho</Typography>
        <Box display="flex" pb={1} pt={1}>
          <Box textAlign="center" maxWidth={338} width="100%" mx="auto">
            <InputImei
              value={formik.values.imei}
              handleBlur={formik.handleBlur}
              handleChange={formik.handleChange}
              errors={formik.touched.imei ? formik.errors.imei : null}
              inputProps={{ maxLength: 15 }}
              helperText="Caso não seja possível obter o imei o preenchimento deste campo é opcional."
            />
          </Box>
        </Box>
        <Box width="100%" py={1}>
          <Divider />
        </Box>
        <StepperSelectDevice />
        <Box width="100%" py={1}>
          <Divider />
        </Box>
        <Collapse in={$state.isSelectedDevice} unmountOnExit>
          <SelectDefects disabledBtnNextStep={setDisabledBtnNext} />
          <Box display="flex" my={3}>
            <Box textAlign="center" maxWidth={500} width="100%" mx="auto">
              <InputDefectComment
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                value={formik.values.defectComment}
                errors={formik.touched.defectComment ? formik.errors.defectComment : null}
              />
            </Box>
          </Box>
        </Collapse>
      </form>
      <StepperButtons
        backStep={backStep}
        nextStep={disabledBtnNext ? undefined : formik.submitForm}
      />
    </>
  );
}

SelectDevice.propTypes = SelectDevicePropTypes;
SelectDevice.defaultProps = SelectDeviceDefaultProps;

export default ConnectComponentRedux(SelectDevice);
