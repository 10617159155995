import { Container, styled } from '@material-ui/core';
import React from 'react';

const CardContent = ({ className, children }) => (
  <Container
    maxWidth="xs"
  >
    <div className={className}>{children}</div>
  </Container>
);

export default styled((props) => <CardContent {...props} />)({
  padding: '1rem',
  border: '1px solid gainsboro',
  boxShadow: '0px 2px 1px #e2e2e2',
  marginTop: '-10px',
  background: 'white',
});
