import React from 'react';
import * as P from 'prop-types';
import { TextField } from '@material-ui/core';
import { textFieldType, textFieldSize } from '../Common';

function Street({
  value, handleChange, handleBlur, disabled, prefix, error,
}) {
  return (
    <TextField
      fullWidth
      value={value}
      size={textFieldSize}
      disabled={disabled}
      onChange={handleChange}
      onBlur={handleBlur}
      label="Rua"
      error={!!error}
      helperText={error}
      variant={textFieldType}
      name={`${prefix}[street]`}
    />
  );
}

Street.propTypes = {
  value: P.string,
  handleChange: P.func.isRequired,
  handleBlur: P.func.isRequired,
  disabled: P.bool,
  prefix: P.string.isRequired,
  error: P.string,
};

Street.defaultProps = {
  value: '',
  error: '',
  disabled: false,
};

export default React.memo(Street);
