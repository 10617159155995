import React from 'react';
import * as P from 'prop-types';
import { Icon, IconButton } from '@material-ui/core';

export default function ButtonCollapse({ isCollapsed, toggleShowForm }) {
  let titleButtonCollapse = 'Bloqueado para edição';
  let iconButtonCollapse = 'lock';
  if (toggleShowForm && isCollapsed) {
    titleButtonCollapse = 'Esconder preenchimento de endereço';
    iconButtonCollapse = 'expand_less';
  }
  if (toggleShowForm && !isCollapsed) {
    titleButtonCollapse = 'Abrir preenchimento de endereço';
    iconButtonCollapse = 'expand_more';
  }

  return (
    <IconButton
      disabled={!toggleShowForm}
      onClick={toggleShowForm}
      title={titleButtonCollapse}
    >
      <Icon>{ iconButtonCollapse }</Icon>
    </IconButton>
  );
}

ButtonCollapse.propTypes = {
  isCollapsed: P.bool.isRequired,
  toggleShowForm: P.func,
};

ButtonCollapse.defaultProps = {
  toggleShowForm: null,
};
