import React from 'react';
// YUP
import * as Yup from 'yup';
// MATERIAL-UI
import {
  Box, Typography, Collapse, Button, Grid,
} from '@material-ui/core';
// FORMIK
import { useFormik } from 'formik';
// ICONS
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
// REDUX
import { useSelector, useDispatch } from 'react-redux';
import {
  setCollect,
  setDelivery,
  setStep,
  middlewares,
  setCollectData,
  resetDeliveryModalities,
  resetCollectModalities,
  setCollectModality,
} from '@redux/actions/NewRepairActions';
// DATE FORMATTERS
import {
  format, add, isWeekend, isFriday, parse,
} from 'date-fns';
import Holidays from '@/utils/Holidays';
// COMPONENTS
import FocusError from '@/utils/FocusError';
import InputZipCode from './Components/InputZipCode';
import Modality from './Components/InputDate';
import StepperButtons from '../StepperButtons';
// FUNCTIONS
import checkZipCode from '../../../../utils/GetShipping';
import searchZipCodeInfo from '../../../../utils/SearchZipCodeInfo';
import ScheduleCollect from './Components/InputDate/ScheduleCollect';
// STYLES
import useStyles from './style';

function Shipping() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const customerData = useSelector((state) => state.NewRepairReducer);
  const { customer, activeStep } = customerData;
  const [collectData, setCollectZipCode] = React.useState(null);
  const { collect, delivery } = customer;
  const [selectedMethod, setSelectedMethod] = React.useState({});
  const [isLoading, setLoading] = React.useState(false);
  const [disabledButton, setDisabledButton] = React.useState(true);
  const [alert, setAlert] = React.useState({
    show: false,
    message: null,
    severity: null,
  });

  const showAlert = ({ message, severity }) => setAlert({
    message,
    severity,
    show: true,
  });
  const closeAlert = () => setAlert({
    ...alert,
    show: false,
  });

  const initialCollectDate = () => {
    if (isWeekend(add(new Date(), { days: 1 }))) {
      return add(new Date(), { days: 3 });
    }
    if (Holidays.isHoliday(add(new Date(), { days: 1 }))) {
      if (isFriday(add(new Date(), { days: 1 }))) {
        return add(new Date(), { days: 4 });
      }
      return add(new Date(), { days: 2 });
    }
    return add(new Date(), { days: 1 });
  };
  const [selectedDate, setDate] = React.useState(initialCollectDate);

  const formik = useFormik({
    initialValues: {
      zipCode: collect.zipCode,
      chooseCollect: customerData.collect.chosenModality.collect_slug,
      collectDate: customerData.collect?.schedule_collect
        ? parse(
            customerData.collect?.schedule_collect,
            'dd-MM-yyyy',
            new Date(),
        )
        : selectedDate,
    },

    validationSchema: Yup.object({
      zipCode: InputZipCode.rules,
      chooseCollect: Yup.string().required(
        'Você deve selecionar uma modalidade de coleta',
      ),
      collectDate: Yup.date().required(
        'Selecione uma data válida para coleta do aparelho',
      ),
    }),

    onSubmit: async (values) => {
      closeAlert();
      const formattedDate = format(values.collectDate, 'dd-MM-yyyy');
      if (values.chooseCollect) {
        dispatch(setCollectData({ schedule_collect: formattedDate }));
      }
      try {
        dispatch(middlewares.calculateTotalBudgetAmount());
        return dispatch(setStep(activeStep + 1));
      } catch (error) {
        setAlert({
          show: true,
          severity: 'error',
          message:
            'Não foi possível estimar o frete, estamos passando por problemas, tente novamente mais tarde, sentimos muito 😢',
        });
      }
    },
  });

  const isSubmitting = formik.isSubmitting
    ? StepperButtons.SUBMITTING.NEXT
    : null;

  const backStep = () => {
    dispatch(
      setCollect({
        ...collectData,
        zipCode: formik.values.zipCode ?? '',
        isChartered: false,
        blockZipCodeEdit: true,
      }),
    );
    dispatch(
      setDelivery({
        ...collectData,
        zipCode: formik.values.zipCode ?? '',
        isChartered: false,
        blockZipCodeEdit: false,
      }),
    );

    if (formik.values.chooseCollect) {
      dispatch(setCollectData(formik.values.collectDate));
    }
    dispatch(setStep(activeStep - 1));
  };

  const calculateShipping = () => {
    if (String(formik.values.zipCode ?? '').length === 9) {
      setLoading(true);
      setDisabledButton(true);
      dispatch(resetCollectModalities());
      dispatch(resetDeliveryModalities());
      dispatch(setCollectModality({ collect_slug: undefined }));
      if (collect.zipCode && delivery.zipCode) {
        dispatch(
          setCollect({
            city: undefined,
            complement: undefined,
            neighborhood: undefined,
            number: undefined,
            state: undefined,
            street: undefined,
            zipCode: undefined,
            isChartered: false,
            blockZipCodeEdit: false,
          }),
        );
        dispatch(
          setDelivery({
            city: undefined,
            complement: undefined,
            neighborhood: undefined,
            number: undefined,
            state: undefined,
            street: undefined,
            zipCode: undefined,
            isChartered: false,
            blockZipCodeEdit: false,
          }),
        );
      }
      (async () => {
        try {
          const response = await searchZipCodeInfo(formik.values.zipCode);
          if (response) setCollectZipCode(response);
          const responseZipCode = await checkZipCode(formik.values.zipCode);
          if (responseZipCode) {
            dispatch(
              setCollect({
                ...response,
                zipCode: formik.values.zipCode ?? '',
                isChartered: false,
                blockZipCodeEdit: true,
              }),
            );
            dispatch(
              setDelivery({
                ...response,
                zipCode: formik.values.zipCode ?? '',
                isChartered: false,
                blockZipCodeEdit: false,
              }),
            );
          } else {
            showAlert({
              message:
                'Ainda não temos abrangência para o CEP selecionado, para prosseguir é necessário inserir um CEP válido do estado de São Paulo.',
              severity: 'error',
            });
          }
        } catch (e) {
          showAlert({
            message:
              'Não foi possível encontrar as informações deste CEP, mas não se preocupe, você pode nos fornecer mais detalhes nos próximo passos.',
            severity: 'warning',
          });
        } finally {
          setLoading(false);
        }
      })();
    }
  };

  React.useEffect(() => {
    document
      .querySelector('#top-content')
      .scrollIntoView({ behavior: 'smooth' });
  }, []);

  return (
    <>
      <Box maxWidth={668}>
        <form onSubmit={formik.handleSubmit}>
          <Box py={1}>
            <Box py={2}>
              <Typography variant="h6">Local de Coleta do Aparelho</Typography>
            </Box>
            <Grid container>
              <Grid item xs={12} md={8} className={classes.cepAvailable}>
                <InputZipCode
                  disabled={formik.isSubmitting}
                  typeInput="outlined"
                  value={formik.values.zipCode}
                  errors={formik.touched.zipCode ? formik.errors.zipCode : null}
                  handleChange={formik.handleChange}
                  handleBlur={formik.handleBlur}
                  isLoading={isLoading}
                  setFieldValue={formik.setFieldValue}
                  setDisabledButton={setDisabledButton}
                />
              </Grid>
              <Grid item xs={12} md={4} className={classes.cepAvailable}>
                <Button
                  fullWidth
                  size="large"
                  color="secondary"
                  variant="contained"
                  onClick={calculateShipping}
                  disabled={isLoading || disabledButton}
                  endIcon={<LocalShippingIcon fontSize="small" />}
                >
                  Calcular Frete
                </Button>
              </Grid>
            </Grid>
          </Box>
          <Box width="100%" py={1} display="flex" />
          <Box py={1}>
            <Collapse in={collect.zipCode}>
              <Box py={1}>
                <Typography variant="h6">Data de Coleta</Typography>
              </Box>
              <Box
                display="flex"
                style={{ flexWrap: 'wrap', justifyContent: 'space-between' }}
                width="100%"
              >
                <Modality
                  disabled={formik.isSubmitting}
                  value={formik.values.chooseCollect}
                  errors={
                    formik.touched.chooseCollect
                      ? formik.errors.chooseCollect
                      : null
                  }
                  handleChange={formik.handleChange}
                  handleBlur={formik.handleBlur}
                  setSelectedMethod={setSelectedMethod}
                  selectedMethod={selectedMethod}
                  setDate={formik.setFieldValue}
                  disabledButton={formik.isSubmitting}
                />
                <Collapse
                  in={customerData.collect.chosenModality?.collect_slug}
                >
                  <>
                    <ScheduleCollect
                      collectId={formik.values.chooseCollect}
                      disabled={formik.isSubmitting}
                      value={formik.values.collectDate}
                      errors={
                        formik.touched.collectDate
                          ? formik.errors.collectDate
                          : null
                      }
                      handleChangeDate={formik.handleChange}
                      selectedMethod={selectedMethod}
                      setDate={setDate}
                      setFieldValue={formik.setFieldValue}
                    />
                    <Button
                      fullWidth
                      size="large"
                      color="secondary"
                      variant="contained"
                      onClick={formik.submitForm}
                      disabled={isSubmitting}
                      endIcon={<LocalShippingIcon fontSize="small" />}
                      style={{ marginTop: '10px' }}
                    >
                      Solicitar Coleta
                    </Button>
                  </>
                </Collapse>
              </Box>
            </Collapse>
          </Box>
          <FocusError
            errors={formik.errors}
            isSubmitting={formik.isSubmitting}
            isValidating={formik.isValidating}
          />
        </form>
      </Box>
      <StepperButtons
        backStep={backStep}
        nextStep={formik.submitForm}
        isSubmitting={isSubmitting}
      />
    </>
  );
}

export default Shipping;
