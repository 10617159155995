import React from 'react';
import Box from '@material-ui/core/Box';
// COMPONENTS
import FormAddress from '../../../FormAddress';
// FUNCTIONS
import connect from './ConnectComponentRedux';
import { propTypes, defaultProps } from './PropTypes';
import FocusError from '@/utils/FocusError';

function Address({ $state, formik }) {
  const { address } = $state;
  const { values } = formik;

  const setAddressValues = (newValues) => {
    formik.setFieldValue(
      'address', {
        ...values.address,
        ...newValues,
      },
    );
  };

  const autoFill = address.isChartered
    ? null
    : (
      () => (
        formik.setFieldValue(
          'address', {
            zipCode: address.zipCode,
            state: address.state,
            city: address.city,
            neighborhood: address.neighborhood,
            street: address.street,
            number: address.number,
            complement: address.complement,
          },
        )
      )
    );

  return (
    <Box py={1} maxWidth={668} width="100%" component="form" id="address">
      <FormAddress
        isEditable={!formik.isSubmitting}
        prefix="address"
        title="Endereço de cobrança"
        autoFillLabel="Preencher com endereço de entrega"
        desc="Qual endereço deve ser considerado para cobrança? Isto ajuda na análise de risco da operadora do cartão."
        autoFill={autoFill}
        defaultShow={!autoFill}
        errors={formik.errors}
        values={values.address}
        touched={formik.touched}
        setValues={setAddressValues}
        handleChange={formik.handleChange}
        handleBlur={formik.handleBlur}
      />
      <FocusError errors={formik.errors} isSubmitting={formik.isSubmitting} isValidating={formik.isValidating} />
    </Box>
  );
}

Address.propTypes = propTypes;
Address.propTypes = defaultProps;
Address.rules = FormAddress.rules;

export default connect(Address);
