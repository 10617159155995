import React from 'react';
// MATERIAL-UI
import {
  Box, Step, Stepper, StepLabel, Typography,
} from '@material-ui/core';
// REDUX
import { useSelector } from 'react-redux';
// CLSX
import clsx from 'clsx';
// CONNECTOR STYLES
import StepConnector from '../StepConnector';
// ICON
import StepIcon from '../StepIcon';
// UTILS
import steps from '../../../../utils/steps';
// STYLES
import makeStyles from './style';

const mapActiveStepToIconStep = [0, 1, 2, 3];
function StepperHeader() {
  const classes = makeStyles();
  const customerData = useSelector((state) => state.NewRepairReducer);
  const { activeStep } = customerData;
  const partnerData = useSelector((state) => state.PartnerStoreReducer);
  const { branch } = partnerData;
  const { branch_name } = branch;

  return (
    <Stepper
      alternativeLabel
      activeStep={mapActiveStepToIconStep[activeStep]}
      connector={<StepConnector />}
      className={classes.stepper}
    >
      {steps.map((label) => (
        <Step key={label}>
          <StepLabel StepIconComponent={StepIcon}>
            <Typography
              className={clsx(classes.header, {
                [classes.headerCarrefour]: branch_name === 'Carrefour',
                [classes.headerReparofone]: branch_name === 'Limelocker',
              })}
              component={Box}
              variant="caption"
              m={0}
            >
              {label}
            </Typography>
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  );
}

export default StepperHeader;
