/* eslint-disable no-unused-expressions */
import React from 'react';
import { useDispatch } from 'react-redux';
import {
  setOriginUser,
  setOriginStore,
} from '@/redux/actions/NewRepairActions';
import ReactGA from 'react-ga';
import insertUser from './graphql/schemas/InsertUser';
import Header from './components/Header/Header';
import Banner from './components/Banner/Banner';
import Funcionamento from './components/ComoFunciona/Funcionamento';
import Mapa from './components/Mapa';
import Formulario from './components/Form';
import Footer from './components/Footer/Footer';
import Servicos from './components/Servicos/Servicos';

let cssLoaded = false;
let GMTLoaded = false;
let GMTNoScriptLoaded = false;

const Home = () => {
  React.useEffect(() => {
    if (cssLoaded === false) {
      cssLoaded = true;
      import('../../layout/css/pages/NewOrder.css');
      import('semantic-ui-css/semantic.min.css');
      import('slick-carousel/slick/slick.css');
      import('slick-carousel/slick/slick-theme.css');
    }
    if (!GMTLoaded) {
      GMTLoaded = true;
      // Google Tag Manager
      (function (w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({
          'gtm.start': new Date().getTime(),
          event: 'gtm.js',
        });
        const f = d.getElementsByTagName(s)[0];
        const j = d.createElement(s);
        const dl = l !== 'dataLayer' ? `&l=${l}` : '';
        j.async = true;
        j.src = `https://www.googletagmanager.com/gtm.js?id=${i}${dl}`;
        f.parentNode.insertBefore(j, f);
      })(window, document, 'script', 'dataLayer', 'GTM-597BDG5');
      // End Google Tag Manager
      // <!-- Hotjar Tracking Code for https://reparofone.com.br/novaordem -->
      (function (h, o, t, j, a, r) {
        h.hj =
          h.hj ||
          function () {
            (h.hj.q = h.hj.q || []).push(arguments);
          };
        h._hjSettings = { hjid: 2735986, hjsv: 6 };
        a = o.getElementsByTagName('head')[0];
        r = o.createElement('script');
        r.async = 1;
        r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
        a.appendChild(r);
      })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
    }
    if (process.env.NODE_ENV === 'production') {
      ReactGA.event({
        category: 'createOrder',
        action: 'Step 0',
        label: 'Entrou na Página',
        value: 0,
      });
    }
  }, []);
  const dispatch = useDispatch();
  React.useEffect(() => {
    insertUser().then((data) => {
      dispatch(setOriginUser(data.ReparafoneGetTradeInUserByName));
      dispatch(setOriginStore(data.ReparafoneGetStoreByName));
    });
  }, [dispatch]);
  return (
    <div>
      {/* Google Tag Manager (noscript) */}
      {!GMTNoScriptLoaded && (
        <noscript>
          <iframe
            title="GTM-597BDG5"
            src="https://www.googletagmanager.com/ns.html?id=GTM-597BDG5"
            height="0"
            width="0"
            style={{ display: 'none', visibility: 'hidden' }}
          />
        </noscript>
      )}
      {/* End Google Tag Manager (noscript) */}
      <Header />
      <Banner />
      <Funcionamento />
      <Servicos />
      <Mapa />
      <Formulario />
      <Footer />
    </div>
  );
};
export default Home;
GMTNoScriptLoaded = true;
