import React from 'react';
import * as P from 'prop-types';
import { Button, Icon } from '@material-ui/core';

export default function ButtonMoreBrands({ showMore, handleClick }) {
  return (
    <Button
      size="small"
      color="primary"
      variant="text"
      onClick={handleClick}
      endIcon={<Icon>{showMore ? 'remove' : 'add'}</Icon>}
    >
      {showMore ? 'Menos Marcas' : 'Outras marcas'}
    </Button>
  );
}

ButtonMoreBrands.propTypes = {
  showMore: P.bool.isRequired,
  handleClick: P.func.isRequired,
};
