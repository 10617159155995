import client from '@/Apollo';
import { GetStores } from '../query/GetStores';

const insertStores = () => new Promise((resolve, reject) => {
  client
    .query({
      query: GetStores,
    })
    .then((response) => resolve(response.data.ReparafoneGetStoresAll))
    .catch((e) => {
      console.log(e.message, 'erro ao inserir as stores');
      reject(e.message);
    });
});

export default insertStores;
