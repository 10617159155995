import { gql } from '@apollo/client';

const RequestChangeEmail = gql`
  mutation($email: String!, $newEmail: String!){
    CustomerRequestChangeEmail(email: $email, newEmail: $newEmail){
      id
      customer_email
      new_email_bcrypt
    }
  }
`;

export default RequestChangeEmail;
