import { useQuery } from '@apollo/client';
import React from 'react';
import { Box, Typography } from '@material-ui/core';
import GetConditionQuestionsQuery from '../../graphql/query/GetConditionQuestions';
import ListQuestions from './ListQuestions';

const DeviceCondition = () => {
  const { data, loading, error } = useQuery(GetConditionQuestionsQuery);
  if (loading) return 'Carregando...';
  if (error) return 'Ocorreu um erro.';
  return (
    <>
      <Box maxWidth={668}>
        <Box py={1}>
          <Box py={1}>
            <Typography variant="h6" align="center">Condição do dispositivo</Typography>
          </Box>
          <Box>
            <ListQuestions questions={data.ReparafoneGetConditionQuestions} />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default DeviceCondition;
