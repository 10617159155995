import React from 'react';

import { Header, List } from 'semantic-ui-react';

const Contato = () => (
  <div className="info-contatos">
    <Header as="h3" content="Informações de Contato" />
    <List animated verticalAlign="middle">
      <List.Item
        icon="marker"
        content={(
          <a
            href="https://goo.gl/maps/HqU2y6yZf7MzB3mH7"
            rel="noreferrer"
            target="_blank"
          >
            Rua Campos Vergueiro, 256 - Espace Center, Módulo 20 - Vila
            Anastácio, São Paulo - SP. CEP 05095-020
          </a>
        )}
      />
      <List.Item
        icon="whatsapp"
        content={(
          <a href="/atendimento-wpp" rel="noreferrer" target="_blank">
            (11) 91197-3880
          </a>
        )}
      />
      <List.Item
        icon="mail"
        content={(
          <a href="mailto:atendimento.reparo@trocafone.com " rel="noreferrer" target="_blank">
            atendimento.reparo@trocafone.com
          </a>
        )}
      />
    </List>
  </div>
);

export default Contato;
