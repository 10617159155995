import React from 'react';
import { Header, Button } from 'semantic-ui-react';
import { HashLink as Link } from 'react-router-hash-link';
import Lista from './Lista';

import TrocaDeVidro from '../../../../images/Servicos/servicos-tela-de-vidro.png';
import TrocaDeTela from '../../../../images/Servicos/servicos-de-tela.png';
import Camera from '../../../../images/Servicos/servico-camera.png';
import Carcaca from '../../../../images/Servicos/servico-carcaca.png';

import Bateria from '../../../../images/Servicos/servico-bateria.png';
import Vibracall from '../../../../images/Servicos/servico-vibracall.png';
import Botoes from '../../../../images/Servicos/servico-toque.png';
import Falante from '../../../../images/Servicos/servico-falante.svg';

import './Servicos.css';

const Servicos = () => (
  <>
    <div id="services" className="servicos">
      <div className="servicos-interno">
        <div className="titulo">
          <Header as="h2" content="Nossos Serviços" />
        </div>
        {/* Primeira Parte */}
        <Lista
          icon={TrocaDeVidro}
          title="Troca de vidro"
          text="Substituição total de vidro do aparelho com todas as funcionalidades."
        />
        <Lista
          icon={TrocaDeTela}
          title="Troca de tela"
          text="Substituição total de tela voltando todas as cores e resoluções."
        />
        <Lista
          icon={Camera}
          title="Câmeras"
          text="Conserto ou substituição de câmeras reajustando as capturas de imagens."
        />
        <Lista
          icon={Carcaca}
          title="Carcaça"
          text="Temos e trocamos todas as carcaças danificadas e capas de proteção para celular."
        />
        {/* Segunda Parte */}
        <Lista
          icon={Bateria}
          title="Bateria Danificada"
          text="Reparo ou substituição de bateria cansada ou danificada por excesso de uso."
        />
        <Lista
          icon={Vibracall}
          title="Vibracall"
          text="Conserto completo do sistema de vibracall de qualquer modelo com mal funcionamento."
        />
        <Lista
          icon={Botoes}
          title="Botões Não Funcionam"
          text="Análise e reparo no aparelho para funcionalidades do sistema touch."
        />
        <Lista
          icon={Falante}
          title="Alto Falante e Microfone"
          text="Conserto ou substituição de alto falante e microfone com mal funcionamento."
        />

        <div className="section-orcamento">
          <Link to="#do-estimate">
            <Button
              content="Solicitar Orçamento"
              icon="dollar"
              labelPosition="right"
              className="button-services"
            />
          </Link>
        </div>
      </div>
    </div>
  </>
);

export default Servicos;
