import React from 'react';
import {
  Box,
  Button, CircularProgress, Collapse, Grid, Paper, Typography,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import {
  groupBy, map, maxBy, uniqBy,
} from 'lodash';
import CalculateRepairCost from '@/pages/neworder/utils/CalculateRepairCost';
import LabelBudget from '../LabelBudget';
import PropTypes from './PropTypes';
import BrazillianCurrency from '../../../../../../utils/NationalCurrency';

function BoxRepairBudget({
  defects, hookCalcBudget, selectedDefects, deliveryZipcode, collectZipcode,
}) {
  const [beCalc, setBeCalc] = React.useState(false);
  const [isCalculating, setIsCalculating] = React.useState(false);
  const [repairPrice, setRepairPrice] = React.useState({
    repair: 0,
    taxDelivery: 0,
    taxCollect: 0,
  });
  const [budgetCalculated, setBudgetCalculated] = React.useState(false);
  const canBeRepaired = !defects.find((defect) => !defect.maxPrice);
  const calcBudget = async (createLead = true) => {
    setBudgetCalculated(true);
    setIsCalculating(true);
    if (hookCalcBudget) {
      await hookCalcBudget(createLead, canBeRepaired);
    }
    if (!canBeRepaired) {
      setBeCalc(false);
      setIsCalculating(false);
      return;
    }
    setBeCalc(false);
    const { budgetRepair, taxCollect, taxDelivery } = await CalculateRepairCost(
      selectedDefects, collectZipcode, deliveryZipcode,
    );
    console.log({ budgetRepair, taxCollect, taxDelivery }, 'preço calculado');
    setRepairPrice(
      {
        repair: budgetRepair + taxCollect + taxDelivery,
        taxDelivery,
        taxCollect,
      },
    );
    setIsCalculating(false);
  };

  React.useEffect(() => {
    setBeCalc(true);
    setBudgetCalculated(false);
  }, [defects]);

  React.useEffect(() => {
    if (defects.length) calcBudget(false);
  }, []);
  /**
   * A ideia é pegar os defeitos e agrupá-los de acordo com o tipo de peça mais cara e concatenar
   * o nome dos defeitos para gerar uma estrutura [{defectName, maxPrice}, ...]
   */
  const pricesByGroupedDefects = map(
    groupBy(
      selectedDefects.map(
        (defect) => ({ ...defect, moreExpensivePart: maxBy(defect.parts, 'part_price_selling') }),
      ),
      (d) => d.moreExpensivePart.part_type,
    ), (r) => {
      const name = r.reduce((acc, value) => [...acc, value.name], []).join(', ');
      return { name, maxPrice: r[0].maxPrice };
    },
  );
  return (
    <Grid container spacing={2}>
      {budgetCalculated && !canBeRepaired && (
      <Grid item xs={12} md={12}>
        <Alert severity="error">
          Infelizmente ainda não conseguimos reparar esse defeito do seu aparelho.
        </Alert>
      </Grid>
      )}
      <Grid item xs={12} md={!beCalc && !isCalculating ? 6 : 12}>
        <Button
          fullWidth
          size="large"
          color="secondary"
          variant="contained"
          onClick={calcBudget}
          disabled={defects.length < 1 || !beCalc || isCalculating}
          endIcon={(
            isCalculating ? <CircularProgress size="1.2rem" /> : null
          )}
        >
          Estimar orçamento
        </Button>
      </Grid>
      <Grid item xs={12} md={6}>
        <Collapse
          in={!beCalc && !isCalculating && canBeRepaired}
          unmountOnExit
          orientation="horizontal"
        >
          <LabelBudget budget={repairPrice.repair} />
        </Collapse>
      </Grid>
      {!beCalc && !isCalculating && canBeRepaired && (
        <Grid item xs={12}>
          <Paper elevation={1} variant="outlined">
            <Box p={2}>
              <Typography variant="subtitle2">Custo de reparo detalhado</Typography>

              {pricesByGroupedDefects.map((defect) => (
                <div key={defect.name}>
                  <Grid container direction="row">
                    <Grid item xs>
                      <Typography variant="body2" align="left">
                        {defect.name}
                      </Typography>
                    </Grid>
                    <Grid item xs="auto">
                      <Typography variant="body2" align="right">
                        {BrazillianCurrency(defect.maxPrice / 100).format()}
                      </Typography>
                    </Grid>
                  </Grid>
                </div>
              ))}
              <Typography variant="subtitle2">Custo de transporte</Typography>
              <Grid container direction="row">
                <Grid item xs>
                  <Typography variant="body2" align="left">
                    Coleta
                  </Typography>
                </Grid>
                <Grid item xs="auto">
                  <Typography variant="body2" align="right">
                    {repairPrice.taxCollect === 0
                      ? 'Grátis' : BrazillianCurrency(repairPrice.taxCollect / 100).format()}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container direction="row">
                <Grid item xs>
                  <Typography variant="body2" align="left">
                    Entrega
                  </Typography>
                </Grid>
                <Grid item xs="auto">
                  <Typography variant="body2" align="right">
                    {repairPrice.taxDelivery === 0
                      ? 'Grátis' : BrazillianCurrency(repairPrice.taxDelivery / 100).format()}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Grid>
      )}
      {!beCalc && !isCalculating && canBeRepaired && (
      <Grid item xs={12}>
        <Typography variant="body1" align="center">
          {'O prazo para reparo do aparelho é de '}
          <strong>3 dias úteis</strong>
          .
        </Typography>
      </Grid>
      )}
    </Grid>
  );
}

BoxRepairBudget.propTypes = PropTypes.propTypes;

export default React.memo(BoxRepairBudget);
