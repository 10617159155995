import React, { useState } from 'react';
import * as P from 'prop-types';
import {
  Box, Checkbox, FormControlLabel, Typography,
} from '@material-ui/core';
import ReactGA from 'react-ga';
import { useSelector } from 'react-redux';
import DialogTerms from './DialogTerms';

export default function TermsOfService({ handleChange }) {
  const partnerData = useSelector((state) => state.PartnerStoreReducer);
  const { branch } = partnerData;
  const [termsChecked, setTermsChecked] = useState(false);
  const [customerAgreed, setCustomerAgreed] = useState(false);
  const [open, setOpen] = React.useState(false);
  const handleDialogTerms = () => setOpen(true);
  const handleCheckBoxChange = ({ target }) => {
    setTermsChecked(target.checked);
  };
  const handleCustomerCheckBoxChange = ({ target }) => {
    setCustomerAgreed(target.checked);
  };
  const saveClick = () => {
    handleDialogTerms();
    if (process.env.NODE_ENV === 'production') {
      ReactGA.event({
        category: `createOrder${branch.branch_name}`,
        action: 'seenTermsOfService',
        label: 'Visualizou os termos de serviço',
      });
    }
  };
  React.useEffect(() => {
    handleChange(termsChecked);
  }, [termsChecked]);

  return (
    <Box py={1}>
      <DialogTerms
        open={open}
        close={setOpen}
      />
      <FormControlLabel
        control={(
          <Checkbox
            checked={customerAgreed}
            onChange={handleCustomerCheckBoxChange}
          />
        )}
        label={(
          <span>
            Confirmo receber atualizações do
            {' '}
            <b>Reparo</b>
            {' '}
            através do canal do
            {' '}
            <b>Whatsapp</b>
          </span>
        )}
      />
      <FormControlLabel
        control={
          <Checkbox checked={termsChecked} onChange={handleCheckBoxChange} />
        }
        label={(
          <span>
            Confirmo que li e concordo com os
            {' '}
            <a onClick={saveClick}>
              Termos de Serviços
            </a>
            {' '}
            e Política de Privacidade
            {/* <a href="/termsofservice/reparofone" target="_blank">
              Política de Privacidade
            </a> */}
          </span>
        )}
      />
      <Typography style={{ marginTop: '15px', fontSize: '0.9rem' }} variant="subtitle1">
        Os dados pessoais serão coletados e tratados exclusivamente pela
        Trocafone e seus Parceiros, de acordo com a lei nº 13.709/2018.
      </Typography>
    </Box>
  );
}

TermsOfService.propTypes = {
  handleChange: P.func.isRequired,
};
