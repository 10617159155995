import { CreateLead } from '../mutation/CreateLead';
import { client } from '../../../../Apollo';

const createLeadOrder = (data, setLead) => new Promise((resolve, reject) => {
  client
    .mutate({
      mutation: CreateLead,
      variables: { ...data },
    })
    .then((r) => {
      setLead(r.data.createLeadOrderRepair);
      resolve(r.data.createLeadOrderRepair);
    })
    .catch((e) => {
      console.log(e.message, 'erro ao criar');
      reject(e.message);
    });
});

export default createLeadOrder;
