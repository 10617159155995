import makeStyle from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyle(({ spacing, breakpoints, zIndex }) => ({
  cepAvailable: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '10px',
  },
  backdrop: {
    zIndex: zIndex.drawer + 1,
    color: '#fff',
  },
}));

export default useStyles;
