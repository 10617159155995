import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles(() => ({
  optionCarrefour: {
    '& div>div>div.MuiAutocomplete-tag': {
      backgroundColor: '#1752CC',
    },
    '& div>div>div>svg.MuiChip-deleteIcon': {
      color: '#fcfcfc',
    },
  },
  optionReparofone: {
    '& div>div>div.MuiAutocomplete-tag': {
      backgroundColor: '#5C97FC',
    },
  },
}));
