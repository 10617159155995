import React from 'react';
// REDUX
import { useSelector } from 'react-redux';
// STYLES
import styles from './style';

const Logo = () => {
  const classes = styles();
  const partnerData = useSelector((state) => state.PartnerStoreReducer);
  return (
    <div className={classes.logo}>
      <a className={classes.logoLink} href={window.location.pathname}>
        <img
          src={require(`@/images/Logo/${partnerData.branch.branch_slug}-logo.png`)}
          alt={partnerData.branch.branch_name}
          className={classes.img}
        />
      </a>
    </div>
  );
};

export default Logo;
