import gql from 'graphql-tag';

export const getModelsByBrandQuery = gql`
	query ($brand: Mixed!){
		models(hasColor: true, where:{AND:[{column:BRAND, value: $brand}, {column: IN_MARKET, value: true}]}){
			id
			name
			brand
			image
		}
	}
`;

export default getModelsByBrandQuery;
