import React from 'react';
import {
  Button, Container,
} from '@material-ui/core';
import Modal from 'react-modal';
import { mapStatusSlugToComponent } from './MapComponentsToStatusSlug';
import FullWidthModal from '../../../../components/modal/FullWidthModal';

Modal.setAppElement('#root');

const ModalStepper = (props) => {
  const { order, status } = props;
  const [modalState, setModalState] = React.useState(false);
  const handleModalState = (e) => () => {
    setModalState(e);
  };
  const mappedStatus = mapStatusSlugToComponent[status];
  return (
    <>
      <Button
        fullWidth
        variant="contained"
        color="primary"
        onClick={handleModalState(true)}
      >
        {mappedStatus.buttonTitle}
      </Button>
      <FullWidthModal
        isOpen={modalState}
        handleModalState={handleModalState}
        title="Aprovação de Orçamento"
      >
        <Container>
          {React.createElement(mappedStatus.component, { order })}
        </Container>
      </FullWidthModal>
    </>
  );
};

export default ModalStepper;
