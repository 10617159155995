import React from 'react';
import {
  Grid,
  Typography,
  Divider,
  Button,
  FormControl,
  FormHelperText,
  InputAdornment,
  IconButton,
  TextField,
  Collapse,
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { Formik, Form } from 'formik';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import Alert from '@material-ui/lab/Alert';
import ReCAPTCHA from 'react-google-recaptcha';
import { defaultTo } from 'lodash';
import { requestLoginPartner } from '../../../redux/actions/auth/AuthActions';

const Login = ({ requestLoginPartner, outerHistory, outerLocation }) => {
  const [state, setState] = React.useState({ status: true });
  const [showPassword, setShowPassword] = React.useState(false);
  const recaptchaRef = React.useRef();
  const collapseRef = React.useRef();
  const id = React.useMemo(() => `fetching-${Math.floor(Math.random() * 100)}`);

  const onSubmitForm = (data, { setSubmitting }) => {
    setState(true);
    requestLoginPartner({ ...data })
      .then((r) => {
        console.log(r);
        const { from } = outerLocation.state || {
          from: { pathname: '/partners/servicerepair' },
        };
        outerHistory.replace(from);
        return setState(r.data);
      })
      .catch(({ response }) => {
        console.log(response);
        setState({ status: false, data: response.data });
        if (recaptchaRef.current) recaptchaRef.current.reset();
      })
      .finally(() => setSubmitting(false));
  };

  const SignInSchema = Yup.object().shape({
    email: Yup.string()
      .email('Digite um e-mail válido!')
      .required('Digite o seu e-mail!'),
    password: Yup.string()
      .min(3, 'Senha muito curta!')
      .required('Digite a sua senha!'),
    captcha:
      state.data && state.data.captcha
        ? Yup.string().required(state.data.captcha)
        : Yup.string().notRequired(),
  });

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  return (
    <>
      <Grid container direction="row">
        <Grid item xs={12}>
          <Typography variant="subtitle1">Inicie sua sessão</Typography>
        </Grid>
      </Grid>
      <Divider style={{ marginTop: '0.5rem', marginBottom: '0.5rem' }} />
      <Formik
        initialValues={{
          email: '',
          password: '',
          captcha: '',
        }}
        validationSchema={SignInSchema}
        onSubmit={onSubmitForm}
      >
        {({
          errors,
          touched,
          isSubmitting,
          setFieldValue,
          handleChange,
          values,
        }) => (
          <Form>
            <Grid container direction="column" spacing={4}>
              <Grid item xs={12}>
                <TextField
                  disabled={isSubmitting}
                  fullWidth
                  value={values.email}
                  label="E-mail"
                  name="email"
                  onChange={handleChange}
                  helperText={touched.email && errors.email}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  disabled={isSubmitting}
                  onChange={handleChange}
                  type={showPassword ? 'text' : 'password'}
                  fullWidth
                  label="Senha"
                  name="password"
                  value={values.password}
                  helperText={errors.password}
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                          disabled={isSubmitting}
                        >
                          {!showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Collapse
                id={id}
                in={!state.status
                && state.data
                && (state.data.error || state.data.email)}
                ref={collapseRef}
                style={{ width: '100%' }}
              >
                <Grid item xs={12}>
                  <Alert severity="error">
                    {state?.data?.error}
                  </Alert>
                </Grid>
              </Collapse>
              {!state.status && state.data && state.data.captcha && (
                <Grid item xs={12}>
                  <FormControl error={!!errors.captcha}>
                    <ReCAPTCHA
                      ref={recaptchaRef}
                      sitekey="6Ld4JNAZAAAAADLjk2lF26MsKpEqbjntNAxqfT9I"
                      onChange={(v) => setFieldValue('captcha', v)}
                    />
                    <FormHelperText>{errors.captcha}</FormHelperText>
                  </FormControl>
                  <input
                    type="hidden"
                    name="captcha"
                    value={defaultTo(
                      recaptchaRef.current && recaptchaRef.current.getValue(),
                      '',
                    )}
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <Button
                  disabled={isSubmitting}
                  type="submit"
                  color="primary"
                  variant="contained"
                  fullWidth
                  title="Entrar"
                >
                  Entrar
                </Button>
              </Grid>
              <Grid item>
                <Typography variant="body1" align="center">
                  Esqueceu sua senha?
                  <Link
                    style={{
                      textDecoration: 'none',
                      color: '#5146A2',
                      fontWeight: '500',
                    }}
                    role="link"
                    to="/partners/recover/password"
                  >
                    {' '}
                    Clique aqui!
                  </Link>
                </Typography>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};

const mapStateToProps = (state) => {
  const { AuthReducer } = state;
  const { isFetching } = AuthReducer;
  return {
    isFetching,
  };
};

export default connect(mapStateToProps, { requestLoginPartner })(Login);
