import React from 'react';
import * as P from 'prop-types';
import clsx from 'clsx';
import { TextField, Icon, InputAdornment } from '@material-ui/core';
import useStyle from '../../../../../style';

export default function InputSearchModel({ disabled, handleSearch }) {
  const [search, setSearch] = React.useState('');
  const [id, setId] = React.useState(null);
  const classes = useStyle();

  const handleInputSearch = ({ currentTarget }) => (
    currentTarget
      && setSearch(currentTarget.value)
  );

  React.useEffect(() => {
    if (id) {
      clearTimeout(id);
    }

    setId(setTimeout(() => (
      handleSearch(search)
    ), 750));
    return () => clearTimeout(id);
  }, [search]);

  return (
    <TextField
      label="Pesquisar modelo"
      value={search}
      variant="standard"
      size="small"
      onChange={handleInputSearch}
      onBlur={handleInputSearch}
      disabled={disabled}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Icon className={clsx(['fas', 'fa-search', classes.inputIcon])} color="action" />
          </InputAdornment>
        ),
      }}
    />
  );
}

InputSearchModel.propTypes = {
  disabled: P.bool.isRequired,
  handleSearch: P.func.isRequired,
};
