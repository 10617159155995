import { client } from '@/Apollo';
import { GetCustomer } from '../query/GetCustomer';

const getCustomerInfo = () => new Promise((resolve, reject) => {
  client
    .query({
      query: GetCustomer,
    })
    .then((r) => {
      resolve(r.data.MeCustomer);
    })
    .catch((e) => {
      console.log(e.message, 'erro ao buscar cliente');
      reject(e.message);
    });
});

export default getCustomerInfo;
