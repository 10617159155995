import React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import ReparafoneTOS from '../pages/termsOfService/ReparafoneTOS';
import VivoTOS from '../pages/termsOfService/VivoTOS';

const TermsOfServiceRoutes = withRouter(({ location }) => (
  <Switch location={location}>
    <Route exact path="/termsofservice/reparofone">
      <ReparafoneTOS />
    </Route>
    <Route exact path="/termsofservice/vivo">
      <VivoTOS />
    </Route>
  </Switch>
));

export default TermsOfServiceRoutes;
