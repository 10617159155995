import React from 'react';
import * as P from 'prop-types';
import { Grid, Collapse, Button } from '@material-ui/core';

export default function InlineGridBrand({ brand, handleClick, show }) {
  return (
    <Grid
      item
      xs={6}
      sm={4}
      md={3}
      component="div"
      className={`gridBrand${show ? '' : ' hideGrid'}`}
    >
      <Collapse in={show}>
        <Button className="brandCard" value={brand.name} onClick={handleClick} title={brand.name}>
          <div className="brandContainer">
            <div className="brandContainerImage">
              <img src={brand.image} alt={brand.name} />
            </div>
            <span className="brandName">{ brand.name }</span>
          </div>
        </Button>
      </Collapse>
    </Grid>
  );
}

InlineGridBrand.propTypes = {
  show: P.bool.isRequired,
  handleClick: P.func.isRequired,
  brand: P.shape({
    name: P.string.isRequired,
    image: P.string.isRequired,
  }).isRequired,
};
