import React from 'react';
import {
  Grid, Typography, useMediaQuery, useTheme,
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

const OrderInfo = ({ label, value, isLoading }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Grid container direction="row">
      <Grid item xs={12} sm={4}>
        <Typography variant="subtitle2" style={{ color: '#383838' }}>
          {isLoading ? <Skeleton /> : `${label} `}
        </Typography>
      </Grid>
      <Grid item xs style={isMobile ? null : { paddingLeft: '50px' }}>
        <Typography variant="body1">{isLoading ? <Skeleton /> : value}</Typography>
      </Grid>
    </Grid>
  );
};

export default OrderInfo;
