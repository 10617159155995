import {
  Grid,
  TextField,
  makeStyles,
  CircularProgress,
  InputAdornment,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Button,
} from '@material-ui/core';
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
  setCouponData,
  setTransactionAmount,
  removeCouponData,
} from '@/redux/actions/NewOrderActions';
import validateCoupon from '@/components/inputs/Coupon/graphql/schema/Validate';
import discountCoupon from '@/components/inputs/Coupon/graphql/schema/Discount';
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';
import AlertCoupon from './components/AlertCoupon';

const useStyles = makeStyles((theme) => ({
  couponButton: {
    background: '#21BA45',
    color: '#FFFFFF',
    '&:hover': {
      background: '#21BA45',
    },
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexShrink: 0,
  },
  accordion: {
    marginTop: '10px',
    boxShadow: 'none',
    background: '#FCFCFC',
    border: '1px solid rgba(0,0,0,0.12)',
    borderTop: '1px solid rgba(0,0,0,0.12)',
    '&::before': {
      content: 'none',
    },
  },
}));

const Coupon = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const orderData = useSelector((state) => state.NewOrderReducer);
  const { transactionAmount, coupons, device } = orderData;
  const { brand } = device;
  const { taxCollect, taxDelivery, budgetRepair } = transactionAmount;

  const [couponCode, setCouponCode] = React.useState('');
  const [conditions, setConditions] = React.useState(null);
  const [valid, setIsValid] = React.useState(null);
  const [error, setErrorAlert] = React.useState(null);
  const [disabledButton, setDisabledButton] = React.useState(true);
  const [disabledTextField, setDisabledTextField] = React.useState(false);
  const [expanded, setExpanded] = React.useState(false);
  const [arrayData, setArrayData] = React.useState({
    coupon: null,
    estimate: null,
  });

  const handleChangeAccordion = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleChange = (e) => {
    const { value } = e.target;
    if (value) {
      setDisabledButton(false);
      setCouponCode(value);
      setConditions([
        { minimumValue: budgetRepair },
        { brand },
      ]);
      return setArrayData({
        coupon: value,
        estimate: [
          { cost: budgetRepair, type: 'repair' },
          { cost: taxDelivery, type: 'shipping' },
        ],
      });
    }
    return setDisabledButton(true);
  };

  const isLoading = (enabled) => {
    setDisabledTextField(enabled);
    setDisabledButton(enabled);
  };

  const validateCouponCode = async () => {
    isLoading(true);
    setIsValid(null);
    const responseValidate = await validateCoupon(
      couponCode,
      setDisabledTextField,
      setErrorAlert,
      conditions,
    );
    if (!responseValidate) {
      setDisabledTextField(false);
      setIsValid(false);
      dispatch(removeCouponData(0));
      return dispatch(
        setTransactionAmount({
          taxCollect,
          taxDelivery,
          budgetRepair,
          totalBudgetRepair: budgetRepair,
        }),
      );
    }
    if (coupons.length > 0) dispatch(removeCouponData(0));
    const responseDiscount = await discountCoupon(
      arrayData,
      setDisabledTextField,
      setErrorAlert,
    );
    setIsValid(true);
    setDisabledTextField(false);
    dispatch(setCouponData(responseDiscount));
    return dispatch(
      setTransactionAmount({
        taxCollect,
        taxDelivery,
        budgetRepair,
        totalBudgetRepair: budgetRepair - responseDiscount.discount,
      }),
    );
  };

  return (
    <>
      <AlertCoupon
        valid={valid}
        setIsValid={setIsValid}
        error={error}
        setErrorAlert={setErrorAlert}
      />
      <Accordion
        className={classes.accordion}
        expanded={expanded === 'panel1'}
        onChange={handleChangeAccordion('panel1')}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography className={classes.heading}>
            Inserir cupom de desconto
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container justify="center" alignItems="center">
            <Grid style={{ paddingLeft: '10px' }}>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                disabled={disabledTextField}
                label="Digite seu cupom"
                onChange={handleChange}
                InputProps={{
                  endAdornment: (
                    <>
                      {disabledTextField ? (
                        <InputAdornment position="end">
                          <CircularProgress color="inherit" size={20} />
                        </InputAdornment>
                      ) : null}
                    </>
                  ),
                }}
              />
            </Grid>
            <Grid style={{ paddingLeft: '10px' }}>
              <Button
                variant="contained"
                size="small"
                className={classes.couponButton}
                onClick={validateCouponCode}
                disabled={disabledButton}
                disableElevation
                startIcon={<ConfirmationNumberIcon />}
              >
                Validar
              </Button>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </>
  );
};
export default Coupon;
