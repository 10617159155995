import React from 'react';

import { Typography } from '@material-ui/core';
import { HashLink as Link } from 'react-router-hash-link';

import ReactGA from 'react-ga';

import { Button } from 'semantic-ui-react';

import './BannerFranchisee.css';

const handleCTA = () => {
  if (process.env.NODE_ENV === 'production') {
    ReactGA.event({
      category: 'Interactivity',
      action: 'bannerFranchiseeButtonClicked',
      label: 'Clicou no botão quero ser um parceiro',
    });
  }
};

const Banner = () => (
  <div id="home-franch" className="banner-franchisee">
    <div className="banner-franchisee-container">
      <Typography variant="h1" align="left" className="titulo-banner">
        Seja um parceiro!
      </Typography>
      <Typography variant="h1" align="left" className="subtitulo-banner">
        Seja nosso parceiro, ganhe comissão por reparo vendido, e tenha a equipe
        Reparofone comprometida em consertar os celulares por você.
      </Typography>
      <Link to="#form-partner">
        <Button
          icon="handshake outline"
          type="button"
          content="Quero ser parceiro Reparofone"
          labelPosition="right"
          className="button-banner"
          onClick={handleCTA}
        />
      </Link>
    </div>
  </div>
);

export default Banner;
