import {
  Button, Grid, Typography,
} from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import React from 'react';
import { Link } from 'react-router-dom';
import Header from './components/Header';
import CardContent from './components/CardContent';

const Pending = () => (
  <>
    <Header color={grey[500]} title="Pagamento pendente!" description="O seu pagamento está pendente no meio de pagamento e assim que for aprovado atualizaremos o status da ordem de serviço, fique atento ao seu e-mail!" />
    <CardContent>
      <Grid container direction="column" alignItems="center" spacing={2}>
        <Grid item>
          <Typography variant="body1">
            Você pode continuar acompanhando a sua ordem de serviço através da nossa plataforma!
          </Typography>
        </Grid>
        <Grid item>
          <Button component={Link} to="/customer/repairs" color="primary" variant="contained">Ver ordens de serviço</Button>
        </Grid>
      </Grid>
    </CardContent>
  </>
);

export default Pending;
