import React from 'react';
import { TextField } from '@material-ui/core';
import PropTypes from './PropTypes';
import rules from './Rules';

export default function InputDefectComment({ handleChange, value }) {
  return (
    <TextField
      multiline
      fullWidth
      style={{ marginBottom: '20px' }}
      name="defectComment"
      variant="outlined"
      label="Comentários adicionais sobre o defeito"
      placeholder="O aparelho só liga acima de 30%..."
      rows={2}
      rowsMax={6}
      value={value}
      onChange={handleChange}
    />
  );
}

InputDefectComment.propTypes = PropTypes.propTypes;
InputDefectComment.defaultProps = PropTypes.defaultProps;
InputDefectComment.rules = rules;
