import client from '@/Apollo';
import CreateOrderMutation from '@/pages/neworderpartner/graphql/mutation/CreateOrderMutation';
import { PROCESS_PAYMENT_TIME } from '@/redux/reducers/NewOrderReducer/initialState';
import { pick } from 'lodash';
import { setOrderData } from '../actions';

function creditCardData(paymentData, transactionAmount, customer) {
  return {
    payment_gateway: 'mercadopago',
    payment_method_id: paymentData.data.paymentMethodId, // TO-DO
    transaction_amount:
      transactionAmount.budgetRepair
      + transactionAmount.taxCollect
      + transactionAmount.taxDelivery,
    installments: paymentData.data.installments,
    token: paymentData.data.cardToken,
    payer: {
      email: customer.email,
      identification: {
        number: customer.cpf,
        type: 'CPF',
      },
    },
  };
}

const mapDataToSmartprod = (currState) => {
  const {
    NewOrderReducer: {
      device,
      customer,
      payment,
      processPayment,
      billingAddress,
      transactionAmount,
      coupons,
      lead,
      tradeInUser,
      collect,
      delivery,
      fluxType,
      warranty,
    },
    PartnerStoreReducer: { employee, id: storeId, branch },
    VivoStoreReducer: { employee: vivoEmployee, id: vivoStoreId },
  } = currState;
  const collectType = collect.modalities.map((modality) => modality.collect.collect_slug);
  const deliveryType = delivery.modalities.map((modality) => modality.delivery.delivery_slug);
  const [deliveryDay, deliveryMonth, deliveryYear] = delivery.schedule_delivery.match(/[0-9]+/g).map((num) => parseInt(num, 10));
  return {
    order_flux_type: fluxType?.slug ?? 'fora-de-garantia',
    order_warranty: warranty?.type ?? 0,
    order_warranty_type: fluxType?.slug ?? 'fora-de-garantia',
    order_imei: device.imei ?? '',
    order_branch_id: branch.id ?? '',
    order_trade_in_user_id: tradeInUser.id ?? '',
    coupons: coupons.length > 0 ? coupons[0].code : null,
    order_branch_slug: branch.branch_slug ?? 'vivo',
    order_store_id: storeId || vivoStoreId,
    defects: device.selectedDefects.map((def) => pick(def, ['id'])),
    observation: device.defectComment,
    order_product_id: device.product.id,
    order_lead_id: lead.id ?? '',
    origin_user: employee.id || vivoEmployee.id,
    origin_store: storeId || vivoStoreId,
    delivery_date_limit: [deliveryDay, deliveryMonth, deliveryYear].join('/') ?? '',
    collect_type: collectType[0],
    delivery_type: deliveryType[0],
    conditions: device.conditions.map(({ id, condition }) => ({
      id,
      condition,
    })),
    customer: {
      customer_first_name: customer.name.split(' ')[0],
      customer_last_name: customer.name.split(' ').splice(1).join(' '),
      customer_email: customer.email,
      customer_phone: customer.phone.replace(/[^0-9]/gm, ''),
      customer_landline: customer.landline ? customer.landline.replace(/[^0-9]/gm, '') : '',
      customer_cpf: customer.cpf.replace(/[^0-9]/gm, ''),
      customer_rg: '',
    },
    address: {
      collect: {
        zip_code: customer.collect.zipCode.replace(/[^0-9]/gm, ''),
        state: customer.collect.state,
        city: customer.collect.city,
        neighborhood: customer.collect.neighborhood,
        street: customer.collect.street,
        street_number: customer.collect.number,
        complement: customer.collect.complement || '',
      },
      delivery: {
        zip_code: customer.delivery.zipCode.replace(/[^0-9]/gm, ''),
        state: customer.delivery.state,
        city: customer.delivery.city,
        neighborhood: customer.delivery.neighborhood,
        street: customer.delivery.street,
        street_number: customer.delivery.number,
        complement: customer.delivery.complement || '',
      },
      charge: {
        zip_code: billingAddress.zipCode.replace(/[^0-9]/gm, ''),
        state: billingAddress.state,
        city: billingAddress.city,
        neighborhood: billingAddress.neighborhood,
        street: billingAddress.street,
        street_number: billingAddress.number,
        complement: billingAddress.complement || '',
      },
    },
    process_payment: processPayment,
    payment:
      payment.data && payment.data.paymentMethodId
        ? creditCardData(payment, transactionAmount, customer)
        : null,
    delivery_by_store: customer.delivery.isChartered,
  };
};

export default function createOrder(handleAlert, setWarrantyLoading = false, currentOrderId) {
  return async (dispatch, getState) => new Promise((resolve, reject) => {
    const data = mapDataToSmartprod(getState());
    client
      .mutate({
        mutation: CreateOrderMutation,
        variables: {
          input: { ...data, current_order_id: currentOrderId || '' },
          needPaymentLink:
              data.process_payment === PROCESS_PAYMENT_TIME.AFTER_CREATION,
        },
      })
      .then(({ data: { ReparafoneCreateVivoOrder } }) => {
        if (handleAlert) {
          handleAlert(
            'Sua ordem de Garantia foi criada com sucesso! 🎉',
            'success',
            true,
            4000,
          );
          document.location.reload(true);
        }
        dispatch(setOrderData(ReparafoneCreateVivoOrder));
        resolve({ status: true });
      })
      .catch((errors) => {
        console.log(errors, 'erro ao criar');
        if (handleAlert) {
          handleAlert(
            'Houve um erro ao criar a ordem de Garantia, por favor, tente novamente mais tarde.',
            'error',
            true,
            4000,
          );
        }
        // eslint-disable-next-line prefer-promise-reject-errors
        reject({ status: false, errors });
      })
      .finally(() => setWarrantyLoading ?? setWarrantyLoading(false));
  });
}
