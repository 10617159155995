import { gql } from '@apollo/client';

const ValidateEmailToken = gql`
  mutation($email: String!, $token: String!){
    ValidateChangeEmailToken(email: $email, token: $token){
      id
      customer_email
    }
  }
`;

export default ValidateEmailToken;
