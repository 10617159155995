import gql from 'graphql-tag';

export const CreateOrderMutation = gql`
	mutation ($input: CreateVivoOrderInput!, $needPaymentLink: Boolean = true){
  ReparafoneCreateVivoOrder(input: $input){
    id
    createdAt: order_created_at
    defects: informedDefects
    imei:order_imei
    product{
      name: product_name
    }
    budget: order_pre_price
    commentDefects: informedObservation
    shortPaymentLink @include(if: $needPaymentLink)
  }
}
`;

export default CreateOrderMutation;
