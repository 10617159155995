import client from '@/Apollo';
import GetEmployeeById from '../query/GetEmployee';

export default function getEmployeeById(id) {
  return new Promise((resolve, reject) => {
    client
      .query({
        query: GetEmployeeById,
        variables: { id },
      })
      .then(
        (response) => {
          resolve(response.data.ReparafoneGetTradeInUserById);
        },
      )
      .catch((error) => {
        console.log(error.message, 'erro ao buscar');
        reject(error);
      });
  });
}
