import * as P from 'prop-types';
import { DeviceProps, ProductProps } from '../../../../../../redux/reducers/NewOrderReducer/PropTypes';

export const StepperSelectDevicePropTypes = {
  $state: P.shape({
    device: P.shape({
      brand: DeviceProps.brand,
      model: DeviceProps.model,
      color: DeviceProps.color,
    }).isRequired,
    product: P.shape({
      name: ProductProps.name,
    }).isRequired,
  }),
  $actions: P.shape({
    setDevice: P.func.isRequired,
    searchProduct: P.func.isRequired,
  }),
};

export const StepperSelectDeviceDefaultProps = {};

export default { StepperSelectDevicePropTypes, StepperSelectDeviceDefaultProps };
