import React from 'react';
// ICONS
import BrokenImageIcon from '@material-ui/icons/BrokenImage';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import PermScanWifiIcon from '@material-ui/icons/PermScanWifi';
import PermDataSettingIcon from '@material-ui/icons/PermDataSetting';
import BatteryCharging20Icon from '@material-ui/icons/BatteryCharging20';

export default {
  1: <BrokenImageIcon />,
  2: <CameraAltIcon />,
  3: <BatteryCharging20Icon />,
  4: <CameraAltIcon />,
  5: <PermDataSettingIcon />,
  6: <PermScanWifiIcon />,
};
