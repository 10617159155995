import { uniqBy } from 'lodash';

const CalculateRepairCost = async (
  selectedDefects,
  chosenModality,
  modalities,
) => {
  const usedParts = uniqBy(
    selectedDefects.map((item) => item.parts).flat(),
    'part_type',
  );
  const budgetRepair = usedParts.reduce(
    (value, part) => value + (part?.part_price_selling ?? 0),
    0,
  );

  const result = modalities.find(
    (modality) => modality.collect.collect_slug === chosenModality.collect_slug,
  );

  if (result.price === undefined || result.price === null) {
    throw new Error('Erro ao consultar preços de coleta/entrega.');
  }
  return {
    budgetRepair,
    taxDelivery: 0,
    taxCollect: result.price,
  };
};

export default CalculateRepairCost;
