import React from 'react';
import * as P from 'prop-types';
import { Grid, Typography } from '@material-ui/core';

export default function InlineGridInfo({ title, value }) {
  return (
    <>
      <Grid item xs={6} sm={4} lg={3}>
        <Typography variant="subtitle2">{title}</Typography>
      </Grid>
      <Grid item xs={6} sm={8} lg={9}>
        <Typography variant="body1">{value}</Typography>
      </Grid>
    </>
  );
}

InlineGridInfo.propTypes = {
  title: P.string.isRequired,
  value: P.oneOfType([P.string, P.number]),
};

InlineGridInfo.defaultProps = {
  value: '',
};
