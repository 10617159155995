import * as P from 'prop-types';
import { ModelProps } from '@redux/reducers/NewOrderReducer/PropTypes';

export const ListModelsPropTypes = {
  models: P.arrayOf(P.shape(ModelProps)).isRequired,
  handleSelect: P.func.isRequired,
  width: P.string.isRequired,
};

export default { ListModelsPropTypes };
