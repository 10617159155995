// LODASH
import { isEmpty } from 'lodash';

export const addressFormatter = (
  addresses,
  setAddressCollect,
  setAddressDelivery,
  setAddressCharge,
) => {
  if (isEmpty(addresses)) return null;
  return addresses.map((address) => {
    switch (address.addressType) {
      case 'order_collect':
        return setAddressCollect(`${address?.street}, ${address?.number} ${address?.complement} - ${address?.neighborhood}, ${address?.city} - ${address?.state}, ${address?.zipCode.replace(/(\d{5})(\d{3})/, '$1-$2')}`);
      case 'order_delivery':
        return setAddressDelivery(`${address?.street}, ${address?.number} ${address?.complement} - ${address?.neighborhood}, ${address?.city} - ${address?.state}, ${address?.zipCode.replace(/(\d{5})(\d{3})/, '$1-$2')}`);
      case 'order_charge':
        return setAddressCharge(`${address?.street}, ${address?.number} ${address?.complement} - ${address?.neighborhood}, ${address?.city} - ${address?.state}, ${address?.zipCode.replace(/(\d{5})(\d{3})/, '$1-$2')}`);
      default: return null;
    }
  });
};

export default addressFormatter;
