import React from 'react';
import { Box, Button, Collapse, Typography } from '@material-ui/core';
import getPrintView from './Utils/GetPrintView';
// FUNCTIONS
import { defaultProps, propTypes } from './PropTypes';
import connect from './ConnectComponentRedux';
import QRCode from 'qrcode.react';
// import ServiceOrder from './ServiceOrder';

function PrintServiceOrder({ in: show, $state }) {
  const { orderData, customer } = $state;
  const QRCodeURL = `https://reparofone.com.br/customer/repairs/${orderData.id}`;
  const handleClick = () => {
    const url = '/';
    const options = 'width=1100,height=900';
    const win = window.open(url, '_blank', options);

    win.document.write(getPrintView(customer, orderData));
  };

  return (
    <Collapse in={show} unmountOnExit>
      <Box height="100%" py={5} px={4} style={{ textAlign: 'center' }}>
        <Typography variant="h6">Recebemos sua ordem de serviço!</Typography>
        {orderData.shortPaymentLink && (
          <>
            <Typography variant="body1">
              Escaneie o QR Code abaixo para abrir a página de pagamento no seu
              dispositivo!
            </Typography>
            {/* value={orderData.shortPaymentLink} */}
            <QRCode value={QRCodeURL} size={300} includeMargin />
          </>
        )}
        <Button
          fullWidth
          size="large"
          color="primary"
          variant="outlined"
          onClick={handleClick}
        >
          Imprimir ordem
        </Button>
      </Box>
    </Collapse>
  );
}

PrintServiceOrder.propTypes = propTypes;

PrintServiceOrder.defaultProps = defaultProps;

export default connect(PrintServiceOrder);
