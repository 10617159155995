import { createAction } from '@reduxjs/toolkit';
import { MapBoxTypes } from '../reducers/MapBoxReducer';

export const setStores = createAction(MapBoxTypes.SET_STORES);
export const setCurrentStore = createAction(MapBoxTypes.SET_CURRENT_STORE);
export const setBranch = createAction(MapBoxTypes.SET_BRANCH);
export const setBranches = createAction(MapBoxTypes.SET_BRANCHES);
export const resetAllStoresData = createAction(MapBoxTypes.RESET_ALL_STORES);

export default {
  setCurrentStore,
  setStores,
  setBranch,
  setBranches,
  resetAllStoresData,
};
