import React from 'react';
import P from 'prop-types';
import clsx from 'clsx';
// ICONS
import PersonIcon from '@material-ui/icons/Person';
import DeviceUnknownRoundedIcon from '@material-ui/icons/DeviceUnknownRounded';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import CreditCardRoundedIcon from '@material-ui/icons/CreditCardRounded';
import AssignmentIcon from '@material-ui/icons/Assignment';
// FUNCTIONS
import PhoneIphoneRounded from '@material-ui/icons/PhoneIphoneRounded';
import style from './style';

export default function StepIcon(props) {
  const classes = style();
  const { active, completed, icon } = props;

  const icons = [
    null,
    <PersonIcon fontSize="small" />,
    <DeviceUnknownRoundedIcon fontSize="small" />,
    <AssignmentIndIcon fontSize="small" />,
    <CreditCardRoundedIcon fontSize="small" />,
    <AssignmentIcon fontSize="small" />,
  ];

  return (
    <div
      className={clsx(classes.root, 'stepIcon', {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[icon]}
    </div>
  );
}

StepIcon.propTypes = {
  active: P.bool.isRequired,
  completed: P.bool.isRequired,
  icon: P.number.isRequired,
};
