import { makeStyles } from '@material-ui/core/';

const useStyles = makeStyles((theme) => ({
  chip: {
    margin: theme.spacing(0.5),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(0.25),
    top: theme.spacing(0.25),
    color: theme.palette.grey[500],
  },
  dialogTitleText: {
    fontSize: '1.1rem',
    fontWeight: 500,
  },
  dialogTitle: {
    padding: '16px 60px 16px 16px',
  },
  dialogContent: {
    padding: '16px 10px',
  },
}));

export default useStyles;
