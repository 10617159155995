import React from 'react';
import {
  Route,
  Redirect,
} from 'react-router-dom';
import { connect } from 'react-redux';
import { useLazyQuery } from '@apollo/client';
import { GetEmployeeByIdWithStoreById } from '@/pages/neworder/graphql/query/GetEmployee';
import { bindActionCreators } from 'redux';
import { setStore, setEmployee } from '@redux/actions/VivoStoreActions';
import { omit } from 'lodash';

function VivoSimpleRoute({ children, $actions, ...rest }) {
  const { dispatch, VivoStoreReducer: { encryptedId: storeId, employee, address } } = rest;
  const [isAuthenticated, setAuthenticated] = React.useState(false);
  const [getData, { data, error, loading = true }] = useLazyQuery(GetEmployeeByIdWithStoreById);
  React.useEffect(() => {
    if (employee.id || storeId) {
      getData({ variables: { employeeId: employee.encryptedId, storeId } });
    }
  }, [employee.id, storeId]);
  React.useEffect(() => {
    if (data) {
      const { ReparofoneGetEncryptedUser, ReparofoneGetEncryptedStore } = data;
      if (ReparofoneGetEncryptedUser === null || ReparofoneGetEncryptedStore === null) return;
      setAuthenticated(true);
      const storeAddress = {...ReparofoneGetEncryptedStore.addresses[0]};
      storeAddress.zipCode = `${storeAddress.zipCode.substr(0, 5)}-${storeAddress.zipCode.substr(5, 3)}`;
      storeAddress.complement = storeAddress.complement ?? '';
      $actions.setStore({ ...omit(ReparofoneGetEncryptedStore, ['addresses']), address: storeAddress });
      $actions.setEmployee(ReparofoneGetEncryptedUser);
    }
  }, [data]);
  return (
    <Route
      {...rest}
      render={
        ({ location }) => (isAuthenticated && address ? (
          <>
            {children}
          </>
        ) : (<FallBack loading={loading} error={error} address={address} />))
      }
    />
  );
}
const FallBack = ({ loading, error, address }) => {
  if (loading) return <div>Carregando...</div>;
  if (error) return <div>Ocorreu um erro, tente novamente mais tarde.</div>;
  if (!address) return <div>Esta loja não possui endereço cadastrado, entre em cotnato com o suporte.</div>;
  return <div>Acesso não autorizado.</div>;
};
function mapStateToProps(state) {
  const { VivoStoreReducer } = state;
  return {
    VivoStoreReducer,
  };
}
const mapDispatchToProps = (dispatch) => ({
  $actions: bindActionCreators(
    { setStore, setEmployee },
    dispatch,
  ),
});
export default connect(mapStateToProps, mapDispatchToProps)(VivoSimpleRoute);
