import React from 'react';
import {
  Grid, Typography, Divider, Button,
} from '@material-ui/core';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import { connect } from 'react-redux';
import { useMutation } from '@apollo/client';
import { startFetching, stopFetching } from '../../../redux/actions/auth/AuthActions';
import { validateRecoverPasswordToken } from '../../../utils/GraphQL';

const RequestTokenShape = Yup.object().shape({
  token: Yup.string().required('Digite o código que foi enviado para o seu email.'),
});

const RequestToken = (props) => {
  const {
    history, location, startFetching, stopFetching,
  } = props;
  const [validateToken, { error }] = useMutation(validateRecoverPasswordToken);
  const onSubmitForm = ({ token }, { setSubmitting }) => {
    startFetching();
    validateToken({ variables: { email: location.state.email, token } })
      .then((r) => history.push('/recover/password/change', { email: r.data.ValidateRecoverPasswordToken.customer_email, token }))
      .catch((_e) => console.log('token errado'))
      .finally(() => { setSubmitting(false); stopFetching(); });
  };
  return (
    <>
      <Grid container direction="row">
        <Grid item xs={12}>
          <Typography variant="subtitle1">Validar código</Typography>
        </Grid>
      </Grid>
      <Divider style={{ marginTop: '0.5rem', marginBottom: '0.5rem' }} />
      <Formik
        initialValues={{
          token: '',
        }}
        validationSchema={RequestTokenShape}
        onSubmit={onSubmitForm}
      >
        {({ errors, touched, isSubmitting, dirty }) => (
          <Form>
            <Grid container direction="column" spacing={4}>
              <Grid item>
                <Typography variant="body2">Você recebeu no seu e-mail um código que deve ser inserido no campo abaixo para prosseguir com a recuperação de sua senha.</Typography>
              </Grid>
              <Grid item xs={12}>
                <Field disabled={isSubmitting} component={TextField} fullWidth label="Código" name="token" error={!!(touched.token && errors.token) || !!error?.message} helperText={(touched.token && errors.token) || error?.message} variant="outlined" />
              </Grid>
              <Grid item xs={12}>
                <Button disabled={isSubmitting || !dirty} type="submit" color="secondary" variant="contained" fullWidth title="Confirmar">
                  Confirmar
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};
const mapStateToProps = (state) => {
  const { AuthReducer } = state;
  const { isFetching } = AuthReducer;
  return {
    isFetching,
  };
};
export default connect(mapStateToProps, { startFetching, stopFetching })(RequestToken);
