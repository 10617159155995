import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setCustomer, setDelivery, setStep } from '@redux/actions/NewOrderActions/actions';

const mapStateToProps = (state) => {
  const { NewOrderReducer, PartnerStoreReducer } = state;
  const { activeStep, customer } = NewOrderReducer;
  return {
    $state: {
      step: activeStep,
      name: customer.name,
      email: customer.email,
      phone: customer.phone,
      landline: customer.landline,
      cpf: customer.cpf,
      zipCode: customer.delivery.zipCode,
      storeDelivery: customer.delivery.isChartered,
      storeAddress: PartnerStoreReducer.addresses[0],
    },
  };
};

const mapDispatchToProps = (dispatch) => ({
  $actions: bindActionCreators(
    { setCustomer, setDelivery, setStep },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps);
