import gql from 'graphql-tag';

export const GetRefusedBudgetReasons = gql`
  query GetRefusedBudgetReasons {
    ReparofoneGetRefusedBudgetReasons {
      id
      name
      description
      slug
      lay_name
    }
  }
`;

export default GetRefusedBudgetReasons;
