import React from 'react';
import { Link } from 'react-router-dom';
import {
  Container, Box, Grid, Button,
} from '@material-ui/core';
// import SVG from 'react-inlinesvg';
import { ReactComponent as NotFoundImage } from '../../images/NotFound404.svg';

const NotFoundPage = (props) => (
  <Container>
    <Box p={1}>
      <Grid container direction="column" alignContent="center" alignItems="center" spacing={2}>
        <Grid item>
          <NotFoundImage />
        </Grid>
        <Grid item>
          <Button variant="outlined" color="primary" component={Link} to="/login">Voltar à página inicial</Button>
        </Grid>
      </Grid>
    </Box>
  </Container>
);

export default NotFoundPage;
