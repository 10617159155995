import React from 'react';
import * as P from 'prop-types';
import { Button as MuiButton, withStyles } from '@material-ui/core';
import domain from '@utils/Domain';
import { Skeleton } from '@material-ui/lab';
import { ModelProps } from '@redux/reducers/NewOrderReducer/PropTypes';
import unknownPhoneImg from '@images/unknown-phone.png';
import useStyle from './style';

const Button = withStyles({ label: { height: 148 } })(MuiButton);

function ModelCard({ model, handleClick }) {
  const [loading, setLoading] = React.useState(true);
  const [imageUrl, setImageUrl] = React.useState(
    model.image,
  );
  const classes = useStyle();

  const handleError = () => setImageUrl(unknownPhoneImg);
  const handleLoad = () => setLoading(false);
  const handleButtonClick = () => handleClick(model);
  return (
    <Button className={classes.modelCard} onClick={handleButtonClick} title={model.name}>
      <div className="modelContainer">
        <div className="modelContainerImage">
          {loading ? (
            <Skeleton variant="text" width="100%" height="100%" />
          ) : null}
          <img onError={handleError} onLoad={handleLoad} src={imageUrl} alt={model.model} />
        </div>
        <span className="modelName">{ model.name }</span>
      </div>
    </Button>
  );
}

ModelCard.propTypes = {
  model: P.shape(ModelProps).isRequired,
  handleClick: P.func.isRequired,
};

export default React.memo(ModelCard);
