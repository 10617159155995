/* eslint-disable no-unused-expressions */
import React from 'react';
import ReactGA from 'react-ga';
import { useSelector } from 'react-redux';
import Header from './components/Header';
import Banner from './components/Banner';
import Formulario from './components/Form';
import Footer from './components/Footer';
import Funcionamento from '../home/components/ComoFunciona/Funcionamento';
import Mapa from '../home/components/Mapa';
import Servicos from '../home/components/Servicos/Servicos';
let cssLoaded = false;

const Home = () => {
  const partnerData = useSelector((state) => state.PartnerStoreReducer);
  const { branch } = partnerData;
  React.useEffect(() => {
    if (cssLoaded === false) {
      cssLoaded = true;
      import('../../layout/css/pages/NewOrder.css');
      import('semantic-ui-css/semantic.min.css');
      import('slick-carousel/slick/slick.css');
      import('slick-carousel/slick/slick-theme.css');
    }
  
    if (process.env.NODE_ENV === 'production') {
      ReactGA.event({
        category: `createOrder${branch.branch_name}`,
        action: 'Step 0',
        label: 'Entrou na Página',
        value: 0,
      });
    }
  }, []);
  return (
    <div>
      <Header />
      <Banner />
      <Funcionamento />
      <Servicos />
      <Mapa />
      <Formulario />
      <Footer />
    </div>
  );
};
export default Home;
