import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { DefectsProps } from '@redux/reducers/NewOrderReducer/PropTypes';

export default function OptionDefect({ name, desc }) {
  return (
    <Box>
      <Typography variant="body2" component="div">
        { name }
      </Typography>
      { desc
        ? (
          <Typography variant="caption" component="div">
            { desc }
          </Typography>
        ) : null}
    </Box>
  );
}

OptionDefect.propTypes = DefectsProps;
