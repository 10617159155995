export default {
  store: {
    id: undefined,
    name: undefined,
    addresses: [{
      zipCode: undefined,
      state: undefined,
      city: undefined,
      neighborhood: undefined,
      street: undefined,
      number: undefined,
      complement: undefined,
      longitude: undefined,
      latitude: undefined,
      distance: undefined,
    }],
  },
  stores: [],
  branches: [],
  branch: {
    id: undefined,
    branch_name: undefined,
    branch_slug: undefined,
    branch_logo: undefined,
  },
};
