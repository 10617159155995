import gql from 'graphql-tag';

export const GetProductByModelAndColorsQuery = gql`
query GetProductByModelAndColorsQuery($modelId: Mixed!, $color: Mixed!){
  products(where:{AND:[{column:MODEL_ID, value: $modelId}, {column:PRODUCT_COLOR, value:$color}]}){
 		id
    product_name
    product_color
    defects{
			id
      name
      description
			maxPrice
      isAble
      parts{
        id
        part_type
        part_name
        part_price_selling
        in_market
        out_of_stock_selling
        stock
      }
		}
  }
}
`;
export default GetProductByModelAndColorsQuery;
