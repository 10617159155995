import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles(() => ({
  footer: {
    height: 'auto',
    padding: '70px 20px',
  },
  footerCarrefour: {
    fontFamily: 'Milliard Book',
  },
  footerReparofone: {
    fontFamily: 'Roboto',
  },
  footerInterno: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    maxWidth: '1200px',
    margin: '0 auto',
  },
}));

export default styles;
