import React from 'react';
import { InputAdornment, TextField } from '@material-ui/core';
import DescriptionIcon from '@material-ui/icons/Description';
import { propTypes, defaultProps } from './PropTypes';
import useStyle from './style';

function InputDescription({
  handleChange,
  handleBlur,
  errors,
  value,
  typeInput,
  disabled,
}) {
  const classes = useStyle();
  return (
    <TextField
      multiline
      rows={4}
      defaultValue="Quero ser um franqueado!"
      value={value}
      onChange={(event) => {
        if (event) {
          handleChange('descricao', event.target.value);
        }
      }}
      onBlur={handleBlur}
      disabled={disabled}
      fullWidth
      className={classes.textField}
      id="descricao"
      name="descricao"
      label="Descrição"
      size="small"
      variant={typeInput}
      color="primary"
      helperText={errors}
      error={!!errors}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <DescriptionIcon color={!disabled ? 'primary' : 'action'} />
          </InputAdornment>
        ),
      }}
    />
  );
}

InputDescription.propTypes = propTypes;
InputDescription.defaultProps = defaultProps;

export default InputDescription;
