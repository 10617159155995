import * as P from 'prop-types';
import { AddressProps } from '@redux/reducers/NewOrderReducer/PropTypes';

export const propTypes = {
  $state: P.shape({
    step: P.number.isRequired,
    conditions: P.arrayOf(P.shape({
      id: P.string,
      slug: P.string,
      question: P.string,
    })).isRequired,
  }).isRequired,
  $actions: P.shape({
    setStep: P.func.isRequired,
    setDevice: P.func.isRequired,
  }).isRequired,
};

export const defaultProps = {};

export default {
  propTypes,
  defaultProps,
};
