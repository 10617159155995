import { client } from '@/Apollo';
import RequestChangeEmail from '../mutation/RequestChangeEmail';

const requestTokenEmail = (data) => new Promise((resolve, reject) => {
  client
    .mutate({
      mutation: RequestChangeEmail,
      variables: { ...data },
      errorPolicy: 'all',
    })
    .then((r) => {
      resolve(r);
    })
    .catch((e) => {
      console.log(e.message, 'erro ao pegar token email');
      reject(e);
    });
});

export default requestTokenEmail;
