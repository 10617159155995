import produce from 'immer';
import initialState, { PROCESS_PAYMENT_TIME } from './initialState';

const PREFIX = 'NEW_ORDER';

export const NewOrderTypes = {
  SET_STEP: `${PREFIX}.SET_STEP`,
  SET_DEVICE: `${PREFIX}.SET_DEVICE`,
  SET_PRODUCT: `${PREFIX}.SET_PRODUCT`,
  RESET_PRODUCT: `${PREFIX}.RESET_PRODUCT`,
  SET_ORDER_DATA: `${PREFIX}.SET_ORDER_DATA`,
  SET_TRADE_IN_USER: `${PREFIX}.SET_TRADE_IN_USER`,
  SET_CREDIT_CARD: `${PREFIX}.SET_CREDIT_CARD`,
  SET_LATER_PAYMENT: `${PREFIX}.SET_LATER_PAYMENT`,
  SET_AFTER_REPAIR_PAYMENT: `${PREFIX}.SET_AFTER_REPAIR_PAYMENT`,
  SET_CUSTOMER_DATA: `${PREFIX}.SET_CUSTOMER_DATA`,
  SET_BILLING_ADDRESS: `${PREFIX}.SET_BILLING_ADDRESS`,
  SET_CUSTOMER_COLLECT: `${PREFIX}.SET_CUSTOMER_COLLECT`,
  SET_SELECTED_DEFECTS: `${PREFIX}.SET_SELECTED_DEFECTS`,
  SET_CUSTOMER_DELIVERY: `${PREFIX}.SET_CUSTOMER_DELIVERY`,
  SET_TRANSACTION_AMOUNT: `${PREFIX}.SET_TRANSACTION_AMOUNT`,
  ADD_COUPON: `${PREFIX}.ADD_COUPON`,
  REMOVE_COUPON: `${PREFIX}.REMOVE_COUPON`,
  UPDATE_COUPON: `${PREFIX}.UPDATE_COUPON`,
  RESET_ORDER: `${PREFIX}.RESET_ORDER`,
  SET_LEAD: `${PREFIX}.SET_LEAD`,
  DELIVERY_DATA_INSERT: `${PREFIX}.DELIVERY_DATA_INSERT`,
  SET_COLLECT_MODALITIES: `${PREFIX}.SET_COLLECT_MODALITIES`,
  SET_FLUX_TYPE: `${PREFIX}.SET_FLUX_TYPE`,
  SET_WARRANTY: `${PREFIX}.SET_WARRANTY`,
  RESET_SELECTED_DEFECTS: `${PREFIX}.RESET_SELECTED_DEFECTS`,
  SET_REFUSED_BUDGET_REASON: `${PREFIX}.SET_REFUSED_BUDGET_REASON`,
  REFUSED_BUDGET_REASONS: `${PREFIX}.REFUSED_BUDGET_REASONS`,
};

export default function NewOrderReducer(state = initialState, action) {
  const { type, payload } = action;
  const finalState = (() => {
    switch (type) {
      case NewOrderTypes.SET_CUSTOMER_DATA:
        return {
          ...state,
          customer: { ...state.customer, ...payload },
        };
      case NewOrderTypes.SET_CUSTOMER_DELIVERY:
        return {
          ...state,
          customer: {
            ...state.customer,
            delivery: { ...state.customer.delivery, ...payload },
          },
        };
      case NewOrderTypes.SET_CUSTOMER_COLLECT:
        return {
          ...state,
          customer: {
            ...state.customer,
            collect: { ...state.customer.collect, ...payload },
          },
        };
      case NewOrderTypes.DELIVERY_DATA_INSERT:
        return {
          ...state,
          delivery: { ...state.delivery, ...payload },
        };
      case NewOrderTypes.SET_COLLECT_MODALITIES:
        return {
          ...state,
          collect: { ...state.collect, ...payload },
        };
      case NewOrderTypes.SET_DEVICE:
        return {
          ...state,
          device: {
            ...state.device,
            ...payload,
          },
        };
      case NewOrderTypes.SET_PRODUCT:
        return {
          ...state,
          device: {
            ...state.device,
            product: {
              ...state.device.product,
              ...payload,
            },
          },
        };
      case NewOrderTypes.SET_CREDIT_CARD:
        return {
          ...state,
          processPayment: PROCESS_PAYMENT_TIME.AFTER_CREATION,
          payment: {
            type: 'creditCard',
            data: {
              ...state.payment.data,
              ...payload,
            },
          },
        };
      case NewOrderTypes.SET_LATER_PAYMENT:
        return {
          ...state,
          processPayment: PROCESS_PAYMENT_TIME.AFTER_CREATION,
          payment: {
            type: 'laterPayment',
            data: {
              ...state.payment.data,
              ...payload,
            },
          },
        };
      case NewOrderTypes.SET_AFTER_REPAIR_PAYMENT:
        return {
          ...state,
          processPayment: PROCESS_PAYMENT_TIME.AFTER_REPAIR,
          payment: {
            type: 'afterRepairPayment',
            data: {
              ...state.payment.data,
              ...payload,
            },
          },
        };
      case NewOrderTypes.SET_STEP:
        return {
          ...state,
          activeStep: payload,
        };
      case NewOrderTypes.RESET_PRODUCT:
        return {
          ...state,
          device: {
            ...state.device,
            product: initialState.device.product,
            selectedDefects: initialState.device.selectedDefects,
          },
        };
      case NewOrderTypes.SET_SELECTED_DEFECTS:
        return {
          ...state,
          device: {
            ...state.device,
            selectedDefects: payload,
          },
        };
      case NewOrderTypes.RESET_SELECTED_DEFECTS:
        return {
          ...state,
          device: {
            ...state.device,
            selectedDefects: initialState.device.selectedDefects,
          },
        };
      case NewOrderTypes.SET_BILLING_ADDRESS:
        return {
          ...state,
          billingAddress: {
            ...state.billingAddress,
            ...payload,
          },
        };
      case NewOrderTypes.SET_TRANSACTION_AMOUNT:
        return {
          ...state,
          transactionAmount: {
            ...state.transactionAmount,
            ...payload,
          },
        };
      case NewOrderTypes.SET_ORDER_DATA:
        return {
          ...state,
          orderData: payload,
        };
      case NewOrderTypes.SET_TRADE_IN_USER:
        return {
          ...state,
          tradeInUser: {
            ...state.tradeInUser,
            ...payload,
          },
        };
      case NewOrderTypes.SET_LEAD:
        return {
          ...state,
          lead: {
            ...state.lead,
            ...payload,
          },
        };
      case NewOrderTypes.SET_FLUX_TYPE:
        return {
          ...state,
          fluxType: {
            ...state.fluxType,
            ...payload,
          },
        };
      case NewOrderTypes.SET_WARRANTY:
        return {
          ...state,
          warranty: {
            ...state.warranty,
            ...payload,
          },
        };
      case NewOrderTypes.REFUSED_BUDGET_REASONS:
        return {
          ...state,
          allRefusedBudgetReasons: payload,
        };
      case NewOrderTypes.SET_REFUSED_BUDGET_REASON:
        return {
          ...state,
          refusedBudgetReason: {
            ...state.refusedBudgetReason,
            ...payload,
          },
        };
      case NewOrderTypes.ADD_COUPON:
        return {
          ...state,
          coupons: [
            ...state.coupons,
            payload,
          ],
        };
      case NewOrderTypes.REMOVE_COUPON:
        return {
          ...state,
          coupons: state.coupons.filter(
            (coupon, index) => index !== action.payload,
          ),
        };
      case NewOrderTypes.UPDATE_COUPON: {
        return produce(state, (draft) => {
          const index = draft.coupons.findIndex(
            (coupon) => coupon.code === action.coupon.code,
          );
          draft[index] = action.coupon;
        });
      }
      case NewOrderTypes.RESET_ORDER:
        return {
          ...initialState,
          customer: { ...state.customer },
          tradeInUser: { ...state.tradeInUser },
        };
      default:
        return { ...state };
    }
  })();
  return finalState;
}
