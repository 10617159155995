import React from 'react';
import {
  BrowserRouter,
  Redirect,
  Route,
  Switch,
  withRouter,
} from 'react-router-dom';
// MATERIALS
import { ThemeProvider } from '@material-ui/core/styles';
import { CssBaseline } from '@material-ui/core';
// REDUX
import { connect } from 'react-redux';
import { SnackbarProvider } from 'notistack';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import ReactGA from 'react-ga';
import { ApolloProvider } from '@apollo/client';
import { QueryParamProvider } from 'use-query-params';
import LoggedInRoute from './components/route/LoggedInRoute';
import LoggedInRoutePartner from './components/route/LoggedInRoutePartner';
import LoggedInRoutePartnerOrders from './components/route/LoggedInRoutePartnerOrders';
// Páginas
import NewOrderPage from './pages/neworder';
import NewOrderPagePartner from './pages/neworderpartner';
import Theme from './layout/Theme';
import LoginPage from './pages/user/LoginPage';
import LoggedOutRoute from './components/route/LoggedOutRoute';
import LoggedOutRoutePartner from './components/route/LoggedOutRoutePartner';
import Navbar from './layout/navbar/Navbar';
import Repairs from './pages/repairs/Repairs';
import NewRepair from './pages/newrepair';
import Order from './pages/repairs/Order';
import BottomNav from './layout/navbar/BottomNav';
import Logout from './pages/user/Logout';
import Profile from './pages/user/Profile2';
import NotFoundPage from './components/feedback/NotFoundPage';
import TermsOfServiceRoutes from './routes/TermsOfServiceRoutes';
import VivoSimpleRoute from './components/route/VivoSimpleRoute';
import ApolloClient from './Apollo';
import VivoRoutes from './routes/partner/VivoRoutes';
import PartnerRoutes from './routes/partner/PartnerRoutes';
import PartnerOrderRoutes from './routes/partner/PartnerOrderRoutes';
import Home from './pages/home';
import OnlinePartnerRoutes from './routes/onlinepartner';
import LoginPagePartner from './pages/userpartner/LoginPagePartner';
import LogoutPartner from './pages/userpartner/LogoutPartner';
import Payment from './pages/repairs/payment';
import ValidateEmailToken from './pages/user/ValidateEmailToken';
import Franchisee from './pages/franchisee/Franchisee';

if (process.env.NODE_ENV === 'production') {
  ReactGA.initialize('UA-180168572-1');
  ReactGA.pageview(window.location.pathname + window.location.search);
}

const CustomerRoutes = withRouter(({ location }) => (
  <TransitionGroup>
    <CSSTransition classNames="slide" timeout={500} key={location.key}>
      <Switch location={location}>
        <Route exact path="/customer/repairs">
          <Repairs />
        </Route>
        <Route exact path="/customer/repairs/:orderId">
          <Order />
        </Route>
        <Route exact path="/customer/newrepair">
          <NewRepair />
        </Route>
        <Route exact path="/customer/myprofile" component={Profile} />
        <Route
          exact
          path="/customer/change/email/:emailAddress"
          component={ValidateEmailToken}
        />
        <Route path="*">
          <NotFoundPage />
        </Route>
      </Switch>
    </CSSTransition>
  </TransitionGroup>
));

const MainRoutes = withRouter(({ location }) => (
  <Switch location={location}>
    <Route exact path="/">
      <Redirect to="/novaordem" />
    </Route>
    <Route path="/termsofservice">
      <TermsOfServiceRoutes />
    </Route>
    <Route path="/customer/repairs/:orderId/payment">
      <Payment />
    </Route>
    <LoggedInRoute path="/customer">
      <>
        <Navbar>
          <CustomerRoutes />
        </Navbar>

        <BottomNav />
      </>
    </LoggedInRoute>
    <LoggedOutRoute path="/login">
      <LoginPage />
    </LoggedOutRoute>
    <LoggedInRoute path="/logout">
      <Logout />
    </LoggedInRoute>
    <Route path="/novaordem">
      <Home />
    </Route>
    <Route path="/onlinepartner/:id">
      <OnlinePartnerRoutes />
    </Route>
    <Route path="/cadastroparceiro">
      <Franchisee />
    </Route>
    <Route
      path="/atendimento-wpp"
      render={() => {
        if (process.env.NODE_ENV === 'production') {
          ReactGA.event({
            category: 'Interactivity',
            action: 'whatsappOpened',
            label: 'Abriu atendimento whatsapp',
          });
        }
        window.location.href = 'http://wa.me/5511911973880';
        return null;
      }}
    />
    <Route path="/neworder">
      <VivoSimpleRoute path="/neworder/vivo">
        <NewOrderPage />
      </VivoSimpleRoute>
    </Route>
    <Switch path="/partners">
      <LoggedOutRoutePartner exact path="/partners/login">
        <LoginPagePartner />
      </LoggedOutRoutePartner>
      <LoggedInRoutePartner exact path="/partners/logout">
        <LogoutPartner />
      </LoggedInRoutePartner>
      <LoggedInRoutePartner exact path="/partners/servicerepair">
        <NewOrderPagePartner />
      </LoggedInRoutePartner>
      <LoggedInRoutePartnerOrders exact path="/partners/servicerepair/orders">
        <PartnerRoutes />
      </LoggedInRoutePartnerOrders>
      <LoggedInRoutePartnerOrders
        exact
        path="/partners/servicerepair/orders/:id"
      >
        <PartnerOrderRoutes />
      </LoggedInRoutePartnerOrders>
    </Switch>
    <Route path="/partner">
      <VivoRoutes />
    </Route>
    <Route path="*">
      <NotFoundPage />
    </Route>
  </Switch>
));
function Index(props) {
  return (
    <ApolloProvider client={ApolloClient}>
      <ThemeProvider theme={Theme}>
        <CssBaseline>
          <SnackbarProvider maxSnack={5}>
            <BrowserRouter>
              <QueryParamProvider ReactRouterRoute={Route}>
                <MainRoutes />
              </QueryParamProvider>
            </BrowserRouter>
          </SnackbarProvider>
        </CssBaseline>
      </ThemeProvider>
    </ApolloProvider>
  );
}
const mapStateToProps = (state) => {
  const { AuthReducer } = state;
  const { isAuthenticated } = AuthReducer;
  return {
    isAuthenticated,
  };
};
export default connect(mapStateToProps)(Index);
