import React from 'react';
import {
  Container,
  DialogTitle,
  DialogContent,
  Dialog,
  IconButton,
  Typography,
  makeStyles,
  Slide,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ChangeEmail from './ChangeEmail';

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(0.25),
    top: theme.spacing(0.25),
    color: theme.palette.grey[500],
  },
  dialogTitleText: {
    fontSize: '1.7rem',
    fontWeight: 500,
    textAlign: 'center',
  },
  dialogTitle: {
    padding: '16px 60px 16px 16px',
  },
  dialogContent: {
    padding: '24px 0',
  },
}));
const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const ModalChangeEmail = ({ isOpen, handleModalState, handleAlert }) => {
  const classes = useStyles();
  const [isSubmittingForm, setSubmittingForm] = React.useState(false);
  return (
    <Dialog
      open={isOpen}
      aria-labelledby="customized-dialog-title"
      fullWidth
      TransitionComponent={Transition}
      keepMounted
      maxWidth="sm"
    >
      <DialogTitle
        id="alert-dialog-slide-title"
        className={classes.dialogTitle}
      >
        <Typography variant="h2" className={classes.dialogTitleText}>
          Trocar E-mail
        </Typography>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handleModalState(false)}
          disabled={isSubmittingForm}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Container maxWidth="xs">
          <ChangeEmail
            showTitle={false}
            onClose={handleModalState(false)}
            handleAlert={handleAlert}
            setSubmittingForm={setSubmittingForm}
          />
        </Container>
      </DialogContent>
    </Dialog>
  );
};

export default ModalChangeEmail;
