import * as Yup from 'yup';
import InputCvc from './Components/InputCvc';
import InputCpf from './Components/InputCpf';
import InputName from './Components/InputName';
import InputNumber from './Components/InputNumber';
import InputExpiry from './Components/InputExpiry';

export default Yup.object({
  number: InputNumber.rules,
  expiry: InputExpiry.rules,
  name: InputName.rules,
  cvc: InputCvc.rules,
  cpf: InputCpf.rules,
  // issuer: Yup.object({}).required('Este campo é obrigatório'),
  installment: Yup.object({}).required('Este campo é obrigatório'),
});
