import gql from 'graphql-tag';

export const MarkOrderAsReceived = gql`
  mutation MarkOrderAsReceived($id: ID!){
    ReparafoneMarkAsReceivedByCustomer(input:{id: $id}){
      id
      imei: order_imei
      customer{
        fullName
      }
      product{
        name: product_name
      }
      status{
        name: status_name
        slug: status_slug
      }
      lastMovement{
        date: movement_updated_at
      }
      createdAt: order_created_at
      branchId: order_branch_id
    }
  }
`;

export default MarkOrderAsReceived;
