import * as CustomYup from 'yup';

CustomYup.addMethod(CustomYup.string, 'CPF', function (message) {
  return this.test('cpfInternal', message, (value = '') => {
    if (value === '') return true;
    const cpf = String(value).replace(/[^0-9]/gi, '');
    if (cpf.length !== 11) {
      return false;
    }
    if (cpf.match(/(\d)\1{10}/)) {
      return false;
    }
    for (let t = 9; t < 11; t++) {
      let d; let c = 0;
      for (d = 0, c = 0; c < t; c++) {
        d += cpf[c] * ((t + 1) - c);
      }
      d = ((10 * d) % 11) % 10;
      if (cpf[c] != d) {
        return false;
      }
    }
    return true;
  });
});

export default CustomYup;
