import React from 'react';
import * as P from 'prop-types';
import { useDispatch, connect } from 'react-redux';
import {
  setTradeInUser,
} from '@/redux/actions/NewOrderActions';
import { loadCSS } from 'fg-loadcss';
import {
  setCollect as actSetCollect,
  setDelivery as actSetDelivery,
} from '@redux/actions/NewOrderActions/index';
import { AddressProps } from '@redux/reducers/VivoStoreReducer/PropTypes';
import getTradeInUser from './graphql/schemas/GetTradeInUser';
import Header from './components/Header';
import FormStepper from './components/FormStepper';

function App({ setCollect, storeAddress, setDelivery }) {
  React.useEffect(() => {
    const node = loadCSS(
      'https://use.fontawesome.com/releases/v5.12.0/css/all.css',
      document.querySelector('#font-awesome-css'),
    );

    return () => {
      node.parentNode.removeChild(node);
    };
  }, []);

  React.useEffect(() => {
    setCollect({ ...storeAddress, isChartered: true });
    setDelivery({ ...storeAddress, isChartered: true });
  }, [storeAddress]);

  const dispatch = useDispatch();
  React.useEffect(() => {
    getTradeInUser().then((data) => {
      dispatch(setTradeInUser(data));
    });
  }, [dispatch]);

  return (
    <>
      <Header />
      <FormStepper />
    </>
  );
}

App.propTypes = {
  setCollect: P.func.isRequired,
  setDelivery: P.func.isRequired,
  storeAddress: P.shape(AddressProps).isRequired,
};

const mapStateToProps = ({ PartnerStoreReducer }) => ({
  storeAddress: PartnerStoreReducer.addresses[0],
});

const mapDispatchToProps = (dispatch) => ({
  setCollect: (payload) => dispatch(actSetCollect(payload)),
  setDelivery: (payload) => dispatch(actSetDelivery(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
