import React from 'react';
import {
  add, format, lightFormat, isToday, isWeekend, parse,
} from 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import brLocale from 'date-fns/locale/pt-BR';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { includes } from 'lodash';
// Smartprod Components
import Holidays from '@/utils/Holidays';
// STYLES
import useStyles from './style';

const DateRange = ({
  disabledDates,
  disableHolidays,
  disableWeekend,
  disabled,
  value,
  name,
  errors,
  setDate,
  setFieldValue,
}) => {
  const classes = useStyles();
  const labelFormatter = (data, invalidLabel) => invalidLabel
    || format(data, "dd 'de' MMMM 'de' yyyy", { locale: brLocale });
  const maxDate = add(new Date(), { months: 1 });
  const filterOnlyDisabledDates = (day) => {
    if (disableHolidays && Holidays.isHoliday(day)) return true;
    if (disableWeekend && isWeekend(day)) return true;
    return (
      includes(disabledDates, lightFormat(day, 'yyyy-MM-dd')) || isToday(day)
    );
  };
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={brLocale}>
      <KeyboardDatePicker
        required
        className={classes.dateTextField}
        value={value}
        onChange={(event, valueDate) => {
          if (event && valueDate) {
            setDate(event);
            setFieldValue(name, event);
          }
        }}
        name={name}
        disablePast
        labelFunc={labelFormatter}
        disabled={disabled}
        maxDate={maxDate}
        shouldDisableDate={filterOnlyDisabledDates}
        label="Data de Coleta"
        variant="inline"
        inputVariant="outlined"
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
        helperText={errors}
        error={!!errors}
      />
    </MuiPickersUtilsProvider>
  );
};
export default DateRange;
