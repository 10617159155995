import React from 'react';
import { useQuery } from '@apollo/client';
import { TextField, InputAdornment, makeStyles } from '@material-ui/core';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import { GetStoresByBranchId } from '../../query/GetBranch';
import ConnectComponentRedux from '../ConnectComponentRedux';
import checkZipCode from '../../../utils/CheckCep';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

function Select({
  $actions,
  $state,
  setAlert,
  setDialogValue,
  setStoreValues,
  toggleOpen,
  isDisabledDialog,
  setIsDisabled,
}) {
  const classes = useStyles();
  const handleOpenDialog = () => toggleOpen(true);
  const showAlert = ({ message, severity }) => setAlert({ message, severity, show: true });
  const handleAlertError = () => showAlert({
    message:
        'Ainda não temos abrangência para o CEP selecionado, para prosseguir é necessário inserir um CEP válido da cidade de São Paulo.',
    severity: 'error',
  });
  const closeAlert = () => setAlert({ show: false, message: null, severity: null });
  const checkZipcode = async (value) => {
    closeAlert();
    setIsDisabled(true);
    const response = await checkZipCode(value.addresses[0].zipCode);
    if (response) {
      setStoreValues(value);
      setDialogValue(value.name);
      return handleOpenDialog();
    }
    setIsDisabled(false);
    return handleAlertError();
  };
  const {
    partner: {
      employee: { branch_id: branchId },
    },
  } = $state;
  const { loading, error, data } = useQuery(GetStoresByBranchId, {
    variables: { branchId },
  });
  if (loading) {
    return (
      <>
        <Autocomplete
          id="combo-box-loading"
          blurOnSelect
          loading
          disabled
          renderInput={(params) => (
            <TextField
              {...params}
              label="Selecione uma opção"
              variant="outlined"
              style={{ width: 400 }}
              disabled
              InputProps={{
                endAdornment: (
                  <>
                    <InputAdornment position="end">
                      <CircularProgress color="inherit" size={20} />
                    </InputAdornment>
                  </>
                ),
              }}
            />
          )}
        />
      </>
    );
  }
  if (error) return <p>{error.message}</p>;
  const loadStores = () => data.ReparafoneGetStoresByBranchId.data;
  return (
    <>
      <Autocomplete
        id="combo-box-demo"
        options={loadStores()}
        blurOnSelect
        disabled={isDisabledDialog}
        loading={isDisabledDialog}
        getOptionLabel={(option) => option.name}
        style={{ width: 400 }}
        onChange={(event, value) => {
          if (value) {
            checkZipcode(value);
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Selecione uma opção"
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {isDisabledDialog ? (
                    <InputAdornment position="end">
                      <CircularProgress color="inherit" size={20} />
                    </InputAdornment>
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
        renderOption={(option, { inputValue }) => {
          const matches = match(option.name, inputValue);
          const parts = parse(option.name, matches);
          return (
            <div>
              {parts.map((part, index) => (
                <span
                  key={index}
                  style={{ fontWeight: part.highlight ? 700 : 400 }}
                >
                  {part.text}
                </span>
              ))}
            </div>
          );
        }}
      />
    </>
  );
}

export default ConnectComponentRedux(Select);
