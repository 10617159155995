import gql from 'graphql-tag';

export const CreateStatusFailure = gql`
  mutation CreateStatusFailure(
    $failure_order_id: ID!
    $failure_movement_id: ID!
    $failure_reason: String!
    $failure_note: String!
  ) {
    createStatusFailureOrderRepair(
      input: {
        failure_order_id: $failure_order_id
        failure_movement_id: $failure_movement_id
        failure_reason: $failure_reason
        failure_note: $failure_note
      }
    ) {
      id
      failure_order_id
      failure_movement_id
      failure_reason
      failure_note
    }
  }
`;

export default CreateStatusFailure;
