import React from 'react';
// COMPONENTS
import Header from '@/layout/partner/HeaderPartner';
import Home from '@/pages/partner/ordersPartner';
// GRAPHQL
import getPartnerUser from '@/pages/orderdetails/graphql/schema/GetPartnerUser';
import getOrders from '@/pages/orderdetails/graphql/schema/GetOrders';
// MATERIAL-UI
import { CircularProgress, Typography } from '@material-ui/core/';
// ROUTERS
import {
  Route, Switch, withRouter, useParams,
} from 'react-router-dom';
// LODASH
import { isEmpty } from 'lodash';
// STYLES
import styles from './style';

const PartnerOrderRoutes = withRouter(({ location }) => {
  const classes = styles();
  const [loadingInfo, setLoadingInfo] = React.useState(true);
  const [loadingContent, setLoadingContent] = React.useState(false);
  const [orderData, setOrderData] = React.useState();
  const [message, setMessage] = React.useState(null);
  const store = JSON.parse(localStorage.storeCollect);
  const { id } = useParams();

  const getOrderWithStore = () => {
    getOrders({
      id: Number(id),
      page: 1,
      first: 1,
      searchLike: `%${store.name}%`,
      search: store.name,
    })
      .then((r) => {
        if (isEmpty(r.data)) return setMessage('Ordem não pertence à Loja! Tente novamente com outra ordem.');
        setOrderData(r.data[0]);
        return setLoadingContent(true);
      })
      .catch((e) => {
        console.log(e);
        return setMessage('Houve um erro ao autenticar a Loja! Tente novamente mais tarde.');
      })
      .finally(() => setLoadingInfo(false));
  };
  const getPartner = () => {
    getPartnerUser()
      .then((r) => {
        if (!r.enabled) {
          setLoadingInfo(false);
          return setMessage('Você não possui acesso a esta página.');
        }
        return getOrderWithStore();
      })
      .catch((e) => {
        setLoadingInfo(false);
        console.log(e);
        return setMessage('Houve um erro ao autenticar o usuário. Tente novamente mais tarde.');
      });
  };
  React.useEffect(() => {
    setLoadingInfo(true);
    getPartner();
  }, []);
  return (
    <>
      { loadingContent && (
        <>
          <Header />
          <div className={classes.backgroundForm}>
            <Switch location={location}>
              <Route exact path="/partners/servicerepair/orders/:id">
                <Home order={orderData} />
              </Route>
            </Switch>
          </div>
        </>
      )}
      { loadingInfo && (
        <div className={classes.root}>
          <CircularProgress />
        </div>
      )}
      { message && (
        <div className={classes.root}>
          <Typography style={{ fontSize: '20px' }} variant="h1" component="div" alignCenter>
            {message}
          </Typography>
        </div>
      )}
    </>
  );
});

export default PartnerOrderRoutes;
