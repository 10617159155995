import client from '@/Apollo';
import { ValidateCoupons } from '../query/ValidateCoupons';

export default function validateCoupon(
  couponCode,
  setDisabledTextField,
  setErrorAlert,
  conditions,
) {
  const clearedCouponCode = String(couponCode);
  return new Promise((resolve, reject) => {
    client
      .query({
        query: ValidateCoupons,
        variables: { coupon: clearedCouponCode, parameters: conditions },
      })
      .then(({ data: { ReparafoneValidateCoupon } }) => {
        resolve(!!ReparafoneValidateCoupon);
      })
      .catch((e) => {
        setDisabledTextField(false);
        setErrorAlert(true);
      });
  });
}
