import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { setAuthenticated } from '../../redux/actions/auth/AuthActions';

const LogoutPartner = (props) => {
  const { logoutAction } = props;
  logoutAction(false);
  return (
    <Redirect to={{ pathname: '/partners/login' }} />
  );
};

export default connect(null, { logoutAction: setAuthenticated })(LogoutPartner);
