/* eslint-disable camelcase */
// DATE FORMATTERS
import { format, parse } from 'date-fns';
// LODASH
import { isEmpty, last, first } from 'lodash';

const amountFormatter = (value) => {
  const amount = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(value / 100);
  return `${amount}`;
};

const dateFormatter = (value) => {
  if (!value) return null;
  const parsedDate = parse(value, 'yyyy-MM-dd HH:mm:ss', new Date());
  const formattedDate = format(parsedDate, 'dd/MM/yyyy HH:mm');
  return formattedDate;
};

export const laborPriceFormatter = (value) => {
  if (isEmpty(value)) return null;
  const { labor } = last(value);
  const priceFormatted = amountFormatter(labor);
  return priceFormatted;
};

export const revisionDateFormatter = (value) => {
  if (isEmpty(value)) return null;
  const { created_at } = first(value);
  const formattedDate = dateFormatter(created_at);
  return formattedDate;
};

export const totalEstimateFormatter = (value) => {
  const { estimates, collectPrice, deliveryPrice } = value;
  if (isEmpty(estimates)) return null;
  const { price } = last(estimates);
  const priceFormatted = amountFormatter(price + collectPrice + deliveryPrice);
  return priceFormatted;
};

export const usedPartsPriceFormatter = (value) => {
  if (isEmpty(value)) return null;
  const { revisions } = last(value);
  let values = null;
  // eslint-disable-next-line no-return-assign
  revisions.map((rev) => values += rev?.parts?.part_price_selling);
  const priceFormatted = amountFormatter(values);
  return priceFormatted;
};

export const imeiFormatter = (value) => {
  const { order_imei, estimates } = value;
  if (isEmpty(estimates)) return null;
  const { imei } = last(estimates);
  return imei || order_imei;
};
