import gql from 'graphql-tag';

const GetTradeInUserInformation = gql`
  query{
    MeTradeInUser{
      id
      name
      email
      branch_id
    }
  }
`;

export default GetTradeInUserInformation;
