import React from 'react';
// MATERIAL-UI
import {
  Box,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
} from '@material-ui/core';
// REDUX
import { useSelector, useDispatch } from 'react-redux';
import { setDevice, resetProduct, middlewares } from '@redux/actions/NewRepairActions';
// CLSX
import clsx from 'clsx';
// COMPONENTS
import ButtonDeviceStep from '../ButtonDeviceStep';
import LabelModelSelected from '../LabelModelSelected';
import LoadingContent from '../../../LoadingContent';
// FUNCTIONS
import getStepContent from './getStepContent';
import steps from './steps';
import { getStep } from './common';
import useStyle from './style';

function StepperSelectDevice() {
  const searchProduct = middlewares.updateProductByModelColor;
  const dispatch = useDispatch();
  const customerData = useSelector((state) => state.NewRepairReducer);
  const { device } = customerData;
  const {
    model, brand, color, product,
  } = device;
  const partnerData = useSelector((state) => state.PartnerStoreReducer);
  const { branch } = partnerData;
  const { branch_name } = branch;
  const [isLoading, setLoading] = React.useState(false);
  const [step, setStep] = React.useState(getStep({ device, product }));
  const classes = useStyle();

  const advanceSteps = (num = 1) => setTimeout(() => setStep(step + num), 0);
  const returnSteps = (num = 1) => advanceSteps(num * -1);

  const stepperData = {
    /** Brand-step */
    brand: {
      handleNext: (value) => {
        dispatch(setDevice({ brand: value }));
        advanceSteps();
      },
      data: {
        brand,
      },
    },

    /** Model-Step */
    model: {
      handleNext: (value) => {
        dispatch(setDevice({ model: value }));
        advanceSteps();
      },
      handleBack: () => {
        dispatch(setDevice({ brand: undefined }));
        returnSteps();
      },
      data: {
        brand,
        model,
      },
    },

    /** Color-Step */
    color: {
      handleNext: (value) => {
        dispatch(setDevice({ color: value }));
        setLoading(true);
        dispatch(searchProduct(advanceSteps, setLoading));
      },
      handleBack: () => {
        dispatch(setDevice({ color: undefined }));
        returnSteps();
      },
      data: {
        model,
      },
    },
  };

  const getStepHandleClick = (index) => {
    if (index < step) {
      return () => setStep(index);
    }
    return () => null;
  };

  React.useEffect(() => {
    if (step < 3 && product?.name) {
      dispatch(resetProduct());
    }
  }, [step]);

  return (
    <Box paddingTop={2}>
      <Typography variant="h6">Selecione o aparelho</Typography>
      <LoadingContent isLoading={isLoading}>
        <Box py={1}>
          <Stepper activeStep={step} orientation="vertical">
            {steps.map(({ slug, label, concludedLabel }, index) => (
              <Step key={label}>
                <StepLabel
                  className={clsx(classes.root, {
                    [classes.stepCarrefour]: branch_name === 'Carrefour',
                    [classes.stepReparofone]: branch_name === 'Limelocker',
                  })}
                >
                  <ButtonDeviceStep
                    handleClick={getStepHandleClick(index)}
                    isActive={index < step}
                    label={index < step ? concludedLabel(device) : label}
                  />
                </StepLabel>
                <StepContent className={classes.stepperContent}>
                  {getStepContent(
                    index,
                    stepperData[slug]?.data,
                    stepperData[slug]?.handleNext,
                    stepperData[slug]?.handleBack,
                  )}
                </StepContent>
              </Step>
            ))}
          </Stepper>
          <Box>
            {step < 3 || !product?.name ? (
              <Typography variant="body2">
                <i>Selecione o dispositivo para prosseguir</i>
              </Typography>
            ) : (
              <LabelModelSelected
                model={`${brand} ${model.name} ${color}`.trim()}
              />
            )}
          </Box>
        </Box>
      </LoadingContent>
    </Box>
  );
}

export default StepperSelectDevice;
