import * as P from 'prop-types';
import { AddressProps } from '../../../../redux/reducers/NewOrderReducer/PropTypes';

export const AboutCustomerPropTypes = {
  $state: P.shape({
    step: P.number.isRequired,
    name: P.string,
    email: P.string,
    phone: P.string,
    zipCode: P.string,
    storeAddress: P.shape(AddressProps),
  }),
  $actions: P.shape({
    setCustomer: P.func.isRequired,
    setDelivery: P.func.isRequired,
  }).isRequired,
};

export const AboutCustomerDefaultProps = {};

export default { AboutCustomerPropTypes, AboutCustomerDefaultProps };
