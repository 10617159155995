import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  couponButton: {
    background: '#21BA45',
    color: '#FFFFFF',
    '&:hover': {
      background: '#21BA45',
    },
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexShrink: 0,
  },
  accordion: {
    marginTop: '10px',
    boxShadow: 'none',
    background: '#FCFCFC',
    border: '1px solid rgba(0,0,0,0.12)',
    borderTop: '1px solid rgba(0,0,0,0.12)',
    '&::before': {
      content: 'none',
    },
  },
}));

export default useStyles;
