import { useQuery } from '@apollo/client';
import {
  makeStyles, useTheme,
} from '@material-ui/core';
import React from 'react';
import {
  Route, Switch, useParams, useRouteMatch,
} from 'react-router-dom';
import Approved from './Approved';
import CardContent from './components/CardContent';
import Header from './components/Header';
import Failed from './Failed';
import MercadoPagoPro from './gateways/mercadopago/MercadoPagoPro';
import { GetOrderInfoByIdQuery } from './graphql/GetOrderInfoByIdQuery';
import OrderInfo from './OrderInfo';
import PaymentBox from './PaymentBox';
import Pending from './Pending';

const useStyles = makeStyles({
  container: {
    marginTop: '1rem',
  },
  box: {
    padding: '1rem',
    border: '1px solid #cacaca',
  },
});
const Payment = () => {
  const { path, url } = useRouteMatch();
  const theme = useTheme();
  const { orderId } = useParams();
  const { data, loading, error } = useQuery(GetOrderInfoByIdQuery, { variables: { orderId } });
  if (loading) return 'Carregando...';
  if (error) return 'Ocorreu um erro. Tente novamente mais tarde ou contate o suporte.';
  const order = data.customerRepairOrderByIdPayment;
  if (order.isPaid) return 'Ordem já paga.';
  return (
    <Switch>
      <Route exact path={path}>
        <Header color={theme.palette.primary.main} title="Realizar pagamento" description="Realize o pagamento da sua ordem de serviço através dos métodos mais seguros!" />
        <CardContent>
          <OrderInfo order={order} />
          <PaymentBox order={order} />
        </CardContent>
      </Route>
      <Route exact path={`${path}/:gateway/approved`} component={Approved} />
      <Route exact path={`${path}/:gateway/pending`} component={Pending} />
      <Route exact path={`${path}/:gateway/failed`} component={Failed} />
      <Route exact path={`${path}/mercadopago`}>
        <Header color={theme.palette.primary.main} title="Realizar pagamento" description="Realize o pagamento da sua ordem de serviço através dos métodos mais seguros!" />
        <CardContent>
          <MercadoPagoPro order={order} />
        </CardContent>
      </Route>
    </Switch>
  );
};

export default Payment;
