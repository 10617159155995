import client from '@/Apollo';
import GetOrderCoupon from '../query/GetCouponsByOrder';

const getOrderCoupon = (data) => new Promise((resolve, reject) => {
  client
    .query({
      query: GetOrderCoupon,
      variables: { order_id: data.order_id },
    })
    .then((r) => {
      resolve(r.data.ReparafoneGetCouponsByOrder);
    })
    .catch((e) => {
      console.log(e.message, 'erro ao buscar');
      reject(e.message);
    });
});

export default getOrderCoupon;
