import { Box, makeStyles } from '@material-ui/core';
import {
  CarouselProvider, Slide, Slider, Image,
} from 'pure-react-carousel';
import React from 'react';
import 'pure-react-carousel/dist/react-carousel.es.css';
import ImgsViewer from 'react-images-viewer';
import { defaultTo } from 'lodash';

const useStyles = makeStyles(() => ({
  sliderTray: {
    listStyleType: 'none',
    width: '100%!important',
  },
  slide: {
    width: '5%',
  },
}));
const CarouselStrap = (props) => {
  const classes = useStyles();
  const { images } = props;
  const [currentIndex, setCurrentIndex] = React.useState(null);
  const handleImageClick = (index) => () => {
    setCurrentIndex(index);
  };
  const handleClickPrev = () => {
    setCurrentIndex((n) => (Number.isInteger(n) && n > 0 ? n - 1 : defaultTo(n, 0)));
  };
  const handleClickNext = () => {
    setCurrentIndex((n) => (n < images.length ? n + 1 : defaultTo(n, 0)));
  };
  const handleClose = () => {
    setCurrentIndex((n) => null);
  };
  return (
    <>
      <CarouselProvider
        visibleSlides={Math.floor(window.outerWidth / 100)}
        totalSlides={images.length}
        step={1}
        orientation="horizontal"
        naturalSlideWidth={100}
        naturalSlideHeight={100}
        isIntrinsicHeight
      >
        <div style={{ position: 'relative', margin: 'auto' }}>
          <Slider>
            {images.map(({ caption, src }, idx) => (
              <Slide onClick={handleImageClick(idx)} key={src + idx} index={idx}>
                <Image
                  alt={caption}
                  src={src}
                  style={{
                    cursor: 'pointer',
                  }}
                />
              </Slide>
            ))}
          </Slider>
        </div>
      </CarouselProvider>
      <ImgsViewer
        backdropCloseable
        imgs={images}
        currImg={currentIndex}
        isOpen={Number.isInteger(currentIndex)}
        onClickPrev={handleClickPrev}
        onClickNext={handleClickNext}
        onClose={handleClose}
        enableKeyboardInput
      />
    </>
  );
};

export default CarouselStrap;
