import * as P from 'prop-types';

const propTypes = {
  addresses: P.arrayOf(P.shape({
    addressType: P.string.isRequired,
    city: P.string.isRequired,
    complement: P.string,
    neighborhood: P.string.isRequired,
    number: P.string.isRequired,
    state: P.string.isRequired,
    street: P.string.isRequired,
    zipCode: P.string.isRequired,
  })).isRequired,
};

export default {
  propTypes,
  defaultProps: {},
};
