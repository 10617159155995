import React from 'react';
import axios from 'axios';
import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  IconButton,
  Typography,
  TextField,
  Tooltip,
  Zoom,
  Collapse,
  FormControlLabel,
  Box,
  Checkbox,
} from '@material-ui/core/';
import { Autocomplete } from '@material-ui/lab';
import {
  format, add, isWeekend, isFriday,
} from 'date-fns';
import Holidays from '@/utils/Holidays';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import { useDispatch, useSelector } from 'react-redux';
import {
  setCollect,
  setDelivery,
  setCustomer,
  setTradeInUser,
  setDevice,
  setProduct,
  setBillingAddress,
  setDeliveryData,
  setCollectModalities,
  setSelectedDefect,
  middlewares,
  setFluxType,
  setWarranty,
} from '@/redux/actions/NewOrderActions';
import { setStore, setBranch } from '@/redux/actions/PartnerStoreActions';
import PropTypes from './PropTypes';
import { checkCep, checkDeliveryCep } from './CheckCep';
import FormAddress from '../FormAddress';
import useStyles from './style';
import { factoryAddressFields } from './Common';

const Transition = React.forwardRef((props, ref) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Slide direction="up" ref={ref} {...props} />
));

export default function DialogWarranty({
  open,
  order,
  clickClose,
  setWarrantyLoading,
  handleAlert,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const partnerData = useSelector((state) => state.PartnerStoreReducer);
  const {
    id, addresses, product, customer,
  } = order;
  const {
    city, complement, neighborhood, number, state, street, zipCode,
  } = addresses[0];
  const [isEqualCollect, setEqualCollect] = React.useState(true);
  const [enabledAddressField, setEnableAddressField] = React.useState(false);
  const OSAddress = `Editar Endereço - ${street}, ${number} ${complement} - ${neighborhood}, ${city} - ${state}, ${zipCode}`;
  const initialDeliveryDate = () => {
    if (isWeekend(add(new Date(), { days: 3 }))) { return add(new Date(), { days: 5 }); }
    if (Holidays.isHoliday(add(new Date(), { days: 3 }))) {
      if (isFriday(add(new Date(), { days: 3 }))) { return add(new Date(), { days: 6 }); }
      return add(new Date(), { days: 4 });
    }
    if (isFriday(new Date())) return add(new Date(), { days: 5 });
    return add(new Date(), { days: 3 });
  };
  const [deliveryDate, setDeliveryDate] = React.useState(initialDeliveryDate);
  const setModalitiesType = (deliveryCep, collectCep) => {
    const cepCollectCleaned = collectCep.replace(/[^0-9]/gm, '');
    const cepDeliveryCleaned = deliveryCep.replace(/[^0-9]/gm, '');
    axios({
      url: `${process.env.REACT_APP_CI_URL}/reparafone/api-new/range/delivery/get/${cepCollectCleaned}`,
    }).then(({ data: r }) => {
      dispatch(setDeliveryData({ modalities: r }));
    });
    axios({
      url: `${process.env.REACT_APP_CI_URL}/reparafone/api-new/range/collect/get/${cepDeliveryCleaned}`,
    }).then(({ data: r }) => {
      dispatch(setCollectModalities({ modalities: r }));
    });
  };

  const formik = useFormik({
    initialValues: {
      defectComment: '',
      selectedDefects: '',
      collect: factoryAddressFields({ ...order.addresses[0] }),
      delivery: isEqualCollect
        ? factoryAddressFields({ ...order.addresses[0], isChartered: true })
        : factoryAddressFields({ ...order.addresses[1] }),
    },

    validationSchema: Yup.object({
      defectComment: Yup.string().required('Por gentileza, nos diga o motivo'),
      selectedDefects: Yup.array().of(
        Yup.object().shape({
          id: Yup.string(),
          description: Yup.string(),
          isAble: Yup.boolean(),
          maxPrice: Yup.number(),
          name: Yup.string(),
          parts: Yup.array().of(
            Yup.object().shape({
              part_name: Yup.string(),
              part_type: Yup.string(),
            }),
          ),
        }),
      ).required('Por gentileza, nos informe os defeitos'),
      collect: Yup.object(FormAddress.rules),
      delivery: isEqualCollect ? null : Yup.object(FormAddress.rules),
    }),

    onSubmit: async (values) => {
      clickClose();
      setWarrantyLoading(true);
      if (!values.collect.isChartered) {
        const response = await checkCep(values.collect.zipCode);
        if (response) {
          dispatch(setCollect(values.collect));
          dispatch(setBillingAddress(values.collect));
          dispatch(setCustomer({ ...customer, name: customer.fullName }));
          dispatch(setTradeInUser({ ...partnerData.employee }));
          dispatch(
            setDevice({
              color: product.color,
              brand: product.brand,
              defectComment: values.defectComment,
              imei: order.imei,
              model: product.model,
            }),
          );
          dispatch(
            setProduct({
              id: product.id,
              name: product.name,
              defects: product.defects,
            }),
          );
          dispatch(setStore({ id: order.storeId }));
          const branchData = localStorage.getItem('branchData');
          dispatch(setBranch(JSON.parse(branchData)));
          dispatch(
            setDeliveryData({
              schedule_delivery: format(deliveryDate, 'dd-MM-yyyy'),
            }),
          );
          dispatch(setSelectedDefect(values.selectedDefects));
          dispatch(setFluxType({ slug: 'garantia-reparafone' }));
          dispatch(setWarranty({ type: 1 }));
          setModalitiesType(values.delivery.zipCode, values.collect.zipCode);
        } else {
          return handleAlert(
            'Ainda não temos abrangência para o CEP de coleta, sentimos muito 😢',
            'error',
            true,
            3000,
          );
        }
      }

      if (!values.delivery.isChartered) {
        const response = await checkDeliveryCep(values.delivery.zipCode);
        if (response) {
          dispatch(setDelivery(values.delivery));
        } else {
          return handleAlert(
            'Ainda não temos abrangência para o CEP de entrega, sentimos muito 😢',
            'error',
            true,
            3000,
          );
        }
      }
      return dispatch(middlewares.createOrder(handleAlert, setWarrantyLoading, id));
    },
  });

  const toggleAddressField = () => setEnableAddressField(!enabledAddressField);

  const toggleEqualCollect = () => setEqualCollect(!isEqualCollect);

  const setValuesAddress = (field) => (newValues) => formik.setValues((values) => ({
    ...values,
    [field]: {
      ...values[field],
      ...newValues,
    },
  }));

  const autoFill = () => {
    if (order.addresses[0].isChartered) return null;
    return formik.setFieldValue('delivery', {
      zipCode: order.addresses[0].zipCode,
      state: order.addresses[0].state,
      city: order.addresses[0].city,
      neighborhood: order.addresses[0].neighborhood,
      street: order.addresses[0].street,
      number: order.addresses[0].number,
      complement: order.addresses[0].complement,
      isChartered: true,
    });
  };

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <form onSubmit={formik.handleSubmit}>
          <DialogTitle
            id="alert-dialog-slide-title"
            className={classes.dialogTitle}
          >
            <Typography variant="h2" className={classes.dialogTitleText}>
              {`Deseja criar a OS - ${id} de Garantia?`}
            </Typography>
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={clickClose}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent className={classes.dialogContent} dividers>
            <DialogContentText id="alert-dialog-slide-description">
              <Tooltip
                classes={classes.tooltip}
                arrow
                placement="right"
                TransitionComponent={Zoom}
                title="Gostaria de alterar o Endereço?"
              >
                <span>
                  <Chip
                    onClick={toggleAddressField}
                    deleteIcon={<EditIcon />}
                    label={OSAddress}
                    className={classes.chipAddress}
                  />
                </span>
              </Tooltip>
            </DialogContentText>
            <Collapse in={enabledAddressField}>
              <FormAddress
                isEditable={!formik.isSubmitting}
                prefix="collect"
                title="Coleta"
                desc="Endereço no qual iremos coletar seu aparelho"
                handleBlur={formik.handleBlur}
                handleChange={formik.handleChange}
                values={formik.values.collect}
                touched={formik.touched.collect}
                errors={formik.errors.collect}
                setValues={setValuesAddress('collect')}
                defaultShow
              />
              <Box display="flex" py={1} />
              <Collapse in={isEqualCollect}>
                <FormControlLabel
                  control={(
                    <Checkbox
                      checked={isEqualCollect}
                      onChange={toggleEqualCollect}
                      name="equalCollect"
                      color="primary"
                    />
                  )}
                  label="Usar mesmo endereço de coleta para entrega"
                />
              </Collapse>
              <Collapse in={!isEqualCollect}>
                <FormAddress
                  isEditable={!formik.isSubmitting}
                  prefix="delivery"
                  title="Entrega"
                  autoFillLabel="Preencher com endereço de coleta"
                  desc="Endereço no qual iremos entregar seu aparelho"
                  autoFill={autoFill}
                  handleBlur={formik.handleBlur}
                  handleChange={formik.handleChange}
                  values={formik.values.delivery}
                  touched={formik.touched.delivery}
                  errors={formik.errors.delivery}
                  setValues={
                    isEqualCollect
                      ? setValuesAddress('collect')
                      : setValuesAddress('delivery')
                  }
                  isEqualCollect={isEqualCollect}
                  setEqualCollect={toggleEqualCollect}
                />
              </Collapse>
            </Collapse>
            <Autocomplete
              multiple
              style={{ marginTop: '10px' }}
              name="selectedDefects"
              error={formik.errors.selectedDefects}
              helperText={formik.errors.selectedDefects}
              options={product.defects}
              onChange={(e, valueDefect) => {
                formik.setFieldValue('selectedDefects', valueDefect);
              }}
              filterSelectedOptions
              getOptionLabel={(option) => option.name}
              getOptionSelected={(option, value) => option.id === value.id}
              renderInput={(params) => (
                <TextField
            // eslint-disable-next-line react/jsx-props-no-spreading
                  {...params}
                  variant="outlined"
                  label="Defeitos do aparelho"
                  placeholder="Pesquisar"
                />
              )}
            />
            <TextField
              autoFocus
              id="defectComment"
              name="defectComment"
              style={{ marginTop: '10px' }}
              margin="dense"
              label="Diga nos o motivo da criação"
              type="text"
              variant="outlined"
              multiline
              error={formik.errors.defectComment}
              helperText={formik.errors.defectComment}
              rows={4}
              fullWidth
              onChange={formik.handleChange}
            />
          </DialogContent>
          <DialogActions>
            <Button
              disabled={formik.isSubmitting}
              type="reset"
              onClick={clickClose}
            >
              Sair
            </Button>
            <Button
              disabled={formik.isSubmitting}
              type="submit"
              size="small"
              variant="contained"
              color="primary"
            >
              Criar
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}

DialogWarranty.propTypes = PropTypes.propTypes;
DialogWarranty.defaultProps = PropTypes.defaultProps;
