/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import {
  Box, Card, Collapse, Divider,
  Grid, Icon, Typography,
} from '@material-ui/core';
// COMPONENTS
import CheckboxAutocomplete from './Components/CheckboxAutocomplete';
import LabelAddressShortName from './Components/LabelAddressShortName';
import InputNeighborhood from './Components/InputNeighborhood';
import InputComplement from './Components/InputComplement';
import ButtonCollapse from './Components/ButtonCollapse';
import InputStreetNumber from './Components/InputNumber';
import InputZipCode from './Components/InputZipCode';
import InputStreet from './Components/InputStreet';
import InputState from './Components/InputState';
import InputCity from './Components/InputCity';
import Title from './Components/Title';
// FUNCTIONS
import rules from './Rules';
import { propTypes, defaultProps } from './PropTypes';

export default function FormAddress({
  title, desc, isEditable, values, handleChange, handleBlur,
  errors, touched, prefix, autoFill, autoFillLabel, setValues,
  defaultShow, isEqualCollect, setEqualCollect,
}) {
  const [show, setShow] = React.useState(defaultShow);

  const toggleShowForm = (
    isEditable ? (() => setShow(!show)) : null
  );
  const toggleAutoComplete = () => {
    if (!isEqualCollect) {
      autoFill();
      setShow(false);
    }
    setEqualCollect();
  };

  const getFieldProps = (field) => ({
    prefix,
    disabled: !isEditable,
    value: values[field],
    handleBlur,
    handleChange,
    error: touched[field] ? errors[field] : null,
  });

  const hasAnError = Object.keys(touched).some((field) => !!errors[field]);

  return (
    <Card variant="outlined">
      <Box py={1} px={2} bgcolor={show && isEditable ? '#0000000a' : 'inherit'}>
        <Box display="flex">
          <Box display="flex" alignItems="center" pr={2}>
            <Icon className="fas fa-map-marked-alt" fontSize="large" color="action" />
          </Box>
          <Box minWidth="70%">
            <Title title={title} hasError={hasAnError} />
            <Typography variant="caption" component="div">{ desc }</Typography>
            {!!autoFill && isEditable && (
              <CheckboxAutocomplete
                autoFillLabel={autoFillLabel}
                toggleAutoComplete={toggleAutoComplete}
                isEqualCollect={isEqualCollect}
              />
            )}
            <LabelAddressShortName data={values} />
          </Box>
          <Box width="100%" display="flex" alignItems="center" justifyContent="flex-end">
            <ButtonCollapse toggleShowForm={toggleShowForm} isCollapsed={show} />
          </Box>
        </Box>
      </Box>

      <Divider />
      <Box pt="1px" />

      <Collapse in={isEditable && show} unmountOnExit>
        <Box p={2}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={4}>
              <InputZipCode {...getFieldProps('zipCode')} setValues={setValues} />
            </Grid>
            <Grid item xs={12} sm={8}>
              <InputState {...getFieldProps('state')} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputCity {...getFieldProps('city')} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputNeighborhood {...getFieldProps('neighborhood')} />
            </Grid>
            <Grid item xs={12}>
              <InputStreet {...getFieldProps('street')} />
            </Grid>
            <Grid item xs={12} sm={3}>
              <InputStreetNumber {...getFieldProps('number')} />
            </Grid>
            <Grid item xs={12} sm={9}>
              <InputComplement {...getFieldProps('complement')} />
            </Grid>
          </Grid>
        </Box>
      </Collapse>
    </Card>
  );
}

FormAddress.propTypes = propTypes;
FormAddress.defaultProps = defaultProps;
FormAddress.rules = rules;
