import makeStyle from '@material-ui/core/styles/makeStyles';

export default makeStyle(({ spacing, breakpoints }) => ({
  couponBox: {
    border: '1px solid rgba(0, 0, 0, 0.12)',
    marginBottom: spacing(2.5),
    padding: `${spacing(2)}px`,
    borderRadius: '5px',
  },
}));
