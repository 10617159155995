// MATERIAL-UI
import { makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
  '@global': {
    '.mapboxgl-popup-content': {
      padding: 0,
    },
    '.mapboxgl-popup-close-button': {
      color: 'white',
      fontSize: '20px',
      top: '-15px',
    },
    '.mapboxgl-ctrl-geocoder': {
      border: '1px solid #CDCDCD',
      boxShadow: 'none',
      [theme.breakpoints.up('md')]: {
        width: '100%',
        maxWidth: '100%',
      },
    },
  },
  storeMap: {
    maxWidth: '1200px',
    margin: '0 auto',
    padding: '70px 0',
  },
  storeMapMobile: {
    maxWidth: '1200px',
    margin: '0 auto',
    padding: '70px 5px',
  },
  storeMapCards: {
    display: 'flex',
  },
  storeMapCardsMobile: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '20px',
  },
  mapGl: {
    height: 'auto',
    position: 'absolute',
  },
  mapTittle: {
    fontSize: '3em',
    paddingBottom: '40px',
  },
  mapTittleMobile: {
    fontSize: '2em',
    padding: '0 50px',
    textAlign: 'center',
  },
  popup: {
    left: '8px',
    top: '-50px',
  },
}));

export default styles;
