import * as P from 'prop-types';

const propTypes = {
  customer: P.arrayOf(P.shape({
    fullName: P.string,
    cpf: P.string.isRequired,
    email: P.string.isRequired,
    landline: P.string.isRequired,
    phone: P.string.isRequired,
  })).isRequired,
};

export default {
  propTypes,
  defaultProps: {},
};
