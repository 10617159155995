import React from 'react';
import * as P from 'prop-types';
import { Collapse } from '@material-ui/core';
// COMPONENTS
import PreviewOrder from './PreviewOrder';
import TermsOfService from './TermsOfService';

function PreviewCard({
  handleTermsAgree,
  in: show,
}) {
  return (
    <Collapse in={show} unmountOnExit>
      <PreviewOrder />
      <TermsOfService handleChange={handleTermsAgree} />
    </Collapse>
  );
}

PreviewCard.propTypes = {
  handleTermsAgree: P.func.isRequired,
  in: P.bool.isRequired,
};

export default PreviewCard;
