import React from 'react';

import { HashLink as Link } from 'react-router-hash-link';
import { Button } from 'semantic-ui-react';

import ReactGA from 'react-ga';

import Buttons from './Button';

let scrolled = false;

const handleGA = () => {
  if (process.env.NODE_ENV === 'production') {
    if (scrolled === false) {
      scrolled = true;
      ReactGA.event({
        category: 'Interactivity',
        action: 'orcamentoClicked',
        label: 'Clicou no botão Orçamento do Header',
      });
    }
  }
};

const Menu = () => (
  <>
    <div className="menu hidden-mobile">
      <Buttons label="Nosso Método" url="#how-it-works" />
      <Buttons label="Serviços" url="#services" />
      <a
        className="button-menu button-seja-franqueado"
        href="/cadastroparceiro"
        label="Seja um parceiro"
      >
        Seja um parceiro
      </a>
      <Buttons
        url="#do-estimate"
        component={Button}
        className="button-orcamento"
        ButtonProps={{
          icon: 'dollar',
          labelPosition: 'right',
          content: 'Orçamento',
        }}
        onClick={handleGA}
      />
      <a className="button-entrar" href="/login" label="Entrar">
        <Button content="Entrar" icon="right arrow" labelPosition="right" />
      </a>
    </div>
    <div className="hidden-desktop">
      <a className="button-entrar" href="/login" label="Entrar">
        <Button content="Entrar" icon="right arrow" labelPosition="right" />
      </a>
    </div>
  </>
);

export default Menu;
