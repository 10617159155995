/* eslint-disable camelcase */
import React from 'react';
// REDUX
import { useSelector } from 'react-redux';
// CLSX
import clsx from 'clsx';
// COMPONENTS
import Contato from './components/Contato';
import Copyright from './components/Copyright';
import Sobre from './components/Sobre';
import Whatsapp from './components/Whatsapp';
// UTILS
import Holidays from '../../../../utils/Holidays';
// STYLES
import style from './style';

const Footer = () => {
  const classes = style();
  const partnerData = useSelector((state) => state.PartnerStoreReducer);
  const { branch } = partnerData;
  const { branch_name } = branch;
  const toggleViewWhatsApp = () => {
    const date = new Date();

    const diasDaSemana = date.getDay();
    const horas = date.getHours();
    const isHoliday = Holidays.isHoliday(date);

    if (!isHoliday && (diasDaSemana !== 0 && diasDaSemana !== 6) && (horas > 6 && horas < 16)) {
      return true;
    }
    return false;
  };
  return (
    <>
      <div
        id="footer"
        className={clsx(classes.footer, {
          [classes.footerCarrefour]: branch_name === 'Carrefour',
          [classes.footerReparofone]: branch_name === 'Limelocker',
        })}
      >
        <div className={classes.footerInterno}>
          <Sobre />
          <Contato />
        </div>
      </div>
      <Copyright />
      <Whatsapp show={toggleViewWhatsApp()} />
    </>
  );
};

export default Footer;
