import Axios from 'axios';

const searchZipCodeInfo = async (value) => {
  const zipCode = String(value).replace(/[^0-9]/gm, '');

  try {
    const { data } = await Axios.get(`https://viacep.com.br/ws/${zipCode}/json/`);
    return Promise.resolve({
      state: data.uf ? String(data.uf).toUpperCase() : undefined,
      city: data.localidade,
      neighborhood: data.bairro,
      street: data.logradouro,
    });
  } catch (error) {
    throw Promise.reject(error);
  }
};

export default searchZipCodeInfo;
