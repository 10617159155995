export default function getPaymentMethod(bin) {
  return new Promise((resolve, reject) => {
    window.Mercadopago.getPaymentMethod({ bin }, (status, response) => {
      if (status === 200) {
        resolve(response[0].id);
      }
      reject(response);
    });
  });
}
