import { connect } from 'react-redux';

const mapStateToProps = ({ NewOrderReducer }) => ({
  $state: {
    address: NewOrderReducer.customer.delivery,
  },
});
const mapDispatchToProps = null;

export default connect(mapStateToProps, mapDispatchToProps);
