import React from 'react';
import { Box, Grid } from '@material-ui/core';
// COMPONENTS
import Title from './Components/Title';
import DefectsList from './Components/DefectsList';
import InlineGridInfo from './Components/InlineGridInfo';
// FUNCTIONS
import connect from './ConnectComponentRedux';
import { propTypes, defaultProps } from './PropTypes';

function DeviceData({ $state }) {
  const { device } = $state;

  const deviceName = `${device.brand} ${device.model.name} ${device.color}`;

  return (
    <Box py={1}>
      <Title />
      <Grid container spacing={1}>
        <InlineGridInfo title="IMEI:" value={device.imei} />
        <InlineGridInfo title="Aparelho:" value={deviceName} />
        <InlineGridInfo title="Observações:" value={device.defectComment} />
        <InlineGridInfo
          title="Defeitos apontados:"
          value={(
            <DefectsList defects={device.selectedDefects} />
          )}
        />
      </Grid>
    </Box>
  );
}

DeviceData.propTypes = propTypes;
DeviceData.defaultProps = defaultProps;

export default connect(DeviceData);
