import React from 'react';
import * as P from 'prop-types';
import { TextField } from '@material-ui/core';
import { textFieldType, textFieldSize } from '../Common';

function Complement({
  value, handleChange, handleBlur, disabled, prefix, error,
}) {
  return (
    <TextField
      fullWidth
      size={textFieldSize}
      value={value}
      disabled={disabled}
      onChange={handleChange}
      onBlur={handleBlur}
      label="Complemento"
      error={!!error}
      helperText={error}
      variant={textFieldType}
      name={`${prefix}[complement]`}
    />
  );
}

Complement.propTypes = {
  value: P.string,
  handleChange: P.func.isRequired,
  handleBlur: P.func.isRequired,
  disabled: P.bool,
  prefix: P.string.isRequired,
  error: P.string,
};

Complement.defaultProps = {
  value: '',
  error: '',
  disabled: false,
};

export default React.memo(Complement);
