import { client } from '@/Apollo';
import FranchiseeRegistered from '../mutation/FranchiseeRegistered';

const franchiseeRegistered = (data) => new Promise((resolve, reject) => {
  client
    .mutate({
      mutation: FranchiseeRegistered,
      variables: { input: data },
      errorPolicy: 'all',
    })
    .then((r) => {
      resolve(r);
    })
    .catch((e) => {
      console.log(e.message, 'erro ao receber dados do formulário');
      reject(e);
    });
});

export default franchiseeRegistered;
