import makeStyles from '@material-ui/core/styles/makeStyles';

const transition = '300ms';
const show = {
  opacity: 1,
  height: 64,
  paddingTop: 4,
  paddingBottom: 4,
  transition,
};
const hide = {
  opacity: '0.1',
  height: 0,
  paddingTop: 0,
  paddingBottom: 0,
};

export default makeStyles(() => ({
  labelDesc: {
    display: 'block',
    lineHeight: '12px',
  },

  buttonDefect: {
    '&-enter': hide,

    '&-enter&-enter-active': show,

    '&-enter-done': show,

    '&-exit': show,

    '&-exit&-exit-active': hide,
  },
}));
