import React from 'react';
import {
  Grid, useMediaQuery, useTheme, Container, Tabs, Tab, Box, makeStyles,
} from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { has } from 'lodash';
import { Skeleton } from '@material-ui/lab';
import Timeline from '../../components/timeline/Timeline';
import { getRepairOrderById } from '../../utils/GraphQL';
import PageHeader from '../../layout/content/PageHeader';
import StatusPanel from './components/StatusPanel/StatusPanel';
import { mapStatusSlugToComponent } from './components/StatusPanel/MapComponentsToStatusSlug';
import BasicInfo from './components/order/tabs/BasicInfo';
import AddressInfo from './components/order/tabs/AddressInfo/index';
import RevisionInfo from './components/order/tabs/RevisionInfo';
import NotFoundPage from '../../components/feedback/NotFoundPage';

const TabPanel = (props) => {
  const {
    children, value, index, ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      style={{ width: '100%' }}
      {...other}
    >
      {value === index && (
        <Box p={1}>
          <Container>
            {children}
          </Container>
        </Box>
      )}
    </div>
  );
};
const useStyles = makeStyles((theme) => ({
  tabItem: {
    overflowWrap: 'anywhere',
  },
  statusPanel: {
    justifyContent: 'center',
    alignItems: 'center',
  },
}));
const Order = (props) => {
  const { orderId } = useParams();
  const classes = useStyles();
  const { loading, error, data } = useQuery(getRepairOrderById, {
    variables: {
      orderId,
    },
    pollInterval: 5000,
  });
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { customerRepairOrderById: order, getRepairOrderStatuses: statuses } = data || {};
  const [activeTab, setActiveTab] = React.useState(0);
  const handleChangeTab = (e, newTab) => {
    setActiveTab(newTab);
  };
  if (error) return <NotFoundPage />;
  return (
    <div>
      <PageHeader title={`Ordem #${orderId}`} />
      <Container>
        <Grid container direction="column" spacing={3} className={classes.statusPanel}>
          <Grid item>
            {order ? (
              <Timeline
                items={statuses.map((r) => ({ label: r.lay_name || r.status_name, icon: r.icon }))}
                currentIndex={parseInt(order.status.OOW_sequence, 10) - 1}
                orientation={isMobile ? 'vertical' : 'horizontal'}
                style={{ background: 'transparent', alignItems: isMobile ? 'center' : 'flex-start' }}
              />
            ) : <Skeleton height={80} />}
          </Grid>
          {order && has(mapStatusSlugToComponent, order.status.status_slug) && (
          <Grid item>
            <StatusPanel slug={order.status.status_slug} order={order} />
          </Grid>
          )}
        </Grid>
        <div style={{ flexGrow: 1, display: 'flex', justifyContent: 'center' }}>
          {!loading ? (
            <Tabs
              indicatorColor="secondary"
              value={activeTab}
              onChange={handleChangeTab}
              scrollButtons="auto"
              centered
            >
              <Tab className={classes.tabItem} label="Informações" />
              <Tab className={classes.tabItem} label="Endereço" />
              <Tab disabled={!order?.estimates.length > 0 || !order.status.id > 3} className={classes.tabItem} label="Última Revisão" />
            </Tabs>
          ) : <Skeleton /> }
        </div>
        <TabPanel value={activeTab} index={0}>
          <BasicInfo order={order} loading={loading} />
        </TabPanel>
        <TabPanel value={activeTab} index={1}>
          <AddressInfo order={order} loading={loading} />
        </TabPanel>
        <TabPanel value={activeTab} index={2}>
          <RevisionInfo order={order} loading={loading} />
        </TabPanel>
      </Container>
    </div>
  );
};

export default Order;
