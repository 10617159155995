import React from 'react';

import {
  Button,
  BottomNavigation,
  BottomNavigationAction,
  makeStyles,
} from '@material-ui/core';
import {
  BuildRounded,
  HomeRounded,
  InfoRounded,
  AccountCircleRounded,
  MonetizationOnRounded,
  Store,
} from '@material-ui/icons';
import { HashLink as Link } from 'react-router-hash-link';

const useStyles = makeStyles({
  root: {
    width: 500,
  },
});

export default function MenuMobile() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  return (
    <div className="hidden-desktop mobile-menu">
      <BottomNavigation
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
        showLabels
        className={classes.root}
      >
        <BottomNavigationAction
          smooth
          component={Link}
          to="#how-it-works"
          label="Método"
          icon={<InfoRounded />}
        />
        <BottomNavigationAction
          smooth
          component={Link}
          to="#services"
          label="Serviços"
          icon={<BuildRounded />}
        />
        <BottomNavigationAction
          smooth
          component={Link}
          to="#do-estimate"
          label="Orçamento"
          icon={<MonetizationOnRounded />}
        />
        <BottomNavigationAction
          smooth
          component={Button}
          href="/cadastroparceiro"
          label="Parcerias"
          style={{ color: 'rgba(0, 0, 0, 0.54)', textTransform: 'none' }}
          icon={<Store />}
        />
      </BottomNavigation>
    </div>
  );
}
