import React from 'react';
import { Box, Grid } from '@material-ui/core';
// REDUX
import { useSelector } from 'react-redux';
// COMPONENTS
import Title from './Components/Title';
import DefectsList from './Components/DefectsList';
import InlineGridInfo from './Components/InlineGridInfo';

function DeviceData() {
  const customerData = useSelector((state) => state.NewRepairReducer);
  const { device } = customerData;

  const deviceName = `${device.brand} ${device.model.name} ${device.color}`;

  return (
    <Box py={1}>
      <Title />
      <Grid container spacing={1}>
        <InlineGridInfo title="Aparelho:" value={deviceName} />
        <InlineGridInfo
          title="Defeitos apontados:"
          value={(
            <DefectsList defects={device.selectedDefects} />
          )}
        />
      </Grid>
    </Box>
  );
}

export default DeviceData;
