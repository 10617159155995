import { client } from '@/Apollo';
import ValidateEmailToken from '../mutation/ValidateEmailToken';

const validateTokenEmail = (data) => new Promise((resolve, reject) => {
  client
    .mutate({
      mutation: ValidateEmailToken,
      variables: { ...data },
      errorPolicy: 'all',
    })
    .then((r) => {
      resolve(r);
    })
    .catch((e) => {
      console.log(e.message, 'erro ao validar token email');
      reject(e);
    });
});

export default validateTokenEmail;
