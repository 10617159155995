import React from 'react';
import { Box, Typography } from '@material-ui/core';

export default function Title() {
  return (
    <Box py={1}>
      <Typography variant="h5">
        Dados do aparelho
      </Typography>
    </Box>
  );
}
