import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setStep, setDevice } from '@redux/actions/NewOrderActions';

const mapStateToProps = ({ NewOrderReducer: { activeStep, device } }) => ({
  $state: {
    step: activeStep,
    device,
    product: device.product,
    imei: device.imei,
    defectComment: device.defectComment,
    isSelectedDevice: !!device.product.name,
  },
});

const mapDispatchToProps = (dispatch) => ({
  $actions: bindActionCreators(
    { setStep, setDevice },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps);
