import React from 'react';
import {
  Grid, LinearProgress, IconButton, Collapse,
} from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import { connect } from 'react-redux';
import ReparofoneLogo from '../../../images/Reparafone-logo.png';

const Header = ({ isFetching, history, location }) => {
  const collapseRef = React.useRef();
  const id = React.useMemo(() => `fetching-${Math.floor(Math.random() * 100)}`);
  React.useEffect(() => {
    const { current } = collapseRef;
    if (isFetching && current) {
      document.scrollingElement.scrollTo({
        top: current.offsetTop - 140,
        behavior: 'smooth',
      });
    }
  }, [isFetching]);
  return (
    <>
      {history.location.pathname !== '/' && (
      <Grid container direction="row">
        <Grid item xs>
          <IconButton onClick={location.pathname === '/recover/password/change' ? () => history.replace('/') : history.goBack}>
            <ArrowBack style={{ position: 'absolute', fontSize: '2rem' }} />
          </IconButton>
        </Grid>
      </Grid>
      )}
      <Grid container direction="row">
        <Grid item>
          <a className="logo-link" href="/novaordem">
            <img src={ReparofoneLogo} alt="Reparofone" style={{ width: '100%', marginTop: '1rem' }} />
          </a>
        </Grid>
      </Grid>
      <Grid container direction="row">
        <Collapse id={id} in={isFetching} ref={collapseRef} style={{ width: '100%' }}>
          <Grid item xs>
            <LinearProgress color="primary" style={{ width: '100%', margin: '10px 0' }} />
          </Grid>
        </Collapse>
      </Grid>
    </>
  );
};
const mapStateToProps = (state) => {
  const { AuthReducer } = state;
  const { isFetching } = AuthReducer;
  return {
    isFetching,
  };
};
export default connect(mapStateToProps)(Header);
