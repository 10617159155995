import React from 'react';
import * as P from 'prop-types';
import { DefectsProps } from '@redux/reducers/NewRepairReducer/PropTypes';

export default function DefectsList({ defects }) {
  const defectsJoin = defects
    .map(({ name }, index) => {
      if (index === defects.length - 1) {
        return `${name}`;
      } if (index === defects.length - 2) {
        return name;
      }
      return `${name},`;
    })
    .join(' ');

  return (
    <span>
      {defectsJoin}
    </span>
  );
}

DefectsList.propTypes = {
  defects: P.arrayOf(P.shape(DefectsProps)).isRequired,
};
