import client from '@/Apollo';
import { getModelColorsQuery } from '../graphql/query/GetColors';

export default (model) => new Promise((resolve, reject) => {
  client.query({ query: getModelColorsQuery, variables: { modelId: model.id } })
    .then(({ data }) => {
      const { colorsByModel } = data;
      resolve(colorsByModel);
    }).catch(() => reject(new Error('Não foi possível carregar as cores, tente novamente mais tarde.')));
});
