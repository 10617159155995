/* eslint-disable camelcase */
import * as React from 'react';
// REACT
import { useState, useCallback, useRef } from 'react';
// REDUX
import { useSelector, useDispatch } from 'react-redux';
import { setStores, resetAllStoresData } from '@/redux/actions/MapBoxActions';
// MAPBOX
import MapGL, {
  AttributionControl,
  Popup,
  NavigationControl,
  ScaleControl,
  FlyToInterpolator,
  WebMercatorViewport,
  Marker,
} from 'react-map-gl';
// GEOCODER
import Geocoder from 'react-map-gl-geocoder';
// TURF
import * as turf from '@turf/turf';
// MATERIAL-UI
import {
  Hidden,
  useMediaQuery,
  useTheme,
  Typography,
} from '@material-ui/core';
// MARKER
import ImageMarker from '@/images/Map/home.png';
// CONTROLS
import { navStyle, scaleControlStyle } from './controls';
// COMPONENTS
import StoresPanel from './components/StoresPanel/index';
import StoreInfo from './components/StoreInfo';
import Pins from './components/Pins';
// Utils
import {
  scrollView,
  attributionStyle,
  getBbox,
} from './utils';
// STYLES
import styles from './styles';

// TOKEN
const MAPBOX_TOKEN = 'pk.eyJ1IjoiYnJ1bm9zaWx2YXRyb2NhZm9uZSIsImEiOiJja3FncmU2djEyZDZ0Mm5sYzZuaGU2ZGJtIn0.NB6xnGdehwLqCnJS1TDGYA';

export default function Mapa() {
  const classes = styles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const dispatch = useDispatch();
  const mapBoxData = useSelector((state) => state.MapBoxReducer);
  const { stores } = mapBoxData;
  const mapRef = useRef();
  const geocoderContainerRef = useRef();
  const [popupInfo, setPopupInfo] = useState(null);
  const [searchResultLayer, setSearchResultLayer] = useState(null);
  const eventRecognizerOptions = !matches
    ? {
      pan: { threshold: 10 },
      tap: { threshold: 5 },
    }
    : {};
  const [viewport, setViewport] = useState({
    latitude: -23.5506,
    longitude: -46.6327,
    zoom: 8,
    bearing: 0,
    pitch: 0,
  });
  const handleViewportChange = useCallback(
    (newViewport) => setViewport(newViewport),
    [],
  );
  const handleGeocoderViewportChange = useCallback((newViewport) => {
    const geocoderDefaultOverrides = { transitionDuration: 'auto' };
    return handleViewportChange({
      ...newViewport,
      ...geocoderDefaultOverrides,
    });
  }, []);
  const handleGeocoderOnResult = (event) => {
    const searchResult = event.result.geometry;
    const storesWithDistances = [];
    const options = { units: 'kilometers' };
    setSearchResultLayer({
      longitude: event.result.center[0],
      latitude: event.result.center[1],
    });
    stores.map((store) => {
      const from = turf.point(searchResult.coordinates);
      const to = turf.point([
        Number(store.addresses[0].longitude),
        Number(store.addresses[0].latitude),
      ]);
      const distance = turf.distance(from, to, options);
      return storesWithDistances.push({
        ...store,
        addresses: {
          ...store.addresses,
          distance: Math.round(distance * 100) / 100,
        },
      });
    });
    dispatch(resetAllStoresData());
    const sortedList = storesWithDistances.sort((a, b) => {
      if (a.addresses.distance > b.addresses.distance) return 1;
      if (a.addresses.distance < b.addresses.distance) return -1;
      return 0;
    });
    const bbox = getBbox(sortedList, 0, searchResult);
    const viewportWithBbox = new WebMercatorViewport({
      width: 800,
      height: 500,
    }).fitBounds(bbox, {
      padding: 160,
    });
    setViewport({
      ...viewportWithBbox,
      transitionInterpolator: new FlyToInterpolator({ speed: 3 }),
      transitionDuration: 'auto',
    });
    setPopupInfo(sortedList[0]);
    scrollView(-100, '.mapboxgl-map');
    return dispatch(setStores(sortedList));
  };

  const onSelectStore = useCallback(({ longitude, latitude }) => {
    setViewport({
      longitude: Number(longitude),
      latitude: Number(latitude),
      zoom: 14,
      transitionInterpolator: new FlyToInterpolator({ speed: 3 }),
      transitionDuration: 'auto',
    });
    scrollView(-100, '.mapboxgl-map');
  }, []);

  return (
    <div
      id="map-stores"
      className={matches ? classes.storeMap : classes.storeMapMobile}
    >
      <Typography
        variant="h2"
        className={matches ? classes.mapTittle : classes.mapTittleMobile}
        gutterBottom
      >
        Encontre a loja mais perto de você
      </Typography>
      <div
        className={
          matches ? classes.storeMapCards : classes.storeMapCardsMobile
        }
      >
        <Hidden only={['md', 'lg', 'xl']}>
          <StoresPanel
            onSelectStore={onSelectStore}
            setPopupInfo={setPopupInfo}
            data={popupInfo}
            geocoderContainerRef={geocoderContainerRef}
          />
        </Hidden>
        <Hidden only={['sm', 'xs']}>
          <StoresPanel
            onSelectStore={onSelectStore}
            setPopupInfo={setPopupInfo}
            data={popupInfo}
            geocoderContainerRef={geocoderContainerRef}
          />
        </Hidden>
        <MapGL
          id="map-gl"
          ref={mapRef}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...viewport}
          width="100%"
          height="500px"
          mapboxApiAccessToken={MAPBOX_TOKEN}
          className={classes.mapGl}
          mapStyle="mapbox://styles/mapbox/light-v10"
          onViewportChange={handleViewportChange}
          dragRotate={!!matches}
          attributionControl={false}
          eventRecognizerOptions={eventRecognizerOptions}
          dragPan={!!matches}
          touchAction="pan-y"
        >
          <Geocoder
            mapRef={mapRef}
            containerRef={geocoderContainerRef}
            onViewportChange={handleGeocoderViewportChange}
            mapboxApiAccessToken={MAPBOX_TOKEN}
            position="top-right"
            placeholder="Pesquise seu Endereço"
            onResult={(event) => {
              if (stores.length > 0) {
                return handleGeocoderOnResult(event);
              }
              scrollView(-100, '.mapboxgl-map');
              return setSearchResultLayer({
                longitude: event.result.center[0],
                latitude: event.result.center[1],
              });
            }}
            countries="BR"
            language="pt"
            minLength={3}
          />
          <Pins data={stores} onClick={setPopupInfo} />
          { searchResultLayer && (
            <Marker
              latitude={searchResultLayer.latitude}
              longitude={searchResultLayer.longitude}
              offsetLeft={-20}
              offsetTop={-10}
            >
              <img
                style={{
                  cursor: 'pointer',
                  fill: '#d00',
                  stroke: 'none',
                  transform: `translate(${-56 / 2}px,${-56}px)`,
                }}
                alt="marker-reparofone"
                src={ImageMarker}
              />
            </Marker>
          )}
          {popupInfo && (
            <Popup
              tipSize={5}
              anchor="bottom"
              longitude={Number(popupInfo.addresses[0].longitude)}
              latitude={Number(popupInfo.addresses[0].latitude)}
              closeOnClick={false}
              onClose={setPopupInfo}
              className={classes.popup}
            >
              <StoreInfo info={popupInfo} />
            </Popup>
          )}
          <AttributionControl
            customAttribution="Design by Reparofone"
            style={attributionStyle}
          />
          <NavigationControl style={navStyle} />
          <ScaleControl style={scaleControlStyle} />
        </MapGL>
      </div>
    </div>
  );
}
