import React from 'react';
// REDUX
import { useSelector } from 'react-redux';
// CLSX
import clsx from 'clsx';
// STYLES
import style from './style';

const Copyright = () => {
  const classes = style();
  const date = new Date();
  const year = date.getFullYear();
  const partnerData = useSelector((state) => state.PartnerStoreReducer);
  const { branch } = partnerData;
  const { branch_name } = branch;
  return (
    <div
      className={clsx(classes.copyright, {
        [classes.copyrightCarrefour]: branch_name === 'Carrefour',
        [classes.copyrightReparofone]: branch_name === 'Limelocker',
      })}
    >
      ©
      {' '}
      {year}
      {' '}
      TROCAFONE - Comercialização de Aparelhos Eletrônicos LTDA. Todos os direitos reservados.
    </div>
  );
};

export default Copyright;
