import React from 'react';
import { Box, Typography } from '@material-ui/core';
// COMPONENTS
import InputDefects from '../InputDefects';
import BoxRepairBudget from '../BoxRepairBudget';
import ButtonsMainDefects from '../ButtonsMainDefects';
// FUNCTIONS
import ConnectComponentRedux from './ConnectComponentRedux';
import defectIcons from './DefectIcons';
import createLeadRepair from '../../../../utils/CreateLeadRepair';
import { propTypes, defaultProps } from './PropTypes';

function SelectDefects({
  $state, $actions, disabledBtnNextStep,
}) {
  const { product } = $state;
  const { setLead } = $actions;

  const addDefect = (defect) => {
    disabledBtnNextStep(true);
    $actions.setSelectedDefect([...$state.selectedDefects, defect]);
  };
  const handleSelectDefects = (defects) => {
    disabledBtnNextStep(true);
    $actions.setSelectedDefect(defects);
  };
  const hookCalcBudget = async (createLead = true, canRepair = true) => {
    if (createLead) await createLeadRepair($state, setLead);
    if (canRepair) disabledBtnNextStep(false);
  };

  const mainDefects = React.useMemo(() => (
    product.defects
      .map((defect) => ({
        ...defect,
        icon: defectIcons[defect.id],
      }))
      .filter((defect) => (
        !!defect.icon
          && !$state.selectedDefects.some((selectedDefect) => (
            selectedDefect.id === defect.id
          ))
      ))
      .slice(0, 3)
  ), [product.defects, $state.selectedDefects]);

  return (
    <Box width="100%">
      <Typography variant="h6">Selecione os defeitos</Typography>
      <Box display="flex" justifyContent="center" width="100%">
        <Box maxWidth={500} width="100%" display="block" mx={1}>
          <ButtonsMainDefects
            defects={mainDefects}
            handleSelect={addDefect}
          />
          <InputDefects
            defects={product.defects}
            selectedDefects={$state.selectedDefects}
            handleSelect={handleSelectDefects}
          />
          <BoxRepairBudget
            collectZipcode={$state.collectZipcode}
            deliveryZipcode={$state.collectZipcode}
            defects={$state.selectedDefects}
            selectedDefects={$state.selectedDefects}
            hookCalcBudget={hookCalcBudget}
          />
        </Box>
      </Box>
    </Box>
  );
}

SelectDefects.propTypes = propTypes;
SelectDefects.defaultProps = defaultProps;

export default ConnectComponentRedux(React.memo(SelectDefects));
