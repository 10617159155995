import React from 'react';
import * as Yup from 'yup';
import {
  Box, Icon, IconButton, Typography,
} from '@material-ui/core';
import { useFormik } from 'formik';
// COMPONENTS
import FocusError from '@/utils/FocusError';
import FormAddress from '../FormAddress';
import InputCpf from './Components/InputCpf';
import StepperButtons from '../StepperButtons';
// FUUNCTIONS
import GetShipping, { checkDeliveryCep } from '../../utils/GetShipping';
import connect from './ConnectComponentRedux';
import { factoryAddressFields } from './Common';
import { propTypes, defaultProps } from './PropTypes';
import InlineAlertCollapse from '../InlineAlertCollapse';

function MoreAboutCustomer({ $actions, $state }) {
  const { cpf, collectAddress, deliveryAddress } = $state;
  const [alert, setAlert] = React.useState({
    show: false,
    message: null,
    severity: null,
  });

  const closeAlert = () => setAlert({
    ...alert,
    show: false,
  });

  const formik = useFormik({
    initialValues: {
      cpf,
      collect: factoryAddressFields(collectAddress),
      delivery: factoryAddressFields(deliveryAddress),
    },

    validationSchema: Yup.object({
      cpf: InputCpf.rules,
      collect: Yup.object(FormAddress.rules),
      delivery: Yup.object(FormAddress.rules),
    }),

    onSubmit: async (values) => {
      $actions.setCustomer({
        cpf: values.cpf,
      });

      if (!$state.cpfCreditCard) {
        $actions.setCreditCard({
          cpf: values.cpf,
        });
      }

      if (!collectAddress.isChartered) {
        const response = await GetShipping(values.collect.zipCode);
        if (response) {
          $actions.setCollect(values.collect);
        } else {
          setAlert({
            show: true,
            severity: 'error',
            message: 'Ainda não temos abrangência para o CEP de coleta, sentimos muito 😢',
          });
          return;
        }
      }

      if (!deliveryAddress.isChartered) {
        const response = await checkDeliveryCep(values.delivery.zipCode);
        if (response) {
          $actions.setDelivery(values.delivery);
        } else {
          setAlert({
            show: true,
            severity: 'error',
            message: 'Ainda não temos abrangência para o CEP de entrega, sentimos muito 😢',
          });
          return;
        }
      }

      try {
        await $actions.calculateTotalBudgetAmount();
        $actions.setStep($state.step + 1);
      } catch (error) {
        setAlert({
          show: true,
          severity: 'error',
          message: 'Não foi possível estimar o frete, estamos passando por problemas, tente novamente mais tarde, sentimos muito 😢',
        });
      }
    },
  });

  const isSubmitting = formik.isSubmitting ? StepperButtons.SUBMITTING.NEXT : null;

  const backStep = () => {
    $actions.setCustomer({
      cpf: formik.values.cpf,
    });

    if (!collectAddress.isChartered) {
      $actions.setCollect(formik.values.collect);
    }

    if (!deliveryAddress.isChartered) {
      $actions.setDelivery(formik.values.delivery);
    }
    $actions.setStep($state.step - 1);
  };

  const isDisabledField = (typeAddress) => (
    (field) => (field === 'zipCode' && typeAddress.blockZipCodeEdit)
  );

  const setValuesAddress = (field) => (newValues) => (
    formik.setValues((values) => ({
      ...values,
      [field]: {
        ...values[field],
        ...newValues,
      },
    }))
  );

  return (
    <>
      <Box maxWidth={668}>
        <form onSubmit={formik.handleSubmit}>
          <Box py={1}>
            <Box py={1}>
              <Typography variant="h6">Dados do cliente</Typography>
            </Box>
            <Box textAlign="center" maxWidth={400} width="100%" mx="auto">
              <InputCpf
                disabled={formik.isSubmitting}
                typeInput="outlined"
                value={formik.values.cpf}
                errors={formik.touched.cpf ? formik.errors.cpf : null}
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
              />
            </Box>
          </Box>
          <Box width="100%" py={1} display="flex" />
          <Box py={1}>
            <Typography variant="h6">Endereço</Typography>
            <Box py={1} mx="auto" width="100%" maxWidth={560}>
              <Box py={1}>
                <InlineAlertCollapse
                  show={alert.show}
                  message={alert.message}
                  severity={alert.severity}
                  button={(
                    <IconButton onClick={closeAlert}>
                      <Icon>close</Icon>
                    </IconButton>
                  )}
                />
              </Box>
              <FormAddress
                isEditable={!collectAddress.isChartered && !formik.isSubmitting}
                prefix="collect"
                title="Coleta"
                desc="Endereço no qual iremos coletar seu aparelho"
                handleBlur={formik.handleBlur}
                handleChange={formik.handleChange}
                values={formik.values.collect}
                touched={formik.touched.collect}
                errors={formik.errors.collect}
                isDisabledField={isDisabledField(collectAddress)}
                setValues={setValuesAddress('collect')}
              />
              <Box display="flex" py={1} />
              <FormAddress
                isEditable={!deliveryAddress.isChartered && !formik.isSubmitting}
                prefix="delivery"
                title="Entrega"
                desc="Endereço no qual iremos entregar seu aparelho"
                handleBlur={formik.handleBlur}
                handleChange={formik.handleChange}
                values={formik.values.delivery}
                touched={formik.touched.delivery}
                errors={formik.errors.delivery}
                isDisabledField={isDisabledField(deliveryAddress)}
                setValues={setValuesAddress('delivery')}
                defaultShow
              />
            </Box>
          </Box>
          <FocusError errors={formik.errors} isSubmitting={formik.isSubmitting} isValidating={formik.isValidating} />
        </form>
      </Box>
      <StepperButtons
        backStep={backStep}
        nextStep={formik.submitForm}
        isSubmitting={isSubmitting}
      />
    </>
  );
}

MoreAboutCustomer.propTypes = propTypes;
MoreAboutCustomer.defaultProps = defaultProps;

export default connect(MoreAboutCustomer);
