import React from 'react';
import * as P from 'prop-types';
import { Box, Typography } from '@material-ui/core';

export default function LabelModelSelected({ model }) {
  return (
    <Box display="flex" alignItems="center">
      <span>
        <Typography variant="subtitle2" component="span">Modelo:</Typography>
        { ' ' }
        { `${model}` }
      </span>
    </Box>
  );
}

LabelModelSelected.propTypes = {
  model: P.string.isRequired,
};
