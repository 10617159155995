import React from 'react';
import Panel from './components/Panel';
import PageHeader from '../../layout/content/PageHeader';

const Repairs = (props) => {
  return (
    <div>
      <PageHeader title="Reparos" />
      <Panel />
    </div>
  );
};

export default Repairs;
