import React from 'react';
// MATERIALS
import {
  Grid, Typography, LinearProgress, Collapse,
} from '@material-ui/core';
import { isArray, uniq, sumBy } from 'lodash';
// REDUX
import { useSelector } from 'react-redux';
import { format, addBusinessDays, eachDayOfInterval } from 'date-fns';
// Apollo e GraphQL
import { useQuery } from '@apollo/react-hooks';
import DateRange from './DateRange';
import getScheduleCollect from '../../../../../../graphql/query/GetSchedule';
// STYLES
import useStyles from './style';

const ScheduleCollect = ({
  collectId,
  handleChangeDate,
  errors,
  value,
  disabled,
  setDate,
  setFieldValue,
}) => {
  const classes = useStyles();
  const customerData = useSelector((state) => state.NewRepairReducer);
  const { device, collect } = customerData;

  const { loading, error, data } = useQuery(getScheduleCollect);
  const maxSchedules = !loading
    && !error
    && data.getReparofoneCollectTypes.find((r) => parseInt(r.id, 10) === collectId)
      ?.max_schedules;
  const minimumRangeDate = () => {
    let estimatedSupplyTime = 0;
    device.selectedDefects.map((defect) => {
      defect.parts.map((part) => {
        if (sumBy(part.trocafone_parts, 'stock_reduced') < 1) {
          estimatedSupplyTime = estimatedSupplyTime > part.estimated_supply_time_in_days
            ? estimatedSupplyTime
            : part.estimated_supply_time_in_days;
        }
      });
    });
    const minimumDate = addBusinessDays(
      new Date(),
      estimatedSupplyTime === 0 ? 0 : estimatedSupplyTime,
    );
    return estimatedSupplyTime
      ? eachDayOfInterval({
        start: new Date(),
        end: minimumDate,
      }).map((r) => format(r, 'Y-MM-dd'))
      : [];
  };
  const disabledDates = isArray(data?.getScheduledCollectsByDate) && [
    ...uniq(
      data.getScheduledCollectsByDate
        .filter((r) => parseInt(r.count, 10) >= maxSchedules)
        .map((r) => r.scheduled_date),
    ),
    ...minimumRangeDate(),
  ];
  if (loading) {
    return (
      <Grid container item sm={6}>
        <LinearProgress size="20" style={{ width: '100%' }} />
      </Grid>
    );
  }
  return (
    <>
      <Collapse className={classes.dateCollapse} in={collect?.chosenModality?.collect_slug === 'mtboy'}>
        <DateRange
          onChange={handleChangeDate}
          disableHolidays
          disableWeekend
          disabled={disabled}
          disabledDates={disabledDates}
          value={value}
          name="collectDate"
          errors={errors}
          setDate={setDate}
          setFieldValue={setFieldValue}
        />
      </Collapse>
      <Grid
        container
        item
        sm={12}
        style={{
          border: '1px solid #ccc',
          justifyContent: 'center',
          padding: '10px',
          marginTop: '15px',
          borderRadius: '5px',
        }}
      >
        {collect?.chosenModality?.collect_slug === 'mtboy' && (
          <>
            <Typography
              align="center"
              variant="body1"
            >
              A coleta será realizada no dia agendado durante o horário comercial
            </Typography>
          </>
        )}
        {collect?.chosenModality?.collect_slug === 'correios' && (
          <>
            <Typography
              align="center"
              variant="body1"
            >
              Em até um dia útil, enviaremos no seu email um ticket para a
              postagem de seu aparelho em uma
              {' '}
              <span style={{ fontWeight: 'bold' }}>Agência dos Correios</span>
              .
            </Typography>
          </>
        )}
        {collect?.chosenModality?.collect_slug === 'dhl' && (
          <>
            <Typography
              align="center"
              variant="body1"
            >
              <span style={{ fontWeight: 'bold' }}>A DHL ou um representante</span>
              {' '}
              irá no seu endereço coletar em até 3
              dias úteis a partir da solicitação do reparo.
            </Typography>
          </>
        )}
      </Grid>
    </>
  );
};

export default ScheduleCollect;
