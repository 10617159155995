import gql from 'graphql-tag';

export const GetDiscount = gql`
query GetDiscount($input: ReparofoneCouponDiscount!){
    ReparafoneCouponDiscount(input: $input){
    code
    discount
  }
}
`;

export default GetDiscount;
