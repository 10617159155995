/* eslint-disable react/jsx-no-duplicate-props */
import React from 'react';
import * as Yup from 'yup';
import { Icon, InputAdornment, TextField } from '@material-ui/core';
import ReactInputMask from 'react-input-mask';
import { propTypes, defaultProps } from '../Utils/TextFieldPropTypes';
import useStyle from '../../../../../style';

export default function InputCvc({
  handleChange, handleBlur, handleFocus, value, error, typeInput, disabled,
}) {
  const classes = useStyle();

  return (
    <ReactInputMask
      mask="999"
      placeholder="000"
      value={value}
      onBlur={handleBlur}
      disabled={disabled}
      onFocus={handleFocus}
      onChange={handleChange}
      maskPlaceholder={null}
    >
      <TextField
        fullWidth
        size="small"
        autoComplete="off"
        label="Código de Seg."
        variant={typeInput}
        error={!!error}
        helperText={error || null}
        inputProps={{
          id: 'securityCode',
          'data-checkout': 'securityCode',
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Icon className={classes.inputIcon}>lock</Icon>
            </InputAdornment>
          ),
        }}
      />
    </ReactInputMask>
  );
}

InputCvc.propTypes = propTypes;
InputCvc.defaultProps = defaultProps;
InputCvc.rules = Yup
  .string()
  .matches(/\d{3}/gm, 'Formato inválido')
  .required('Este campo é obrigatório');
