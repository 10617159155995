export const PROCESS_PAYMENT_TIME = {
  AFTER_CREATION: 'AFTER_CREATION',
  AFTER_REPAIR: 'AFTER_REPAIR',
};

const addressInfo = {
  zipCode: undefined,
  state: undefined,
  city: undefined,
  neighborhood: undefined,
  street: undefined,
  number: undefined,
  complement: undefined,
  isChartered: false,
  blockZipCodeEdit: false,
};

const initialState = {
  activeStep: 0,
  delivery: {
    modalities: undefined,
  },
  collect: {
    modalities: undefined,
  },
  fluxType: undefined,
  warranty: 0,
  customer: {
    name: undefined,
    email: undefined,
    phone: undefined,
    landline: undefined,
    collect: addressInfo,
    delivery: { ...addressInfo, isChartered: false },
  },
  tradeInUser: {
    id: undefined,
    name: undefined,
    email: undefined,
    branch_id: undefined,
  },
  device: {
    imei: undefined,
    brand: undefined,
    model: {
      id: undefined,
      name: undefined,
      brand: undefined,
    },
    color: undefined,
    product: {
      id: undefined,
      name: undefined,
      defects: [],
    },
    conditions: [],
    selectedDefects: [],
    defectComment: undefined,
    fakeDefect: false,
    isLoadingFakeDefect: false,
    isDisabledFakeDefect: false,
  },
  processPayment: PROCESS_PAYMENT_TIME.AFTER_REPAIR,
  payment: {
    type: 'afterRepairPayment',
    data: {
      cpf: undefined,
      cvc: undefined,
      name: undefined,
      number: undefined,
      expiry: undefined,
      installments: undefined,
      installmentsText: undefined,
      cardToken: undefined,
    },
  },
  billingAddress: {
    zipCode: undefined,
    state: undefined,
    city: undefined,
    neighborhood: undefined,
    street: undefined,
    number: undefined,
    complement: undefined,
  },
  transactionAmount: {
    taxDelivery: 0,
    taxCollect: 0,
    budgetRepair: 0,
  },
  coupons: [],
  lead: {},
  orderData: undefined,
  refusedBudgetReason: {
    id: undefined,
    name: undefined,
    description: undefined,
    slug: undefined,
    lay_name: undefined,
  },
  allRefusedBudgetReasons: [],
};
export default initialState;
