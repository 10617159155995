import P from 'prop-types';
import StatePropTypes from '@redux/reducers/PartnerStoreReducer/PropTypes';

export const FormStepperPropTypes = {
  $state: P.shape({
    name: StatePropTypes.name,
    branch: StatePropTypes.branch,
  }),
  $actions: P.shape({
    setStep: P.func.isRequired,
    resetOrder: P.func.isRequired,
  }).isRequired,
};

export const FormStepperDefaultProps = {};

export default { FormStepperPropTypes, FormStepperDefaultProps };
