import * as P from 'prop-types';

const propTypes = {
  onClick: P.func.isRequired,
  label: P.string.isRequired,
  url: P.string.isRequired,
  ButtonProps: P.shape({
    icon: P.string.isRequired,
    labelPosition: P.string.isRequired,
    content: P.string.isRequired,
  }).isRequired,
  component: P.element.isRequired,
};

export default {
  propTypes,
  defaultProps: {},
};
