import gql from 'graphql-tag';

export const GetEmployeeByBranchWithStoreByBranch = gql`
  query GetEmployeeByBranchWithStoreByBranch(
    $branch_id: String!
  ) {
    ReparafoneGetTradeInUserByBranchId(branch_id: $branch_id) {
      id
      name
      email
      branch_id
    }
    ReparafoneGetStoreByBranchId(branch_id: $branch_id) {
      id
      name
    }
  }
`;

export default GetEmployeeByBranchWithStoreByBranch;
