import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles(({ transitions: { easing } }) => ({
  nextStep: {
    '& .stepIcon': {
      animation: `$shake 5s ${easing.easeIn} infinite`,
    },
  },
  '@keyframes shake': {
    '40%, 64%': { transform: 'translate(0px, 0px) rotate(0deg)' },
    '42%, 46%, 50%, 54%, 58%, 62%': { transform: 'translate(-1px, 2px) rotate(-3deg)' },
    '44%, 48%, 52%, 56%, 60%': { transform: 'translate(2px, -1px) rotate(3deg)' },
  },
  stepper: {
    padding: '24px 0',
  },
  header: {
    textAlign: 'center',
  },
  headerCarrefour: {
    fontFamily: 'Milliard Book',
  },
  headerReparofone: {
    fontFamily: 'Roboto',
  },
}));
