import gql from 'graphql-tag';

export const GetBranchById = gql`
  query GetBranchById($id: ID!) {
    ReparafoneGetBranchById(id: $id) {
      id
      branch_name
      branch_address
      branch_slug
      service_terms
      branch_logo
    }
  }
`;

export const GetStoresByBranchId = gql`
  query GetStoresByBranchId($branchId: String!, $page: Int) {
    ReparafoneGetStoresByBranchId(
      branch_id: $branchId
      first: 100
      page: $page
      enabled: true
      selling_enabled: true
    ) {
      paginatorInfo {
        perPage
        currentPage
        total
      }
      data {
        id
        name
        addresses {
          zipCode: zip_code
          state
          city
          neighborhood
          street
          number: street_number
          complement
        }
      }
    }
  }
`;

export default GetBranchById;
