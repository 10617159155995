import React from 'react';
// MATERIAL-UI
import { CssBaseline, AppBar, Toolbar } from '@material-ui/core';
// CLSX
import clsx from 'clsx';
// COMPONENTS
import Logo from './components/Logo';
import Menu from './components/Menu';
// STYLES
import styles from './style';

const Header = () => {
  const classes = styles();

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar)}
      >
        <Toolbar>
          <Logo />
          <Menu />
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default Header;
