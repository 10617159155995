import React from 'react';
// MATERIAL UI
import {
  InputAdornment, TextField,
} from '@material-ui/core';
// ICONS
import CommentIcon from '@material-ui/icons/Comment';
// PROP-TYPES
import { propTypes, defaultProps } from './PropTypes';
// STYLES
import useStyle from './style';

function InputObservations({
  handleChange, handleBlur, errors, value,
  typeInput, disabled, name,
}) {
  const classes = useStyle();
  return (
    <TextField
      fullWidth
      className={classes.textField}
      id={name}
      name={name}
      label="Observação"
      rows={4}
      multiline
      size="small"
      value={value}
      variant={typeInput}
      color="primary"
      helperText={errors}
      error={!!errors}
      onBlur={handleBlur}
      disabled={disabled}
      onChange={handleChange}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <CommentIcon color={!disabled ? 'primary' : 'action'} />
          </InputAdornment>
        ),
      }}
    />
  );
}

InputObservations.propTypes = propTypes;
InputObservations.defaultProps = defaultProps;

export default InputObservations;
