// MATERIAL-UI
import { makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
  storesPanel: {
    background: '#ffffff',
    padding: '0 25px',
    lineHeight: 2,
    color: '#6b6b76',
    textTransform: 'uppercase',
    outline: 'none',
    paddingBottom: '20px',
    width: '65%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      maxWidth: '100%',
      padding: '0 5px',
    },
  },
  geocoder: {
    paddingBottom: 10,
  },
}));

export default styles;
