import gql from 'graphql-tag';

export const CreateLead = gql`
  mutation CreateLead(
    $channel: String!
    $firstName: String!
    $lastName: String!
    $email: String!
    $phone: String!
    $landline: String!
    $estimate: Int!
    $informedPhoneDefects: String!
    $informedPhoneProduct: Int!
    $store_id: ID!
    $user_id: ID!
    $refused_budget_obs_id: ID
  ) {
    createLeadOrderRepair(
      input: {
        channel: $channel
        firstName: $firstName
        lastName: $lastName
        email: $email
        phone: $phone
        landline: $landline 
        estimate: $estimate
        informedPhoneDefects: $informedPhoneDefects
        informedPhoneProduct: $informedPhoneProduct
        store_id: $store_id
        user_id: $user_id
        refused_budget_obs_id: $refused_budget_obs_id
      }
    ) {
      id
      channel
      firstName
      email
      phone
      landline
      estimate
      informedPhoneDefects
      informedPhoneProduct
      store_id
      user_id
    }
  }
`;

export default CreateLead;
