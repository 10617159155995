import React from 'react';
// SEMANTIC
import { List } from 'semantic-ui-react';
// MATERIAL-UI
import { Typography } from '@material-ui/core';
// REDUX
import { useSelector } from 'react-redux';
// CLSX
import clsx from 'clsx';
// STYLES
import style from './style';

const Contato = () => {
  const classes = style();
  const partnerData = useSelector((state) => state.PartnerStoreReducer);
  const { branch } = partnerData;
  const { branch_name } = branch;
  return (
    <div className={classes.infoContatos}>
      <Typography
        className={clsx(classes.title, {
          [classes.titleCarrefour]: branch_name === 'Carrefour',
          [classes.titleReparofone]: branch_name === 'Limelocker',
        })}
      >
        Informações de Contato
      </Typography>
      <List animated verticalAlign="middle">
        <List.Item
          icon="marker"
          content={(
            <a
              href="https://goo.gl/maps/HqU2y6yZf7MzB3mH7"
              rel="noreferrer"
              target="_blank"
            >
              Rua Campos Vergueiro, 256 - Espace Center, Módulo 20 - Vila
              Anastácio, São Paulo - SP. CEP 05095-020
            </a>
        )}
        />
        <List.Item
          icon="whatsapp"
          content={(
            <a href="/atendimento-wpp" rel="noreferrer" target="_blank">
              (11) 91197-3880
            </a>
        )}
        />
        <List.Item
          icon="mail"
          content={(
            <a href="mailto:atendimento.reparo@trocafone.com " rel="noreferrer" target="_blank">
              atendimento.reparo@trocafone.com
            </a>
        )}
        />
      </List>
    </div>
  );
};

export default Contato;
