import { Button, IconButton, TextField } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import React from 'react';
import { useQueryParam, StringParam } from 'use-query-params';

const Searcher = ({ fetchSearch }) => {
  const [search, setSearch] = useQueryParam('search', StringParam);
  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };
  const doFetchSearch = () => {
    fetchSearch({ variables: { page: 1, search, searchLike: `%${search}%` } });
  };
  return (
    <div>
      <TextField
        size="small"
        label="Pesquisar"
        defaultValue={search}
        onChange={handleSearchChange}
        variant="outlined"
        InputProps={{
          endAdornment: (
            <IconButton onClick={doFetchSearch}>
              <Search />
            </IconButton>
          ),
        }}
      />
    </div>
  );
};

export default Searcher;
