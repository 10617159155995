import * as P from 'prop-types';

export const propTypes = {
  disabled: P.bool,
  handleChange: P.func.isRequired,
  handleBlur: P.func.isRequired,
  handleFocus: P.func.isRequired,
  value: P.string.isRequired,
  error: P.string,
  typeInput: P.oneOf([
    'standard', 'filled', 'outlined',
  ]).isRequired,
};

export const defaultProps = {
  disabled: false,
  error: null,
};

export default {

};
