import gql from 'graphql-tag';

export const getModelColorsQuery = gql`
  query colorsByModel($modelId: String!){
    colorsByModel(modelId: $modelId){
      color
      rgbCode
    }
  }
`;

export default getModelColorsQuery;
