const mercadoPagoMessages = [{
  statusDetail: 'accredited',
  message: 'Pronto, seu pagamento foi aprovado!',
}, {
  statusDetail: 'pending_contingency',
  message: 'Estamos processando o pagamento.Não se preocupe, em menos de 2 dias úteis informaremos por e-mail se foi creditado.',
}, {
  statusDetail: 'pending_review_manual',
  message: 'Estamos processando seu pagamento.Não se preocupe, em menos de 2 dias úteis informaremos por e-mail se foi creditado ou se necessitamos de mais informação.',
}, {
  statusDetail: 'cc_rejected_bad_filled_card_number',
  message: 'Revise o número do cartão.',
}, {
  statusDetail: 'cc_rejected_bad_filled_date',
  message: 'Revise a data de vencimento.',
}, {
  statusDetail: 'cc_rejected_bad_filled_other',
  message: 'Revise os dados.',
}, {
  statusDetail: 'cc_rejected_bad_filled_security_code',
  message: 'Revise o código de segurança do cartão.',
}, {
  statusDetail: 'cc_rejected_blacklist',
  message: 'Não pudemos processar seu pagamento.',
}, {
  statusDetail: 'cc_rejected_call_for_authorize',
  message: 'Você deve autorizar à sua operadora do cartão o pagamento do valor ao Mercado Pago.',
}, {
  statusDetail: 'cc_rejected_card_disabled',
  message: 'Ligue para a operadora do cartão para ativar seu cartão. O telefone está no verso do seu cartão.',
}, {
  statusDetail: 'cc_rejected_card_error',
  message: 'Não conseguimos processar seu pagamento.',
}, {
  statusDetail: 'cc_rejected_duplicated_payment',
  message: 'Você já efetuou um pagamento com esse valor. Caso precise pagar novamente, utilize outro cartão ou outra forma de pagamento.',
}, {
  statusDetail: 'cc_rejected_high_risk',
  message: 'Seu pagamento foi recusado.Escolha outra forma de pagamento. Recomendamos meios de pagamento em dinheiro.',
}, {
  statusDetail: 'cc_rejected_insufficient_amount',
  message: 'O seu cartão possui saldo insuficiente.',
}, {
  statusDetail: 'cc_rejected_invalid_installments',
  message: 'O operador do seu cartão não processa pagamentos na quantidade de parcelas escolhida.',
}, {
  statusDetail: 'cc_rejected_max_attempts',
  message: 'Você atingiu o limite de tentativas permitido.Escolha outro cartão ou outra forma de pagamento.',
}, {
  statusDetail: 'cc_rejected_other_reason',
  message: 'O operador do seu cartão não processa o pagamento.',
}];

export default mercadoPagoMessages;