const MenuItems = [
  {
    label: 'Meus reparos',
    to: '/customer/repairs',
    icon: 'FormatListBulletedRounded',
    appearIn: ['bottom', 'main'],
  },
  {
    label: 'Novo reparo',
    to: '/customer/newrepair',
    icon: 'QueueRounded',
    appearIn: ['bottom', 'main'],
  },
  {
    label: 'Minha conta',
    to: '/customer/myprofile',
    icon: 'Person',
    appearIn: ['drawer'],
  },
  {
    label: 'Ajuda',
    icon: 'ContactSupportIcon',
    appearIn: ['drawer'],
    openPopupAssist: true,
  },
  {
    label: 'Sair',
    to: '/logout',
    icon: 'MeetingRoom',
    appearIn: ['drawer'],
  },
];

export default MenuItems;
