// MATERIAL-UI
import { makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
  name: {
    background: '#FFAA49',
    color: '#fff',
    margin: '-15px 0 0',
    padding: '10px',
    borderRadius: '3px 3px 0 0',
    fontWeight: '300',
    fontSize: '1rem',
    paddingRight: '30px',
  },
  address: {
    margin: 0,
    padding: '10px',
    fontWeight: 400,
    fontSize: '0.9rem',
  },
}));

export default styles;
