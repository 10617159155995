import client from '@/Apollo';
import { GetBranchById } from '../query/GetBranch';

export default function setBranch(id) {
  return new Promise((resolve, reject) => {
    client
      .query({
        query: GetBranchById,
        variables: { id },
      })
      .then(
        (response) => {
          resolve(response.data.ReparafoneGetBranchById);
        },
      )
      .catch((error) => {
        console.log(error.message, 'erro ao buscar');
        reject(error);
      });
  });
}
