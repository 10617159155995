import CalculateRepairCost from './CalculateRepairCost';
// GRAPHQL API
import createLeadOrder from '../graphql/schemas/CreateLead';

export default ({
  selectedDefects, product, customer, origin_store, origin_user, branch_slug,
}) => new Promise((resolve, reject) => {
  CalculateRepairCost(selectedDefects).then((repairCost) => {
    const data = {
      firstName: customer?.name ? customer?.name?.split(' ')[0] : '',
      lastName: customer?.name ? customer?.name.split(' ').splice(1).join(' ') : '',
      email: customer?.email || '',
      phone: customer?.phone || '',
      landline: customer?.phone || '',
      informedPhoneProduct: Number(product.id),
      informedPhoneDefects: selectedDefects.map((def) => def.name).join(', '),
      estimate: repairCost.budgetRepair,
      channel: branch_slug || 'reparofone online',
      store_id: origin_store.id,
      user_id: origin_user.id,
    };
    resolve(createLeadOrder({ ...data }));
  })
    .catch((error) => {
      console.log(error.message, 'erro ao calcular');
      reject(error.message);
    });
});
