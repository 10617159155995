import {
  createMuiTheme,
  responsiveFontSizes,
} from '@material-ui/core/styles';
import { ptBR } from '@material-ui/core/locale';

const Theme = createMuiTheme({
  typography: {
    fontFamily: [
      'Roboto',
    ],
  },
  palette: {
    primary: {
      light: '#2888b2',
      main: '#5146A2',
      dark: '#2E2761',
    },
    secondary: {
      main: '#F6C23A',
      dark: '#F8B402',
    },
  },
  overrides: {
    MuiPaper: {
      root: {
        backgroundColor: '#fcfcfc',
      },
    },
    MuiTypography: {
      caption: {
        fontSize: '11px',
      },
    },
    MuiButton: {
      root: {
        borderRadius: '22px',
      },
      contained: {
        paddingLeft: '25px',
        paddingRight: '25px',
        paddingTop: '11px',
        paddingBottom: '11px',
        fontWeight: 400,
        textTransform: 'inherit',
        fontSize: '13px',
        '&:disabled': {
          color: '#FFFFFF',
          backgroundColor: '#D7D7D7',
          border: '1px solid #D7D7D7',
        },
        '&:sizeSmall': {
          fontSize: '12px',
          borderRadius: '15.5px',
          paddingLeft: '15px',
          paddingRight: '15px',
          paddingTop: '7px',
          paddingBottom: '7px',
        },
        '&:sizeLarge': {
          fontSize: '15px',
          borderRadius: '25px',
          paddingLeft: '31px',
          paddingRight: '31px',
          paddingTop: '12px',
          paddingBottom: '12px',
        },
      },
      containedPrimary: {
        boxShadow: '0px 2px 1px #9696967a',
        color: '#FFFFFF',
        backgroundColor: '#5146A2',
        border: '1px solid #5146A2',
        '&:hover': {
          backgroundColor: '#2E2761',
          border: '1px solid #2E2761',
        },
      },
      containedSecondary: {
        boxShadow: '0px 2px 1px #9696967a',
        color: '#FFFFFF',
        backgroundColor: '#F6C23A',
        border: '1px solid #F6C23A',
        '&:hover': {
          backgroundColor: '#F8B402',
          border: '1px solid #F8B402',
        },
      },
      outlinedPrimary: {
        boxShadow: '0px 2px 1px #9696967a',
        color: '#5146A2',
        backgroundColor: '#FFFFFF',
        border: '1px solid #5146A2',
        '&:hover': {
          color: '#FFFFFF',
          border: '1px solid #5146A2',
          backgroundColor: '#5146A2',
        },
      },
      outlinedSecondary: {
        border: '1px solid #F6C23A',
        boxShadow: '0px 2px 1px #9696967a',
        backgroundColor: '#FFFFFF',
        color: '#F6C23A',
        '&:hover': {
          color: '#FFFFFF',
          border: '1px solid #F6C23A',
          backgroundColor: '#F6C23A',
        },
      },
      outlined: {
        paddingLeft: '23.5px',
        paddingRight: '23.5px',
        paddingTop: '12px',
        paddingBottom: '12px',
        fontFamily: 'Open Sans',
        fontWeight: 600,
        fontSize: '13px',
        textTransform: 'inherit',
        '&:disabled': {
          color: '#FFFFFF',
          backgroundColor: '#D7D7D7',
          border: '1px solid #D7D7D7',
        },
      },
      outlinedSizeSmall: {
        fontSize: '12px',
        borderRadius: '15.5px',
        paddingLeft: '13px',
        paddingRight: '13px',
        paddingTop: '7px',
        paddingBottom: '7px',
      },
      outlinedSizeLarge: {
        fontSize: '15px',
        borderRadius: '25px',
        paddingLeft: '28px',
        paddingRight: '28px',
        paddingTop: '12px',
        paddingBottom: '12px',
      },
    },
    MuiListItem: {
      button: {
        '&:hover': {
          backgroundColor: '#5C97FC',
          color: 'white',
        },
      },
    },
    MuiAutocomplete: {
      option: {
        '&[data-focus="true"]': {
          backgroundColor: '#5C97FC',
          color: 'white',
        },
      },
      tag: {
        backgroundColor: '#5C97FC',
        color: 'white',
      },
    },
    MuiChip: {
      deleteIcon: {
        color: '#3A62A8',
      },
    },
  },
},
ptBR);
// reparafoneTheme = responsiveFontSizes(reparafoneTheme);
// reparafoneTheme.overrides = {

//   MuiButton: {
//     root: {
//       borderRadius: '22px',
//     },
//     contained: {
//       paddingLeft: '25px',
//       paddingRight: '25px',
//       paddingTop: '11px',
//       paddingBottom: '11px',
//       fontWeight: 400,
//       textTransform: 'inherit',
//       fontSize: '13px',
//       '&:disabled': {
//         color: '#FFFFFF',
//         backgroundColor: '#D7D7D7',
//         border: '1px solid #D7D7D7',
//       },
//       '&:sizeSmall': {
//         fontSize: '12px',
//         borderRadius: '15.5px',
//         paddingLeft: '15px',
//         paddingRight: '15px',
//         paddingTop: '7px',
//         paddingBottom: '7px',
//       },
//       '&:sizeLarge': {
//         fontSize: '15px',
//         borderRadius: '25px',
//         paddingLeft: '31px',
//         paddingRight: '31px',
//         paddingTop: '12px',
//         paddingBottom: '12px',
//       },
//     },
//     containedPrimary: {
//       boxShadow: '0px 2px 1px #9696967a',
//       color: '#FFFFFF',
//       backgroundColor: '#5146A2',
//       border: '1px solid #5146A2',
//       '&:hover': {
//         backgroundColor: '#2E2761',
//         border: '1px solid #2E2761',
//       },
//     },
//     containedSecondary: {
//       boxShadow: '0px 2px 1px #9696967a',
//       color: '#FFFFFF',
//       backgroundColor: '#F6C23A!important',
//       border: '1px solid #F6C23A',
//       '&:hover': {
//         backgroundColor: '#F8B402',
//         border: '1px solid #F8B402',
//       },
//     },
//     outlinedPrimary: {
//       boxShadow: '0px 2px 1px #9696967a',
//       color: '#5146A2',
//       backgroundColor: '#FFFFFF',
//       border: '1px solid #5146A2',
//       '&:hover': {
//         color: '#FFFFFF',
//         border: '1px solid #5146A2',
//         backgroundColor: '#5146A2',
//       },
//     },
//     outlinedSecondary: {
//       border: '1px solid #F6C23A',
//       boxShadow: '0px 2px 1px #9696967a',
//       backgroundColor: '#FFFFFF',
//       color: '#F6C23A',
//       '&:hover': {
//         color: '#FFFFFF',
//         border: '1px solid #F6C23A',
//         backgroundColor: '#F6C23A!important',
//       },
//     },
//     outlined: {
//       paddingLeft: '23.5px',
//       paddingRight: '23.5px',
//       paddingTop: '12px',
//       paddingBottom: '12px',
//       fontFamily: 'Open Sans',
//       fontWeight: 600,
//       fontSize: '13px',
//       textTransform: 'inherit',
//       '&:disabled': {
//         color: '#FFFFFF',
//         backgroundColor: '#D7D7D7',
//         border: '1px solid #D7D7D7',
//       },
//     },
//     outlinedSizeSmall: {
//       fontSize: '12px',
//       borderRadius: '15.5px',
//       paddingLeft: '13px',
//       paddingRight: '13px',
//       paddingTop: '7px',
//       paddingBottom: '7px',
//     },
//     outlinedSizeLarge: {
//       fontSize: '15px',
//       borderRadius: '25px',
//       paddingLeft: '28px',
//       paddingRight: '28px',
//       paddingTop: '12px',
//       paddingBottom: '12px',
//     },
//   },
//   MuiListItem: {
//     button: {
//       '&:hover': {
//         backgroundColor: '#5C97FC',
//         color: 'white',
//       },
//     },
//   },
//   MuiAutocomplete: {
//     option: {
//       '&[data-focus="true"]': {
//         backgroundColor: '#5C97FC',
//         color: 'white',
//       },
//     },
//     tag: {
//       backgroundColor: '#5C97FC',
//       color: 'white',
//     },
//   },
//   MuiChip: {
//     deleteIcon: {
//       color: '#3A62A8',
//     },
//   },
// };
export default responsiveFontSizes(Theme);
