import * as P from 'prop-types';
import NewOrderReducerPropTypes from '@redux/reducers/NewOrderReducer/PropTypes';

export const propTypes = {
  formik: P.object.isRequired,
  setInputFocus: P.func.isRequired,
  $state: P.shape({
    transactionAmount: NewOrderReducerPropTypes.transactionAmount.isRequired,
  }),
};

export const defaultProps = {};

export default { propTypes, defaultProps };
