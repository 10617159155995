import gql from 'graphql-tag';

export const GetPartnerUser = gql`
  {
    ReparafonePartnerUser {
      id
      name
      enabled
    }
  }
`;

export default GetPartnerUser;
