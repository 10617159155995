import React from 'react';
import {
  Route,
  Redirect,
} from 'react-router-dom';
import { connect } from 'react-redux';
import { useLazyQuery } from '@apollo/client';
import { setEmployee, setBranch } from '@redux/actions/PartnerStoreActions';
import { bindActionCreators } from 'redux';
import setBranchData from '../../pages/neworderpartner/graphql/schemas/SetBranch';
import { GetPartnerInformation } from '../../utils/Partner';
import { setAuthenticated } from '../../redux/actions/auth/AuthActions';

function LoggedInRoutePartner({ children, $actions, ...rest }) {
  const [islocalStorage, setLocalStorage] = React.useState(true);
  const { dispatch, isAuthenticated } = rest;
  const [getUser, { data, error }] = useLazyQuery(GetPartnerInformation);
  React.useEffect(() => {
    if (localStorage.branchData && islocalStorage) {
      const branch = JSON.parse(localStorage.branchData);
      $actions.setBranch(branch);
      return setLocalStorage(false);
    }
  }, [islocalStorage]);
  React.useEffect(() => {
    if (isAuthenticated) {
      getUser({
        errorPolicy: 'all',
      });
    }
  }, [isAuthenticated]);
  React.useEffect(() => {
    if (error) dispatch(setAuthenticated(false));
  }, [error]);

  React.useEffect(() => {
    if (data) {
      $actions.setEmployee(data.MeTradeInUser);
      if (!localStorage.branchData) {
        setBranchData(data.MeTradeInUser.branch_id)
          .then((r) => {
            $actions.setBranch(r);
            const branchData = JSON.stringify(r);
            localStorage.setItem('branchData', branchData);
          })
          .catch((e) => {
            console.log(e.message);
          });
      }
    }
  }, [data]);

  return (
    <Route
      {...rest}
      render={
        ({ location }) => (isAuthenticated ? (
          <>
            {children}
          </>
        ) : (<Redirect to={{ pathname: '/partners/login', state: { from: location } }} />))
}
    />
  );
}
function mapStateToProps(state) {
  const { AuthReducer } = state;
  const { isAuthenticated, user } = AuthReducer;
  return {
    isAuthenticated,
    user,
  };
}

const mapDispatchToProps = (dispatch) => ({
  $actions: bindActionCreators({ setEmployee, setBranch }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoggedInRoutePartner);
