import React from 'react';
import * as P from 'prop-types';
import Typography from '@material-ui/core/Typography';

function Title({ hasError, title }) {
  return (
    <Typography
      variant="body2"
      component="div"
      color={hasError ? 'error' : 'textPrimary'}
    >
      { `${hasError ? '* ' : ''}${title}` }
    </Typography>
  );
}

Title.propTypes = {
  hasError: P.bool.isRequired,
  title: P.string.isRequired,
};

export default React.memo(Title);
