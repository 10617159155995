import client from '@/Apollo';
import { GetEmployeeByBranchWithStoreByBranch } from '../query/GetUserAndStore';

const insertUserAndStore = (branch_id) => new Promise((resolve, reject) => {
  client
    .query({
      query: GetEmployeeByBranchWithStoreByBranch,
      variables: { branch_id },
    })
    .then((response) => resolve(response.data))
    .catch((e) => {
      console.log(e.message, 'erro ao inserir user online');
      reject(e.message);
    });
});

export default insertUserAndStore;
