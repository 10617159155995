import React from 'react';
import { InputAdornment, TextField } from '@material-ui/core';
import BusinessIcon from '@material-ui/icons/Business';
import { propTypes, defaultProps } from './PropTypes';
import useStyle from './style';

function InputCompany({
  handleChange,
  handleBlur,
  errors,
  value,
  typeInput,
  disabled,
}) {
  const classes = useStyle();
  return (
    <TextField
      value={value}
      onChange={(event, valuePhone) => {
        if (event) {
          handleChange('empresa', event.target.value);
        }
      }}
      onBlur={handleBlur}
      disabled={disabled}
      className={classes.textField}
      id="empresa"
      name="empresa"
      label="Empresa"
      size="small"
      variant={typeInput}
      color="primary"
      helperText={errors}
      error={!!errors}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <BusinessIcon color={!disabled ? 'primary' : 'action'} />
          </InputAdornment>
        ),
      }}
    />
  );
}

InputCompany.propTypes = propTypes;
InputCompany.defaultProps = defaultProps;

export default InputCompany;
