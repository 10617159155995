import gql from 'graphql-tag';

const GetDefect = gql`
  query getDefectByName($defect_name: String!) {
    getDefectByName(defect_name: $defect_name) {
      id
      defect_name
      defect_description
      lay_name
    }
  }
`;

export default GetDefect;
