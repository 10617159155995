/* eslint-disable camelcase */
import * as React from 'react';
// MATERIAL-UI
import {
  Typography,
  Collapse,
  CircularProgress,
  Box,
  Grid,
  Divider,
  Button,
} from '@material-ui/core';
// REDUX
import { useSelector, useDispatch } from 'react-redux';
import {
  setStores,
  setCurrentStore,
  resetAllStoresData,
} from '@/redux/actions/MapBoxActions';
// REACT-WINDOW
import { FixedSizeList } from 'react-window';
// LODASH
import { isEmpty } from 'lodash';
// PROP-TYPES
import PropTypes from './PropTypes';
// GRAPHQL
import getStores from '../../../../../graphql/schemas/InsertStores';
// STYLES
import styles from './styles';

function StoreInfo({ onSelectStore, setPopupInfo }) {
  const classes = styles();
  const dispatch = useDispatch();
  const mapBoxData = useSelector((state) => state.MapBoxReducer);
  const { stores, branches } = mapBoxData;
  const [loadingStores, setLoadingStores] = React.useState(false);
  const selectStore = (store) => {
    dispatch(setCurrentStore(store));
    setPopupInfo(store);
    onSelectStore(store.addresses[0]);
  };
  const filterByAppearBranches = (array) => {
    const arrayFilter = branches.map((branch) => array.filter((store) => {
      if (store.branch_id === branch.id) return true;
      return false;
    }));
    const filterAr = [];
    arrayFilter.map((storeArray) => filterAr.push(...storeArray.values()));
    return filterAr;
  };
  const loadAllStoresData = () => {
    setLoadingStores(true);
    getStores()
      .then((r) => {
        const appearArray = filterByAppearBranches(r);
        dispatch(setStores(appearArray));
      })
      .catch(({ response }) => console.log(response))
      .finally(() => setLoadingStores(false));
  };

  React.useEffect(() => {
    if (!isEmpty(branches)) {
      dispatch(resetAllStoresData());
      return loadAllStoresData();
    }
  }, [branches, dispatch]);
  return (
    <>
      <Collapse
        className={classes.root}
        in={stores.length > 0 && !loadingStores}
        unmountOnExit
      >
        <h3 className={classes.header}>Escolha uma Loja</h3>
        <Box py={2} minWidth="100%">
          <FixedSizeList
            itemData={stores}
            height={400}
            itemSize={90}
            itemCount={stores.length || 0}
            className={classes.fixedSizeList}
          >
            {({ data, index, style: renderStyle }) => (
              <Grid style={renderStyle} className={classes.gridGroup}>
                <Typography
                  key={data[index].id}
                  component="p"
                  variant="body2"
                  color="textPrimary"
                  className={classes.storeName}
                >
                  <Button
                    variant="text"
                    onClick={() => selectStore(data[index])}
                    className={classes.storeNameButton}
                  >
                    {data[index].name}
                  </Button>
                </Typography>
                <Typography
                  key={data[index].id + 1}
                  component="p"
                  variant="body2"
                  color="textPrimary"
                  className={classes.addressName}
                >
                  {`${data[index].addresses[0]?.street}, ${data[index].addresses[0]?.number} - ${data[index].addresses[0]?.neighborhood}`}
                </Typography>
                { data[index].addresses.distance && (
                  <Typography
                    key={data[index].id + 2}
                    component="span"
                    variant="body2"
                    color="textPrimary"
                    className={classes.kilometers}
                  >
                    {`${data[index].addresses.distance} km de distância`}
                  </Typography>
                )}
                <Divider />
              </Grid>
            )}
          </FixedSizeList>
        </Box>
      </Collapse>
      <Collapse
        className={classes.loadingStores}
        in={loadingStores}
        unmountOnExit
      >
        <CircularProgress color="inherit" size={30} />
      </Collapse>
    </>
  );
}

export default React.memo(StoreInfo);

StoreInfo.propTypes = PropTypes.propTypes;
StoreInfo.defaultProps = PropTypes.defaultProps;
