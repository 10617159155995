import makeStyle from '@material-ui/core/styles/makeStyles';

export default makeStyle(({ spacing, breakpoints }) => ({
  backgroundForm: {
    backgroundImage: 'url(//theme.zdassets.com/theme_assets/9251552/e26e16acf092dce541a9f56d8f7d89f6a5ad8c39.png)',
    padding: `${spacing(15)}px ${spacing(6)}px ${spacing(6)}px`,
    display: 'flex',
    justifyContent: 'center',
    [breakpoints.down('xs')]: {
      padding: `${spacing(15)}px 0 ${spacing(6)}px`,
    },
    '& .form': {
      padding: '30px 30px 60px',
      display: 'flex',
      flexDirection: 'column',
      border: '1px solid #ccc',
      borderRadius: '0px',
      minHeight: '600px',
      minWidth: `${breakpoints.values.sm}px`,
      [breakpoints.down('xs')]: {
        minWidth: 'unset',
        width: '100%',
        padding: `${spacing(2.5)}px ${spacing(0.5)}px`,
      },
    },
  },
  topContent: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    position: 'relative',
    marginBottom: '2vh',
  },
  clearButton: {
    position: 'absolute',
    left: 0,
  },
  partnerInfo: {
    '& strong': {
      fontWeight: 500,
    },
  },
}));
