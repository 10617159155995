import React from 'react';
import {
  Grid,
  Container,
  Typography,
  useTheme,
  useMediaQuery,
  Card,
} from '@material-ui/core';
import { parseISO, format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { useQuery } from '@apollo/client';
import { range } from 'lodash';
import { Link } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroller';
import { Skeleton } from '@material-ui/lab';
import getMyRepairOrders from '../../../utils/GraphQL';

const header = [
  'Data de Criação',
  'Número OS',
  'Nome do aparelho',
  'Status do reparo',
];
const Panel = (props) => {
  const theme = useTheme();
  const mediaQuery = useMediaQuery(theme.breakpoints.down('sm'));
  const { loading, data, fetchMore } = useQuery(getMyRepairOrders, {
    variables: {
      cursorPath: 'orders',
    },
    notifyOnNetworkStatusChange: true,
  });

  if (!data) return <SkeletonOrder />;
  const { orders } = data.MeCustomer;
  const customer = data.MeCustomer;
  const statuses = data.getRepairOrderStatuses;
  const loadMoreOrder = loading || !orders.pageInfo.hasNextPage
    ? () => {}
    : () => fetchMore({
      variables: {
        cursor: orders.pageInfo.endCursor,
      },
    });
  return (
    <Container style={{ padding: '2rem 0' }}>
      {mediaQuery ? (
        ''
      ) : (
        <Grid container direction="row" style={{ padding: '0 1rem' }}>
          {header.map((head) => (
            <Grid item xs key={head}>
              <Typography variant="subtitle2">{head}</Typography>
            </Grid>
          ))}
        </Grid>
      )}
      <div style={{ padding: '1rem' }}>
        <InfiniteScroll
          pageStart={0}
          hasMore={orders.edges.length < orders.pageInfo.total}
          loader={(
            <SkeletonOrder
              key={1}
              useSuffixInKey="iS"
              length={orders.pageInfo.total - orders.edges.length}
            />
          )}
          loadMore={loadMoreOrder}
          threshold={(orders.pageInfo.total - orders.edges.length) * 50}
        >
          <div>
            {orders.edges.map(({ node: item }, index) => (
              <OrderCard
                key={index}
                item={item}
                customer={customer}
                statuses={statuses}
                mediaQuery={mediaQuery}
              />
            ))}
          </div>
        </InfiniteScroll>
      </div>
    </Container>
  );
};
const SkeletonOrder = ({ length = 10, useSuffixInKey = '' }) => (
  <Container style={{ padding: '1rem' }}>
    {range(1, length).map((key) => (
      <OrderCard key={useSuffixInKey + key} />
    ))}
  </Container>
);
const OrderCard = ({ item, statuses, mediaQuery, customer }) => (item ? (
  <Card
    variant="outlined"
    style={{
      padding: '1rem',
      background: '#f5f5f5',
      borderRadius: '1rem',
      marginBottom: '1rem',
    }}
  >
    {mediaQuery ? (
      <MobileGrid item={item} statuses={statuses} customer={customer} />
    ) : (
      <DesktopGrid item={item} statuses={statuses} customer={customer} />
    )}
  </Card>
) : (
  <Skeleton height={40} />
));

const DesktopGrid = ({ item, statuses, customer }) => {
  return (
    <>
      <Grid container direction="row" alignItems="center">
        <Link
          to={`repairs/${item && item.id}`}
          style={{
            textDecoration: 'inherit',
            color: 'inherit',
            width: '80%',
            display: 'contents',
          }}
        >
          <Grid item xs>
            <Typography variant="body1">
              {item
                && format(parseISO(item.order_created_at), 'dd MMMM yyyy', {
                  locale: ptBR,
                })}
            </Typography>
          </Grid>
          <Grid item xs>
            <Typography variant="body1">{item && item.id}</Typography>
          </Grid>
          <Grid item xs>
            <Typography variant="body1" style={{ paddingRight: '1rem' }}>
              {item && item.product.product_name}
            </Typography>
          </Grid>
          <Grid item xs>
            <Typography variant="body1">
              {item && item.status.lay_name}
            </Typography>
          </Grid>
        </Link>
      </Grid>
    </>
  );
};

const MobileGrid = ({ item, statuses, customer}) => (
  <>
    <Grid container direction="column" spacing={3}>
      <Link
        to={`repairs/${item && item.id}`}
        style={{ textDecoration: 'inherit', color: 'inherit' }}
      >
        <Grid item style={{ padding: '12px' }}>
          <RowAndHead rowData={item.id} headLabel="Número da OS" />
        </Grid>
        <Grid item style={{ padding: '12px' }}>
          <RowAndHead
            rowData={format(parseISO(item.order_created_at), 'dd MMMM yyyy', {
              locale: ptBR,
            })}
            headLabel="Data de Criação"
          />
        </Grid>
        <Grid item style={{ padding: '12px' }}>
          <RowAndHead
            rowData={item.product.product_name}
            headLabel="Nome do aparelho"
          />
        </Grid>
        <Grid item style={{ padding: '12px' }}>
          <RowAndHead
            rowData={item.status.lay_name}
            headLabel="Status do reparo"
          />
        </Grid>
      </Link>
      {/* <Grid item>
        <ExpansionOrderPanel order={item} statuses={statuses} isMobile />
      </Grid> */}
    </Grid>
  </>
);

const RowAndHead = ({ rowData, headLabel }) => (
  <>
    <Grid container direction="column">
      <Grid item>
        <Typography variant="subtitle2">{headLabel}</Typography>
      </Grid>
      <Grid item>
        {!React.isValidElement(rowData) ? (
          <Typography variant="body1">{rowData}</Typography>
        ) : (
          rowData
        )}
      </Grid>
    </Grid>
  </>
);

export default Panel;
