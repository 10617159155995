import React from 'react';
import PropTypes from 'prop-types';
import {
  IconButton,
  Table, TableBody, TableCell, TableFooter, TableHead, TablePagination, TableRow, useMediaQuery, useTheme,
} from '@material-ui/core';
import {
  FirstPage, KeyboardArrowLeft, KeyboardArrowRight, LastPage,
} from '@material-ui/icons';
import OrderLine from './OrderLine';

function TablePaginationActions(props) {
  const theme = useTheme();
  const {
    count, page, rowsPerPage, onChangePage,
  } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 1);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 2);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage)));
  };

  return (
    <div style={{ display: 'flex' }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="primeira página"
      >
        {theme.direction === 'rtl' ? <LastPage /> : <FirstPage />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="página anterior">
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="próxima página"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="última página"
      >
        {theme.direction === 'rtl' ? <FirstPage /> : <LastPage />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};
const tableHeader = [
  { dataKey: 'id', label: 'Ordem Nº' },
  { dataKey: 'customer.name', label: 'Cliente' },
  { dataKey: 'product.name', label: 'Produto' },
  { dataKey: 'status.name', label: 'Status' },
  { dataKey: 'informedDefects', label: 'Defeitos informados' },
  { dataKey: 'repairedDefects', label: 'Defeitos reparados' },
  { dataKey: 'createdAt', label: 'Criado em' },
  { dataKey: 'lastMovement.date', label: 'Atualizado em' },
];
const DesktopWrapper = ({
  page, count, rowsPerPage, handlePageChange, handleChangeRowsPerPage, children,
}) => (
  <div>
    <Table>
      <TableHead>
        <TableRow>
          {tableHeader.map((head) => <TableCell key={head.dataKey}>{head.label}</TableCell>)}
        </TableRow>
      </TableHead>
      <TableBody>
        {children}
      </TableBody>
      <TableFooter>
        <TablePagination
          rowsPerPage={10}
          rowsPerPageOptions={[10]}
          count={count}
          page={page - 1}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        />
      </TableFooter>
    </Table>
  </div>
);
const MobileWrapper = ({
  page, count, rowsPerPage, handlePageChange, handleChangeRowsPerPage, children,
}) => (
  <div style={{ paddingTop: '1rem', paddingBottom: '1rem' }}>
    {children}
    <TablePagination
      rowsPerPage={10}
      rowsPerPageOptions={[10]}
      count={count}
      page={page - 1}
      onChangePage={handlePageChange}
      onChangeRowsPerPage={handleChangeRowsPerPage}
      ActionsComponent={TablePaginationActions}
    />
  </div>
);

const ListOrders = ({
  OrderLineComponent, orders, paginatorInfo, isLoading, fetchMore,
  handlePageChange, page, limit,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  if (isLoading || !orders) return null;

  return (
    <>
      {React.createElement(isMobile
        ? MobileWrapper : DesktopWrapper, {
        count: limit,
        page,
        handlePageChange,
      },
      orders.map(
        (order) => (<OrderLineComponent key={order.id} orderData={order} />),
      ))}
    </>
  );
};

ListOrders.propTypes = {
  OrderLineComponent: PropTypes.elementType,
};
ListOrders.defaultProps = {
  OrderLineComponent: OrderLine,
};
export default ListOrders;
