import { uniqBy } from 'lodash';

const CalculateRepairCost = async (selectedDefects) => {
  const usedParts = uniqBy(selectedDefects.map((item) => item.parts).flat(), 'part_type');
  const budgetRepair = usedParts.reduce(
    (value, part) => (value + (part?.part_price_selling ?? 0)),
    0,
  );

  return {
    budgetRepair, taxDelivery: 0, taxCollect: 0,
  };
};

export default CalculateRepairCost;
