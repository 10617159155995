import { gql } from '@apollo/client';

const changePasswordMutation = gql`
  mutation ($email:String, $newPassword:String!, $token:String){
    PartnerChangePassword(input: { email:$email, newPassword:$newPassword, token:$token }){
      id
      password_changed
    }
  }
`;

export default changePasswordMutation;
