import React from 'react';
import { InputAdornment, TextField } from '@material-ui/core';
import EmailIcon from '@material-ui/icons/Email';
import { propTypes, defaultProps } from './PropTypes';
import useStyle from './style';

function InputEmail({
  handleChange,
  handleBlur,
  errors,
  value,
  typeInput,
  disabled,
}) {
  const classes = useStyle();
  return (
    <TextField
      value={value}
      onChange={(event, valuePhone) => {
        if (event) {
          handleChange('email', event.target.value);
        }
      }}
      onBlur={handleBlur}
      disabled={disabled}
      fullWidth
      className={classes.textField}
      id="email"
      name="email"
      label="Email"
      size="small"
      variant={typeInput}
      color="primary"
      helperText={errors}
      error={!!errors}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <EmailIcon color={!disabled ? 'primary' : 'action'} />
          </InputAdornment>
        ),
      }}
    />
  );
}

InputEmail.propTypes = propTypes;
InputEmail.defaultProps = defaultProps;

export default InputEmail;
