import * as P from 'prop-types';
import { DefectsProps } from '@redux/reducers/NewRepairReducer/PropTypes';

const propTypes = {
  defects: P.arrayOf(P.shape(DefectsProps)).isRequired,
  selectedDefects: P.arrayOf(P.shape(DefectsProps)).isRequired,
  handleSelect: P.func.isRequired,
};

export default {
  propTypes,
  defaultProps: {},
};
