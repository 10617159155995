import { makeStyles } from '@material-ui/core/';

const styles = makeStyles(({ spacing, breakpoints }) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '30px',
  },
  fade: {
    transitionDelay: '1000ms',
  },
}));

export default styles;
