import initialState from './initialState';

const PREFIX = 'MAP_BOX';

export const MapBoxTypes = {
  SET_STORES: `${PREFIX}.SET_STORES`,
  SET_CURRENT_STORE: `${PREFIX}.SET_CURRENT_STORE`,
  SET_BRANCHES: `${PREFIX}.SET_BRANCHES`,
  SET_BRANCH: `${PREFIX}.SET_BRANCH`,
  RESET_ALL_STORES: `${PREFIX}.RESET_ALL_STORES`,
};

export default function MapBoxReducer(state = initialState, action) {
  const { type, payload } = action;
  const finalState = (() => {
    switch (type) {
      case MapBoxTypes.SET_CURRENT_STORE:
        return {
          ...state,
          store: { ...state.store, ...payload },
        };
      case MapBoxTypes.SET_STORES:
        return {
          ...state,
          stores: [...state.stores, ...payload],
        };
      case MapBoxTypes.SET_BRANCHES:
        return {
          ...state,
          branches: [...state.branches, ...payload],
        };
      case MapBoxTypes.SET_BRANCH:
        return {
          ...state,
          branch: { ...state.branch, ...payload },
        };
      case MapBoxTypes.RESET_ALL_STORES:
        return {
          ...state,
          stores: [],
        };
      default:
        return { ...state };
    }
  })();
  return finalState;
}
