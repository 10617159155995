import * as Yup from 'yup';

export default {
  // zipCode: Yup.string().matches(/\d{5}-\d{3}/).required('Este campo é obrigatório'),
  zipCode: Yup.string().required('Este campo é obrigatório'),
  state: Yup.string().required('Este campo é obrigatório'),
  city: Yup.string().required('Este campo é obrigatório'),
  neighborhood: Yup.string().required('Este campo é obrigatório'),
  street: Yup.string().required('Este campo é obrigatório'),
  number: Yup.string().required('Este campo é obrigatório'),
  complement: null,
};
