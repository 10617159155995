// DATE FORMATTERS
import { format, parse } from 'date-fns';

export const dateFormatter = (value) => {
  if (!value) return null;
  const parsedDate = parse(value, 'yyyy-MM-dd HH:mm:ss', new Date());
  const formattedDate = format(parsedDate, 'dd/MM/yyyy');
  return formattedDate;
};

export default dateFormatter;
