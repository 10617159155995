import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import {
  relayStylePagination,
} from '@apollo/client/utilities';

if (window.location.pathname.includes('payment')) {
  const urlParams = new URLSearchParams(window.location.search);
  const urlToken = urlParams.get('token')
    ? `Bearer ${urlParams.get('token')}`
    : '';
  if (urlParams.get('token')) {
    localStorage.setItem('apiToken', urlToken);
  }
}
const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('apiToken');
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      Authorization: token,
    },
  };
});

const link = createHttpLink({
  uri: `${process.env.REACT_APP_BASE_URL}/run/graphql`,
});
export const client = new ApolloClient({
  cache: new InMemoryCache({
    typePolicies: {
      ReparafoneCustomer: {
        fields: {
          orders: relayStylePagination(),
        },
      },
      Query: {
        fields: {
          getReparafoneDefect: relayStylePagination(),
          ReparofonePartnerOrders: {
            keyArgs: ['page'],
            merge(
              existing = {},
              { paginatorInfo, data },
              { args: { first, page } }
            ) {
              const merged = existing?.data ? existing.data.slice(0) : [];
              const firstOffset = first * (page - 1);
              for (let i = 0; i < data.length; ++i) {
                merged[firstOffset + i] = data[i];
              }
              const result = { ...existing, paginatorInfo, data: merged };
              return result;
            },
            read(existing, args) {
              if (!existing) return existing;
              const {
                variables: { first, page, limit },
              } = args;
              const data = existing?.data || [];
              const result = {
                ...existing,
                data: data.slice(first * (page - 1), limit),
              };
              return result;
            },
          },
        },
      },
    },
  }),
  link: authLink.concat(link),
});

export default client;
