import { connect } from 'react-redux';

const mapStateToProps = (state) => {
  const { NewOrderReducer } = state;
  return {
    $state: {
      activeStep: NewOrderReducer.activeStep,
    },
  };
};

export default connect(mapStateToProps);
