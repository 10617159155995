import React from 'react';
// MATERIAL-UI
import {
  Button, Dialog, DialogActions, DialogContent, DialogTitle,
} from '@material-ui/core';
// COMPONENTS
import PartnerTOS from '@/pages/termsOfService/PartnerTOS';
// REDUX
import { useSelector } from 'react-redux';
// PROP-TYPES
import PropTypes from './PropTypes';

export default function DialogTerms({ open, close }) {
  const handleClose = () => close(false);
  const partnerData = useSelector((state) => state.PartnerStoreReducer);
  const { branch } = partnerData;
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        Termos de Serviço
      </DialogTitle>
      <DialogContent>
        <PartnerTOS terms={branch.service_terms} />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Entendido!
        </Button>
      </DialogActions>
    </Dialog>
  );
}

DialogTerms.propTypes = PropTypes.propTypes;
DialogTerms.defaultProps = PropTypes.defaultProps;
