import React from 'react';
import {
  Button,
  Grid,
  CircularProgress,
  Tooltip,
  Zoom,
  useTheme,
  useMediaQuery,
  Paper,
  Collapse,
} from '@material-ui/core';
import { get, defaultTo } from 'lodash';
import { isValid, parseISO, format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import OrderInfo from '../OrderInfo';
import { BrazillianCurrency } from '../../../../../utils/NationalCurrency';
import { useStyles } from './style';
import getMovementsAndStatus from '../../../graphql/schema/GetStatusAndMovements';
import createMovimentationsAndStatusOrder from '../../../graphql/schema/CreateMovimentationsAndStatusOrder';
import createStatusFailureOrder from '../../../graphql/schema/CreateStatusFailure';
import getCustomerInfo from '../../../graphql/schema/GetCustomerInfo';
import getOrderCoupon from '../../../graphql/schema/GetCouponByOrder';
import getCoupon from '../../../graphql/schema/FindCouponById';
import AlertCancel from '../../AlertCancel';
import Dialogo from '../../Dialogo';

const BasicInfo = ({ order, loading }) => {
  const theme = useTheme();
  const mediaQuerySM = useMediaQuery(theme.breakpoints.up('sm'));
  const classes = useStyles();
  const [failureNote, setFailureNote] = React.useState(null);
  const defects = order
    ? JSON.parse(
      get(
        order.meta.find((m) => m.meta_key === 'informed_phone_defects'),
        'meta_value',
        '["Não encontrado"]',
      ),
    )
    : [];
  const [isLoading, setLoading] = React.useState(false);
  const [isCoupon, setCoupon] = React.useState(false);
  const [couponData, setCouponData] = React.useState({});
  const [valid, setIsValid] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [customerId, setCustomerId] = React.useState([]);
  const [failureChange, setFailureChange] = React.useState(false);
  const getCustomerInfoData = () => {
    getCustomerInfo()
      .then((r) => {
        setCustomerId(r.id);
      })
      .catch((e) => setIsValid(false));
  };
  const getCouponData = (couponId) => {
    getCoupon({ id: couponId })
      .then((r) => setCouponData(r))
      .catch((e) => console.log(e));
  };
  const getOrderCouponData = (orderId) => {
    getOrderCoupon({ order_id: orderId })
      .then((r) => {
        if (r) {
          setCoupon(true);
          getCouponData(r.coupon_id);
        }
      })
      .catch((e) => console.log(e));
  };
  const handleClickOpen = () => {
    setOpen(true);
    getCustomerInfoData();
  };
  const handleClose = () => {
    setOpen(false);
    setFailureChange(false);
  };
  const createStatusFailureData = (data) => {
    createStatusFailureOrder(data)
      .then(() => {
        setIsValid(true);
      })
      .catch((e) => {
        setIsValid(false);
      })
      .finally(() => setLoading(false));
  };
  const createMovimentationsAndStatusOrderData = (dataCreate) => {
    createMovimentationsAndStatusOrder(dataCreate)
      .then((r) => {
        createStatusFailureData({
          failureOrderId: order.id,
          failureMovementId: r.createMovimentationsOrderRepair.id,
          failureReason: 'Cliente cancelou através do site',
          failureNote,
        });
      })
      .catch((e) => {
        setIsValid(false);
        setLoading(false);
      });
  };
  const getStatusAndMovementsData = (allData) => {
    getMovementsAndStatus(allData)
      .then((r) => {
        const lastIndex = r.getReparofoneMovimentations.length - 1;
        createMovimentationsAndStatusOrderData({
          id: order.id,
          statusOrderId: r.getReparofoneOrderStatus.id,
          orderId: order.id,
          statusId: r.getReparofoneOrderStatus.id,
          previousStatusId:
            r.getReparofoneMovimentations[lastIndex].newStatus.id,
          userId: 0,
        });
      })
      .catch((e) => {
        setIsValid(false);
        setLoading(false);
      });
  };
  const handleCancelOrder = () => {
    const data = { statusName: 'Cancelado', orderId: order.id };
    if (failureNote) {
      setFailureChange(false);
      handleClose();
      setLoading(true);
      return getStatusAndMovementsData(data);
    }
    return setFailureChange(true);
  };
  React.useEffect(() => {
    if (order) getOrderCouponData(order.id);
  }, [order]);
  return (
    <>
      <Dialogo
        cancelOrder={handleCancelOrder}
        open={open}
        order={order}
        setFailureNote={setFailureNote}
        clickClose={handleClose}
        setFailureChange={setFailureChange}
        failureChange={failureChange}
      />
      <AlertCancel valid={valid} setIsValid={setIsValid} />
      <Paper className={classes.paper}>
        <Grid item container direction="column" spacing={3}>
          {order && !order.status.OOW_sequence && (
            <Grid item container direction="row">
              <Grid item xs={12}>
                <OrderInfo label="Status atual" value={order.status.lay_name} />
              </Grid>
            </Grid>
          )}
          {
            <Grid item container direction="row">
              <Grid item xs={12}>
                {order
                  && defaultTo(order?.meta, []).find(
                    (m) => m.meta_key === 'trackingCodeOutbound',
                  ) && (
                    <OrderInfo
                      isLoading={loading}
                      label="Código de rastreio(Envio)"
                      value={get(
                        defaultTo(order?.meta, []).find(
                          (m) => m.meta_key === 'trackingCodeOutbound',
                        ),
                        'meta_value',
                        'Não está disponível',
                      )}
                    />
                )}
              </Grid>
            </Grid>
          }
          {order?.collect.need_schedule && (
            <Grid item container direction="row">
              <Grid item xs={12}>
                <OrderInfo
                  isLoading={loading}
                  label="Data agendada para coleta"
                  value={
                    (isValid(
                      parseISO(
                        get(
                          order?.collectSchedule.slice(-1).shift(),
                          'scheduled_date',
                        ),
                      ),
                    )
                      && format(
                        parseISO(
                          get(
                            order.collectSchedule.slice(-1).shift(),
                            'scheduled_date',
                          ),
                        ),
                        "dd 'de' MMMM 'de' yyyy",
                        { locale: ptBR },
                      ))
                    || 'Sem data'
                  }
                />
              </Grid>
            </Grid>
          )}
          <Grid item container direction="row">
            <Grid item xs={12}>
              <OrderInfo
                isLoading={loading}
                label="Defeitos informados"
                value={`${defects.slice(0, -1).join(', ')} ${
                  defects.length > 1 ? 'e' : ''
                } ${defects.slice(-1)}`}
              />
            </Grid>
          </Grid>
          <Grid item container direction="row">
            <Grid item xs={12}>
              <OrderInfo
                isLoading={loading}
                label="Tipo de Coleta"
                value={`${order?.collect.collect_name} - ${BrazillianCurrency(
                  defaultTo(
                    get(
                      defaultTo(order?.meta, []).find(
                        (m) => m.meta_key === 'collect_price',
                      ),
                      'meta_value',
                    ),
                    0,
                  ) / 100,
                ).format()}`}
              />
            </Grid>
          </Grid>
          <Grid item container direction="row">
            <Grid item xs={12}>
              <OrderInfo
                isLoading={loading}
                label="Imei informado"
                value={order?.order_imei || 'Não informado'}
              />
            </Grid>
          </Grid>
          <Grid item container direction="row">
            <Grid item xs={12}>
              <OrderInfo
                isLoading={loading}
                label="Produto informado"
                value={order?.informed_product_name || 'Não informado'}
              />
            </Grid>
          </Grid>
          <Grid item container direction="row">
            <Grid item xs={12}>
              <OrderInfo
                isLoading={loading}
                label="Orçamento Inicial"
                value={BrazillianCurrency(
                  defaultTo(order?.order_pre_price, 0) / 100,
                )
                  .add(
                    defaultTo(
                      get(
                        defaultTo(order?.meta, []).find(
                          (m) => m.meta_key === 'collect_price',
                        ),
                        'meta_value',
                      ),
                      0,
                    ) / 100,
                  )
                  .subtract(
                    couponData.discount_type === 'fixed'
                      ? defaultTo(couponData.discount_amount, 0) / 100
                      : defaultTo((couponData.discount_amount / 100) * order?.order_pre_price, 0) / 100,
                  )
                  .format()}
              />
            </Grid>
          </Grid>
          <Grid item>
            <Collapse in={isCoupon}>
              <Grid item container direction="row">
                <Grid item xs={12}>
                  <OrderInfo
                    isLoading={loading}
                    label="Cupom - Desconto"
                    value={`${couponData.code} - ${couponData.discount_type === 'fixed' ? BrazillianCurrency(
                      defaultTo(couponData.discount_amount, 0) / 100,
                    ).format() : BrazillianCurrency(
                      defaultTo((couponData.discount_amount / 100) * order?.order_pre_price, 0) / 100,
                    ).format()}`}
                  />
                </Grid>
              </Grid>
            </Collapse>
          </Grid>
          <Grid item container direction="row">
            <Grid item xs={12}>
              <OrderInfo
                isLoading={loading}
                label="Observações informadas"
                value={get(
                  defaultTo(order?.meta, []).find(
                    (m) => m.meta_key === 'informed_phone_obs',
                  ),
                  'meta_value',
                  'Nada informado',
                )}
              />
            </Grid>
          </Grid>
          <Grid item container direction="row">
            <Grid item xs={12} sm={4}>
              <Tooltip
                classes={classes.tooltip}
                arrow
                placement="right"
                TransitionComponent={Zoom}
                title={
                  order
                  && (order.status.lay_name === 'Pendente Processamento'
                    || order.status.lay_name === 'Aguardando Coleta')
                    ? 'Cancelar a Ordem de Reparo'
                    : 'Não é mais possivel fazer essa ação'
                }
              >
                <span>
                  <Button
                    onClick={handleClickOpen}
                    disabled={
                      !(
                        order
                        && (order.status.lay_name === 'Pendente Processamento'
                          || order.status.lay_name === 'Aguardando Coleta')
                      ) || isLoading
                    }
                    variant="contained"
                    color="secondary"
                    size="small"
                    className={classes.buttonCancel}
                    endIcon={
                      isLoading && (
                      <CircularProgress
                        size={20}
                      />
                      )
                    }
                  >
                    Cancelar
                  </Button>
                </span>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};
export default BasicInfo;
