/* eslint-disable react/jsx-props-no-spreading */
import React, { useRef, useState } from 'react';
import { useFormik } from 'formik';
import { Box, Grid } from '@material-ui/core';
// COMPONENTS
import Card from './Components/Card';
import ListFields from './Components/ListFields';
// FUNCTIONS
import formikRules from './Rules';
import connect from './ConnectComponentRedux';
import { propTypes, defaultProps } from './PropTypes';
// INCLUDES
import './Services/MercadoPago';
import InlineAlertCollapse from '../../../InlineAlertCollapse';

const { Mercadopago } = window;

const CreditCardMethod = React.forwardRef(({ $state, $actions }, ref) => {
  const [focusedInput, setInputFocus] = useState('');
  const [alert, setAlert] = useState({
    show: false,
    message: null,
    severity: null,
  });

  const showAlert = (options) => setAlert({ show: true, ...options });
  const closeAlert = () => setAlert({ ...alert, show: false });

  const valuesRef = useRef(null);
  const formRef = useRef(null);

  const formik = useFormik({
    initialValues: {
      cpf: $state.cpf ?? '',
      cvc: $state.cvc ?? '',
      name: $state.name ?? '',
      number: $state.number ?? '',
      expiry: $state.expiry ?? '',
      installment: undefined,
      issuer: undefined,
      paymentMethodId: '',
    },

    validationSchema: formikRules,

    onSubmit: () =>
      new Promise((resolve, reject) => {
        formik.setSubmitting(true);
        closeAlert();
        window.Mercadopago.clearSession();
        window.Mercadopago.createToken(formRef.current, (status, response) => {
          const { values } = formik;
          if (status !== 200 && status !== 201) {
            showAlert({
              message:
                'Houve um erro com o cartão, por favor, revise os dados e tente novamente',
              severity: 'warning',
            });
            reject(response);
            return;
          }

          $actions.setCreditCard({
            cpf: values.cpf,
            cvc: values.cvc,
            name: values.name,
            number: values.number,
            expiry: values.expiry,
            installments: values.installment.value,
            installmentsText: values.installment.text,
            paymentMethodId: values.paymentMethodId,
            issuer: values.issuer,
            cardToken: response.id,
          });

          $actions
            .checkCreditCard()
            .then((check) => {
              if (check) {
                $actions.setStep($state.step + 1);
              }
            })
            .finally(() => {
              formik.setSubmitting(false);
              resolve();
            });
        });
      }),
  });

  const { values } = formik;

  React.useEffect(() => {
    /* Config key MercadoPago */
    const initMercadopago = async () => {
      window.Mercadopago.setPublishableKey(process.env.REACT_APP_MEPA_PUBLISHABLE_KEY);
    };
    initMercadopago();
  }, []);

  React.useImperativeHandle(
    ref,
    () => ({
      backStep: () => {
        const { current } = valuesRef;
        $actions.setCreditCard({
          cpf: current?.cpf,
          cvc: current?.cvc,
          name: current?.name,
          number: current?.number,
          issuer: current?.issuer,
          expiry: current?.expiry,
        });
        $actions.setStep($state.step - 1);
      },
      nextStep: formik.submitForm,
    }),
    [values]
  );

  React.useEffect(() => {
    valuesRef.current = values;
  }, [values]);

  React.useEffect(
    () => () => {
      const { current } = valuesRef;
      $actions.setCreditCard({
        cpf: current?.cpf,
        cvc: current?.cvc,
        name: current?.name,
        issuer: current?.issuer,
        number: current?.number,
        expiry: current?.expiry,
      });
    },
    []
  );

  return (
    <>
      <Box
        id="paymentForm"
        component="form"
        py={1}
        ref={formRef}
        onSubmit={formik.submitForm}
      >
        <Box py={1}>
          <InlineAlertCollapse
            show={alert.show}
            message={alert.message}
            severity={alert.severity}
          />
        </Box>
        <Grid container>
          <Card values={values} focusedInput={focusedInput} />
          <ListFields setInputFocus={setInputFocus} formik={formik} />
        </Grid>
      </Box>
    </>
  );
});

CreditCardMethod.propTypes = propTypes;
CreditCardMethod.defaultProps = defaultProps;

export default connect(CreditCardMethod);
