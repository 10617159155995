import { client } from '@/Apollo';
import changePasswordMutation from '../mutation/ChangePassword';

const changePassword = (data) => new Promise((resolve, reject) => {
  client
    .mutate({
      mutation: changePasswordMutation,
      variables: { ...data },
    })
    .then((r) => {
      resolve(r.data.PartnerChangePassword);
    })
    .catch((e) => {
      console.log(e.message, 'erro ao mudar senha');
      reject(e.message);
    });
});

export default changePassword;
