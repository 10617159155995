import makeStyle from '@material-ui/core/styles/makeStyles';

export default makeStyle(({ spacing, breakpoints }) => ({
  dateCollapse: {
    [breakpoints.down('xs')]: {
      marginTop: `${spacing(2)}px`,
      width: '100%',
    },
    marginTop: `${spacing(1.5)}px`,
  },
  dateTextField: {
    [breakpoints.down('xs')]: {
      width: '100%',
    },
  },
}));
