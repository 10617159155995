import React from 'react';
import * as Yup from 'yup';
import { Icon, InputAdornment, TextField } from '@material-ui/core';
import ReactInputMask from 'react-input-mask';
import { propTypes, defaultProps } from '../Utils/TextFieldPropTypes';
import useStyle from '../../../../../style';

export default function InputExpiry({
  handleChange, handleBlur, handleFocus, value, error, typeInput, disabled,
}) {
  const classes = useStyle();

  const [monthExpiry, yearExpiry] = String(value).split('/');

  return (
    <div>
      <ReactInputMask
        mask="99/99"
        placeholder="MM/AA"
        value={value}
        disabled={disabled}
        onBlur={handleBlur}
        onFocus={handleFocus}
        onChange={handleChange}
        maskPlaceholder={null}
      >
        <TextField
          fullWidth
          size="small"
          autoComplete="off"
          label="Valido até"
          variant={typeInput}
          error={!!error}
          helperText={error || null}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Icon className={classes.inputIcon}>event</Icon>
              </InputAdornment>
            ),
          }}
        />
      </ReactInputMask>
      <input
        type="hidden"
        id="cardExpirationMonth"
        data-checkout="cardExpirationMonth"
        value={monthExpiry}
      />
      <input
        type="hidden"
        id="cardExpirationYear"
        data-checkout="cardExpirationYear"
        value={yearExpiry}
      />
    </div>
  );
}

Yup.addMethod(Yup.string, 'expiryDate', function checkExpiryDate() {
  return this.test({
    name: 'name',
    message: 'Data inválida',
    test: (date) => {
      const [month, year] = String(date).split('/').map(
        (num) => Number.parseInt(num, 10),
      );

      if (Number.isNaN(month + year)) {
        return true;
      }

      if (month > 12) {
        return false;
      }

      if (2000 + year < new Date().getFullYear()) {
        return false;
      }

      if (
        2000 + year === new Date().getFullYear()
          && month - 1 <= new Date().getMonth()
      ) {
        return false;
      }

      return true;
    },
  });
});

InputExpiry.propTypes = propTypes;
InputExpiry.defaultProps = defaultProps;
InputExpiry.rules = Yup.string().expiryDate().required('Este campo é obrigatório');
