import client from '@/Apollo';
import { GetOrderStatusWithMovements } from '../query/GetOrderStatus';

const getMovementsAndStatus = (data) => new Promise((resolve, reject) => {
  client
    .query({
      query: GetOrderStatusWithMovements,
      variables: { movement_order_id: data.orderId, status_name: data.statusName },
    })
    .then((r) => {
      resolve(r.data);
    })
    .catch((e) => {
      console.log(e.message, 'erro ao buscar');
      reject(e.message);
    });
});

export default getMovementsAndStatus;
