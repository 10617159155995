/* eslint-disable camelcase */
// LODASH
import { isEmpty, last } from 'lodash';

export const repairObsFormatter = (value) => {
  if (value.length <= 1) return null;
  const { repair_obs } = last(value);
  return repair_obs;
};
export const rootCauseFormatter = (value) => {
  if (isEmpty(value)) return null;
  const { rootCauses } = last(value);
  if (isEmpty(rootCauses)) return null;
  const { cause_name } = rootCauses;
  return cause_name;
};
export const repairResultFormatter = (value) => {
  if (isEmpty(value)) return null;
  const { repairResult } = last(value);
  if (isEmpty(repairResult)) return null;
  const { result_name } = repairResult;
  return result_name;
};
