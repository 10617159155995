import React from 'react';
import Modal from 'react-modal';
import { Grid, IconButton, Typography, Box } from '@material-ui/core';
import { Close } from '@material-ui/icons';

Modal.setAppElement('#root');
class FullWidthModal extends React.Component {
  constructor(props) {
    super(props);
  
  this.modalRefs = {};
  }
  refCallback = (refName) => node => {
    if(node){
      this.modalRefs[refName] = node;  
    }
  }
  render() {
    const {
      handleModalState, children, title, ...otherProps
    } = this.props;
    return (
      <Modal
        style={{
          overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 10,
          },
          content: {
            position: 'absolute',
            top: '0px',
            left: '0px',
            right: '0px',
            bottom: '0px',
            border: '1px solid #ccc',
            background: '#fff',
            overflow: 'auto',
            WebkitOverflowScrolling: 'touch',
            borderRadius: '4px',
            outline: 'none',
            padding: '0',
          },
        }}
        {...otherProps}
        closeTimeoutMS={500}
        overlayRef={this.refCallback('overlayRef')}
        contentRef={this.refCallback('contentRef')}
      >
        {handleModalState && (
        <Grid container direction="row" style={{marginTop:'0.5rem'}}>
          <Grid item xs style={{ position: 'absolute' }}>
            <IconButton onClick={handleModalState(false)}>
              <Close />
            </IconButton>
          </Grid>
          <Grid item xs style={{paddingTop:'0.5rem'}}>
            <Typography variant="h6" align="center">{title}</Typography>
          </Grid>
        </Grid>
        )}
        <Box p={3}>
        {React.Children.map(children, (child) => {
          const props = { modalRefs: this.modalRefs };
          return React.isValidElement(child) ? React.cloneElement(child, props) : child;
        })}
        </Box>
      </Modal>
    );
  }
}

export default FullWidthModal;
