import * as P from 'prop-types';

const propTypes = {
  handleClose: P.func.isRequired,
  open: P.bool.isRequired,
  duration: P.number.isRequired,
  message: P.string.isRequired,
  severity: P.string.isRequired,
};

export default {
  propTypes,
  defaultProps: {},
};
