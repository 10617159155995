import { makeStyles } from '@material-ui/core/';

const styles = makeStyles(({ spacing, breakpoints }) => ({
  title: {
    fontSize: '20px',
  },
  box: {
    marginTop: '30px',
  },
  icon: {
    fontSize: '2.5rem',
  },
  list: {
    paddingLeft: `${spacing(3)}px`,
    [breakpoints.down('xs')]: {
      padding: 0,
    },
  },
}));

export default styles;
