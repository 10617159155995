import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setCollect } from '@redux/actions/NewOrderActions/actions';
import { setStore, setEmployee, setBranch } from '@redux/actions/PartnerStoreActions';

const mapDispatchToProps = (dispatch) => ({
  $actions: bindActionCreators(
    {
      setCollect, setStore, setEmployee, setBranch,
    },
    dispatch,
  ),
});
const mapStateToProps = ({ NewOrderReducer, PartnerStoreReducer }) => ({
  $state: {
    customer: NewOrderReducer,
    partner: PartnerStoreReducer,
  },
});

export default connect(mapStateToProps, mapDispatchToProps);
