import P from 'prop-types';
import StatePropTypes from '../../../../redux/reducers/NewOrderReducer/PropTypes';

export const StepperHeaderPropTypes = {
  $state: P.shape({
    activeStep: StatePropTypes.activeStep,
  }).isRequired,
};

export const StepperHeaderPropDefault = {};

export default { StepperHeaderPropTypes, StepperHeaderPropDefault };
