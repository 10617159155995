import React from 'react';
import { Grid, Typography } from '@material-ui/core';

const PageHeader = (props) => {
  const { title, ...otherProps } = props;
  return (
    <Grid
      container
      direction="row"
      style={{
        color: 'white',
        background: '#3c3584',
        padding: '2rem',
      }}
      {...otherProps}
    >
      <Grid item>
        <Typography style={{ fontSize: '2.1rem' }} variant="h3">{title}</Typography>
      </Grid>
    </Grid>
  );
};

export default PageHeader;
