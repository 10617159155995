import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setDevice, resetProduct, middlewares } from '../../../../../../redux/actions/NewOrderActions';

const mapStateToProps = ({ NewOrderReducer }) => ({
  $state: {
    device: NewOrderReducer.device,
    product: NewOrderReducer.device.product,
  },
});

const mapDispatchToProps = (dispatch) => ({
  $actions: bindActionCreators({
    setDevice,
    resetProduct,
    searchProduct: middlewares.updateProductByModelColor,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
