import client from '@/Apollo';
import GetCepAvailability from '../graphql/query/GetCepAvailability';

export default function checkCep(zipCode) {
  const clearedZipCode = String(zipCode).replace(/[^0-9]/gm, '');
  return new Promise((resolve, reject) => {
    client.query({ query: GetCepAvailability, variables: { cep: clearedZipCode } })
      .then(({ data: { ReparafoneValidateCepAvailability } }) => {
        const collect = ReparafoneValidateCepAvailability.slice().pop();
        resolve(!!collect);
      }).catch((e) => reject(e));
  });
}
