import React from 'react';
import * as P from 'prop-types';
import { Box, Button } from '@material-ui/core';
// COMPONENTS
import ListModels from './ListModels';
import LoadingContent from '../../LoadingContent';
import InputSearchModel from './InputSearchModel';
import InlineAlertCollapse from '../../InlineAlertCollapse';
// FUNCTIONS
import getModelsByBrand from '../../../../../utils/getModelsByBrand';

export default function StepSelectModel({ data, handleNextStep, handleBackStep }) {
  const [alert, setAlert] = React.useState({ message: null, type: 'info' });
  const [isSubmitting, setSubmitting] = React.useState(false);
  const [models, setModels] = React.useState([]);
  const [search, setSearch] = React.useState('');
  const setAlertMessage = (message, type) => setAlert({ message, type });

  const disabled = !models?.length;

  React.useEffect(() => {
    setSubmitting(true);
    setTimeout(async () => {
      try {
        const result = await getModelsByBrand(data.brand);
        if (!result?.length) {
          setAlertMessage('Não estamos trabalhando com esta marca ainda. Mas logo logo teremos o que você precisa!', 'warning');
        }
        setModels(result ?? []);
      } catch (e) {
        setAlertMessage('Houve um erro ao tentar recuperar os dados, tente novamente mais tarde.', 'error');
      }
      setSubmitting(false);
    }, 0);
  }, [data.brand]);

  const filteredModels = React.useMemo(() => (
    search
      ? models.filter(
        (model) => (
          String(model.name).toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) > -1
        ),
      )
      : models
  ), [search, models]);

  return (
    <Box maxWidth="500px">
      <InlineAlertCollapse
        show={!!alert.message}
        message={alert.message ?? ''}
        severity={alert.type}
      />
      <InputSearchModel disabled={disabled} handleSearch={setSearch} />
      <LoadingContent isLoading={isSubmitting}>
        {
          filteredModels?.length
            ? <ListModels models={filteredModels} handleSelect={handleNextStep} />
            : <Box p={2}>{ isSubmitting ? '' : 'Não encontrado' }</Box>
        }
      </LoadingContent>
      <Button size="small" color="secondary" onClick={handleBackStep}>Escolher outra marca</Button>
    </Box>
  );
}

StepSelectModel.propTypes = {
  data: P.shape({
    brand: P.string.isRequired,
  }).isRequired,
  handleNextStep: P.func.isRequired,
  handleBackStep: P.func.isRequired,
};
