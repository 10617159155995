import React from 'react';
import { groupBy, defaultTo, get } from 'lodash';
import {
  Typography,
  Grid,
  Divider,
  useTheme,
  useMediaQuery,
  Paper,
} from '@material-ui/core';
import currency from 'currency.js';
import getOrderCoupon from '../../../graphql/schema/GetCouponByOrder';
import getCoupon from '../../../graphql/schema/FindCouponById';
import BrazillianCurrency from '../../../../../utils/NationalCurrency';
import { useStyles } from './style';

const listFormatter = new Intl.ListFormat('pt-BR', {
  style: 'long',
  type: 'conjunction',
});

const RevisionsTable = ({
  rawRevisions, estimate, order, ...props
}) => {
  const theme = useTheme();
  const classes = useStyles();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [isCoupon, setCoupon] = React.useState(false);
  const [couponData, setCouponData] = React.useState({});
  const getCouponData = (couponId) => {
    getCoupon({ id: couponId })
      .then((r) => setCouponData(r))
      .catch((e) => console.log(e));
  };
  const getOrderCouponData = (orderId) => {
    getOrderCoupon({ order_id: orderId })
      .then((r) => {
        if (r) {
          setCoupon(true);
          getCouponData(r.coupon_id);
        }
      })
      .catch((e) => console.log(e));
  };
  const revisions = (rawRevisions
      && Object.values(groupBy(rawRevisions, (r) => r.defect.defect_name)).map(
        (r) => ({
          defect: r[0]?.defect.defect_name,
          parts: listFormatter.format(r.map((item) => item.parts.part_name)),
          price: r.reduce((a, v) => a + v.total_price, 0),
        }),
      ))
    || [];
  React.useEffect(() => {
    if (order) getOrderCouponData(order.id);
  }, [order]);
  return (
    <Grid container direction="column" spacing={isMobile ? 4 : 2} {...props}>
      <Paper className={classes.paper}>
        {revisions.map((item, index) => (
          <Grid
            key={item.defect}
            item
            container
            style={{ alignItems: 'center' }}
          >
            <Grid container direction="column" xs>
              <Grid item xs={12} sm={4} spacing={3}>
                <Typography variant="subtitle2" className={classes.defectLabel}>
                  {item.defect}
                </Typography>
                <Typography variant="body2">{item.parts}</Typography>
              </Grid>
            </Grid>
            <Grid item>
              <Typography variant="body1" className={classes.totalPriceLabel}>
                {BrazillianCurrency(item.price / 100, {
                  symbol: 'R$ ',
                  decimal: ',',
                  separator: '.',
                })
                  .add(
                    currency(defaultTo(estimate?.labor, 0) / 100).distribute(
                      revisions.length,
                    )[index],
                  )
                  .format()}
              </Typography>
            </Grid>
          </Grid>
        ))}
        <Grid item>
          <Divider className={classes.divider} />
        </Grid>
        <Grid item>
          <Grid container direction="row">
            <Grid item xs={6} sm={4} spacing={3}>
              <Typography variant="subtitle2" className={classes.defectLabel}>
                Valor do Reparo:
              </Typography>
            </Grid>
            <Grid item xs={6} sm={8} spacing={3}>
              <Typography variant="body1" className={classes.totalPriceLabel}>
                {(
                  (revisions.map((r) => r.price).reduce((a, v) => a + v, 0)
                    + defaultTo(estimate?.labor, 0))
                  / 100
                ).toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                })}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container direction="row">
            <Grid item xs={6} sm={4} spacing={3}>
              <Typography variant="subtitle2" className={classes.defectLabel}>
                Valor do Transporte (Coleta e Entrega):
              </Typography>
            </Grid>
            <Grid item xs={6} sm={8} spacing={3}>
              <Typography variant="body1" className={classes.totalPriceLabel}>
                {BrazillianCurrency(
                  defaultTo(
                    get(
                      defaultTo(order?.meta, []).find(
                        (m) => m.meta_key === 'collect_price',
                      ),
                      'meta_value',
                    ),
                    0,
                  ) / 100,
                ).format()}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container direction="row">
            <Grid item xs={6} sm={4} spacing={3}>
              <Typography variant="subtitle2" className={classes.defectLabel}>
                Cupom (
                {couponData.code}
                ):
              </Typography>
            </Grid>
            <Grid item xs={6} sm={8} spacing={3}>
              <Typography variant="body1" className={classes.totalPriceLabel}>
                {couponData.discount_type === 'fixed'
                  ? BrazillianCurrency(
                    defaultTo(couponData.discount_amount, 0) / 100,
                  ).format()
                  : BrazillianCurrency(
                    defaultTo(
                      (couponData.discount_amount / 100)
                            * ((revisions
                              .map((r) => r.price)
                              .reduce((a, v) => a + v, 0)
                              + defaultTo(estimate?.labor, 0))
                              / 100),
                      0,
                    ) / 100,
                  ).format()}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Divider className={classes.divider} />
        </Grid>
        <Grid item>
          <Grid container direction="row">
            <Grid item xs={6} sm={4} spacing={3}>
              <Typography variant="body1" className={classes.totalLabel}>
                Valor Total do Reparo:
              </Typography>
            </Grid>
            <Grid item xs={6} sm={8} spacing={3}>
              <Typography variant="body1" className={classes.totalPriceLabel}>
                {BrazillianCurrency((revisions.map((r) => r.price).reduce((a, v) => a + v, 0)
                    + defaultTo(estimate?.labor, 0)) / 100)
                  .add(
                    defaultTo(
                      get(
                        defaultTo(order?.meta, []).find(
                          (m) => m.meta_key === 'collect_price',
                        ),
                        'meta_value',
                      ),
                      0,
                    ) / 100,
                  )
                  .subtract(
                    couponData.discount_type === 'fixed'
                      ? defaultTo(couponData.discount_amount, 0) / 100
                      : defaultTo(
                        (couponData.discount_amount / 100)
                            * (revisions.map((r) => r.price).reduce((a, v) => a + v, 0)
                    + defaultTo(estimate?.labor, 0)),
                        0,
                      ) / 100,
                  )
                  .format()}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

export default RevisionsTable;
