import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setStep, setBillingAddress, setDeliveryData } from '@redux/actions/NewOrderActions';

const mapStateToProps = ({ NewOrderReducer }) => ({
  $state: {
    step: NewOrderReducer.activeStep,
    billingAddress: NewOrderReducer.billingAddress,
    payment: NewOrderReducer.payment,
  },
});

const mapDispatchToProps = (dispatch) => ({
  $actions: bindActionCreators({
    setStep, setBillingAddress, setDeliveryData,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
