import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles(({ palette: { primary, secondary } }) => ({
  stepperContent: {
    maxWidth: 500,
  },
  stepCarrefour: {
    '& span>svg.MuiStepIcon-root.MuiStepIcon-active': {
      color: '#1752CC',
    },
    '& span>svg.MuiStepIcon-root.MuiStepIcon-completed': {
      color: '#1752CC',
    },
  },
  stepReparofone: {
    '& span>svg.MuiStepIcon-root.MuiStepIcon-active': {
      color: primary.main,
    },
    '& span>svg.MuiStepIcon-root.MuiStepIcon-completed': {
      color: primary.main,
    },
  },
}));
