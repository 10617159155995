import { styled, Typography } from '@material-ui/core';
import React from 'react';
import BrazillianCurrency from '@/utils/NationalCurrency';

const PriceInformation = ({
  title, price, className, isDeduction = false,
}) => (
  <div className={className}>
    <Typography variant="body1" align="left" className="title">{`${title}:`}</Typography>
    <Typography variant="body1" align="right" className="price">{BrazillianCurrency(price / 100).format()}</Typography>
  </div>
);

export default styled((props) => <PriceInformation {...props} />)({
  display: 'flex',
  justifyContent: 'space-between',
  '& .price, .title': {
    fontWeight: ({ bold }) => (bold ? 500 : 'inherit'),
  },
});
