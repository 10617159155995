import { CreateMovimentationsAndUpdateStatusOrder } from '../mutation/UpdateStatusOrder';
import { client } from '../../../../Apollo';

const createMovimentationsAndStatusOrder = (data) => new Promise((resolve, reject) => {
  client
    .mutate({
      mutation: CreateMovimentationsAndUpdateStatusOrder,
      variables: {
        id: data.id,
        order_status_id: data.statusOrderId,
        movement_order_id: data.orderId,
        movement_status_id: data.statusId,
        movement_previous_status: data.previousStatusId,
        movement_user_id: data.userId,
      },
    })
    .then((r) => {
      resolve(r.data);
    })
    .catch((e) => {
      console.log(e.message, 'erro ao criar');
      reject(e.message);
    });
});

export default createMovimentationsAndStatusOrder;
