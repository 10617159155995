import * as P from 'prop-types';

const propTypes = {
  order: P.arrayOf(P.shape({
    order_imei: P.string.isRequired,
    product: P.shape({
      product_name: P.string.isRequired,
    }).isRequired,
    estimates: P.arrayOf(P.shape({
      imei: P.string.isRequired,
      created_at: P.string.isRequired,
      price: P.number.isRequired,
      revision: P.arrayOf(P.shape({
        parts: P.shape({
          part_price_selling: P.number.isRequired,
        }).isRequired,
      })).isRequired,
    })).isRequired,
  })).isRequired,
};

export default {
  propTypes,
  defaultProps: {},
};
