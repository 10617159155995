import Axios from 'axios';
import { omit } from 'lodash';
import {
  START_FETCHING, STOP_FETCHING, SET_AUTHENTICATED, UPDATE_USER_DATA,
} from '../../reducers/AuthReducer';

export const startFetching = () => ({
  type: START_FETCHING,
});
export const stopFetching = () => ({
  type: STOP_FETCHING,
});
export const setAuthenticated = (isAuthenticated, userData = {}) => {
  if (isAuthenticated) localStorage.setItem('apiToken', userData.token);
  else {
    localStorage.removeItem('apiToken');
    localStorage.removeItem('storeCollect');
    localStorage.removeItem('branchData');
  }
  const saveUserData = omit(userData, ['token', 'status']);
  return {
    type: SET_AUTHENTICATED,
    isAuthenticated,
    saveUserData,
  };
};
export const updateUserData = (userData) => ({
  type: UPDATE_USER_DATA,
  userData,
});

export const requestLogin = (userData) => (dispatch) => {
  dispatch(startFetching());
  return new Promise((resolve, reject) => Axios({
    url: `${process.env.REACT_APP_BASE_URL}/reparafone/login`,
    method: 'POST',
    data: userData,
  })
    .then((r) => {
      if (r.data.status) dispatch(setAuthenticated(true, r.data));
      resolve(r);
    })
    .catch((e) => reject(e))
    .finally(() => dispatch(stopFetching())));
};

export const requestLoginPartner = (userData) => (dispatch) => {
  dispatch(startFetching());
  return new Promise((resolve, reject) => Axios({
    url: `${process.env.REACT_APP_BASE_URL}/reparafone/partner/login`,
    method: 'POST',
    data: userData,
  })
    .then((r) => {
      if (r.data.status) dispatch(setAuthenticated(true, r.data));
      resolve(r);
    })
    .catch((e) => reject(e))
    .finally(() => dispatch(stopFetching())));
};
