export default [
  {
    slug: 'brand',
    label: 'Selecione a marca',
    concludedLabel: (device) => `Marca${device.brand ? ': ' : ''} ${device.brand ?? ''}`,
  },
  {
    slug: 'model',
    label: 'Selecione o modelo',
    concludedLabel: (device) => `Modelo${device.model?.name ? ': ' : ''} ${device.model?.name ?? ''}`,
  },
  {
    slug: 'color',
    label: 'Selecione a cor',
    concludedLabel: (device) => `Cor${device.color ? ': ' : ''} ${device.color ?? ''}`,
  },
];
