import React from 'react';
import { useDispatch } from 'react-redux';
import {
  setOriginUser,
  setOriginStore,
  setCollect,
  setCustomer,
} from '@/redux/actions/NewRepairActions';
import Header from './components/Header';
import Form from './components/Form';
import getCustomerInfo from './graphql/schemas/GetCustomerInfo';
import insertUser from './graphql/schemas/InsertUser';

const NewRepair = () => {
  const dispatch = useDispatch();
  React.useEffect(() => {
    getCustomerInfo().then((data) => {
      const collectData = {
        complement: data.customer_address_complement,
        city: data.customer_city,
        cpf: data.customer_cpf,
        neighborhood: data.customer_neighborhood,
        state: data.customer_state,
        street: data.customer_street,
        number: data.customer_street_number,
        zipCode: data.customer_zip_code.replace(/(\d{5})?(\d{3})?/, '$1-$2'),
        isChartered: false,
        blockZipCodeEdit: true,
      };
      const customerData = {
        email: data.customer_email,
        name: `${data.customer_first_name} ${data.customer_last_name}`,
        phone: data.customer_phone,
        cpf: data.customer_cpf,
      };
      dispatch(setCollect(collectData));
      dispatch(setCustomer(customerData));
    });
    insertUser().then((data) => {
      dispatch(setOriginUser(data.ReparafoneGetTradeInUserByName));
      dispatch(setOriginStore(data.ReparafoneGetStoreByName));
    });
  }, [dispatch]);
  return (
    <div>
      <Header title="Novo Reparo" />
      <Form />
    </div>
  );
};

export default NewRepair;
