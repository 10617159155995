import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles(({ typography, breakpoints, transitions }) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    transition: transitions.create(['margin', 'width'], {
      easing: transitions.easing.sharp,
      duration: transitions.duration.leavingScreen,
    }),
    backgroundColor: '#ffffff',
  },
}));

export default styles;
