import React from 'react';
// PROP-TYPES
import * as P from 'prop-types';
// MATERIAL-UI
import { Button, Icon, makeStyles } from '@material-ui/core';
// CLSX
import clsx from 'clsx';
// REDUX
import { useSelector } from 'react-redux';

const styles = makeStyles(({ palette: { primary } }) => ({
  labelCarrefour: {
    color: '#1752CC',
  },
  labelReparofone: {
    color: primary.main,
  },
}));

export default function ButtonMoreBrands({ showMore, handleClick }) {
  const classes = styles();
  const partnerData = useSelector((state) => state.PartnerStoreReducer);
  return (
    <Button
      size="small"
      variant="text"
      onClick={handleClick}
      endIcon={<Icon>{showMore ? 'remove' : 'add'}</Icon>}
      className={clsx(classes.root, {
        [classes.labelCarrefour]: partnerData.branch.branch_name === 'Carrefour',
        [classes.labelReparofone]: partnerData.branch.branch_name === 'Limelocker',
      })}
    >
      {showMore ? 'Menos Marcas' : 'Outras marcas'}
    </Button>
  );
}

ButtonMoreBrands.propTypes = {
  showMore: P.bool.isRequired,
  handleClick: P.func.isRequired,
};
