import { client } from '@/Apollo';
import validateRecoverPasswordTokenPartner from '../mutation/ValidateRecover';

const validateToken = (data) => new Promise((resolve, reject) => {
  client
    .mutate({
      mutation: validateRecoverPasswordTokenPartner,
      variables: { ...data },
    })
    .then((r) => {
      resolve(r.data.ValidateRecoverPasswordTokenPartner);
    })
    .catch((e) => {
      console.log(e.message, 'erro ao validar');
      reject(e.message);
    });
});

export default validateToken;
