import React from 'react';
import * as P from 'prop-types';
import Typography from '@material-ui/core/Typography';

export default function Title({ label }) {
  return (
    <Typography variant="h6">{label}</Typography>
  );
}

Title.propTypes = {
  label: P.string.isRequired,
};
