import * as P from 'prop-types';

const propTypes = {
  order: P.arrayOf(P.shape({
    isPaid: P.bool.isRequired,
    totalPaid: P.number.isRequired,
    discountValue: P.number.isRequired,
    deliveryPrice: P.number.isRequired,
  })).isRequired,
};

export default {
  propTypes,
  defaultProps: {},
};
