import client from '@/Apollo';
import GetCepAvailability from '../graphql/query/GetCepAvailability';

export default function GetShipping(zipCode) {
  return new Promise((resolve, reject) => {
    const clearZipCode = zipCode.replace(/[^0-9]/gm, '');
    client.query({ query: GetCepAvailability, variables: { cep: clearZipCode } })
      .then(({ data: { ReparafoneValidateCepAvailability } }) => {
        const collect = ReparafoneValidateCepAvailability.slice().pop();
        if (!collect) {
          return resolve(null);
        }
        return resolve(collect);
      });
  });
}
