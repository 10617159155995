import React from 'react';
import {
  Grid, Typography, Divider, Button,
} from '@material-ui/core';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import { connect } from 'react-redux';
import Alerts from '../components/Alert';
import {
  startFetching,
  stopFetching,
} from '../../../redux/actions/auth/AuthActions';
import requestTokenGeneration from '../graphql/schemas/RequestRecover';

const RecoverPassSchema = Yup.object().shape({
  email: Yup.string()
    .email('Digite um e-mail válido!')
    .required('Digite o seu e-mail!'),
});

const RequestPasswordRecover = ({ history, startFetching, stopFetching }) => {
  const [error, setError] = React.useState(false);
  const [showAlert, setShowAlert] = React.useState(null);
  const [alertMessage, setMessageAlert] = React.useState(false);
  const [alertSeverity, setSeverityAlert] = React.useState(false);
  const [alertDuration, setDurationAlert] = React.useState(false);
  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowAlert(null);
  };
  const handleAlert = (message, severity, openAlert, duration) => {
    setMessageAlert(message);
    setSeverityAlert(severity);
    setShowAlert(openAlert);
    setDurationAlert(duration);
  };
  const onSubmitForm = ({ email }, { setSubmitting }) => {
    startFetching();
    requestTokenGeneration({ email })
      .then((r) => history.push('/partners/recover/password/token', {
        email: r.email,
      }))
      .catch((e) => {
        console.log(e, 'erro');
        setError(true);
        handleAlert(
          'E-mail não cadastrado!',
          'error',
          true,
          5000,
        );
      })
      .finally(() => { setSubmitting(false); stopFetching(); });
  };
  return (
    <>
      <Alerts
        open={showAlert}
        handleClose={handleCloseAlert}
        message={alertMessage}
        severity={alertSeverity}
        duration={alertDuration}
      />
      <Grid container direction="row">
        <Grid item xs={12}>
          <Typography variant="subtitle1">Recuperar credenciais</Typography>
        </Grid>
      </Grid>
      <Divider style={{ marginTop: '0.5rem', marginBottom: '0.5rem' }} />
      <Formik
        initialValues={{
          email: '',
        }}
        validationSchema={RecoverPassSchema}
        onSubmit={onSubmitForm}
      >
        {({
          errors, touched, isSubmitting, dirty,
        }) => (
          <Form>
            <Grid container direction="column" spacing={4}>
              <Grid item xs={12}>
                <Typography variant="body2">
                  Digite o e-mail que você utiliza para fazer login.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Field
                  disabled={isSubmitting}
                  component={TextField}
                  fullWidth
                  label="E-mail"
                  name="email"
                  variant="outlined"
                  error={
                    !!(touched.email && errors.email)
                    || (touched.email && !!error)
                  }
                  helperText={
                    (touched.email && errors.email)
                    || (touched.email && error)
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  disabled={isSubmitting || !dirty}
                  type="submit"
                  color="secondary"
                  variant="contained"
                  fullWidth
                  title="Recuperar senha"
                >
                  Recuperar senha
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};
const mapStateToProps = (state) => {
  const { AuthReducer } = state;
  const { isFetching } = AuthReducer;
  return {
    isFetching,
  };
};
export default connect(mapStateToProps, { startFetching, stopFetching })(
  RequestPasswordRecover,
);
