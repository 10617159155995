import client from '@/Apollo';
import GetProductByModelAndColorsQuery from '@/pages/neworder/graphql/query/GetProducts';
import { setProduct } from '../actions';

const getDefect = (id, name, desc, totalPrice) => ({
  id: String(id),
  name,
  desc,
  averagePrice: totalPrice,
  isAble: name !== 'Não vibra',
  parts: [
    { id: '1', name: 'peça-1', price: totalPrice / 2 },
    { id: '2', name: 'peça-2', price: totalPrice / 4 },
    { id: '3', name: 'peça-3', price: totalPrice / 4 },
  ],
});

const defaultDefects = [
  getDefect(1, 'Problema na bateria', 'Não segura carga', 16000),
  getDefect(2, 'Display quebrado', 'Tela tricada ou quebrada', 22000),
  getDefect(3, 'Câmera frontal', 'Câmera frontal embaçada ou não funciona', 7600),
  getDefect(4, 'Câmera traseira', 'Câmera traseira embaçada ou não funciona', 8800),
  getDefect(5, 'Não carrega', 'Conecta USB mas não carrefa', 6500),
  getDefect(6, 'Não funciona Rede', 'Não liga e nem recebe ligação, não funciona 3G ou demais', 2600),
  getDefect(7, 'Wi-Fi não funciona', 'Não conecta Wi-Fi', 3600),
  getDefect(8, 'Trocar carcaça', 'Carcaça danificada, arranhada ou quebrada', 10200),
  getDefect(9, 'Não vibra', 'Não vibra/mexe', 1600),
];
function getProduct(modelId, color) {
  return new Promise((resolve, reject) => {
    client
      .query({ query: GetProductByModelAndColorsQuery, variables: { modelId, color } })
      .then(({ data }) => {
        const product = data.products.slice().pop();
        if (!product) reject(new Error('Não há reparo disponível para o produto informado.'));
        resolve(product);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export default function updateProductByModelColor() {
  return async (dispatch, getState) => {
    const { device } = getState().NewOrderReducer;
    const product = await getProduct(device.model.id, device.color);
    dispatch(setProduct({
      id: product.id,
      name: `${device.model.name} ${device.brand} ${device.color}`.trim(),
      defects: product.defects,
    }));
    return Promise.resolve();
  };
}
