import React from 'react';
import { InputAdornment, TextField, Icon } from '@material-ui/core';
import ReactInputMask from 'react-input-mask';
import clsx from 'clsx';
import { InputPhonePropTypes, InputPhoneDefaultProps } from './PropTypes';
import InputPhoneRules from './Rules';
import useStyle from '../../../../../../style';

export default function InputPhone(props) {
  const {
    handleChange, handleBlur, errors, value, typeInput, disabled, id,
  } = props;
  const classes = useStyle();

  const getMask = (str) => (
    String(str).length > 14 && String(str)[5] === '9'
      ? '(99) 99999-9999'
      : '(99) 9999-99999'
  );

  const adjustValue = (ev) => {
    const copyEv = ev;
    const { target } = copyEv;
    const mask = getMask(target.value);

    if (mask[10] === '-') {
      target.value = target.value
        .replace(/[^\d]/g, '')
        .replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
    } else if (mask[9] === '-') {
      target.value = target.value
        .replace(/[^\d]/g, '')
        .replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2-$3');
    }
    handleChange(copyEv);
  };

  return (
    <ReactInputMask
      mask={getMask(value)}
      value={value}
      onChange={adjustValue}
      onBlur={handleBlur}
      disabled={disabled}
      maskPlaceholder={null}
    >
      <TextField
        fullWidth
        name="phone"
        label="Telefone para contato"
        variant={typeInput}
        color="primary"
        id={id}
        helperText={errors}
        error={!!errors}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Icon className={clsx(['fas', 'fa-mobile-alt', classes.inputIcon])} />
            </InputAdornment>
          ),
        }}
      />
    </ReactInputMask>
  );
}

InputPhone.propTypes = InputPhonePropTypes;
InputPhone.defaultProps = InputPhoneDefaultProps;
InputPhone.rules = InputPhoneRules;
