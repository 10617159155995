import { connect } from 'react-redux';

const mapStateToProps = ({ NewOrderReducer }) => ({
  $state: {
    transactionAmount: NewOrderReducer.transactionAmount,
    coupons: NewOrderReducer.coupons,
  },
});

const mapDispatchToProps = null;

export default connect(mapStateToProps, mapDispatchToProps);
