import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  testimonials: {
    border: '1px solid #ccc',
    borderRadius: '20px',
    padding: '10px',
    margin: '30px 0',
    width: '50%',
    background: '#fefefe',
  },
  testimonialsMobile: {
    border: '1px solid #ccc',
    borderRadius: '20px',
    padding: '10px',
    margin: '0 20px 60px',
    background: '#fefefe',
  },
  testimonialsData: {
    display: 'flex !important',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  slider: {
    margin: '0 20px 20px',
  },
  image: {
    float: 'left',
    marginRight: '20px',
    width: '60px',
    margin: '10px',
  },
  name: {
    float: 'left',
    color: '#736861',
    margin: '20px 0 5px',
    textShadow: '0 1px rgba(255,255,255,0.5)',
    fontSize: '1.1rem',
  },
  comment: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'baseline',
    padding: '5px',
  },
  city: {
    fontWeight: '200',
  },
  title: {
    fontSize: '1.5rem',
    color: '#736861',
    margin: '0 20px 20px',
  },
  blockquote: {
    margin: 0,
    float: 'left',
    padding: '15px',
    width: '100%',
    backgroundColor: '#FFAA49',
    color: 'white',
    boxShadow: '0 5px 2px rgba(0,0,0,0.1)',
    position: 'relative',
    transition: 'background-color 0.6s linear',
    '&:after': {
      content: '""',
      height: 0,
      width: 0,
      position: 'absolute',
      top: '100%',
      border: 'solid transparent',
      borderTopColor: '#FFAA49',
      borderLeftColor: '#FFAA49',
      borderWidth: '10px',
      left: '10%',
    },
  },
  blockquoteMobile: {
    margin: 0,
    float: 'left',
    padding: '15px',
    width: '100%',
    backgroundColor: '#FFAA49',
    color: 'white',
    boxShadow: '0 5px 2px rgba(0,0,0,0.1)',
    position: 'relative',
    transition: 'background-color 0.6s linear',
    '&:after': {
      content: '""',
      height: 0,
      width: 0,
      position: 'absolute',
      top: '100%',
      border: 'solid transparent',
      borderTopColor: '#FFAA49',
      borderLeftColor: '#FFAA49',
      borderWidth: '10px',
      left: '10%',
    },
  },
  loadingTestimonials: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '30px',
  },
}));

export default useStyles;
