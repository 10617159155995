import React from 'react';
import {
  Box,
  Button, CircularProgress, Collapse, Grid, Paper, Typography,
} from '@material-ui/core';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import { Alert } from '@material-ui/lab';
import {
  groupBy, map, maxBy,
} from 'lodash';
import CalculateRepairCost from '@/pages/newrepair/utils/CalculateRepairCost';
import BrazillianCurrency from '@/utils/NationalCurrency';
import LabelBudget from '../LabelBudget';
import PropTypes from './PropTypes';

function BoxRepairBudget({
  defects, hookCalcBudget, selectedDefects, setSelectDefect, nextStep,
}) {
  const [beCalc, setBeCalc] = React.useState(false);
  const [isCalculating, setIsCalculating] = React.useState(false);
  const [repairPrice, setRepairPrice] = React.useState({
    repair: 0,
    taxDelivery: 0,
    taxCollect: 0,
  });
  const [budgetCalculated, setBudgetCalculated] = React.useState(false);
  const canBeRepaired = !defects.find((defect) => !defect.maxPrice);
  const calcBudget = async (createLead = true) => {
    setBudgetCalculated(true);
    setIsCalculating(true);
    setSelectDefect(true);
    if (hookCalcBudget) {
      await hookCalcBudget(createLead, canBeRepaired);
    }
    if (!canBeRepaired) {
      setBeCalc(false);
      setIsCalculating(false);
      setSelectDefect(false);
      return;
    }
    setBeCalc(false);
    const { budgetRepair } = await CalculateRepairCost(
      selectedDefects,
    );
    setRepairPrice(
      {
        repair: budgetRepair,
        taxDelivery: 0,
        taxCollect: 0,
      },
    );
    setIsCalculating(false);
    setSelectDefect(false);
  };

  React.useEffect(() => {
    setBeCalc(true);
    setBudgetCalculated(false);
  }, [defects]);

  React.useEffect(() => {
    if (defects.length) calcBudget(false);
  }, []);
  /**
   * A ideia é pegar os defeitos e agrupá-los de acordo com o tipo de peça mais cara e concatenar
   * o nome dos defeitos para gerar uma estrutura [{defectName, maxPrice}, ...]
   */
  const pricesByGroupedDefects = map(
    groupBy(
      selectedDefects.map(
        (defect) => ({ ...defect, moreExpensivePart: maxBy(defect.parts, 'part_price_selling') }),
      ),
      (d) => d.moreExpensivePart.part_type,
    ), (r) => {
      const name = r.reduce((acc, value) => [...acc, value.name], []).join(', ');
      return { name, maxPrice: r[0].maxPrice };
    },
  );
  return (
    <Grid container spacing={2}>
      {budgetCalculated && !canBeRepaired && (
      <Grid item xs={12} md={12}>
        <Alert severity="error">
          Infelizmente ainda não conseguimos reparar esse defeito do seu aparelho.
        </Alert>
      </Grid>
      )}
      <Grid item xs={12} md={!beCalc && !isCalculating ? 6 : 12}>
        <Button
          fullWidth
          size="large"
          color="secondary"
          variant="contained"
          onClick={calcBudget}
          disabled={defects.length < 1 || !beCalc || isCalculating}
          endIcon={(
            isCalculating ? <CircularProgress size="1.2rem" /> : <AttachMoneyIcon fontSize="small" />
          )}
        >
          Estimar orçamento
        </Button>
        <Collapse
          in={!beCalc && !isCalculating && canBeRepaired}
          unmountOnExit
          orientation="horizontal"
        >
          <Button
            fullWidth
            style={{ marginTop: '0.5rem' }}
            size="large"
            color="secondary"
            variant="contained"
            onClick={nextStep}
            endIcon={(<LocalShippingIcon fontSize="small" />)}
          >
            Ver Frete e Prazo
          </Button>
        </Collapse>
      </Grid>
      <Grid item xs={12} md={6}>
        <Collapse
          in={!beCalc && !isCalculating && canBeRepaired}
          unmountOnExit
          orientation="horizontal"
        >
          <LabelBudget budget={repairPrice.repair} />
        </Collapse>
      </Grid>
      {!beCalc && !isCalculating && canBeRepaired && (
        <Grid item xs={12}>
          <Paper elevation={1} variant="outlined">
            <Box p={2}>
              <Typography variant="subtitle2">Custo de reparo detalhado</Typography>

              {pricesByGroupedDefects.map((defect) => (
                <div key={defect.name}>
                  <Grid container direction="row">
                    <Grid item xs>
                      <Typography variant="body2" align="left">
                        {defect.name}
                      </Typography>
                    </Grid>
                    <Grid item xs="auto">
                      <Typography variant="body2" align="right">
                        {BrazillianCurrency(defect.maxPrice / 100).format()}
                      </Typography>
                    </Grid>
                  </Grid>
                </div>
              ))}
            </Box>
          </Paper>
        </Grid>
      )}
    </Grid>
  );
}

BoxRepairBudget.propTypes = PropTypes.propTypes;

export default React.memo(BoxRepairBudget);
