import React from 'react';
import {
  Box, Collapse, Divider,
} from '@material-ui/core';
// REDUX
import { useSelector, useDispatch } from 'react-redux';
import { setStep } from '@redux/actions/NewRepairActions';
// COMPONENTS
import SelectDefects from './Components/SelectDefects';
import StepperButtons from '../StepperButtons';
import StepperSelectDevice from './Components/StepperSelectDevice';
import RefuseBudget from '../RefuseBudget';
import Alerts from '../RefuseBudget/components/Alerts';

function SelectDevice() {
  const [disabledBtnNext, setDisabledBtnNext] = React.useState(true);
  const dispatch = useDispatch();
  const customerData = useSelector((state) => state.NewRepairReducer);
  const { device, activeStep } = customerData;
  const isSelectedDevice = !!device.product.name;
  const [openRefuseBudget, setOpenRefuseBudget] = React.useState(false);
  const [showAlert, setShowAlert] = React.useState(null);
  const [alertMessage, setMessageAlert] = React.useState(false);
  const [alertSeverity, setSeverityAlert] = React.useState(false);
  const [alertDuration, setDurationAlert] = React.useState(false);

  const handleOpenRefuseBudget = () => {
    setOpenRefuseBudget(true);
  };
  const handleCloseRefuseBudget = () => {
    setOpenRefuseBudget(false);
  };
  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowAlert(null);
  };
  const handleAlert = (message, severity, openAlert, duration) => {
    setMessageAlert(message);
    setSeverityAlert(severity);
    setShowAlert(openAlert);
    setDurationAlert(duration);
  };

  const nextStep = () => dispatch(setStep(activeStep + 1));

  return (
    <>
      <RefuseBudget
        clickToClose={handleCloseRefuseBudget}
        open={openRefuseBudget}
        handleAlert={handleAlert}
      />
      <Alerts
        open={showAlert}
        handleClose={handleCloseAlert}
        message={alertMessage}
        severity={alertSeverity}
        duration={alertDuration}
      />
      <StepperSelectDevice />
      <Box width="100%" py={1}>
        <Divider />
      </Box>
      <Collapse in={isSelectedDevice} unmountOnExit>
        <SelectDefects
          disabledBtnNextStep={setDisabledBtnNext}
          openRefuseBudget={handleOpenRefuseBudget}
          nextStep={nextStep}
        />
      </Collapse>
      <StepperButtons
        nextStep={disabledBtnNext ? undefined : nextStep}
      />
    </>
  );
}

export default SelectDevice;
