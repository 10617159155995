export default function getIssuers(paymentMethodId) {
  return new Promise((resolve, reject) => {
    window.Mercadopago.getIssuers(paymentMethodId, (status, response) => {
      if (status === 200) {
        resolve(response);
      }
      reject(response);
    });
  });
}
