import * as P from 'prop-types';
import NewOrderReducerPropTypes from '@redux/reducers/NewOrderReducer/PropTypes';

export const propTypes = {
  $state: P.shape(NewOrderReducerPropTypes),
};

export const defaultProps = {};

export default {
  propTypes, defaultProps,
};
