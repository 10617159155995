import P from 'prop-types';
import StatePropTypes from '@redux/reducers/NewOrderReducer/PropTypes';

export const SelectDevicePropTypes = {
  $state: P.shape({
    step: StatePropTypes.activeStep,
    imei: P.string,
    defectComment: P.string,
    isSelectedDevice: P.bool.isRequired,
  }),
  $actions: P.shape({
    setStep: P.func.isRequired,
    setDevice: P.func.isRequired,
  }).isRequired,
};

export const SelectDeviceDefaultProps = {};

export default { SelectDevicePropTypes, SelectDeviceDefaultProps };
