import * as P from 'prop-types';
import { CreditCardProps } from '@redux/reducers/NewOrderReducer/PropTypes';

export const propTypes = {
  $state: P.shape({
    step: P.number.isRequired,
    name: CreditCardProps.name,
    number: CreditCardProps.number,
    expiry: CreditCardProps.expiry,
    cvc: CreditCardProps.cvc,
  }),
  $actions: P.shape({
    setStep: P.func.isRequired,
    setCreditCard: P.func.isRequired,
    checkCreditCard: P.func.isRequired,
  }),
};

export const defaultProps = {};

export default {
  propTypes,
  defaultProps,
};
