import React from 'react';
import { Box } from '@material-ui/core';
// REDUX
import { useSelector } from 'react-redux';
// COMPONENTS
import SelectDevice from '../SelectDevice';
import AboutCustomer from '../AboutCustomer';
import Shipping from '../Shipping';
import ConcludedOrder from '../ConcludedOrder';

const steps = [
  SelectDevice,
  Shipping,
  AboutCustomer,
  ConcludedOrder,
];
const StepperContent = () => {
  const customerData = useSelector((state) => state.NewRepairReducer);
  const { activeStep } = customerData;
  return steps[activeStep]
    ? React.createElement(steps[activeStep])
    : <Box textAlign="center">Erro, não encontrado</Box>;
};

export default StepperContent;
