import React from 'react';

import { Header, Button } from 'semantic-ui-react';

import { HashLink as Link } from 'react-router-hash-link';
import Depoimentos from './Depoimentos';
import Cards from './Cards';

import Contrato from '../../../../images/Funcionamento/orcamento-2.png';
import Coleta from '../../../../images/Funcionamento/delivery.png';
import Reparo from '../../../../images/Funcionamento/smartphone.png';
import Pagamento from '../../../../images/Funcionamento/cartao-de-credito.png';
import Entrega from '../../../../images/Funcionamento/entregador.png';

import './ComoFunciona.css';

const Funcionamento = () => (
  <div id="how-it-works" className="como-funciona">
    <div className="como-funciona-cards">
      <div className="como-funciona-cards-intern">
        <Header as="h2" content="Conheça o processo da Reparofone" />
        <Cards
          image={Contrato}
          step="Passo 1"
          title="Orçamento"
          text="Faça seu orçamento completamente Online! (Ou entre em contato conosco)"
        />
        <Cards
          image={Coleta}
          step="Passo 2"
          title="Coleta"
          text="Agende conforme sua conveniência!*"
        />
        <Cards
          image={Reparo}
          step="Passo 3"
          title="Reparo"
          text="Em parceria com a Trocafone - e parceiros homologados - oferecemos a melhor qualidade e rapidez do mercado!**"
        />
        <Cards
          image={Pagamento}
          step="Passo 4"
          title="Pagamento"
          text="Pague por link em até 10x somente depois que o aparelho é reparado e testado!"
        />
        <Cards
          image={Entrega}
          step="Passo 5"
          title="Entrega"
          text="Receba seu celular no conforto da sua casa ou onde for mais cômodo!"
        />
      </div>
      <div className="depoimentos">
        <div className="paragraphs">
          <p className="info-paragraph p-2">*Com garantia de 3 meses no reparo realizado</p>
        </div>
        <Depoimentos />
      </div>
      <div className="section-orcamento">
        <Link to="#do-estimate">
          <Button
            content="Solicitar Orçamento"
            icon="dollar"
            labelPosition="right"
            className="button-cards"
          />
        </Link>
      </div>
    </div>
  </div>
);

export default Funcionamento;
