import getShippingTax, { checkDeliveryCep } from '@/pages/neworder/utils/GetShipping';
import { uniqBy } from 'lodash';

const CalculateRepairCost = async (selectedDefects, collectZipCode, deliveryZipCode) => {
  const usedParts = uniqBy(selectedDefects.map((item) => item.parts).flat(), 'part_type');
  const budgetRepair = usedParts.reduce(
    (value, part) => (value + (part?.part_price_selling ?? 0)),
    0,
  );
  const taxDelivery = await checkDeliveryCep(deliveryZipCode);
  const taxCollect = await getShippingTax(collectZipCode);

  if (!taxDelivery || !taxCollect) {
    throw new Error('Erro ao consultar preços de coleta/entrega.');
  }
  return {
    budgetRepair, taxDelivery: taxDelivery.price, taxCollect: 0,
  };
};

export default CalculateRepairCost;
