import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles(({ typography, breakpoints }) => ({
  logo: {
    [breakpoints.down('xs')]: {
      display: 'flex',
    },
  },
  logoLink: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  img: {
    maxWidth: '200px',
  },
}));

export default styles;
