import React from 'react';
import P from 'prop-types';
import { Box, Grid } from '@material-ui/core';
import Brands from '@/pages/neworderpartner/utils/Brands';
import style from './style';
import InlineGridBrand from '../InlineGridBrand';
import ButtonMoreBrands from '../ButtonMoreBrands';

const maxBrands = 4;

const StepSelectBrand = (props) => {
  const [showAll, setShowAll] = React.useState(false);
  const classes = style();

  const handleShowMore = () => setShowAll(() => !showAll);
  const handleBrandClick = (ev) => (
    ev?.currentTarget
      && props.handleNextStep(ev.currentTarget.value)
  );
  return (
    <Grid container spacing={2} className={classes.brands}>
      {Brands.map(
        (brand, index) => {
          const show = showAll || index < maxBrands;
          return (
            <InlineGridBrand
              key={brand.name}
              show={show}
              brand={brand}
              handleClick={handleBrandClick}
            />
          );
        },
      )}
      <Box textAlign="end" display="block" width="100%">
        <ButtonMoreBrands showMore={showAll} handleClick={handleShowMore} />
      </Box>
    </Grid>
  );
};

StepSelectBrand.propTypes = {
  handleNextStep: P.func.isRequired,
};

export default React.memo(StepSelectBrand);
