import React from 'react';
import {
  InputAdornment, TextField,
} from '@material-ui/core';
import ReactInputMask from 'react-input-mask';
import ContactPhoneIcon from '@material-ui/icons/ContactPhone';
import { propTypes, defaultProps } from './PropTypes';
import useStyle from './style';

function InputPhone({
  handleChange, handleBlur, errors, value, typeInput, disabled,
}) {
  const classes = useStyle();
  return (
    <ReactInputMask
      mask="(99) 99999-9999"
      value={value}
      onChange={(event, valuePhone) => {
        if (event) {
          handleChange('telefone', event.target.value);
        }
      }}
      onBlur={handleBlur}
      disabled={disabled}
      maskPlaceholder={null}
    >
      <TextField
        fullWidth
        className={classes.textField}
        id="telefone"
        name="telefone"
        label="Celular"
        size="small"
        variant={typeInput}
        color="primary"
        helperText={errors}
        error={!!errors}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <ContactPhoneIcon color={!disabled ? 'primary' : 'action'} />
            </InputAdornment>
          ),
        }}
      />
    </ReactInputMask>
  );
}

InputPhone.propTypes = propTypes;
InputPhone.defaultProps = defaultProps;

export default InputPhone;
