import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  setDevice, setStep,
} from '@redux/actions/NewOrderActions/index';

const mapStateToProps = ({ NewOrderReducer: { activeStep, device } }) => ({
  $state: {
    step: activeStep,
    conditions: device.conditions,
  },
});

const mapDispatchToProps = (dispatch) => ({
  $actions: bindActionCreators({
    setStep,
    setDevice,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
