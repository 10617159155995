import React from 'react';
import { Box } from '@material-ui/core';
import { StepperContentPropTypes, StepperContentDefaultProps } from './PropTypes';
import ConnectComponentRedux from './ConnectComponentRedux';
// COMPONENTS
import SelectDevice from '../SelectDevice';
import PaymentMethod from '../PaymentMethod';
import AboutCustomer from '../AboutCustomer';
import MoreAboutCustomer from '../MoreAboutCustomer';
import ConcludedOrder from '../ConcludedOrder';
import DeviceCondition from '../DeviceCondition';

const steps = [
  AboutCustomer,
  SelectDevice,
  DeviceCondition,
  MoreAboutCustomer,
  PaymentMethod,
  ConcludedOrder,
];
const StepperContent = (props) => {
  const { $state } = props;
  const { activeStep } = $state;
  React.useEffect(() => {
    document.querySelector('#topContent').scrollIntoView({ behavior: 'smooth' });
  }, [activeStep]);
  return steps[activeStep]
    ? React.createElement(steps[activeStep])
    : <Box textAlign="center">Erro, não encontrado</Box>;
};

StepperContent.propTypes = StepperContentPropTypes;
StepperContent.defaultProps = StepperContentDefaultProps;

export default ConnectComponentRedux(StepperContent);
