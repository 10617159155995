import { Paper, Typography } from '@material-ui/core';
import React from 'react';
import style from './style';
import StepperContent from '../StepperContent';
import StepperHeader from '../StepperHeader';

function FormStepper() {
  const classes = style();
  return (
    <div className={classes.backgroundForm}>
      <Typography className={classes.header} variant="h2">
        Não perca mais tempo!
      </Typography>
      <Typography className={classes.header} variant="h2">
        Faça sozinho seu orçamento agora.
      </Typography>
      <Paper id="top-content" className="form" elevation={0}>
        <div id="topContent" className={classes.topContent} />
        <StepperHeader />
        <StepperContent />
      </Paper>
    </div>
  );
}

export default FormStepper;
