import { makeStyles } from '@material-ui/core/';

const styles = makeStyles(({ spacing, breakpoints }) => ({
  itemText: {
    '& span': {
      fontSize: '10px',
    },
    '& p': {
      fontSize: '18px',
    },
  },
  itemIcon: {
    minWidth: '50px',
  },
}));

export default styles;
