import React from 'react';
import { Box, Divider, Collapse } from '@material-ui/core';
// REDUX
import { useSelector } from 'react-redux';
// COMPONENTS
import Coupon from './Components/Coupon';
import LabelItem from './Components/LabelItem';
import LabelTotal from './Components/LabelTotal';
// FUNCTIONS
import LabelCoupon from './Components/LabelCoupon';

function BoxCheckout() {
  const customerData = useSelector((state) => state.NewRepairReducer);
  const { transactionAmount, coupons } = customerData;
  const {
    taxCollect,
    taxDelivery,
    budgetRepair,
    totalBudgetRepair,
  } = transactionAmount;

  return (
    <Box paddingTop={0.85}>
      <Box mx="auto" width="100%" paddingTop={1} maxWidth={400}>
        <LabelItem title="Valor do reparo" value={budgetRepair / 100} />
        <LabelItem title="Frete da coleta" value={taxCollect / 100} />
        <LabelItem title="Frete da entrega" value={taxDelivery / 100} />
        <Box py={0.4}>
          <Divider />
        </Box>
        <Collapse in={coupons.length > 0}>
          <LabelTotal subtotal value={(taxCollect + taxDelivery + budgetRepair) / 100} />
          <LabelCoupon value={coupons.length > 0 ? coupons[0].discount / 100 : 'Sem Cupom'} />
        </Collapse>
        <LabelTotal
          value={
            totalBudgetRepair
              ? (taxCollect + taxDelivery + totalBudgetRepair) / 100
              : (taxCollect + taxDelivery + budgetRepair) / 100
          }
        />
        <Coupon />
      </Box>
    </Box>
  );
}

export default BoxCheckout;
