import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles(({ spacing }) => ({
  containerLabel: {
    padding: `${spacing(0.5)}px 0`,
  },
  labelItem: {
    fontSize: '1rem',
    fontWeight: '400',
  },
  labelTotal: {
    fontSize: '1.075rem',
    fontWeight: '400',
  },
  containerTitle: {
    float: 'left',
    display: 'contents',
  },
  containerValue: {
    float: 'right',
    fontWeight: '500',
  },
}));
