/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
// COMPONENTS
import InputCvc from '../InputCvc';
import InputCpf from '../InputCpf';
import InputName from '../InputName';
import InputNumber from '../InputNumber';
import InputExpiry from '../InputExpiry';
import InputsPreSet from '../InputsPreSet';
import InputIssuers from '../InputIssuers';
import InlineGridInput from '../InlineGridInput';
import InputInstallments from '../InputInstallments';
// FUNCTIONS
import connect from './ConnectComponentRedux';
import { defaultProps, propTypes } from './PropTypes';
// SERVICES
import getIssuers from '../../Services/GetIssuers';
import getInstallments from '../../Services/GetInstallments';
import getPaymentMethod from '../../Services/GetPaymentMethod';

let lastBinSearched;

const alreadySearch = (cardNumber) => {
  const bin = cardNumber.replace(/[^0-9]/gm, '').substring(0, 6);
  if (bin.length < 6) return true;

  const response = lastBinSearched === bin;
  lastBinSearched = bin;
  return response;
};

function ListFields({ formik, setInputFocus, $state }) {
  const [installments, setInstallments] = useState([]);
  const [issuers, setIssuers] = useState([]);
  const [isSearching, setSearching] = useState(false);

  const showAlert = () => null;

  const handleFocus = (field) => () => (
    setInputFocus(field)
  );

  const handleSearchPaymentMethod = (cardNumber) => new Promise((resolve) => {
    const bin = cardNumber.replace(/[^0-9]/gm, '').substring(0, 6);
    let paymentMethodId;
    setSearching(true);
    getPaymentMethod(bin)
      .then((responsePaymentMethodId) => {
        paymentMethodId = responsePaymentMethodId;
        formik.setFieldValue('paymentMethodId', paymentMethodId);
      })
      .catch(() => {
        showAlert({
          message: 'Houve um erro ao tentar recuperar informações de pagamento, tente novamente mais tarde, caso persista, contate o suporte',
          severity: 'error',
        });
      })
      .finally(() => {
        setSearching(false);
        resolve(paymentMethodId);
      });
  });

  const handleSearchIssuers = (paymentMethodId) => {
    if (paymentMethodId) {
      getIssuers(paymentMethodId)
        .then((avaibleIssuers) => {
          const mapIssuers = ({ id: value, name: text }) => ({ value, text });
          setIssuers(avaibleIssuers.map(mapIssuers));
        })
        .catch(() => {
          showAlert({
            message: 'Houve um erro ao tentar recuperar informações de emissor, tente novamente mais tarde, caso persista, contate o suporte',
            severity: 'error',
          });
        });
    }
  };

  const handleSearchInstallments = (paymentMethodId) => {
    if (paymentMethodId) {
      getInstallments(
        paymentMethodId,
        $state.transactionAmount / 100,
      )
        .then((firstInstallments) => {
          const mapInstallments = ({
            recommended_message: text,
            installments: value,
          }) => ({ value, text });
          setInstallments(firstInstallments.map(mapInstallments));
        })
        .catch(() => {
          showAlert({
            message: 'Houve um erro ao tentar recuperar informações de parcela, tente novamente mais tarde, caso persista, contate o suporte',
            severity: 'error',
          });
        });
    }
  };

  const currentTargetValue = (field, isBlur) => ({ currentTarget, value }) => {
    const currentValue = value ?? currentTarget?.value ?? '';
    if (isBlur) {
      setInputFocus('');
      if (!formik.touched[field]) {
        formik.setTouched({ [field]: true, ...formik.touched });
      }
    }

    if (field === 'number' && !alreadySearch(currentValue)) {
      handleSearchPaymentMethod(currentValue)
        .then((paymentMethodId) => {
          handleSearchIssuers(paymentMethodId);
          handleSearchInstallments(paymentMethodId);
        });
    }

    // if (field === 'issuer') {
    //   handleSearchInstallments(currentValue, formik.values.paymentMethodId);
    // }

    formik.setFieldValue(field, currentValue);
    formik.validateForm();
  };

  const getInputProps = (field) => ({
    handleChange: currentTargetValue(field),
    handleBlur: currentTargetValue(field, true),
    handleFocus: handleFocus(field),
    value: formik.values[field],
    error: formik.touched[field] ? formik.errors[field] : null,
    disabled: formik.isSubmitting,
  });

  React.useEffect(() => () => {
    lastBinSearched = null;
  }, []);

  return (
    <Grid item xs={12} md={6}>
      <Grid container spacing={2}>
        <InlineGridInput
          col={12}
          Component={InputName}
          {...getInputProps('name')}
        />
        <InlineGridInput
          col={12}
          isLoading={isSearching}
          Component={InputNumber}
          setInstallments={setInstallments}
          {...getInputProps('number')}
        />
        <InlineGridInput
          col={6}
          Component={InputExpiry}
          {...getInputProps('expiry')}
        />
        <InlineGridInput
          col={6}
          Component={InputCvc}
          {...getInputProps('cvc')}
        />
        <InlineGridInput
          col={12}
          Component={InputCpf}
          {...getInputProps('cpf')}
        />
        {/* <InlineGridInput
          col={12}
          Component={InputIssuers}
          options={issuers}
          selected={formik.values.issuer}
          {...getInputProps('issuer')}
        /> */}
        <InlineGridInput
          col={12}
          Component={InputInstallments}
          options={installments}
          selected={formik.values.installment}
          {...getInputProps('installment')}
        />
        <InputsPreSet
          paymentMethodId={formik.values.paymentMethodId}
          transactionAmount={$state.transactionAmount}
        />
      </Grid>
    </Grid>
  );
}

ListFields.propTypes = propTypes;
ListFields.defaultProps = defaultProps;

export default connect(ListFields);
