import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  setStep, setCollect, setDelivery, setCustomer, middlewares,
} from '@redux/actions/NewOrderActions/index';

const mapStateToProps = ({ NewOrderReducer: { activeStep, customer, payment } }) => ({
  $state: {
    step: activeStep,
    cpf: customer.cpf,
    collectAddress: customer.collect,
    deliveryAddress: customer.delivery,
    cpfCreditCard: payment.data.cpf,
  },
});

const mapDispatchToProps = (dispatch) => ({
  $actions: bindActionCreators({
    setStep,
    setCollect,
    setDelivery,
    setCustomer,
    calculateTotalBudgetAmount: middlewares.calculateTotalBudgetAmount,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
