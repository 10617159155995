import React, { useState } from 'react';
import axios from 'axios';
import { Box } from '@material-ui/core';
import LoadingContent from '../LoadingContent';
// COMPONENTS
import StepperButtons from '../StepperButtons';
import PreviewCard from './Components/PreviewCard';
import PrintServiceOrder from './Components/PrintServiceOrder/index';
import InlineAlertCollapse from '../InlineAlertCollapse';
// FUNCTIONS
import connect from './ConnectComponentRedux';
import { propTypes, defaultProps } from './PropTypes';
import handleResponse from './Components/PrintServiceOrder/Utils/ResponseHelper';

function ConcludedOrder({
  $state, $actions,
}) {
  const [isSubmitting, setSubmitting] = useState(false);
  const [termsAgreem, setTermsAgree] = useState(false);
  const [alert, setAlert] = useState({ show: false, message: null, severity: null });
  React.useEffect(() => {
    document.querySelector('body').scrollIntoView({ behavior: 'smooth' });
  }, []);

  const showAlert = (options) => setAlert({
    ...alert,
    ...options,
    show: true,
  });

  const closeAlert = () => setAlert({
    ...alert,
    show: false,
  });

  const handleBackStep = () => {
    $actions.setStep($state.step - 1);
  };

  const handleConfirm = () => {
    setSubmitting(true);
    closeAlert();
    $actions.createOrder()
      .then(() => {
        showAlert({
          severity: 'success',
          message: 'Sua ordem foi criada com sucesso! 🎉',
        });
      })
      .catch(({ errors }) => {
        console.log(errors, "deu erro");
        const errorInfo = {
          category: errors?.graphQLErrors[0].extensions.category,
          reason: errors?.graphQLErrors[0].extensions.reason,
        };
        const errorMessage = handleResponse(errorInfo);
        showAlert({ severity: 'error', message: errorMessage });
      })
      .finally(() => setSubmitting(false));
  };

  React.useEffect(() => {
    function getModalityType(deliveryCep, collectCep) {
      const cepCollectCleaned = collectCep.replace(/[^0-9]/gm, '');
      const cepDeliveryCleaned = deliveryCep.replace(/[^0-9]/gm, '');
      axios({
        url: `${process.env.REACT_APP_CI_URL}/reparafone/api-new/range/delivery/get/${cepCollectCleaned}`,
      }).then(({ data: r }) => {
        $actions.setDeliveryData({ modalities: r });
      });
      axios({
        url: `${process.env.REACT_APP_CI_URL}/reparafone/api-new/range/collect/get/${cepDeliveryCleaned}`,
      }).then(({ data: r }) => {
        $actions.setCollectModalities({ modalities: r });
      });
    }
    getModalityType($state.customer.delivery.zipCode, $state.customer.collect.zipCode);
  }, [$actions]);

  return (
    <>
      <Box maxWidth={668} width="100%">
        <InlineAlertCollapse
          show={alert.show}
          message={alert.message}
          severity={alert.severity}
        />
        <Box py={0.5}>
          <LoadingContent isLoading={isSubmitting}>
            <PreviewCard in={!$state.orderData} handleTermsAgree={setTermsAgree} />
            <PrintServiceOrder in={!!$state.orderData} data={$state.orderData} />
          </LoadingContent>
        </Box>
      </Box>
      <StepperButtons
        backStep={!$state.orderData ? handleBackStep : null}
        nextStep={termsAgreem && !$state.orderData ? handleConfirm : null}
        isSubmitting={isSubmitting ? StepperButtons.SUBMITTING.NEXT : null}
        nextText={!$state.orderData ? 'Confirmar e criar ordem' : 'Próximo'}
      />
    </>
  );
}

ConcludedOrder.propTypes = propTypes;
ConcludedOrder.defaultProps = defaultProps;

export default connect(ConcludedOrder);
