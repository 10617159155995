import React from 'react';
import {
  Icon, InputAdornment, TextField,
} from '@material-ui/core';
import ReactInputMask from 'react-input-mask';
import clsx from 'clsx';
import { InputZipCodePropTypes, InputZipCodeDefaultProps } from './PropTypes';
import InputZipCodeRules from './Rules';
import useStyle from '../../../../style';

function InputZipCode({
  handleChange, handleBlur, errors, value, typeInput, disabled,
}) {
  const classes = useStyle();
  return (
    <ReactInputMask
      mask="99999-999"
      value={value}
      onChange={handleChange}
      onBlur={handleBlur}
      disabled={disabled}
      maskPlaceholder={null}
    >
      <TextField
        fullWidth
        name="zipCode"
        label="CEP para entrega"
        variant={typeInput}
        color="primary"
        helperText={errors}
        error={!!errors}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Icon className={clsx(['fas', 'fa-map-marked-alt', classes.inputIcon])} color="action" />
            </InputAdornment>
          ),
        }}
      />
    </ReactInputMask>
  );
}

InputZipCode.propTypes = InputZipCodePropTypes;
InputZipCode.defaultProps = InputZipCodeDefaultProps;
InputZipCode.rules = InputZipCodeRules;

export default InputZipCode;
