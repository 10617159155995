import React from 'react';
import {
  Box,
  FormGroup,
  FormControlLabel,
  Checkbox,
  CircularProgress,
  Typography,
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import {
  setSelectedDefect,
  setDevice,
  resetSelectedDefect,
} from '@/redux/actions/NewOrderActions';
import PropTypes from './PropTypes';
import getDefectByName from '../../../../graphql/schemas/GetDefect';
import useStyles from './style';

export default function ButtonFakeDefect({ disabledBtnNextStep }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const partnerData = useSelector((state) => state.NewOrderReducer);
  const { device } = partnerData;

  const handleFakeDefect = () => {
    if (device.fakeDefect) {
      dispatch(resetSelectedDefect());
      return dispatch(setDevice({ fakeDefect: false }));
    }
    dispatch(
      setDevice({ isLoadingFakeDefect: true, isDisabledFakeDefect: true }),
    );
    dispatch(resetSelectedDefect());
    return getDefectByName('Verificar Defeito')
      .then((r) => {
        dispatch(setDevice({ fakeDefect: true }));
        disabledBtnNextStep(false);
        return dispatch(
          setSelectedDefect([
            {
              id: r.id,
              description: r.defect_description,
              isAble: true,
              maxPrice: 0,
              name: r.defect_name,
              parts: [
                {
                  part_type: 'Verificar Defeito',
                  part_price_selling: 0,
                },
              ],
            },
          ]),
        );
      })
      .catch((err) => console.log(err))
      .finally(() => dispatch(
        setDevice({ isLoadingFakeDefect: false, isDisabledFakeDefect: false }),
      ));
  };

  React.useEffect(() => {
    if (device.fakeDefect) disabledBtnNextStep(false);
  });

  return (
    <Box py={1}>
      <FormGroup row>
        <FormControlLabel
          disabled={device.isDisabledFakeDefect}
          control={
            device.isLoadingFakeDefect ? (
              <CircularProgress style={{ margin: '10px' }} size="1.5rem" />
            ) : (
              <Checkbox
                checked={device.fakeDefect}
                onChange={handleFakeDefect}
                name="fake_defect"
                color="primary"
              />
            )
          }
          label={(
            <Typography variant="body1" align="center">
              {'Não encontrou defeito? Envie para '}
              <strong>Orçamento</strong>
              !
            </Typography>
          )}
        />
      </FormGroup>
    </Box>
  );
}

ButtonFakeDefect.propTypes = PropTypes.propTypes;
ButtonFakeDefect.defaultProps = PropTypes.defaultProps;
