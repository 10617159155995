import React from 'react';
import {
  Icon, InputAdornment, TextField,
} from '@material-ui/core';
import ReactInputMask from 'react-input-mask';
import clsx from 'clsx';
import { propTypes, defaultProps } from '../../Utils/TextFieldPropTypes';
import rules from './Rules';
import useStyle from '../../../../../../style';

function InputCpf({
  handleChange, handleBlur, handleFocus, value, error, typeInput, disabled,
}) {
  const classes = useStyle();
  return (
    <div>
      <ReactInputMask
        mask="999.999.999-99"
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        disabled={disabled}
        onFocus={handleFocus}
        maskPlaceholder={null}
      >
        <TextField
          fullWidth
          label="CPF do Titular"
          variant={typeInput}
          color="primary"
          helperText={error}
          error={!!error}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Icon className={clsx(['fas', 'fa-id-card', classes.inputIcon])} size="small" />
              </InputAdornment>
            ),
          }}
        />
      </ReactInputMask>
      <select
        hidden
        value="CPF"
        id="docType"
        name="docType"
        data-checkout="docType"
        onChange={() => null}
      >
        <option value="CPF">CPF</option>
      </select>
      <input
        type="hidden"
        id="docNumber"
        name="docNumber"
        data-checkout="docNumber"
        value={String(value).replace(/[^0-9]/gm, '')}
      />
    </div>
  );
}

InputCpf.propTypes = propTypes;
InputCpf.defaultProps = defaultProps;
InputCpf.rules = rules;

export default InputCpf;
