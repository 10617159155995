import React from 'react';
import P from 'prop-types';
import clsx from 'clsx';
// ICONS
import DeviceUnknownRoundedIcon from '@material-ui/icons/DeviceUnknownRounded';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import AssignmentIcon from '@material-ui/icons/Assignment';
// STYLE
import style from './style';

export default function StepIcon(props) {
  const classes = style();
  const { active, completed, icon } = props;

  const icons = [
    null,
    <DeviceUnknownRoundedIcon fontSize="small" />,
    <LocalShippingIcon fontSize="small" />,
    <AssignmentIndIcon fontSize="small" />,
    <AssignmentIcon fontSize="small" />,
  ];

  return (
    <div
      className={clsx(classes.root, 'stepIcon', {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[icon]}
    </div>
  );
}

StepIcon.propTypes = {
  active: P.bool.isRequired,
  completed: P.bool.isRequired,
  icon: P.number.isRequired,
};
