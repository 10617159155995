import React from 'react';
import { Box, Card, Divider } from '@material-ui/core';
// COMPONENTS
import Title from './Components/Title';
import DeviceData from './Components/DeviceData';
import CustomerData from './Components/CustomerData';
import PaymentData from './Components/PaymentData';

export default function PreviewOrder() {
  return (
    <Card variant="outlined" component={Box} mb={2.4} py={1} px={2}>
      <Title />
      <CustomerData />
      <Divider />
      <DeviceData />
      <Divider />
      <PaymentData />
    </Card>
  );
}
