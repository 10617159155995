import React from 'react';
import {
  Grid, Typography, Button,
} from '@material-ui/core';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import { connect } from 'react-redux';
import { useMutation } from '@apollo/client';
import Alert from '@material-ui/lab/Alert';
import { Link } from 'react-router-dom';
import { AlertTitle } from '@material-ui/lab';
import { ChangePasswordMutation } from '../../../utils/GraphQL';
import { updateUserData } from '../../../redux/actions/auth/AuthActions';

const ChangePasswordSchema = Yup.object().shape({
  newPassword: Yup.string()
    .required('Digite a nova senha.')
    .matches(
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/gm,
      {
        excludeEmptyString: true,
        message: `Sua senha deve ter no mínimo 8 carácteres, 
          uma letra maiúscula, uma letra minúscula, um número e um carácter especial.`,
      },
    ),
  confirmPassword: Yup.string()
    .required('Digite a mesma senha.')
    .oneOf(
      [Yup.ref('newPassword'), null],
      'As duas senhas digitadas devem ser iguais!',
    ),
});

const ChangePassword = (props) => {
  const {
    history, location, updateUserData, showTitle = true,
  } = props;
  const [state, setState] = React.useState(false);
  const [changePasswordMutate, { error }] = useMutation(ChangePasswordMutation);

  const onSubmitForm = ({ newPassword }, { setSubmitting }) => {
    const { state: { token, email } = {} } = location || {};
    changePasswordMutate({ variables: { newPassword, token, email } })
      .then((r) => {
        if (history) history.location.state = { ...history.location.state, passwordChanged: true };
        setState(true);
        updateUserData(r.data.CustomerChangePassword);
      })
      .catch((e) => {
        console.log(e, { ...e }, 'objeto');
      })
      .finally(() => setSubmitting(false));
  };
  return (
    <>
      {showTitle && (
      <Grid container direction="row">
        <Grid item xs={12}>
          <Typography variant="subtitle1">Alterar senha</Typography>
        </Grid>
      </Grid>
      )}
      <Formik
        initialValues={{
          newPassword: '',
          confirmPassword: '',
        }}
        validationSchema={ChangePasswordSchema}
        onSubmit={onSubmitForm}
      >
        {({
          errors, touched, isSubmitting, dirty,
        }) => (
          <Form>
            <Grid container direction="column" spacing={4}>
              <Grid item xs={12}>
                <Field
                  type="password"
                  disabled={isSubmitting || state}
                  component={TextField}
                  fullWidth
                  label="Nova Senha"
                  name="newPassword"
                  error={!!touched.newPassword && !!errors.newPassword}
                  helperText={touched.newPassword && errors.newPassword}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  type="password"
                  disabled={isSubmitting || state}
                  component={TextField}
                  fullWidth
                  label="Confirmar senha"
                  name="confirmPassword"
                  error={!!touched.confirmPassword && !!errors.confirmPassword}
                  helperText={touched.confirmPassword && errors.confirmPassword}
                  variant="outlined"
                />
              </Grid>
              {state && (
              <Grid item xs={12}>
                <Alert severity="success">
                  Sua senha foi alterada com sucesso!
                  {' '}
                  {history && <Link to="/">Clique aqui para voltar à pagina inicial!</Link>}
                </Alert>
              </Grid>
              )}
              {error?.graphQLErrors && (
              <Grid item xs={12}>
                <Alert severity="error">
                  <AlertTitle>Ocorreu um erro!</AlertTitle>
                  {Object.values(error.graphQLErrors.slice().shift().extensions.validation).slice().shift().slice()
                    .shift()}
                </Alert>
              </Grid>
              )}
              <Grid item xs={12}>
                <Button disabled={isSubmitting || state || !dirty} type="submit" color="secondary" variant="contained" fullWidth title="Confirmar">
                  Confirmar
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default connect(null, { updateUserData })(ChangePassword);
