import {
  Box,
  FormControl, FormControlLabel, FormHelperText, FormLabel, makeStyles, Radio, RadioGroup, styled,
} from '@material-ui/core';
import { useFormik } from 'formik';
import React from 'react';
import * as Yup from 'yup';
import { defaultTo, mapValues } from 'lodash';
import connect from './ConnectComponentRedux';
import { propTypes, defaultProps } from './PropTypes';
import StepperButtons from '../StepperButtons';

const RadioLabel = styled(({ value, currentValue, ...other }) => (
  <FormControlLabel value={value} {...other} />
))({
  border: ({ value, currentValue }) => `1px solid ${value === currentValue ? '#F6C23A' : '#999'}`,
  padding: '1px 10px',
  paddingRight: '1.5rem',
  minWidth: '85px',
  fontWeight: 500,
  verticalAlign: 'middle',
  marginRight: '1rem',
  marginBottom: '15px',
  marginLeft: '0',
  '&:hover': {
    border: '1px solid #F6C23A',
  },
  '& .MuiFormControlLabel-label': {
    fontWeight: 500,
  },
});
const useStyles = makeStyles((theme) => ({
  radioGroup: {
    marginTop: '1rem',
  },
}));
const ListQuestions = ({ questions, $actions, $state }) => {
  const classes = useStyles();
  const backStep = () => {
    $actions.setStep($state.step - 1);
  };
  const formik = useFormik({
    initialValues: defaultTo($state.conditions, questions)
      .reduce((acc, { id, condition }) => ({ ...acc, [id]: condition || null }), {}),
    validationSchema: Yup.object(defaultTo(questions, [])
      .reduce((acc, { id }) => ({ ...acc, [id]: Yup.string().oneOf(['Y', 'N', 'NA']).required() }),
        {})),
    onSubmit: async (values) => {
      const conditions = Object.values(
        mapValues(values,
          (v, key) => ({ id: key, condition: v, ...questions.find((q) => q.id === key) })),
      );
      $actions.setDevice({ conditions });
      $actions.setStep($state.step + 1);
    },
  });
  const isSubmitting = formik.isSubmitting ? StepperButtons.SUBMITTING.NEXT : null;
  return (
    <form onSubmit={formik.handleSubmit}>
      <Box>
        {questions.map(({ id, question }) => (
          <Box width="100%">
            <FormControl component="fieldset">
              <FormLabel component="span" style={{ fontWeight: 500, color: 'black' }}>{question}</FormLabel>
              <RadioGroup className={classes.radioGroup} row name={id} value={formik.values[id]} onChange={formik.handleChange}>
                <RadioLabel
                  currentValue={formik.values[id]}
                  value="Y"
                  control={<Radio size="small" disabled={!!isSubmitting} />}
                  label="Sim"
                  disabled={!!isSubmitting}
                />
                <RadioLabel
                  currentValue={formik.values[id]}
                  value="N"
                  control={<Radio size="small" disabled={!!isSubmitting} />}
                  label="Não"
                  disabled={!!isSubmitting}
                />
                <RadioLabel
                  currentValue={formik.values[id]}
                  value="NA"
                  control={<Radio size="small" disabled={!!isSubmitting} />}
                  label="Não se aplica"
                  disabled={!!isSubmitting}
                />
              </RadioGroup>
              {formik.touched[id] && <FormHelperText>{formik.errors[id]}</FormHelperText>}
            </FormControl>
          </Box>
        ))}
      </Box>
      <StepperButtons
        backStep={backStep}
        nextStep={formik.submitForm}
        isSubmitting={!!isSubmitting}
      />
    </form>
  );
};
ListQuestions.propTypes = propTypes;
ListQuestions.defaultProps = defaultProps;
export default connect(ListQuestions);
