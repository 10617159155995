import updateProductByModelColor from './UpdateProductByModelColor';
import calculateTotalBudgetAmount from './CalculateTotalBudgetAmount';
import checkCreditCard from './CheckCreditCard';
import createOrder from './CreateOrder';

export default {
  updateProductByModelColor,
  calculateTotalBudgetAmount,
  checkCreditCard,
  createOrder,
};
