import { client } from '@/Apollo';
import EditCustomer from '../mutation/EditCustomer';

const editCustomerInfo = (data) => new Promise((resolve, reject) => {
  client
    .mutate({
      mutation: EditCustomer,
      variables: { ...data },
      errorPolicy: 'all',
    })
    .then((r) => {
      resolve(r.data.EditMeCustomer);
    })
    .catch((e) => {
      console.log(e.message, 'erro ao editar informações');
      reject(e.message);
    });
});

export default editCustomerInfo;
