import React from 'react';
import P from 'prop-types';
import { Grid } from '@material-ui/core';

const InlineGridInput = ({
  fieldName, Component, formik, ...customData
}) => (
  <Grid item xs={12}>
    <Component
      handleBlur={formik.handleBlur}
      handleChange={formik.handleChange}
      errors={formik.touched[fieldName] ? formik.errors[fieldName] : undefined}
      value={formik.values[fieldName]}
      disabled={formik.isSubmitting}
      typeInput="outlined"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...customData}
    />
  </Grid>
);

InlineGridInput.propTypes = {
  fieldName: P.string.isRequired,
  Component: P.oneOfType([P.func, P.element]).isRequired,
  formik: P.shape({
    handleBlur: P.func.isRequired,
    handleChange: P.func.isRequired,
    touched: P.shape({}),
    errors: P.shape({}),
    values: P.shape({}),
    isSubmitting: P.bool.isRequired,
  }).isRequired,
  customData: P.shape({}),
};

InlineGridInput.defaultProps = {
  customData: {},
};

export default InlineGridInput;
