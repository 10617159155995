import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles(({ palette: { primary, secondary } }) => ({
  root: {
    backgroundImage: 'linear-gradient( 136deg, #eee, #ccc )',
    zIndex: 1,
    color: '#fff',
    width: 35,
    height: 35,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  activeCarrefour: {
    backgroundImage: `linear-gradient(136deg,
        #EF3641 5%,        
        #1752CC 90%
      )`,
    boxShadow: '0 0 2px 2px rgba(0,0,0,.25)',
  },
  completedCarrefour: {
    backgroundImage: `linear-gradient(136deg,
        #EF3641 -28%,
        #1752CC 40%
      )`,
  },
  activeReparofone: {
    backgroundImage: `linear-gradient(136deg,
        ${secondary.light} -28%,
        ${primary.light} 30%,
        ${primary.main} 60%
      )`,
    boxShadow: '0 0 2px 2px rgba(0,0,0,.25)',
  },
  completedReparofone: {
    backgroundImage: `linear-gradient(136deg,
        ${secondary.light} -28%,
        ${primary.light} 30%,
        ${primary.main} 60%
      )`,
  },
}));
