import React from 'react';
import { Box, Typography } from '@material-ui/core';
// REDUX
import { useSelector, useDispatch } from 'react-redux';
import { setSelectedDefect, setLead } from '@redux/actions/NewRepairActions';
// COMPONENTS
import createLeadRepair from '@/pages/home/utils/CreateLeadRepair';
import InputDefects from '../InputDefects';
import BoxRepairBudget from '../BoxRepairBudget';
// PROP-TYPES
import { propTypes, defaultProps } from './PropTypes';

function SelectDefects({ disabledBtnNextStep, nextStep, openRefuseBudget }) {
  const dispatch = useDispatch();
  const customerData = useSelector((state) => state.NewRepairReducer);
  const [isSelectedDefect, setSelectDefect] = React.useState(false);
  const partnerData = useSelector((state) => state.PartnerStoreReducer);
  const {
    customer, transactionAmount, device, origin_store, origin_user,
  } = customerData;
  const { product, selectedDefects } = device;
  const { collect } = customer;
  const collectZipCode = collect.zipCode;

  const data = {
    customer,
    estimate: transactionAmount,
    product,
    selectedDefects,
    collectZipCode,
    origin_store,
    origin_user,
    branch_slug: partnerData.branch.branch_slug,
  };
  const handleSelectDefects = (defects) => {
    disabledBtnNextStep(true);
    dispatch(setSelectedDefect(defects));
  };
  const hookCalcBudget = async (createLead = true, canRepair = true) => {
    if (createLead) {
      await createLeadRepair(data).then((leadData) => {
        dispatch(setLead(leadData));
      });
    }
    if (canRepair) disabledBtnNextStep(false);
  };

  return (
    <Box width="100%">
      <Typography variant="h6">Selecione os defeitos</Typography>
      <Box display="flex" justifyContent="center" width="100%">
        <Box maxWidth={500} width="100%" display="block" mx={1}>
          <InputDefects
            defects={product.defects}
            selectedDefects={selectedDefects}
            handleSelect={handleSelectDefects}
            isSelectedDefect={isSelectedDefect}
          />
          <BoxRepairBudget
            defects={selectedDefects}
            selectedDefects={selectedDefects}
            hookCalcBudget={hookCalcBudget}
            setSelectDefect={setSelectDefect}
            nextStep={nextStep}
            openRefuseBudget={openRefuseBudget}
          />
        </Box>
      </Box>
    </Box>
  );
}

SelectDefects.propTypes = propTypes;
SelectDefects.defaultProps = defaultProps;

export default React.memo(SelectDefects);
