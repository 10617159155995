import React from 'react';
import * as P from 'prop-types';

export default function InputsPreSet({
  transactionAmount, paymentMethodId,
}) {
  return (
    <div>
      <input type="hidden" name="transactionAmount" id="transactionAmount" value={transactionAmount} />
      <input type="hidden" name="paymentMethodId" id="paymentMethodId" value={paymentMethodId} />
    </div>
  );
}

InputsPreSet.propTypes = {
  transactionAmount: P.number.isRequired,
  paymentMethodId: P.oneOfType([P.string, P.number]).isRequired,
};
