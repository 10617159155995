import React from 'react';
import {
  Grid, Typography, Divider, Button,
} from '@material-ui/core';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import { connect } from 'react-redux';
import { useMutation } from '@apollo/client';
import { startFetching, stopFetching } from '../../../redux/actions/auth/AuthActions';
import { requestRecoverPasswordCode } from '../../../utils/GraphQL';

const RecoverPassSchema = Yup.object().shape({
  email: Yup.string().email('Digite um e-mail válido!').required('Digite o seu e-mail!'),
});
const RequestPasswordRecover = (props) => {
  const {
    history, startFetching, stopFetching,
  } = props;
  const [requestTokenGeneration, { error }] = useMutation(requestRecoverPasswordCode);
  const onSubmitForm = ({ email }, { setSubmitting }) => {
    startFetching();
    requestTokenGeneration({
      variables: { customerEmail: email },
    }).then((r) => history.push('/recover/password/token', { email: r.data.CustomerRequestRecoverPassword.customer_email }))
      .catch((e) => console.log(e, 'erro'))
      .finally(() => { setSubmitting(false); stopFetching(); });
  };
  return (
    <>
      <Grid container direction="row">
        <Grid item xs={12}>
          <Typography variant="subtitle1">Recuperar credenciais</Typography>
        </Grid>
      </Grid>
      <Divider style={{ marginTop: '0.5rem', marginBottom: '0.5rem' }} />
      <Formik
        initialValues={{
          email: '',
        }}
        validationSchema={RecoverPassSchema}
        onSubmit={onSubmitForm}
      >
        {({ errors, touched, isSubmitting, dirty }) => (
          <Form>
            <Grid container direction="column" spacing={4}>
              <Grid item xs={12}>
                <Typography variant="body2">Digite o e-mail que você utilizou durante a criação da ordem.</Typography>
              </Grid>
              <Grid item xs={12}>
                <Field disabled={isSubmitting} component={TextField} fullWidth label="E-mail" name="email" error={!!(touched.email && errors.email) || (touched.email && !!error?.message)} helperText={(touched.email && errors.email) || (touched.email && error?.message)} variant="outlined" />
              </Grid>
              <Grid item xs={12}>
                <Button disabled={isSubmitting || !dirty} type="submit" color="secondary" variant="contained" fullWidth title="Recuperar senha">
                  Recuperar senha
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};
const mapStateToProps = (state) => {
  const { AuthReducer } = state;
  const { isFetching } = AuthReducer;
  return {
    isFetching,
  };
};
export default connect(mapStateToProps, { startFetching, stopFetching })(RequestPasswordRecover);
