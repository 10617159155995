export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const START_FETCHING = 'START_FETCHING';
export const STOP_FETCHING = 'STOP_FETCHING';
export const SET_AUTHENTICATED = 'SET_AUTHENTICATED';
export const UPDATE_USER_DATA = 'UPDATE_USER_DATA';

const token = localStorage.getItem('apiToken');

const initialState = {
  isAuthenticated: !!token,
  isFetching: false,
  user: {
    token,
  },
};

export function AuthReducer(state = initialState, action) {
  switch (action.type) {
    case START_FETCHING:
      return {
        ...state,
        isFetching: true,
      };
    case STOP_FETCHING:
      return {
        ...state,
        isFetching: false,
      };
    case SET_AUTHENTICATED:
      return {
        ...state,
        isAuthenticated: action.isAuthenticated,
        user: action.saveUserData,
      };
    case UPDATE_USER_DATA:
      return {
        ...state,
        user: { ...state.user, ...action.userData },
      };
    default:
      return state;
  }
}
