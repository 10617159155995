import React from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
// DATE FORMATTERS
import {
  format, add, isWeekend, isFriday,
} from 'date-fns';
import Holidays from '@/utils/Holidays';
// COMPONENTS
import Title from './Components/Title';
import Address from './Components/Address';
import StepperButtons from '../StepperButtons';
import BoxCheckout from './Components/BoxCheckout';
import CreditCardMethod from './Components/CreditCardMethod';
import RadioGroupPayment from './Components/RadioGroupPayment';
// FUNCTIONS
import connect from './ConnectComponentRedux';
import { propTypes, defaultProps } from './PropTypes';
import LaterPaymentMethod from './Components/LaterPaymentMethod';

function PaymentMethod({ $state, $actions }) {
  const { billingAddress } = $state;
  const creditCardRef = React.useRef(null);
  const laterPaymentRef = React.useRef(null);

  const [methodSelect, setMethodSelect] = React.useState(
    $state.payment.type || 'laterPayment',
  );
  const [isSubmitting, setSubmitting] = React.useState(null);
  const refs = { creditCard: creditCardRef, laterPayment: laterPaymentRef };

  const handleRadioChange = ({ currentTarget }) => setMethodSelect(currentTarget?.value);

  const formik = useFormik({
    initialValues: {
      address: billingAddress,
    },
    validationSchema: Yup.object({
      address: Yup.object(Address.rules),
    }),
    onSubmit: async ({ address }) => {
      $actions.setBillingAddress(address);
      setSubmitting(StepperButtons.SUBMITTING.NEXT);
      try {
        if (refs[methodSelect]?.current?.nextStep) {
          await refs[methodSelect].current.nextStep();
        }
      } finally {
        setSubmitting(null);
      }
    },
  });

  const nextStep = formik.submitForm;

  const backStep = () => {
    $actions.setBillingAddress(formik.values.address);
    if (refs[methodSelect]?.current?.backStep) {
      refs[methodSelect].current.backStep();
    } else {
      $actions.setStep($state.step - 1);
    }
  };

  const initialDeliveryDate = () => {
    if (isWeekend(add(new Date(), { days: 3 }))) return add(new Date(), { days: 5 });
    if (Holidays.isHoliday(add(new Date(), { days: 3 }))) {
      if (isFriday(add(new Date(), { days: 3 }))) return add(new Date(), { days: 6 });
      return add(new Date(), { days: 4 });
    }
    if (isFriday(new Date())) return add(new Date(), { days: 5 });
    return add(new Date(), { days: 3 });
  };

  const [deliveryDate, setDeliveryDate] = React.useState(initialDeliveryDate);

  React.useEffect(() => {
    $actions.setDeliveryData({ schedule_delivery: format(deliveryDate, 'dd-MM-yyyy') });
  }, [$actions]);

  return (
    <>
      <Title label="Dados para pagamento" />
      <BoxCheckout />
      <Address formik={formik} />
      <Title label="Selecione o método" />
      <RadioGroupPayment
        handleRadioChange={handleRadioChange}
        methodSelect={methodSelect}
        options={[
          {
            icon: 'payment',
            value: 'creditCard',
            label: 'Cartão de crédito',
            component: <CreditCardMethod ref={creditCardRef} />,
          },
          {
            icon: 'link',
            value: 'laterPayment',
            label: 'Pagar por link no email',
            component: <LaterPaymentMethod ref={laterPaymentRef} />,
          },
        ]}
      />
      <StepperButtons
        isSubmitting={isSubmitting}
        nextStep={nextStep}
        backStep={backStep}
        nextText="Confirmar e revisar"
      />
    </>
  );
}

PaymentMethod.propTypes = propTypes;
PaymentMethod.defaultProps = defaultProps;

export default connect(PaymentMethod);
