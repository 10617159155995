import React from 'react';
import { Button } from 'semantic-ui-react';

import Buttons from './Button';

const Menu = () => (
  <>
    <div
      className="hidden-desktop-button-orcamento"
      style={{
        marginLeft: 'auto',
        display: 'flex',
        paddingTop: '10px',
        paddingBottom: '10px',
      }}
    >
      <Buttons
        url="/novaordem#do-estimate"
        component={Button}
        className="button-orcamento"
        ButtonProps={{
          icon: 'dollar',
          labelPosition: 'right',
          content: 'Orçamento',
        }}
      />
    </div>
    <div
      className="hidden-desktop-button-login"
      style={{
        display: 'flex',
        maxDeviceWidth: '100px',
      }}
    >
      <Buttons
        label="Entrar"
        url="/login"
        component={Button}
        className="button-entrar-franch"
        ButtonProps={{
          icon: 'right arrow',
          labelPosition: 'right',
          content: 'Entrar',
        }}
      />
    </div>
  </>
);

export default Menu;
