import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Modal,
  Backdrop,
  Typography,
  Button,
  makeStyles,
  DialogTitle,
  DialogContent,
  DialogActions,
  Chip,
  Dialog,
} from '@material-ui/core';
import { useSpring, animated } from 'react-spring/web.cjs';
import StoreIcon from '@material-ui/icons/Store';
import logo from '@images/trocafone-reparos/trocafone-reparos -color-desktop.svg';
import ConnectComponentRedux from './ConnectComponentRedux';
import InlineAlertCollapse from './components/InlineAlertCollapse';
import Select from './components/Select';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #f2f2f2',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(3, 4),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
  },
  titleSelect: {
    marginBottom: '0px',
    marginTop: '25px',
  },
  box: {
    padding: 0,
    maxWidth: 'fit-content',
  },
}));

const Fade = React.forwardRef((props, ref) => {
  const { in: open, children, onEnter, onExited, ...other } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter();
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited();
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {children}
    </animated.div>
  );
});

Fade.propTypes = {
  children: PropTypes.element,
  in: PropTypes.bool.isRequired,
  onEnter: PropTypes.func,
  onExited: PropTypes.func,
};

function StoresList({ $actions, $state }) {
  const classes = useStyles();
  const [open, setOpenModal] = React.useState(true);
  const [alert, setAlert] = React.useState({
    show: false,
    message: null,
    severity: null,
  });
  const [openDialog, toggleOpen] = React.useState(false);
  const [isDisabled, setIsDisabled] = React.useState(false);
  const [dialogValue, setDialogValue] = React.useState('');
  const [storeValues, setStoreValues] = React.useState(null);
  const [islocalStorage, setLocalStorage] = React.useState(true);

  const handleCloseDialog = () => {
    setDialogValue('');
    toggleOpen(false);
    setIsDisabled(false);
  };
  const handleCloseModal = () => {
    if (!alert.show) {
      setOpenModal(false);
      handleCloseDialog();
    }
  };
  const setStoreRedux = () => {
    $actions.setCollect(storeValues.addresses[0]);
    $actions.setStore(storeValues);
    const store = JSON.stringify(storeValues);
    localStorage.setItem('storeCollect', store);
    handleCloseModal();
    window.location.reload();
  };
  if (localStorage.storeCollect && islocalStorage) {
    const store = JSON.parse(localStorage.storeCollect);
    $actions.setCollect(store.addresses[0]);
    $actions.setStore(store);
    setLocalStorage(false);
  }
  return (
    <>
      <Dialog open={openDialog} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
          Por gentileza, confirme a escolha da loja.
        </DialogTitle>
        <DialogContent>
          <Chip icon={<StoreIcon />} label={dialogValue} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancelar</Button>
          <Button onClick={setStoreRedux} color="primary">
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
      {!localStorage.storeCollect && (
        <Modal
          aria-labelledby="spring-modal-title"
          aria-describedby="spring-modal-description"
          className={classes.modal}
          open={open}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={open}>
            <div className={classes.paper}>
              <img
                src={logo}
                alt="Trocafone Reparos"
                style={{ width: '350px' }}
                title="Trocafone Reparos"
              />
              <Typography
                className={classes.titleSelect}
                variant="h6"
                component="h6"
                gutterBottom
              >
                Escolha a Loja
              </Typography>
              <Box className={classes.box} py={1}>
                <InlineAlertCollapse
                  show={alert.show}
                  message={alert.message}
                  severity={alert.severity}
                />
              </Box>
              <Select
                setAlert={setAlert}
                setDialogValue={setDialogValue}
                setStoreValues={setStoreValues}
                toggleOpen={toggleOpen}
                isDisabledDialog={isDisabled}
                setIsDisabled={setIsDisabled}
              />
            </div>
          </Fade>
        </Modal>
      )}
    </>
  );
}

export default ConnectComponentRedux(StoresList);
