import React from 'react';
import {
  Box, Collapse, Divider,
} from '@material-ui/core';
// REDUX
import { useSelector, useDispatch } from 'react-redux';
import { setStep } from '@redux/actions/NewRepairActions';
// COMPONENTS
import SelectDefects from './Components/SelectDefects';
import StepperButtons from '../StepperButtons';
import StepperSelectDevice from './Components/StepperSelectDevice';

function SelectDevice() {
  const [disabledBtnNext, setDisabledBtnNext] = React.useState(true);
  const dispatch = useDispatch();
  const customerData = useSelector((state) => state.NewRepairReducer);
  const { device, activeStep } = customerData;
  const isSelectedDevice = !!device.product.name;

  const nextStep = () => dispatch(setStep(activeStep + 1));

  return (
    <>
      <StepperSelectDevice />
      <Box width="100%" py={1}>
        <Divider />
      </Box>
      <Collapse in={isSelectedDevice} unmountOnExit>
        <SelectDefects disabledBtnNextStep={setDisabledBtnNext} nextStep={nextStep} />
      </Collapse>
      <StepperButtons
        nextStep={disabledBtnNext ? undefined : nextStep}
      />
    </>
  );
}

export default SelectDevice;
