import React from 'react';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';

const Lista = (props) => {
  const { icon, title, text } = props;

  return (
    <>
      <div className="lista-servicos">
        <List className="root">
          <ListItem alignItems="center">
            <ListItemAvatar>
              <Avatar alt={title} src={icon} />
            </ListItemAvatar>
            <ListItemText
              primary={title}
              secondary={text}
            />
          </ListItem>
        </List>
      </div>
    </>
  );
};

export default Lista;
