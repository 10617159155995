import * as P from 'prop-types';

const propTypes = {
  order: P.arrayOf(P.shape({
    isPaid: P.bool.isRequired,
    estimates: P.arrayOf(P.shape({
      repair_obs: P.string.isRequired,
      repairResult: P.shape({
        result_name: P.string.isRequired,
      }).isRequired,
      rootCauses: P.shape({
        cause_name: P.string.isRequired,
      }).isRequired,
    })).isRequired,
  })).isRequired,
};

export default {
  propTypes,
  defaultProps: {},
};
