import React from 'react';
import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import { LinearProgress } from '@material-ui/core';
import ListOrders from './ListOrders';
import OrderLine from './OrderLine';
import Searcher from './Searcher';

const OrdersBridge = ({ OrderLineComponent, query }) => {
  const queryParams = new URLSearchParams(window.location.search);
  const [search] = React.useState(queryParams.get('search') || '');
  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(10);

  const store = JSON.parse(localStorage.storeCollect);
  const storeId = store.id;
  const {
    data, loading, error, fetchMore,
  } = useQuery(query, {
    notifyOnNetworkStatusChange: true,
    variables: {
      storeId, page, first: 10, search, searchLike: `%${search}%`, limit,
    },
  });
  const handlePageChange = (event, newPage) => {
    if (newPage < 1) return;
    setPage(newPage);
    fetchMore({ variables: { page: newPage } }).then(({ data }) => {
      setLimit(data.ReparofonePartnerOrders.paginatorInfo.total);
    });
  };
  const fetchSearch = (options) => {
    setPage(1);
    return fetchMore(options).then(({ data }) => {
      setLimit(data.ReparofonePartnerOrders.paginatorInfo.total);
    });
  };
  const orders = data?.ReparofonePartnerOrders.data;
  const paginatorInfo = data?.ReparofonePartnerOrders.paginatorInfo;
  React.useEffect(() => {
    const newLimit = data?.ReparofonePartnerOrders.paginatorInfo.total;
    if (newLimit) setLimit(newLimit);
  }, [data]);
  return (
    <>
      <Searcher fetchSearch={fetchSearch} />
      <div style={{ marginTop: '1rem' }}>
        {loading && <LinearProgress />}
      </div>
      <ListOrders
        isLoading={loading}
        OrderLineComponent={OrderLineComponent}
        fetchMore={fetchMore}
        query={query}
        paginatorInfo={paginatorInfo}
        limit={limit}
        orders={orders}
        handlePageChange={handlePageChange}
        page={page}
      />
    </>
  );
};
OrdersBridge.propTypes = {
  OrderLineComponent: PropTypes.elementType,
  query: PropTypes.any.isRequired,
};
OrdersBridge.defaultProps = {
  OrderLineComponent: OrderLine,
};
export default OrdersBridge;
