import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles(({ typography, breakpoints }) => ({
  copyright: {
    width: '100%',
    background: '#3C3C3C',
    color: '#f9f9f9',
    padding: '10px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    fontSize: '1.2em',
    fontWeight: 100,
    [breakpoints.down('sm')]: {
      paddingBottom: '70px',
    },
  },
  copyrightCarrefour: {
    fontFamily: 'Milliard Book',
  },
  copyrightReparofone: {
    fontFamily: 'Roboto',
  },
}));

export default styles;
