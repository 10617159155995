import React from 'react';
// MATERIAL-UI
import {
  Box, Typography, List,
} from '@material-ui/core';
// ICONS
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';
import PaymentIcon from '@material-ui/icons/Payment';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import AssessmentIcon from '@material-ui/icons/Assessment';
// PROP-TYPES
import PropTypes from './PropTypes';
// COMPONENTS
import { InfoData } from '../Common';
// UTILS
import {
  paymentFormatter,
  paymentResultFormatter,
} from './utils';
// STYLES
import styles from './styles';

const Payment = ({ order }) => {
  const classes = styles();
  const [isPaid, setIsPaid] = React.useState(null);
  const [totalPaid, setTotalPaid] = React.useState(null);
  const [discountCoupon, setDiscountCoupon] = React.useState(null);
  const [deliveryPrice, setDeliveryPrice] = React.useState(null);
  React.useEffect(() => {
    setIsPaid(paymentResultFormatter(order.isPaid));
    setTotalPaid(paymentFormatter(order.totalPaid));
    setDiscountCoupon(paymentFormatter(order.discountValue));
    setDeliveryPrice(paymentFormatter(order.deliveryPrice));
  }, []);
  return (
    <Box className={classes.box}>
      <Typography className={classes.title} variant="h2" align="left">
        Dados do Pagamento
      </Typography>
      <List className={classes.list}>
        <InfoData
          title="Status do Pagamento"
          info={isPaid}
          icon={<PaymentIcon className={classes.icon} />}
        />
        <InfoData
          title="Status da Transação"
          info={order.statusTransaction}
          icon={<AssessmentIcon className={classes.icon} />}
        />
        <InfoData
          title="Valor do Desconto"
          info={discountCoupon}
          icon={<ConfirmationNumberIcon className={classes.icon} />}
        />
        <InfoData
          title="Valor do Frete"
          info={deliveryPrice}
          icon={<LocalShippingIcon className={classes.icon} />}
        />
        <InfoData
          title="Valor do Pagamento"
          info={totalPaid}
          icon={<MonetizationOnIcon className={classes.icon} />}
        />
      </List>
    </Box>
  );
};

export default Payment;

Payment.propTypes = PropTypes.propTypes;
Payment.defaultProps = PropTypes.defaultProps;
