import gql from 'graphql-tag';

export const GetBranchById = gql`
  query GetBranchById($id: ID!) {
    ReparafoneGetBranchById(id: $id) {
      id
      branch_name
      branch_address
      branch_slug
      service_terms
      branch_logo
      show_white_label
    }
  }
`;

export default GetBranchById;
