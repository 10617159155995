import * as P from 'prop-types';

export const propTypes = {
  handleChange: P.func.isRequired,
  disabled: P.bool,
  value: P.string,
};

export const defaultProps = {
  disabled: false,
  value: null,
};

export default { propTypes, defaultProps };
