import {
  Box, Button, Grid,
} from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';

const PaymentBox = () => (
  <Box py={2}>
    <Grid container direction="row">
      <Grid item xs>
        <Button
          fullWidth
          component={Link}
          to="payment/mercadopago"
          variant="contained"
          color="primary"
        >
          Prosseguir para pagamento
        </Button>
      </Grid>
    </Grid>
  </Box>
);

export default PaymentBox;
