import React from 'react';
import * as P from 'prop-types';
import {
  Box, Card, Collapse, Divider, FormControlLabel,
  Icon, Radio, makeStyles, Typography,
} from '@material-ui/core';

const useStyles = makeStyles(({ spacing }) => ({
  cardRadio: {
    margin: `${spacing(0.5)}px 0`,
  },
  divider: {
    marginBottom: '1px',
  },
  label: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    background: 'linear-gradient(45deg, #dedede, transparent)',
    padding: '8px',
    margin: '2px 12px 2px 0',
    display: 'inline-flex',
    borderRadius: 50,
  },
}));

export default function RadioOptionPayment({
  value, label, selected, children, icon,
}) {
  const classes = useStyles();

  return (
    <Card variant="outlined" className={classes.cardRadio}>
      <Box px={2} py={1.5} bgcolor={selected === value ? '#00000005' : 'inherit'}>
        <FormControlLabel
          value={value}
          label={(
            <Typography
              variant="subtitle1"
              className={classes.label}
              color={selected === value ? 'textPrimary' : 'textSecondary'}
            >
              <Box px={1} className={classes.icon}>
                {
                  typeof icon === 'string'
                    ? (<Icon>{icon}</Icon>)
                    : icon
                }
              </Box>
              <Box>
                {label}
              </Box>
            </Typography>
          )}
          control={<Radio size="small" color="primary" />}
        />
      </Box>
      <Divider className={classes.divider} />
      <Collapse in={selected === value} unmountOnExit>
        <Box>
          {children}
        </Box>
      </Collapse>
    </Card>
  );
}

RadioOptionPayment.propTypes = {
  value: P.string.isRequired,
  label: P.string.isRequired,
  selected: P.string,
  children: P.oneOfType([P.node, P.string, P.number]).isRequired,
  icon: P.oneOfType([P.string, P.node]).isRequired,
};

RadioOptionPayment.defaultProps = {
  selected: null,
};
