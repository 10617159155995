import React from 'react';
import { Box, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
// COMPONENTS
import OptionDefect from '../OptionDefect';
// FUNCTIONS
import PropTypes from './PropTypes';

function InputDefects({
  defects, selectedDefects, handleSelect, isSelectedDefect,
}) {
  const handleChange = (_ev, options) => handleSelect(options);
  const handleSearch = (options, { inputValue }) => options.filter((option) => (
    String(option.name).toLowerCase().indexOf(inputValue.toLowerCase()) > -1
     || String(option.desc).toLowerCase().indexOf(inputValue.toLowerCase()) > -1
  ));

  const getOptionLabel = (option) => option.name;
  const getOptionSelected = (option, value) => option.id === value.id;

  return (
    <Box py={1}>
      <Autocomplete
        multiple
        options={defects}
        value={selectedDefects}
        onChange={handleChange}
        filterSelectedOptions
        filterOptions={handleSearch}
        renderOption={OptionDefect}
        getOptionLabel={getOptionLabel}
        getOptionSelected={getOptionSelected}
        disabled={isSelectedDefect}
        renderInput={(params) => (
          <TextField
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...params}
            variant="outlined"
            label="Defeitos do aparelho"
            placeholder="Pesquisar"
          />
        )}
      />
    </Box>
  );
}

InputDefects.propTypes = PropTypes.propTypes;
InputDefects.defaultProps = PropTypes.defaultProps;

export default React.memo(InputDefects);
