import React from 'react';

const Copyright = () => {
  const date = new Date();
  const year = date.getFullYear();
  return (
    <div className="copyright">
      © {year} TROCAFONE - Comercialização de Aparelhos Eletrônicos LTDA. Todos os direitos reservados.
    </div>
  );
};

export default Copyright;
