import { gql } from '@apollo/client';

const EditCustomer = gql`
  mutation($input: EditMeCustomerInput!){
    EditMeCustomer(input: $input){
      id
      customer_first_name
      customer_last_name
      customer_email
      customer_cpf
      customer_rg
      customer_phone
      customer_landline
    }
  }
`;

export default EditCustomer;
