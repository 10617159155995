import makeStyle from '@material-ui/core/styles/makeStyles';

export default makeStyle(({ spacing, breakpoints }) => ({
  backgroundForm: {
    backgroundColor: '#F2F2F2',
    padding: `${spacing(9)}px ${spacing(0)}px`,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    [breakpoints.down('xs')]: {
      padding: `${spacing(7)}px ${spacing(0.5)}px`,
    },
    '& .form': {
      padding: '30px 30px 60px',
      display: 'flex',
      marginTop: '30px',
      flexDirection: 'column',
      border: '1px solid #ccc',
      borderRadius: '0px',
      minHeight: '600px',
      maxWidth: `${breakpoints.values.sm}px`,
      minWidth: `${breakpoints.values.sm}px`,
      [breakpoints.down('xs')]: {
        minWidth: 'unset',
        width: '100%',
        padding: `${spacing(2.5)}px ${spacing(1.5)}px`,
      },
    },
  },
  topContent: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    position: 'relative',
    marginBottom: '2vh',
  },
  clearButton: {
    position: 'absolute',
    left: 0,
  },
  partnerInfo: {
    '& strong': {
      fontWeight: 500,
    },
  },
  header: {
    fontSize: '3em',
    textAlign: 'center',
    padding: '10px 0',
    [breakpoints.down('xs')]: {
      fontSize: '2em',
    },
  },
}));
