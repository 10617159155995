import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  Box,
  Icon,
  IconButton,
  Divider,
  Grid,
  Typography,
  Collapse,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
// PROP-TYPES
import FocusError from '@/utils/FocusError';
// REDUX
import { useSelector, useDispatch } from 'react-redux';
import {
  setCustomer,
  setCollect,
  setStep,
  setDelivery,
  setBillingAddress,
} from '@redux/actions/NewRepairActions';
// COMPONENTS
import InputName from './Components/InputName';
import InputEmail from './Components/InputEmail';
import InputPhone from './Components/InputPhone';
import InputCpf from './Components/InputCpf';
import StepperButtons from '../StepperButtons';
import InlineGridInput from './Components/InlineGridInput';
import InlineAlertCollapse from '../InlineAlertCollapse';
import FormAddress from '../FormAddress';
// FUNCTIONS
import { checkCep, checkDeliveryCep } from '../../../../utils/CheckCep';
import { factoryAddressFields } from './Common';

function AboutCustomer() {
  const dispatch = useDispatch();
  const customerData = useSelector((state) => state.NewRepairReducer);
  const { customer, activeStep } = customerData;
  const [isEqualCollect, setEqualCollect] = React.useState(true);
  const [alert, setAlert] = React.useState({
    show: false,
    message: null,
    severity: null,
  });

  const closeAlert = () => setAlert({ ...alert, show: false });

  const formik = useFormik({
    initialValues: {
      name: customer.name,
      email: customer.email,
      phone: customer.phone,
      cpf: customer.cpf,
      collect: factoryAddressFields(customer.collect),
      delivery: isEqualCollect
        ? factoryAddressFields(customer.collect)
        : factoryAddressFields(customer.delivery),
    },

    validationSchema: Yup.object({
      name: InputName.rules,
      email: InputEmail.rules,
      phone: InputPhone.rules,
      cpf: InputCpf.rules,
      collect: Yup.object(FormAddress.rules),
      delivery: isEqualCollect ? null : Yup.object(FormAddress.rules),
    }),

    onSubmit: async (values) => {
      closeAlert();
      dispatch(
        setCustomer({
          name: values.name,
          email: values.email,
          phone: values.phone,
          cpf: values.cpf,
        }),
      );
      if (!customer.collect.isChartered) {
        const response = await checkCep(values.collect.zipCode);
        if (response) {
          dispatch(setCollect(values.collect));
          dispatch(setBillingAddress(values.collect));
        } else {
          return setAlert({
            show: true,
            severity: 'error',
            message:
              'Ainda não temos abrangência para o CEP de coleta, sentimos muito 😢',
          });
        }
      }

      if (!customer.delivery.isChartered) {
        if (!isEqualCollect) {
          const response = await checkDeliveryCep(values.delivery.zipCode);
          console.log(response);
          if (response) dispatch(setDelivery(values.delivery));
          else {
            return setAlert({
              show: true,
              severity: 'error',
              message:
                'Ainda não temos abrangência para este CEP de entrega, sentimos muito 😢',
            });
          }
        } else {
          const response = await checkDeliveryCep(values.collect.zipCode);
          if (response) dispatch(setDelivery(values.collect));
          else {
            return setAlert({
              show: true,
              severity: 'error',
              message:
                'Ainda não temos abrangência para este CEP de entrega, sentimos muito 😢',
            });
          }
        }
      }
      dispatch(setStep(activeStep + 1));
    },
  });

  const isSubmitting = formik.isSubmitting
    ? StepperButtons.SUBMITTING.NEXT
    : null;

  const isDisabledField = (typeAddress) => (field) => field === 'zipCode' && typeAddress.blockZipCodeEdit;

  const setValuesAddress = (field) => (newValues) => formik.setValues((values) => ({
    ...values,
    [field]: {
      ...values[field],
      ...newValues,
    },
  }));

  const backStep = () => {
    dispatch(
      setCustomer({
        name: formik.values.name,
        email: formik.values.email,
        phone: formik.values.phone,
        cpf: formik.values.cpf,
      }),
    );

    if (!customer.collect.isChartered) {
      dispatch(setCollect(formik.values.collect));
    }

    if (!customer.delivery.isChartered) {
      dispatch(setDelivery(formik.values.delivery));
    }
    dispatch(setStep(activeStep - 1));
  };

  const autoFill = () => {
    if (customer.collect.isChartered) return null;
    return formik.setFieldValue('delivery', {
      zipCode: customer.collect.zipCode,
      state: customer.collect.state,
      city: customer.collect.city,
      neighborhood: customer.collect.neighborhood,
      street: customer.collect.street,
      number: customer.collect.number,
      complement: customer.collect.complement,
    });
  };

  const toggleEqualCollect = () => setEqualCollect(!isEqualCollect);

  return (
    <>
      <Box>
        <Typography variant="h6">Diga-nos um pouco sobre você</Typography>
        <Box p={2} textAlign="left" margin="0 auto">
          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={2}>
              <Box py={1}>
                <InlineAlertCollapse
                  show={alert.show}
                  message={alert.message}
                  severity={alert.severity}
                />
              </Box>
              <InlineGridInput
                id="name"
                fieldName="name"
                Component={InputName}
                formik={formik}
              />
              <InlineGridInput
                id="email"
                fieldName="email"
                Component={InputEmail}
                formik={formik}
              />
              <InlineGridInput
                id="phone"
                fieldName="phone"
                Component={InputPhone}
                formik={formik}
              />
              <InlineGridInput
                id="cpf"
                fieldName="cpf"
                Component={InputCpf}
                formik={formik}
              />
              <Box py={0.5} paddingTop={2} width="100%">
                <Divider />
              </Box>
              <Grid py={1} item xs={12}>
                <Typography variant="h6">Endereço</Typography>
                <Box py={1} mx="auto" width="100%" maxWidth={560}>
                  <Box py={1}>
                    <InlineAlertCollapse
                      show={alert.show}
                      message={alert.message}
                      severity={alert.severity}
                      button={(
                        <IconButton onClick={closeAlert}>
                          <Icon>close</Icon>
                        </IconButton>
                      )}
                    />
                  </Box>
                  <FormAddress
                    isEditable={
                      !customer.collect.isChartered && !formik.isSubmitting
                    }
                    prefix="collect"
                    title="Coleta"
                    desc="Endereço no qual iremos coletar seu aparelho"
                    handleBlur={formik.handleBlur}
                    handleChange={formik.handleChange}
                    values={formik.values.collect}
                    touched={formik.touched.collect}
                    errors={formik.errors.collect}
                    isDisabledField={isDisabledField(customer.collect)}
                    setValues={setValuesAddress('collect')}
                    defaultShow
                  />
                  <Box display="flex" py={1} />
                  <Collapse in={isEqualCollect}>
                    <FormControlLabel
                      disabled={formik.isSubmitting}
                      control={(
                        <Checkbox
                          checked={isEqualCollect}
                          onChange={toggleEqualCollect}
                          name="equalCollect"
                          color="primary"
                        />
                      )}
                      label="Usar mesmo endereço de coleta para entrega"
                    />
                  </Collapse>
                  <Collapse in={!isEqualCollect}>
                    <FormAddress
                      isEditable={!formik.isSubmitting}
                      prefix="delivery"
                      title="Entrega"
                      autoFillLabel="Preencher com endereço de coleta"
                      desc="Endereço no qual iremos entregar seu aparelho"
                      autoFill={autoFill}
                      handleBlur={formik.handleBlur}
                      handleChange={formik.handleChange}
                      values={formik.values.delivery}
                      touched={formik.touched.delivery}
                      errors={formik.errors.delivery}
                      isDisabledField={isDisabledField(customer.delivery)}
                      setValues={
                        isEqualCollect
                          ? setValuesAddress('collect')
                          : setValuesAddress('delivery')
                      }
                      isEqualCollect={isEqualCollect}
                      setEqualCollect={toggleEqualCollect}
                    />
                  </Collapse>
                </Box>
              </Grid>
            </Grid>
            <FocusError
              errors={formik.errors}
              isSubmitting={formik.isSubmitting}
              isValidating={formik.isValidating}
            />
          </form>
        </Box>
      </Box>
      <StepperButtons
        backStep={backStep}
        nextStep={formik.submitForm}
        isSubmitting={isSubmitting}
      />
    </>
  );
}

export default AboutCustomer;
