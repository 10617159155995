/* eslint-disable no-unused-expressions */
import * as React from 'react';
import { Label } from 'semantic-ui-react';
import { Box, Collapse, LinearProgress, Grid } from '@material-ui/core';
import './Franchisee.css';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Button from '@material-ui/core/Button';
import ReactGA from 'react-ga';
import jQuery from 'jquery';
import franchiseeRegistered from './graphql/schema/FranchiseeRegistered';
import InputPhone from './components/InputPhone';
import InputCompany from './components/InputCompany';
import InputEmail from './components/InputEmail';
import InputDescription from './components/InputDescription';
import Header from './Header/Header';
import Footer from './Footer/Footer';
import BannerFranchisee from './BannerFranchisee/BannerFranchisee';
import Funcionamento from './ComoFunciona/Funcionamento';
import PerguntasFrequentes from './PerguntasFrequentes/PerguntasFrequentes';

let cssLoaded = false;

const validationSchema = yup.object({
  empresa: yup.string('Coloque sua empresa').required('Empresa necessária'),
  email: yup
    .string('Insira seu e-mail')
    .email('Insira um e-mail válido')
    .required('E-mail necessário'),
  telefone: yup.string('Coloque seu telefone').required('Número necessário'),
  descricao: yup.string('Coloque a descrição').required('Descrição necessária'),
});
const WithMaterialUI = () => {
  React.useEffect(() => {
    if (cssLoaded === false) {
      cssLoaded = true;
      import('../../layout/css/pages/NewOrder.css');
      import('semantic-ui-css/semantic.min.css');
      import('slick-carousel/slick/slick.css');
      import('slick-carousel/slick/slick-theme.css');
    }
    if (process.env.NODE_ENV === 'production') {
      ReactGA.event({
        category: 'paginaParceiro',
        action: 'acessPageFranchisee',
        label: 'Entrou na Página',
      });
    }
    function handleCTA() {
      if (process.env.NODE_ENV === 'production') {
        ReactGA.event({
          category: 'paginaParceiro',
          action: 'acessFormFranchisee',
          label: 'Foi até o Formulário para Parceria',
        });
      }
    }
    let scrolled = false;
    jQuery(window).scroll(() => {
      const { pathname } = window.location;
      if (pathname !== '/cadastroparceiro') return;
      if (
        jQuery('#form-partner').offset().top <= jQuery(window).scrollTop() &&
        scrolled === false
      ) {
        scrolled = true;
        handleCTA();
      }
    });
  }, []);

  const [isLoading, setLoading] = React.useState(false);

  const formik = useFormik({
    initialValues: {
      empresa: '',
      email: '',
      telefone: '',
      descricao: '',
    },
    validationSchema,
    onSubmit: (values) => {
      setLoading(true);
      franchiseeRegistered(values)
        .then((r) => {
          alert('email enviado com sucesso');
          if (process.env.NODE_ENV === 'production') {
            ReactGA.event({
              category: 'paginaParceiro',
              action: 'sendEmailFranchisee',
              label: 'Enviou o email',
            });
          }
        })
        .catch((erros) => {
          alert('houve um erro no envio');
        })
        .finally((done) => setLoading(false));
    },
  });

  return (
    <>
      <div
        id="cadastro-franqueado"
        className="formulario"
        style={{ marginBottom: '5px' }}
      >
        <form
          className="formulario-cadastro-franqueado"
          onSubmit={formik.handleSubmit}
        >
          <Header />
          <BannerFranchisee />
          <Funcionamento />
          <Box
            component="div"
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              paddingTop: '70px',
              '& .textField': {
                width: '60%',
              },
            }}
          >
            <div
              id="form-partner"
              className="form"
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: 'white',
                padding: '50px 20px',
                border: '1px  solid #ccc',
                maxWidth: '650px',
                marginBottom: '70px',
                marginLeft: '10px',
                marginRight: '10px',
              }}
            >
              <Label
                className="form-title"
                as="h2"
                content="Preencha o formulário"
              />
              <InputCompany
                disabled={isLoading}
                typeInput="outlined"
                value={formik.values.empresa}
                errors={formik.touched.empresa ? formik.errors.empresa : null}
                handleChange={formik.setFieldValue}
                handleBlur={formik.handleBlur}
              />
              <InputEmail
                disabled={isLoading}
                typeInput="outlined"
                value={formik.values.email}
                errors={formik.touched.email ? formik.errors.email : null}
                handleChange={formik.setFieldValue}
                handleBlur={formik.handleBlur}
              />
              <InputPhone
                disabled={isLoading}
                typeInput="outlined"
                value={formik.values.telefone}
                errors={formik.touched.telefone ? formik.errors.telefone : null}
                handleChange={formik.setFieldValue}
                handleBlur={formik.handleBlur}
              />
              <Label
                className="informacoes-relevantes"
                as="h3"
                content="Coloque abaixo as informações relevantes sobre sua loja que você acha necessário para sua aprovação:"
              />
              <InputDescription
                disabled={isLoading}
                typeInput="outlined"
                value={formik.values.descricao}
                errors={
                  formik.touched.descricao ? formik.errors.descricao : null
                }
                handleChange={formik.setFieldValue}
                handleBlur={formik.handleBlur}
              />
              <Collapse in={isLoading} style={{ width: '80%' }}>
                <Grid item xs>
                  <LinearProgress
                    color="primary"
                    style={{ width: '100%', margin: '10px 0' }}
                  />
                </Grid>
              </Collapse>
              <Button
                disabled={isLoading}
                color="primary"
                variant="contained"
                fullWidth
                type="submit"
                style={{ width: '35%', fontSize: '1rem', fontWeight: 650 }}
              >
                Enviar
              </Button>
            </div>
            <div className="perguntas-frequentes">
              <div className="perguntas-frequentes-titulo">
                <Label
                  className="header-3"
                  as="h1"
                  content="Perguntas frequentes"
                />
              </div>
              <PerguntasFrequentes />
            </div>
          </Box>
        </form>
      </div>
      <Footer />
    </>
  );
};

export default WithMaterialUI;
