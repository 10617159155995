import gql from 'graphql-tag';

const CreateOrderWithCollectDate = gql`
  mutation ($input: CreateOrderInput!, $needPaymentLink: Boolean = true) {
    ReparafoneCreateOrder(input: $input) {
      id
      createdAt: order_created_at
      defects: informedDefects
      imei: order_imei
      product {
        name: product_name
      }
      budget: order_pre_price
      commentDefects: informedObservation
      shortPaymentLink @include(if: $needPaymentLink)
    }
  }
`;

export default CreateOrderWithCollectDate;
