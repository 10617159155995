import React from 'react';
// MATERIAL-UI
import {
  BottomNavigation,
  BottomNavigationAction,
} from '@material-ui/core';
// MATERIAL ICONS
import {
  QuestionAnswer,
  InfoRounded,
  MonetizationOnRounded,
} from '@material-ui/icons';
// REDUX
import { useSelector } from 'react-redux';
// CLSX
import clsx from 'clsx';
// ROUTERS
import { HashLink as Link } from 'react-router-hash-link';
// STYLES
import styles from './style';

export default function MenuMobile() {
  const classes = styles();
  const [value, setValue] = React.useState(0);
  const partnerData = useSelector((state) => state.PartnerStoreReducer);
  const { branch } = partnerData;
  const { branch_name } = branch;

  return (
    <div
      className={clsx(classes.mobileMenu, {
        [classes.mobileMenuCarrefour]: branch_name === 'Carrefour',
        [classes.mobileMenuReparofone]: branch_name === 'Limelocker',
      })}
    >
      <BottomNavigation
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
        showLabels
      >
        <BottomNavigationAction
          className={clsx(classes.bottomItems, {
            [classes.bottomItemsCarrefour]: branch_name === 'Carrefour',
            [classes.bottomItemsReparofone]: branch_name === 'Limelocker',
          })}
          smooth
          component={Link}
          to="#do-estimate"
          label="Orçamento"
          icon={<MonetizationOnRounded />}
        />
        <BottomNavigationAction
          className={classes.bottomItems}
          smooth
          component={Link}
          to="#how-it-works"
          label="Método"
          icon={<InfoRounded />}
        />
        <BottomNavigationAction
          className={classes.bottomItems}
          smooth
          component={Link}
          to="#answers"
          label="Dúvidas"
          icon={<QuestionAnswer />}
        />
      </BottomNavigation>
    </div>
  );
}
