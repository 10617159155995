import React from 'react';
import { Box, Grid } from '@material-ui/core';
// COMPONENTS
import InlineGridInfo from './Components/InlineGridInfo';
import Title from './Components/Title';
// FUNCTIONS
import getLabelAddress from './Utils/GetLabelAddress';
import connect from './ConnectComponentRedux';
import { propTypes, defaultProps } from './PropTypes';

function PreviewOrder({ $state }) {
  const { customer } = $state;

  return (
    <Box py={1}>
      <Title />
      <Grid container spacing={1}>
        <InlineGridInfo title="Nome:" value={customer.name} />
        <InlineGridInfo title="E-mail:" value={customer.email} />
        <InlineGridInfo title="Telefone:" value={customer.phone} />
        <InlineGridInfo title="CPF:" value={customer.cpf} />
        <InlineGridInfo title="Endereço de coleta:" value={getLabelAddress(customer.collect)} />
        <InlineGridInfo title="Endereço de entrega:" value={getLabelAddress(customer.delivery)} />
      </Grid>
    </Box>
  );
}

PreviewOrder.propTypes = propTypes;
PreviewOrder.defaultProps = defaultProps;

export default connect(PreviewOrder);
