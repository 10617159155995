import * as P from 'prop-types';
import { AddressProps } from '@redux/reducers/NewOrderReducer/PropTypes';

export const propTypes = {
  formik: P.object.isRequired,
  $state: P.shape({
    address: AddressProps,
  }).isRequired,
};
export const defaultProps = {};

export default {
  propTypes, defaultProps,
};
