import CalculateRepairCost from './CalculateRepairCost';
// GRAPHQL API
import createLeadOrder from '../graphql/schemas/CreateLead';

export default ({
  selectedDefects, product, customer, partner,
}, setLead) => new Promise((resolve, reject) => {
  CalculateRepairCost(
    selectedDefects, customer.collect.zipCode, customer.delivery.zipCode,
  ).then((repairCost) => {
    const data = {
      firstName: customer.name.split(' ')[0],
      lastName: customer.name.split(' ').splice(1).join(' '),
      email: customer.email,
      phone: customer.phone,
      landline: customer.phone,
      informedPhoneProduct: Number(product.id),
      informedPhoneDefects: selectedDefects.map((def) => def.name).join(', '),
      estimate: repairCost.budgetRepair + repairCost.taxCollect + repairCost.taxDelivery,
      channel: 'vivo',
      store_id: partner.id,
      user_id: partner.employee.id,
    };
    resolve(createLeadOrder({ ...data }, setLead));
  })
    .catch((error) => {
      console.log(error.message, 'erro ao calcular');
      reject(error.message);
    });
});
