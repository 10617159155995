import React from 'react';
import {
  Grid, useTheme, useMediaQuery,
} from '@material-ui/core';
import { useStyles } from './style';
import Adresses from './Addresses';

const AddressInfo = ({ order }) => {
  const theme = useTheme();
  const classes = useStyles();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const collectAddress = order?.addresses && order.addresses.find((item) => item.address_type === 'order_collect');
  const deliveryAddress = order?.addresses && order.addresses.find((item) => item.address_type === 'order_delivery');
  const chargeAddress = order?.addresses && order.addresses.find((item) => item.address_type === 'order_charge');
  return (
    <Grid container direction="row" spacing={isMobile ? 4 : 2} style={{ padding: '30px 0', justifyContent: 'space-between' }}>
      <Adresses
        title="Onde será coletado"
        zipCode={collectAddress?.zip_code}
        state={collectAddress?.state}
        city={collectAddress?.city}
        neighborhood={collectAddress?.neighborhood}
        street={collectAddress?.street}
        streetNumber={collectAddress?.street_number}
        complement={collectAddress?.complement}
        reference={collectAddress?.reference}
      />
      <Adresses
        title="Onde será entregue"
        zipCode={deliveryAddress?.zip_code}
        state={deliveryAddress?.state}
        city={deliveryAddress?.city}
        neighborhood={deliveryAddress?.neighborhood}
        street={deliveryAddress?.street}
        streetNumber={deliveryAddress?.street_number}
        complement={deliveryAddress?.complement}
        reference={deliveryAddress?.reference}
      />
      { chargeAddress && (
        <Adresses
          title="Onde será a cobrança"
          zipCode={chargeAddress?.zip_code}
          state={chargeAddress?.state}
          city={chargeAddress?.city}
          neighborhood={chargeAddress?.neighborhood}
          street={chargeAddress?.street}
          streetNumber={chargeAddress?.street_number}
          complement={chargeAddress?.complement}
          reference={chargeAddress?.reference}
        />
      )}
    </Grid>
  );
};

export default AddressInfo;
