import produce from 'immer';
import initialState from './initialState';

const PREFIX = 'NEW_REPAIR';

export const NewRepairTypes = {
  SET_STEP: `${PREFIX}.SET_STEP`,
  SET_DEVICE: `${PREFIX}.SET_DEVICE`,
  SET_PRODUCT: `${PREFIX}.SET_PRODUCT`,
  RESET_PRODUCT: `${PREFIX}.RESET_PRODUCT`,
  SET_ORDER_DATA: `${PREFIX}.SET_ORDER_DATA`,
  SET_CREDIT_CARD: `${PREFIX}.SET_CREDIT_CARD`,
  SET_LATER_PAYMENT: `${PREFIX}.SET_LATER_PAYMENT`,
  SET_CUSTOMER_DATA: `${PREFIX}.SET_CUSTOMER_DATA`,
  SET_BILLING_ADDRESS: `${PREFIX}.SET_BILLING_ADDRESS`,
  SET_CUSTOMER_COLLECT: `${PREFIX}.SET_CUSTOMER_COLLECT`,
  SET_SELECTED_DEFECTS: `${PREFIX}.SET_SELECTED_DEFECTS`,
  SET_CUSTOMER_DELIVERY: `${PREFIX}.SET_CUSTOMER_DELIVERY`,
  SET_TRANSACTION_AMOUNT: `${PREFIX}.SET_TRANSACTION_AMOUNT`,
  ADD_COUPON: `${PREFIX}.ADD_COUPON`,
  REMOVE_COUPON: `${PREFIX}.REMOVE_COUPON`,
  UPDATE_COUPON: `${PREFIX}.UPDATE_COUPON`,
  RESET_ORDER: `${PREFIX}.RESET_ORDER`,
  SET_LEAD: `${PREFIX}.SET_LEAD`,
  SET_ORIGIN_USER: `${PREFIX}.SET_ORIGIN_USER`,
  SET_ORIGIN_STORE: `${PREFIX}.SET_ORIGIN_STORE`,
  COLLECT_DATA_INSERT: `${PREFIX}.COLLECT_DATA_INSERT`,
  DELIVERY_DATA_INSERT: `${PREFIX}.DELIVERY_DATA_INSERT`,
  CHOOSE_COLLECT_MODALITY: `${PREFIX}.CHOOSE_COLLECT_MODALITY`,
  SET_COLLECT_MODALITIES: `${PREFIX}.SET_COLLECT_MODALITIES`,
  SET_DELIVERY_MODALITIES: `${PREFIX}.SET_DELIVERY_MODALITIES`,
  RESET_DELIVERY_MODALITIES: `${PREFIX}.RESET_DELIVERY_MODALITIES`,
  RESET_COLLECT_MODALITIES: `${PREFIX}.RESET_DELIVERY_MODALITIES`,
  SET_REFUSED_BUDGET_REASON: `${PREFIX}.SET_REFUSED_BUDGET_REASON`,
  REFUSED_BUDGET_REASONS: `${PREFIX}.REFUSED_BUDGET_REASONS`,
};

export default function NewRepairReducer(state = initialState, action) {
  const { type, payload } = action;
  const finalState = (() => {
    switch (type) {
      case NewRepairTypes.SET_CUSTOMER_DATA:
        return {
          ...state,
          customer: { ...state.customer, ...payload },
        };
      case NewRepairTypes.SET_CUSTOMER_DELIVERY:
        return {
          ...state,
          customer: {
            ...state.customer,
            delivery: { ...state.customer.delivery, ...payload },
          },
        };
      case NewRepairTypes.SET_CUSTOMER_COLLECT:
        return {
          ...state,
          customer: {
            ...state.customer,
            collect: { ...state.customer.collect, ...payload },
          },
        };
      case NewRepairTypes.COLLECT_DATA_INSERT:
        return {
          ...state,
          collect: { ...state.collect, ...payload },
        };
      case NewRepairTypes.CHOOSE_COLLECT_MODALITY:
        return {
          ...state,
          collect: {
            ...state.collect,
            chosenModality: { ...state.collect.chosenModality, ...payload },
          },
        };
      case NewRepairTypes.SET_COLLECT_MODALITIES:
        return {
          ...state,
          collect: {
            ...state.collect,
            modalities: [...state.collect.modalities, ...payload],
          },
        };
      case NewRepairTypes.RESET_COLLECT_MODALITIES:
        return {
          ...state,
          collect: {
            ...state.collect,
            modalities: [],
          },
        };
      case NewRepairTypes.SET_DELIVERY_MODALITIES:
        return {
          ...state,
          delivery: {
            ...state.delivery,
            modalities: [...state.delivery.modalities, ...payload],
          },
        };
      case NewRepairTypes.RESET_DELIVERY_MODALITIES:
        return {
          ...state,
          delivery: {
            ...state.delivery,
            modalities: [],
          },
        };
      case NewRepairTypes.DELIVERY_DATA_INSERT:
        return {
          ...state,
          delivery: { ...state.delivery, ...payload },
        };
      case NewRepairTypes.SET_DEVICE:
        return {
          ...state,
          device: {
            ...state.device,
            ...payload,
          },
        };
      case NewRepairTypes.SET_PRODUCT:
        return {
          ...state,
          device: {
            ...state.device,
            product: {
              ...state.device.product,
              ...payload,
            },
          },
        };
      case NewRepairTypes.SET_CREDIT_CARD:
        return {
          ...state,
          payment: {
            type: 'creditCard',
            data: {
              ...state.payment.data,
              ...payload,
            },
          },
        };
      case NewRepairTypes.SET_LATER_PAYMENT:
        return {
          ...state,
          payment: {
            type: 'laterPayment',
            data: {
              ...state.payment.data,
              ...payload,
            },
          },
        };
      case NewRepairTypes.SET_STEP:
        return {
          ...state,
          activeStep: payload,
        };
      case NewRepairTypes.RESET_PRODUCT:
        return {
          ...state,
          device: {
            ...state.device,
            product: initialState.device.product,
            selectedDefects: initialState.device.selectedDefects,
          },
        };
      case NewRepairTypes.SET_SELECTED_DEFECTS:
        return {
          ...state,
          device: {
            ...state.device,
            selectedDefects: payload,
          },
        };
      case NewRepairTypes.SET_BILLING_ADDRESS:
        return {
          ...state,
          billingAddress: {
            ...state.billingAddress,
            ...payload,
          },
        };
      case NewRepairTypes.SET_TRANSACTION_AMOUNT:
        return {
          ...state,
          transactionAmount: {
            ...state.transactionAmount,
            ...payload,
          },
        };
      case NewRepairTypes.SET_ORDER_DATA:
        return {
          ...state,
          orderData: payload,
        };
      case NewRepairTypes.SET_LEAD:
        return {
          ...state,
          lead: {
            ...state.lead,
            ...payload,
          },
        };
      case NewRepairTypes.ADD_COUPON:
        return {
          ...state,
          coupons: [...state.coupons, payload],
        };
      case NewRepairTypes.REMOVE_COUPON:
        return {
          ...state,
          coupons: state.coupons.filter(
            (coupon, index) => index !== action.payload,
          ),
        };
      case NewRepairTypes.UPDATE_COUPON: {
        return produce(state, (draft) => {
          const index = draft.coupons.findIndex(
            (coupon) => coupon.code === action.coupon.code,
          );
          draft[index] = action.coupon;
        });
      }
      case NewRepairTypes.RESET_ORDER:
        return {
          ...initialState,
        };
      case NewRepairTypes.SET_ORIGIN_USER:
        return {
          ...state,
          origin_user: { ...state.origin_user, ...payload },
        };
      case NewRepairTypes.SET_ORIGIN_STORE:
        return {
          ...state,
          origin_store: { ...state.origin_store, ...payload },
        };
      case NewRepairTypes.REFUSED_BUDGET_REASONS:
        return {
          ...state,
          allRefusedBudgetReasons: payload,
        };
      case NewRepairTypes.SET_REFUSED_BUDGET_REASON:
        return {
          ...state,
          refusedBudgetReason: {
            ...state.refusedBudgetReason,
            ...payload,
          },
        };
      default:
        return { ...state };
    }
  })();
  return finalState;
}
