import { makeStyles } from '@material-ui/core/';
import { green } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    right: '10%',
    marginTop: 5,
  },
  buttonProgressMobile: {
    color: green[500],
    position: 'absolute',
    left: '30%',
    marginTop: 10,
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
  },
  arrow: {
    color: theme.palette.common.black,
  },
  buttonCancel: {
    backgroundColor: theme.palette.common.white,
    color: 'red',
    border: '1px solid red',
    '&:hover': {
      border: '1px solid red',
      backgroundColor: theme.palette.common.white,
    },
  },
}));

export default useStyles;
