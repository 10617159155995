import React from 'react';
import { Icon, InputAdornment, TextField } from '@material-ui/core';
import clsx from 'clsx';
import { InputNamePropTypes, InputNameDefaultProps } from './PropTypes';
import InputNameRules from './Rules';
import useStyle from '../../../../style';

export default function InputName(props) {
  const {
    handleChange, handleBlur, errors, value, typeInput, disabled, id,
  } = props;
  const classes = useStyle();
  return (
    <TextField
      fullWidth
      name="name"
      label="Nome do cliente"
      id={id}
      variant={typeInput}
      color="primary"
      value={value}
      onChange={handleChange}
      onBlur={handleBlur}
      helperText={errors}
      error={!!errors}
      disabled={disabled}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Icon className={clsx(['fas', 'fa-user', classes.inputIcon])} color="action" />
          </InputAdornment>
        ),
      }}
    />
  );
}

InputName.propTypes = InputNamePropTypes;
InputName.defaultProps = InputNameDefaultProps;
InputName.rules = InputNameRules;
