import gql from 'graphql-tag';

export const GetStoresById = gql`
  query GetStoresById($id: ID!) {
    ReparafoneGetStoreById(id: $id, enabled: true) {
      id
      name
      enabled
      addresses {
        id
        zip_code
        state
        city
        neighborhood
        street
        street_number
        complement
      }
    }
  }
`;
export const GetStores = gql`
  query GetStores {
    ReparafoneGetStoresAll {
      id
      name
      enabled
      branch_id
      addresses {
        zipCode: zip_code
        state
        city
        neighborhood
        street
        number: street_number
        complement
        longitude
        latitude
      }
    }
  }
`;
export default GetStoresById;
