import React from 'react';
// FORMIK
import { Form, Formik } from 'formik';
// YUP
import * as Yup from 'yup';
// MATERIAL-UI
import {
  Button, Grid, Collapse, LinearProgress,
} from '@material-ui/core/';
// REDUX
import { useDispatch, useSelector } from 'react-redux';
import {
  resetOrder,
  setStep,
  setLead,
} from '@/redux/actions/NewRepairActions';
// GRAPHQL
import createRefusedBudgetObservation from '../../../../../../graphql/schemas/CreateRefusedBudgetObservation';
import createLeadOrder from '../../../../../../graphql/schemas/CreateLead';
// COMPONENTS
import InputReasons from './components/InputReasons';
import InputObservations from './components/InputObservations';
// PropTypes
import PropTypes from './PropTypes';
// STYLES
import styles from './style';

const validationSchema = Yup.object().shape({
  refused_budget_reason_id: Yup.string().required('Por favor, nos diga o motivo!'),
  reason: Yup.string().required('Deixe uma observação!'),
});

export default function DialogRefused({ clickToClose, handleAlert }) {
  const classes = styles();
  const dispatch = useDispatch();
  const customerData = useSelector((state) => state.NewRepairReducer);
  const { customer, device, lead, origin_store, origin_user } = customerData;
  const { product } = device;
  const [isLoading, setLoading] = React.useState(false);
  const createLead = (obsId) => {
    createLeadOrder({
      firstName: customer?.name ? customer.name.split(' ')[0] : '',
      lastName: customer?.name ? customer.name.split(' ').splice(1).join(' ') : '',
      email: customer.email || '',
      phone: customer.phone || '',
      landline: customer.phone || '',
      informedPhoneProduct: Number(product.id),
      informedPhoneDefects: lead.informedPhoneDefects,
      estimate: lead.estimate,
      channel: 'reparofone',
      store_id: origin_store.id,
      user_id: origin_user.id,
      refused_budget_obs_id: obsId,
    }, setLead)
      .then(() => {
        dispatch(resetOrder());
        dispatch(setStep(0));
        handleAlert(
          'Enviado com sucesso!',
          'success',
          true,
          4000,
        );
        return clickToClose();
      })
      .catch((err) => {
        console.log(err);
        return handleAlert(
          'Houve um erro ao criar o Lead. Por favor, Atualize a página!',
          'error',
          true,
          7000,
        );
      })
      .finally(() => setLoading(false));
  };

  return (
    <div>
      <Formik
        initialValues={{
          reason: '',
          refused_budget_reason_id: '',
          store_id: Number(origin_store.id),
          partner_id: Number(origin_user.id),
        }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          setLoading(true);
          createRefusedBudgetObservation({
            ...values,
            refused_budget_reason_id: Number(values.refused_budget_reason_id.id),
          })
            .then((r) => createLead(r.id))
            .catch(({ response }) => {
              console.log(response);
              return handleAlert(
                'Houve um erro ao enviar. Por favor, Atualize a página!',
                'error',
                true,
                7000,
              );
            });
        }}
      >
        {({
          values,
          touched,
          errors,
          handleChange,
          handleBlur,
          setFieldValue,
        }) => (
          <Form noValidate autoComplete="off">
            <InputReasons
              disabled={isLoading}
              typeInput="outlined"
              name="refused_budget_reason_id"
              value={values.refused_budget_reason_id}
              errors={touched.refused_budget_reason_id ? errors.refused_budget_reason_id : null}
              handleChange={handleChange}
              setFieldValue={setFieldValue}
              handleBlur={handleBlur}
            />
            <InputObservations
              disabled={isLoading}
              typeInput="outlined"
              name="reason"
              value={values.reason}
              errors={touched.reason ? errors.reason : null}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />
            <Collapse in={isLoading}>
              <LinearProgress className={classes.alert} />
            </Collapse>
            <Grid container row justify="space-between" alignItems="center">
              <Button disabled={isLoading} onClick={clickToClose}>
                Sair
              </Button>
              <Button
                disabled={isLoading}
                color="primary"
                variant="contained"
                type="submit"
                className={classes.button}
              >
                Enviar
              </Button>
            </Grid>
          </Form>
        )}
      </Formik>
    </div>
  );
}

DialogRefused.propTypes = PropTypes.propTypes;
DialogRefused.defaultProps = PropTypes.defaultProps;
