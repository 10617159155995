import gql from 'graphql-tag';

export const GetConditionQuestions = gql`
  {
    ReparafoneGetConditionQuestions{
      id
      slug
      question
    }
  }
`;

export default GetConditionQuestions;
