import gql from 'graphql-tag';

const FindCouponById = gql`
  query FindCouponById($id: ID!) {
    ReparafoneFindCoupon(id: $id) {
      code
      discount_type
      discount_amount
    }
  }
`;

export default FindCouponById;
