import * as React from 'react';
// MATERIAL-UI
import { Typography } from '@material-ui/core';
// PROP-TYPES
import PropTypes from './PropTypes';
// STYLES
import styles from './styles';

function StoreInfo({ info }) {
  const classes = styles();
  const { name, addresses } = info;

  return (
    <>
      <Typography
        variant="h3"
        className={classes.name}
      >
        {name}
        <br />
        {`${addresses[0].city} - ${addresses[0].state}`}
      </Typography>
      <Typography
        variant="h4"
        className={classes.address}
      >
        {`${addresses[0].street}, ${addresses[0].number} - ${addresses[0].neighborhood}`}
      </Typography>
    </>
  );
}

export default React.memo(StoreInfo);

StoreInfo.propTypes = PropTypes.propTypes;
StoreInfo.defaultProps = PropTypes.defaultProps;
