import React from 'react';
import * as P from 'prop-types';
import { DefectsProps } from '@redux/reducers/NewRepairReducer/PropTypes';

export default function DefectsList({ defects }) {
  const defectsJoin = defects
    .map(({ name }, index) => {
      if (defects.length === 1) return name;
      if (defects.length === 2) {
        return index === 0 ? `${name} e ` : name;
      }
      return index === defects.length - 1 ? `${name}` : `${name}, `;
    })
    .join(' ');

  const defectsSlice = `${defects.slice(0, -1).join(', ')} ${
    defects.length > 1 ? 'e' : ''
  } ${defects.slice(-1)}`;

  return (
    <span>
      {defectsJoin}
    </span>
  );
}

DefectsList.propTypes = {
  defects: P.arrayOf(P.shape(DefectsProps)).isRequired,
};
