/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
// COMPONENTS
import StepSelectBrand from '../StepSelectBrand';
import StepSelectModel from '../StepSelectModel';
import StepSelectColor from '../StepSelectColor';

export default function getStepContent(step, data, handleNextStep, handleBackStep) {
  const defaultProps = { data, handleNextStep, handleBackStep };
  switch (step) {
    case 0:
      return <StepSelectBrand {...defaultProps} />;
    case 1:
      return <StepSelectModel {...defaultProps} />;
    case 2:
      return <StepSelectColor {...defaultProps} />;
    default:
      return 'Unknown Step';
  }
}
