import gql from 'graphql-tag';

export const GetCepAvailability = gql`
  query ReparafoneValidateCepAvailability($cep: String!) {
    ReparafoneValidateCepAvailability(cep: $cep) {
      id
      collect {
        id
      }
      price
    }
  }
`;

export const GetDeliveryCepAvailability = gql`
  query ReparafoneValidateDeliveryCepAvailability($cep: String!) {
    ReparafoneValidateDeliveryCepAvailability(cep: $cep) {
      id
      delivery {
        id
      }
      price
    }
  }
`;

export default GetCepAvailability;
