import React from 'react';
// MATERIAL-UI
import {
  ListItem, ListItemText, ListItemIcon,
} from '@material-ui/core';
// PROP-TYPES
import PropTypes from './PropTypes';
// STYLES
import styles from './styles';

const RepairInfo = ({ title, info, icon }) => {
  const classes = styles();
  return (
    <ListItem dense disableGutters>
      <ListItemIcon className={classes.itemIcon}>
        {icon}
      </ListItemIcon>
      <ListItemText
        className={classes.itemText}
        primary={title}
        secondary={info || 'Sem Dados'}
      />
    </ListItem>
  );
};

export default RepairInfo;

RepairInfo.propTypes = PropTypes.propTypes;
RepairInfo.defaultProps = PropTypes.defaultProps;
