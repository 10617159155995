import React from 'react';
import * as P from 'prop-types';
import Cards from 'react-credit-cards';
import { Box, Grid, makeStyles } from '@material-ui/core';
// INCLUDES
import 'react-credit-cards/es/styles-compiled.css';

const useStyle = makeStyles((theme) => ({
  creditCardContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      maxWidth: '100%',
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: '220px',
    },
    '& > .rccs': {
      transform: 'scale(0.8)',
    },
  },
}));

export default function Card({ values, focusedInput }) {
  const classes = useStyle();

  return (
    <Grid item xs={12} md={6}>
      <Box className={classes.creditCardContainer}>
        <Cards
          cvc={values.cvc}
          number={values.number}
          expiry={values.expiry}
          focused={focusedInput}
          name={String(values.name || 'NOME DO TITULAR').toUpperCase()}
          locale={{
            valid: 'Válido até',
          }}
        />
      </Box>
    </Grid>
  );
}

Card.propTypes = {
  values: P.shape({
    cvc: P.string,
    name: P.string,
    number: P.string,
    expiry: P.string,
  }).isRequired,
  focusedInput: P.string.isRequired,
};
