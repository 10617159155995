import React, { useState } from 'react';
import * as P from 'prop-types';
import { Box, Checkbox, FormControlLabel } from '@material-ui/core';
import ReactGA from 'react-ga';

export default function TermsOfService({ handleChange }) {
  const [termsChecked, setTermsChecked] = useState(false);
  const [customerAgreed, setCustomerAgreed] = useState(false);
  const [checkedMarketing, setCheckedMarketing] = useState(false);
  const handleCheckBoxChange = ({ target }) => {
    setTermsChecked(target.checked);
  };
  const handleCustomerCheckBoxChange = ({ target }) => {
    setCustomerAgreed(target.checked);
  };
  const handleCheckBoxMarketing = ({ target }) => {
    setCheckedMarketing(target.checked);
  };

  const saveClick = () => {
    if (process.env.NODE_ENV === 'production') {
      ReactGA.event({
        category: 'createOrder',
        action: 'seenTermsOfService',
        label: 'Visualizou os termos de serviço',
      });
    }
  };
  React.useEffect(() => {
    handleChange(termsChecked);
  }, [termsChecked]);

  return (
    <Box py={1}>
      <FormControlLabel
        control={<Checkbox checked={customerAgreed} onChange={handleCustomerCheckBoxChange} />}
        label={(
          <span>
          Concordo em receber Atualizações sobre meu <b>Reparo</b> pelo <b>Whatsapp</b>
          no número do meu telefone informado neste cadastro.
          </span>
        )}
      />
      <FormControlLabel
      className='spacing-box'
        control={
          <Checkbox checked={termsChecked} onChange={handleCheckBoxChange} />
        }
        label={(
          <span>
            Autorizo a coleta, armazenamento e tratamento de dados
            pela Trocafone, de acordo com a <a href="https://www.trocafone.com.br/politica-de-privacidade" target="_blank">Política de privacidade</a>
            / <a href="https://www.trocafone.com.br/termos-e-condicoes" target="_blank">Termos de uso</a> /
            <a href="https://www.trocafone.com.br/politica-de-cookies" target="_blank">Política de cookies</a>
          </span>
        )}
      />

      <FormControlLabel
      control={
        <Checkbox checked={checkedMarketing} onChange={handleCheckBoxMarketing} />
      }
      label={(
        <span>
          Autorizo que meus dados sejam utilizados para contato pela TROCAFONE para continuidade de negócios, 
          melhorar minha experiência comercial inclusive marketing.
        </span>
      )}
    />
    </Box>
  );
}

TermsOfService.propTypes = {
  handleChange: P.func.isRequired,
};
