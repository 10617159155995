export const factoryAddressFields = ({
  zipCode,
  state,
  city,
  neighborhood,
  street,
  number,
  complement,
}) => ({
  zipCode,
  state,
  city,
  neighborhood,
  street,
  number,
  complement,
});

export default {
  factoryAddressFields,
};
