import { client } from '../../../../Apollo';
import CreateRefusedBudgetObservation from '../mutation/CreateRefusedBudgetObservation';

const createRefusedBudgetObservation = (data) => new Promise((resolve, reject) => {
  client
    .mutate({
      mutation: CreateRefusedBudgetObservation,
      variables: {
        input: data,
      },
    })
    .then((r) => {
      resolve(r.data.ReparofoneCreateRefusedBudgetObservation);
    })
    .catch((e) => {
      console.log(e.message, 'erro ao enviar');
      reject(e.message);
    });
});

export default createRefusedBudgetObservation;
