import Samsung from '@/images/logo_samsung.png';
import iPhone from '@/images/logo_iphone.png';
import Motorola from '@/images/logo_motorola.png';
import Xiaomi from '@/images/logo_xiaomi.png';
import Asus from '@/images/logo_asus.png';
import LG from '@/images/logo_lg.png';
import Huawei from '@/images/logo_huawei.png';
import Lenovo from '@/images/logo_lenovo.png';
import iPad from '@/images/logo_ipad.png';
import Sony from '@/images/logo_sony.png';

const Brands = [
  { name: 'Samsung', image: Samsung },
  { name: 'iPhone', image: iPhone },
  { name: 'Motorola', image: Motorola },
  { name: 'Xiaomi', image: Xiaomi },
  // { name: 'Asus', image: Asus },
  { name: 'LG', image: LG },
  // { name: 'Huawei', image: Huawei },
  { name: 'Lenovo', image: Lenovo },
  // { name: 'iPad', image: iPad },
  // { name: 'Sony', image: Sony },
];

export default Brands;
