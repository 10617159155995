import React from 'react';
import {
  Box, Button, Card, CardActionArea, Collapse, Grid,
} from '@material-ui/core';
import domain from '@utils/Domain';
// COMPONENTS
import LoadingContent from '../../../LoadingContent';
import InlineAlertCollapse from '../../../InlineAlertCollapse';
// FUNCTIONS
import useStyles from './style';
import { StepSelectColorPropTypes } from './PropTypes';
import getColorsByModel from '../../../../utils/getColorsByModel';

function StepSelectColor({ data, handleNextStep, handleBackStep }) {
  const [alert, setAlert] = React.useState({ message: null, type: null });
  const [colors, setColors] = React.useState([]);
  const [isSubmitting, setSubmitting] = React.useState(false);
  const classes = useStyles();

  const setAlertMessage = (message, type) => setAlert({ message, type });
  const handleGetColors = () => {
    setSubmitting(true);
    setAlertMessage(null, 'error');
    setTimeout(async () => {
      try {
        const result = await getColorsByModel(data.model);
        setColors(result ?? []);
      } catch (e) {
        setAlertMessage('Houve um erro ao tentar recuperar os dados, tente novamente mais tarde.', 'error');
      }
      setSubmitting(false);
    }, 0);
  };

  const handleClick = ({ currentTarget }) => (
    currentTarget?.value
      && handleNextStep(currentTarget?.value)
  );

  React.useEffect(handleGetColors, [data.model]);

  return (
    <Box maxWidth="500px">
      <InlineAlertCollapse
        show={!!alert.message}
        message={alert.message ?? ''}
        severity={alert.type}
        button={<Button onClick={handleGetColors} color="inherit" size="small">Tentar novamente</Button>}
      />
      <LoadingContent isLoading={isSubmitting}>
        <Collapse in={!!colors.length}>
          <Grid container spacing={1} className={classes.gridContainer}>
            {
              colors.map(({ color, rgbCode }) => (
                <Grid key={color} item xs={12} md={7} className="gridItem">
                  <Card className={classes.cardColor} variant="outlined">
                    <CardActionArea
                      className={classes.cardAction}
                      onClick={handleClick}
                      value={color}
                    >
                      <Box display="flex" alignItems="center">
                        <Box
                          className={classes.colorView}
                          style={{
                            backgroundColor: `rgb(${rgbCode})`,
                          }}
                        />
                        {color}
                      </Box>
                    </CardActionArea>
                  </Card>
                </Grid>
              ))
            }
          </Grid>
        </Collapse>
      </LoadingContent>
      <Button size="small" color="secondary" onClick={handleBackStep}>Escolher outro modelo</Button>
    </Box>
  );
}

StepSelectColor.propTypes = StepSelectColorPropTypes;

export default React.memo(StepSelectColor);
