export default {
  id: undefined,
  name: undefined,
  addresses: [{
    zipCode: undefined,
    state: undefined,
    city: undefined,
    neighborhood: undefined,
    street: undefined,
    number: undefined,
    complement: undefined,
  }],
  employee: {
    id: undefined,
    name: undefined,
  },
  branch: {
    id: undefined,
    branch_name: undefined,
    branch_slug: undefined,
    branch_logo: undefined,
  },
};
