import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles(({ spacing, overrides }) => ({
  brands: {
    padding: `${spacing(2)}px ${0}px`,
    maxWidth: '500px',
    '& .gridBrand': {
      padding: overrides.MuiGrid?.item.padding ?? '8px',
      transition: 'padding 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      '&.hideGrid': {
        padding: '0px',
      },
    },
    '& .brandLabel': {
      width: '100%',
      textAlign: 'center',
    },
    '& .brandCard': {
      borderRadius: '15px',
      boxShadow: '0px 1px 6px 1px #00000012',
      backgroundColor: '#ffffff40',
      '&:hover': {
        '& .brandContainerImage': {
          padding: `${spacing(1)}px ${spacing(1)}px`,
        },
      },
      '& .brandContainer': {
        height: '90px',
        width: '100px',
        '& .brandContainerImage': {
          transition: 'padding .2s cubic-bezier(.36,.07,.19,.97)',
          padding: `${spacing(2) - 4}px ${spacing(2) - 4}px`,
          display: 'flex',
          height: '80%',
        },
      },
      '& > span': {
        height: '100%',
      },
      '& img': {
        maxHeight: '100%',
        maxWidth: '100%',
        margin: 'auto',
      },
      '& .brandName': {
        fontSize: 12,
        display: 'block',
        height: '20%',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    },
  },
}));
