import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles(({ spacing }) => ({
  modelCard: {
    height: '100%',
    width: 125,
    borderRadius: '15px',
    boxShadow: '0px 1px 6px 1px #00000012',
    backgroundColor: '#ffffff40',
    '&:hover': {
      '& .modelContainerImage': {
        padding: `${spacing(1)}px ${spacing(1)}px`,
      },
    },
    '& .modelContainer': {
      height: '100%',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      '& .modelContainerImage': {
        transition: 'padding .2s cubic-bezier(.36,.07,.19,.97)',
        padding: `${spacing(2) - 4}px ${spacing(2) - 4}px`,
        display: 'flex',
        height: '80%',
      },
    },
    '& img': {
      maxHeight: '100%',
      maxWidth: '100%',
      margin: 'auto',
    },
    '& .modelName': {
      fontSize: 12,
      display: 'block',
      height: '20%',
      lineHeight: 1.35,
      marginTop: 'auto',
      marginBottom: 0,
    },
  },
}));
