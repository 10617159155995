import gql from 'graphql-tag';

const CreateRefusedBudgetObservation = gql`
  mutation($input: ReparofoneCreateRefusedBudgetObservationInput!) {
    ReparofoneCreateRefusedBudgetObservation(input: $input) {
      id
      reason
    }
  }
`;

export default CreateRefusedBudgetObservation;
