import React from 'react';
import BrazillianCurrency from '@/utils/NationalCurrency';
import { isNumber } from 'lodash';
import useStyle from '../Utils/Style';

export default function LabelCoupon({ value }) {
  const valueParsed = BrazillianCurrency(value);
  const classes = useStyle();

  return (
    <div className={classes.containerLabel}>
      <div className={classes.labelTotal}>
        <div className={classes.containerTitle}>Cupom</div>
        <div className={classes.containerValue}>{isNumber(value) ? valueParsed.format() : value}</div>
      </div>
    </div>
  );
}
