import P from 'prop-types';

export const AddressProps = {
  zipCode: P.string,
  state: P.string,
  city: P.string,
  neighborhood: P.string,
  street: P.string,
  number: P.string,
  complement: P.string,
  isChartered: P.bool,
  blockZipCodeEdit: P.bool,
};

export const CustomerProps = {
  name: P.string,
  email: P.string,
  phone: P.string,
  cpf: P.string,
  collect: P.shape(AddressProps).isRequired,
  delivery: P.shape(AddressProps).isRequired,
};

export const PartProps = {
  id: P.string,
  name: P.string,
  price: P.number,
};

export const DefectsProps = {
  id: P.string,
  name: P.string,
  parts: P.arrayOf(P.shape(PartProps)),
};

export const ModelProps = {
  id: P.oneOfType([P.string, P.number]),
  name: P.string,
  brand: P.string,
};

export const ProductProps = {
  id: P.oneOfType([P.string, P.number]),
  name: P.string,
  defects: P.arrayOf(P.shape(DefectsProps)),
};

export const DeviceProps = {
  imei: P.string,
  brand: P.string,
  model: P.shape(ModelProps).isRequired,
  color: P.string,
  product: P.shape(ProductProps).isRequired,
  selectedDefects: P.arrayOf(P.shape(DefectsProps)),
  defectComment: P.string,
};

export const BillingAddressProps = {
  zipCode: P.string,
  state: P.string,
  city: P.string,
  neighborhood: P.string,
  street: P.string,
  number: P.string,
  complement: P.string,
};

export const CreditCardProps = {
  cpf: P.string,
  cvc: P.string,
  name: P.string,
  number: P.string,
  expiry: P.string,
  installments: P.number,
  installmentsText: P.string,
};

export const TransactionAmountProps = {
  taxDelivery: P.number.isRequired,
  taxCollect: P.number.isRequired,
  budgetRepair: P.number.isRequired,
};

export const CouponsProps = {
  code: P.string,
  discount: P.number,
};

export const LeadProps = {
  channel: P.string,
  firstName: P.string,
  lastName: P.string,
  email: P.string,
  phone: P.string,
  landline: P.string,
  estimate: P.number,
  informedPhoneDefects: P.string,
  informedPhoneProduct: P.number,
};

export const OriginUser = {
  id: P.oneOfType([P.string, P.number]),
  name: P.string,
};

export const OriginStore = {
  id: P.oneOfType([P.string, P.number]),
  name: P.string,
  email: P.string,
  branch_id: P.string,
};

export const CollectDateProps = {
  scheduled_collect: P.string,
  chosenModality: P.string,
  modalities: P.array,
};

export const DeliveryDateProps = {
  scheduled_delivery: P.string,
  modalities: P.array,
};

export const OrderDataProps = {};

export const RefusedBudgetReason = {
  id: P.oneOfType([P.string, P.number]),
  name: P.string,
  description: P.string,
  slug: P.string,
  lay_name: P.string,
};

const PropTypes = {
  activeStep: P.number.isRequired,
  origin_user: P.shape(OriginUser).isRequired,
  origin_store: P.shape(OriginStore).isRequired,
  customer: P.shape(CustomerProps).isRequired,
  device: P.shape(DeviceProps).isRequired,
  creditCard: P.shape(CreditCardProps).isRequired,
  billingAddress: P.shape(BillingAddressProps).isRequired,
  transactionAmount: P.shape(TransactionAmountProps).isRequired,
  coupons: P.shape(CouponsProps).isRequired,
  lead: P.shape(LeadProps).isRequired,
  collect: P.shape(CollectDateProps).isRequired,
  delivery: P.shape(DeliveryDateProps).isRequired,
  orderData: P.shape(OrderDataProps),
  refusedBudgetReason: P.shape(RefusedBudgetReason),
  allRefusedBudgetReasons: [],
};

export default PropTypes;
