import * as P from 'prop-types';

const propTypes = {
  open: P.bool.isRequired,
  clickToClose: P.func.isRequired,
  handleAlert: P.func.isRequired,
};

export default {
  propTypes,
  defaultProps: {},
};
