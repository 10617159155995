import React from 'react';
// MATERIAL-UI
import { Typography } from '@material-ui/core';
// REDUX
import { useSelector } from 'react-redux';
// CLSX
import clsx from 'clsx';
// STYLES
import style from './style';

const Sobre = () => {
  const classes = style();
  const partnerData = useSelector((state) => state.PartnerStoreReducer);
  const { branch } = partnerData;
  const { branch_name } = branch;
  return (
    <div className={classes.sobreReparofone}>
      <Typography
        className={clsx(classes.title, {
          [classes.titleCarrefour]: branch_name === 'Carrefour',
          [classes.titleReparofone]: branch_name === 'Limelocker',
        })}
      >
        Reparofone
      </Typography>
      <p className={classes.textoSobre}>
        Não atendemos neste endereço. Atendemos e comercializamos reparos
        exclusivamente por meio do nosso site.
      </p>
      <p className={classes.textoSobre}>CNPJ: 20.553.221/0001-02</p>
    </div>
  );
};

export default Sobre;
