import React from 'react';

import Reparafone from '../../../../images/Logo/Reparafone-logo.png';

const Logo = () => (
  <div className="logo">
    <a className="logo-link" href="/novaordem">
      <img src={Reparafone} alt="Reparofone" />
    </a>
  </div>
);

export default Logo;
