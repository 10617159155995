import React, { useState } from 'react';
import * as P from 'prop-types';
import { Box, Checkbox, FormControlLabel } from '@material-ui/core';
import ReactGA from 'react-ga';

export default function TermsOfService({ handleChange }) {
  const [termsChecked, setTermsChecked] = useState(false);
  const [customerAgreed, setCustomerAgreed] = useState(false);
  const handleCheckBoxChange = ({ target }) => {
    setTermsChecked(target.checked);
  };
  const handleCustomerCheckBoxChange = ({ target }) => {
    setCustomerAgreed(target.checked);
  };
  const saveClick = () => {
    if (process.env.NODE_ENV === 'production') {
      ReactGA.event({
        category: 'createOrder',
        action: 'seenTermsOfService',
        label: 'Visualizou os termos de serviço',
      });
    }
  };
  React.useEffect(() => {
    handleChange(termsChecked);
  }, [termsChecked]);

  return (
    <Box py={1}>
      <FormControlLabel
        control={<Checkbox checked={customerAgreed} onChange={handleCustomerCheckBoxChange} />}
        label={(
          <span>
            Confirmo receber atualizações do <b>Reparo</b> através do canal do <b>Whatsapp</b>
          </span>
        )}
      />
      <FormControlLabel
        control={
          <Checkbox checked={termsChecked} onChange={handleCheckBoxChange} />
        }
        label={(
          <span>
            Confirmo que li e concordo com os
            {' '}
            <a href="/termsofservice/reparofone" onClick={saveClick} target="_blank">
              Termos de Serviços
            </a>
          </span>
        )}
      />
    </Box>
  );
}

TermsOfService.propTypes = {
  handleChange: P.func.isRequired,
};
