import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  Box, Backdrop, CircularProgress, Collapse,
  Divider, Grid, makeStyles, Typography,
} from '@material-ui/core';
// PROP-TYPES
import FocusError from '@/utils/FocusError';
import { AboutCustomerPropTypes, AboutCustomerDefaultProps } from './PropTypes';
// COMPONENTS
import InputName from './Components/InputName';
import InputEmail from './Components/InputEmail';
import InputPhone from './Components/InputPhone';
import InputZipCode from './Components/InputZipCode';
import StepperButtons from '../StepperButtons';
import InlineGridInput from './Components/InlineGridInput';
import RadioInputStoreDelivery from './Components/RadioInputStoreDelivery';
import InlineAlertCollapse from '../InlineAlertCollapse';
// FUNCTIONS
import { checkDeliveryCep } from '../../utils/GetShipping';
import ConnectComponentRedux from './ConnectComponentRedux';
import searchZipCodeInfo from '../../utils/SearchZipCodeInfo';

const { chooses } = RadioInputStoreDelivery;

const isStoreDelivery = (type) => (
  type === chooses.STORE
);

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

function AboutCustomer({ $state, $actions }) {
  const [deliveryData, setDeliveryData] = React.useState(null);
  const [isLoading, setLoading] = React.useState(false);
  const [alert, setAlert] = React.useState({
    show: false,
    message: null,
    severity: null,
  });

  const classes = useStyles();

  const showAlert = ({ message, severity }) => setAlert(({
    message, severity, show: true,
  }));

  const closeAlert = () => setAlert({ ...alert, show: false });

  const formik = useFormik({
    initialValues: {
      name: $state.name,
      email: $state.email,
      phone: $state.phone,
      zipCode: $state.zipCode,
      storeDelivery: $state.storeDelivery ? chooses.STORE : chooses.ANOTHER,
    },

    validationSchema: Yup.object({
      name: InputName.rules,
      email: InputEmail.rules,
      phone: InputPhone.rules,
      zipCode: InputZipCode.rules,
    }),

    onSubmit: async (values) => {
      const storeDelivery = isStoreDelivery(values.storeDelivery);
      closeAlert();
      try {
        const response = storeDelivery || await checkDeliveryCep(values.zipCode);
        if (response) {
          $actions.setCustomer({
            name: values.name,
            email: values.email,
            phone: values.phone,
          });
          $actions.setDelivery({
            ...deliveryData,
            zipCode: values.zipCode ?? '',
            isChartered: storeDelivery,
            blockZipCodeEdit: true,
          });
          $actions.setStep($state.step + 1);
        } else {
          showAlert({
            message: 'Ainda não temos abrangência para o CEP selecionado, estamos trabalhando para melhorar isso.',
            severity: 'error',
          });
        }
      } catch (e) {
        showAlert({
          message: 'Houve um erro, tente novamente mais tarde',
          severity: 'error',
        });
      }
    },
  });

  const isSubmitting = formik.isSubmitting ? StepperButtons.SUBMITTING.NEXT : null;

  const storeDelivery = isStoreDelivery(formik.values.storeDelivery);

  React.useEffect(() => {
    if (storeDelivery) {
      setDeliveryData($state.storeAddress);
      formik.handleChange('zipCode')($state.storeAddress.zipCode);
    } else {
      setDeliveryData(null);
      formik.handleChange('zipCode')($state.zipCode ?? '');
    }
  }, [formik.values.storeDelivery]);

  React.useEffect(() => {
    if (!storeDelivery && String(formik.values.zipCode ?? '').length === 9) {
      setLoading(true);
      (async () => {
        try {
          const response = await searchZipCodeInfo(formik.values.zipCode);
          setDeliveryData(response);
        } catch (e) {
          showAlert({
            message: 'Não foi possível encontrar as informações deste CEP, mas não se preocupe, você pode nos fornecer mais detalhes nos próximo passos.',
            severity: 'warning',
          });
        } finally {
          setLoading(false);
        }
      })();
    }
  }, [formik.values.zipCode]);

  return (
    <>
      <Box textAlign="center">
        <Typography variant="subtitle1" component="div">Diga-nos um pouco sobre você:</Typography>
        <Box p={2} textAlign="left" maxWidth="420px" margin="0 auto">
          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={2}>
              <Box py={1}>
                <InlineAlertCollapse
                  show={alert.show}
                  message={alert.message}
                  severity={alert.severity}
                />
              </Box>
              <InlineGridInput id="name" fieldName="name" Component={InputName} formik={formik} />
              <InlineGridInput id="email" fieldName="email" Component={InputEmail} formik={formik} />
              <InlineGridInput id="phone" fieldName="phone" Component={InputPhone} formik={formik} />
              <Box py={0.5} width="100%">
                <Divider />
              </Box>
              <InlineGridInput
                fieldName="storeDelivery"
                Component={RadioInputStoreDelivery}
                formik={formik}
              />
              <Collapse
                in={!storeDelivery}
                unmountOnExit
                component={Box}
                width="100%"
              >
                <Box py={1}>
                  <InlineGridInput
                    formik={formik}
                    fieldName="zipCode"
                    Component={InputZipCode}
                    disabled={storeDelivery || formik.isSubmitting}
                  />
                </Box>
              </Collapse>
            </Grid>
            <FocusError errors={formik.errors} isSubmitting={formik.isSubmitting} isValidating={formik.isValidating} />
          </form>
        </Box>
      </Box>
      <StepperButtons nextStep={formik.submitForm} isSubmitting={isSubmitting} />
      <Backdrop className={classes.backdrop} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}

AboutCustomer.propTypes = AboutCustomerPropTypes;
AboutCustomer.defaultProps = AboutCustomerDefaultProps;

export default ConnectComponentRedux(AboutCustomer);
