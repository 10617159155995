import React from 'react';
// MATERIAL-UI
import {
  Box, Typography, List,
} from '@material-ui/core';
// ICONS
import PageviewIcon from '@material-ui/icons/Pageview';
import InsertCommentIcon from '@material-ui/icons/InsertComment';
import BrokenImageIcon from '@material-ui/icons/BrokenImage';
// PROP-TYPES
import PropTypes from './PropTypes';
// COMPONENTS
import { InfoData } from '../Common';
// UTILS
import {
  repairObsFormatter,
  rootCauseFormatter,
  repairResultFormatter,
} from './utils';
// STYLES
import styles from './styles';

const Repair = ({ order }) => {
  const classes = styles();
  const [repairObs, setRepairObs] = React.useState(null);
  const [rootCause, setRootCause] = React.useState(null);
  const [repairResult, setRepairResult] = React.useState(null);
  React.useEffect(() => {
    setRepairObs(repairObsFormatter(order.estimates));
    setRootCause(rootCauseFormatter(order.estimates));
    setRepairResult(repairResultFormatter(order.estimates));
  }, []);
  return (
    <Box className={classes.box}>
      <Typography className={classes.title} variant="h2" align="left">
        Dados do Reparo
      </Typography>
      <List className={classes.list}>
        <InfoData
          title="Observação do Reparo"
          info={repairObs}
          icon={<InsertCommentIcon className={classes.icon} />}
        />
        <InfoData
          title="Resultado do Reparo"
          info={repairResult}
          icon={<PageviewIcon className={classes.icon} />}
        />
        <InfoData
          title="Causa Raiz"
          info={rootCause}
          icon={<BrokenImageIcon className={classes.icon} />}
        />
      </List>
    </Box>
  );
};

export default Repair;

Repair.propTypes = PropTypes.propTypes;
Repair.defaultProps = PropTypes.defaultProps;
