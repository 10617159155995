import gql from 'graphql-tag';

export const GetOrderStatus = gql`
  query GetOrderStatus($status_name: String!) {
    getReparofoneOrderStatus(status_name: $status_name) {
      id
      status_name
      status_slug
      lay_name
      icon
      OOW_sequence
    }
  }
`;

export const GetOrderStatusWithMovements = gql`
  query GetOrderStatusWithMovements($status_name: String!, $movement_order_id: ID!) {
    getReparofoneOrderStatus(status_name: $status_name) {
      id
      status_name
      status_slug
      lay_name
      icon
      OOW_sequence
    }

    getReparofoneMovimentations(movement_order_id: $movement_order_id) {
      id
      oldStatus {
        status_name
        status_slug
        lay_name
        id
        icon
        OOW_sequence
      }
      newStatus {
        status_name
        status_slug
        lay_name
        id
        icon
        OOW_sequence
      }
    }
  }
`;

export default GetOrderStatus;
