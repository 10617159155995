import React from 'react';
import { TextField } from '@material-ui/core';
import PropTypes from './PropTypes';
import rules from './Rules';

export default function InputDefectComment({
  handleChange, handleBlur, errors, value,
}) {
  return (
    <TextField
      multiline
      fullWidth
      name="defectComment"
      variant="outlined"
      label="Comentários adicionais sobre o defeito"
      placeholder="O aparelho só liga acima de 30%..."
      rows={2}
      rowsMax={6}
      value={value}
      error={!!errors}
      onBlur={handleBlur}
      onChange={handleChange}
      helperText={errors || null}
    />
  );
}

InputDefectComment.propTypes = PropTypes.propTypes;
InputDefectComment.defaultProps = PropTypes.defaultProps;
InputDefectComment.rules = rules;
