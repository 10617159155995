import * as P from 'prop-types';

const propTypes = {
  onSelectStore: P.func.isRequired,
  setPopupInfo: P.func.isRequired,
};

export default {
  propTypes,
  defaultProps: {},
};
