import client from '@/Apollo';
import FindCouponById from '../query/FindCouponById';

const getCoupon = (data) => new Promise((resolve, reject) => {
  client
    .query({
      query: FindCouponById,
      variables: { id: data.id },
    })
    .then((r) => {
      resolve(r.data.ReparafoneFindCoupon);
    })
    .catch((e) => {
      console.log(e.message, 'erro ao buscar');
      reject(e.message);
    });
});

export default getCoupon;
