import gql from 'graphql-tag';

export const GetNpsFeedbacks = gql`
  query GetNpsFeedbacks {
    ReparafoneGetNpsFeedbacks(
      orderBy: [{ column: ID, order: DESC }]
      page: 1
      first: 5
    ) {
      paginatorInfo {
        count
        currentPage
        total
        lastPage
        perPage
      }
      data {
        order {
          customer {
            fullName
            customer_city
            customer_state
            customer_first_name
          }
        }
        message
        permission_publish
        score {
          note
        }
        question {
          question
        }
      }
    }
  }
`;

export default GetNpsFeedbacks;
