import React from 'react';
import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  IconButton,
  Typography,
  TextField,
} from '@material-ui/core/';
import { parseISO, format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import PhoneAndroidRoundedIcon from '@material-ui/icons/PhoneAndroidRounded';
import BorderColorRoundedIcon from '@material-ui/icons/BorderColorRounded';
import TodayIcon from '@material-ui/icons/Today';
import CloseIcon from '@material-ui/icons/Close';
import useStyles from './style';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

export default function AlertDialogSlide({
  open,
  clickClose,
  cancelOrder,
  order,
  setFailureNote,
  setFailureChange,
  failureChange,
}) {
  const classes = useStyles();
  const OSDate = `Data de Criação - ${format(parseISO(order.createdAt), 'dd MMMM yyyy', {
    locale: ptBR,
  })}`;
  const OSNumber = `Número da OS - ${order.id}`;
  const OSPhone = `Aparelho - ${order.product.name}`;
  const handleInputObs = (e) => {
    const { value } = e.target;
    if (value) {
      setFailureChange(false);
      return setFailureNote(value);
    }
    setFailureNote(null);
    return setFailureChange(true);
  };

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title" className={classes.dialogTitle}>
          <Typography variant="h2" className={classes.dialogTitleText}>
            Você tem certeza que deseja cancelar a OS de reparo?
          </Typography>
          <IconButton aria-label="close" className={classes.closeButton} onClick={clickClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className={classes.dialogContent} dividers>
          <DialogContentText id="alert-dialog-slide-description">
            <Chip icon={<TodayIcon />} label={OSDate} className={classes.chip} />
            <br />
            <Chip icon={<BorderColorRoundedIcon />} label={OSNumber} className={classes.chip} />
            <br />
            <Chip icon={<PhoneAndroidRoundedIcon />} label={OSPhone} className={classes.chip} />
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="cancel"
            label="Diga nos o motivo do cancelamento"
            type="text"
            variant="outlined"
            multiline
            error={failureChange}
            helperText={
              !failureChange ? null : 'Por gentileza, nos diga o motivo'
            }
            rows={4}
            fullWidth
            onChange={handleInputObs}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={clickClose}>
            Não
          </Button>
          <Button onClick={cancelOrder} size="small" variant="contained" color="primary">
            Sim
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
