import React from 'react';
import { Button } from '@material-ui/core';
import { get } from 'lodash';
import Alert from '@material-ui/lab/Alert';

const WaitingPayment = (props) => {
  const { order } = props;
  const link = `${order.id}/payment`;
  return (
    link ? <Button href={link} component="a" variant="contained" color="primary">Realizar pagamento</Button>
      : (<Alert severity="error">Houve um erro com o seu link de pagamento, por favor contate o suporte.</Alert>)
  );
};

export default WaitingPayment;
