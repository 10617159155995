import React from 'react';
import * as P from 'prop-types';
import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { textFieldType, textFieldSize, states } from '../Common';

function State({
  value, handleChange, disabled, prefix, error,
}) {
  const name = `${prefix}[state]`;
  const getOptionLabel = (option) => option.name ?? '';
  const onChange = (ev, selectedValue) => {
    handleChange(name)(selectedValue?.acronym ?? '');
  };
  const valueParsed = states.find((state) => state.acronym === value);

  return (
    <Autocomplete
      value={{ ...valueParsed }}
      options={states}
      disabled={disabled}
      onChange={onChange}
      name={name}
      getOptionLabel={getOptionLabel}
      renderInput={(params) => (
        <TextField
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...params}
          error={!!error}
          helperText={error || null}
          size={textFieldSize}
          label="Estado"
          variant={textFieldType}
        />
      )}
    />
  );
}

State.propTypes = {
  value: P.string,
  handleChange: P.func.isRequired,
  disabled: P.bool,
  prefix: P.string.isRequired,
  error: P.string,
};

State.defaultProps = {
  value: '',
  disabled: false,
  error: '',
};

export default React.memo(State);
