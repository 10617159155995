import { client } from '../../../../Apollo';
import { GetBranchById } from '../query/GetBranch';

const getBranch = (id) => new Promise((resolve, reject) => {
  client
    .query({
      query: GetBranchById,
      fetchPolicy: 'network-only',
      variables: { id },
    })
    .then((r) => {
      resolve(r.data.ReparafoneGetBranchById);
    })
    .catch((e) => {
      console.log(e.message, 'erro ao buscar');
      reject(e.message);
    });
});

export default getBranch;
