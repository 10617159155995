import {
  Box,
  IconButton,
  Paper,
  Tooltip,
  Typography,
  Zoom,
} from '@material-ui/core';
import React from 'react';
import { Replay } from '@material-ui/icons';
import ReactGA from 'react-ga';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { resetOrder, setStep } from '@redux/actions/NewOrderActions/actions';
import { setBranch } from '@redux/actions/PartnerStoreActions';
import style from './style';
import StepperContent from '../StepperContent';
import { getStep } from '../SelectDevice/Components/StepperSelectDevice/common';
import StepperHeader from '../StepperHeader';
import RefuseBudget from '../RefuseBudget';
import Alerts from '../RefuseBudget/components/Alerts';
import StoresList from '../../graphql/schemas/StoresList';
import { FormStepperPropTypes, FormStepperDefaultProps } from './PropTypes';

function FormStepper({ $actions, $state }) {
  const {
    store: { name: storeName },
    store: { branch: { branch_name: branchName } },
    lead,
  } = $state;
  const classes = style();
  const [step, setProductStep] = React.useState(getStep($state));
  const [openRefuseBudget, setOpenRefuseBudget] = React.useState(false);
  const [showAlert, setShowAlert] = React.useState(null);
  const [alertMessage, setMessageAlert] = React.useState(false);
  const [alertSeverity, setSeverityAlert] = React.useState(false);
  const [alertDuration, setDurationAlert] = React.useState(false);
  const handleOpenRefuseBudget = () => {
    setOpenRefuseBudget(true);
  };
  const handleCloseRefuseBudget = () => {
    setOpenRefuseBudget(false);
  };
  const analyticsClick = () => {
    if (process.env.NODE_ENV === 'production') {
      ReactGA.event({
        category: 'createOrderPartner',
        action: 'Reset Order',
        label: 'Clicou no botão de refazer',
      });
    }
  };
  const handleClearClick = () => {
    if (lead.id) {
      analyticsClick();
      return handleOpenRefuseBudget();
    }
    analyticsClick();
    return $actions.setStep(0);
  };
  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowAlert(null);
  };
  const handleAlert = (message, severity, openAlert, duration) => {
    setMessageAlert(message);
    setSeverityAlert(severity);
    setShowAlert(openAlert);
    setDurationAlert(duration);
  };

  return (
    <div className={classes.backgroundForm}>
      <RefuseBudget
        clickToClose={handleCloseRefuseBudget}
        open={openRefuseBudget}
        handleAlert={handleAlert}
        setStep={setProductStep}
      />
      <Alerts
        open={showAlert}
        handleClose={handleCloseAlert}
        message={alertMessage}
        severity={alertSeverity}
        duration={alertDuration}
      />
      <StoresList />
      <Paper className="form" elevation={0}>
        <div id="topContent" className={classes.topContent}>
          <div className={classes.clearButton}>
            <Tooltip title="Refazer Ordem" TransitionComponent={Zoom}>
              <IconButton onClick={handleClearClick} aria-label="refazer ordem">
                <Replay fontSize="large" htmlColor="#F6C23A" />
              </IconButton>
            </Tooltip>
          </div>
          <Typography
            variant="h5"
            component={Box}
            display="block"
            px={6}
            textAlign="center"
            className={classes.branchName}
          >
            Cadastro de Reparo -
            {' '}
            {branchName}
          </Typography>
        </div>
        <Typography
          variant="overline"
          display="block"
          gutterBottom
          className={classes.storeName}
        >
          {storeName}
        </Typography>
        <StepperHeader />
        <StepperContent step={step} setStep={setProductStep} />
      </Paper>
    </div>
  );
}
const mapDispatchToProps = (dispatch) => ({
  $actions: bindActionCreators({ resetOrder, setBranch, setStep }, dispatch),
});
const mapStateToProps = ({ PartnerStoreReducer, NewOrderReducer }) => ({
  $state: {
    store: PartnerStoreReducer,
    lead: NewOrderReducer.lead,
    device: NewOrderReducer.device,
    product: NewOrderReducer.device.product,
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(FormStepper);

FormStepper.propTypes = FormStepperPropTypes;
FormStepper.defaultProps = FormStepperDefaultProps;
