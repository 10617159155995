import * as P from 'prop-types';
import { ProductProps, DefectsProps } from '@redux/reducers/NewRepairReducer/PropTypes';

export const propTypes = {
  $state: P.shape({
    product: P.shape(ProductProps),
    selectedDefects: P.arrayOf(P.shape(DefectsProps)),
    deliveryZipcode: P.string.isRequired,
    collectZipcode: P.string.isRequired,
  }),
  disabledBtnNextStep: P.func.isRequired,
};

export const defaultProps = {};

export default {
  propTypes,
  defaultProps,
};
