const addressInfo = {
  zipCode: undefined,
  state: undefined,
  city: undefined,
  neighborhood: undefined,
  street: undefined,
  number: undefined,
  complement: undefined,
  isChartered: false,
  blockZipCodeEdit: false,
};

const initialState = {
  activeStep: 0,
  origin_user: {},
  origin_store: {},
  collect: {
    schedule_collect: undefined,
    chosenModality: {
      collect_slug: undefined,
    },
    modalities: [],
  },
  delivery: {
    schedule_delivery: undefined,
    modalities: [],
  },
  customer: {
    name: undefined,
    email: undefined,
    phone: undefined,
    cpf: undefined,
    collect: addressInfo,
    delivery: { ...addressInfo, isChartered: false },
  },
  device: {
    imei: undefined,
    brand: undefined,
    model: {
      id: undefined,
      name: undefined,
      brand: undefined,
    },
    color: undefined,
    product: {
      id: undefined,
      name: undefined,
      defects: [],
    },
    conditions: [],
    selectedDefects: [],
    defectComment: undefined,
  },
  payment: {
    type: 'laterPayment',
    data: {
      cpf: undefined,
      cvc: undefined,
      name: undefined,
      number: undefined,
      expiry: undefined,
      installments: undefined,
      installmentsText: undefined,
      cardToken: undefined,
    },
  },
  billingAddress: {
    zipCode: undefined,
    state: undefined,
    city: undefined,
    neighborhood: undefined,
    street: undefined,
    number: undefined,
    complement: undefined,
  },
  transactionAmount: {
    taxDelivery: 0,
    taxCollect: 0,
    budgetRepair: 0,
  },
  coupons: [],
  lead: {},
  orderData: undefined,
  refusedBudgetReason: {
    id: undefined,
    name: undefined,
    description: undefined,
    slug: undefined,
    lay_name: undefined,
  },
  allRefusedBudgetReasons: [],
};
export default initialState;
