import React from 'react';
// MATERIAL-UI
import { Snackbar } from '@material-ui/core';
// MATERIAL-LAB
import Alert from '@material-ui/lab/Alert';
// PROP-TYPES
import PropTypes from './PropTypes';
// STYLES
import styles from './style';

const Alerts = ({
  open,
  handleClose,
  message,
  severity,
  duration,
}) => {
  const classes = styles();
  return (
    <>
      <Snackbar open={open} autoHideDuration={duration} onClose={handleClose}>
        <Alert onClose={handleClose} variant="filled" severity={severity}>
          {message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default Alerts;

Alerts.propTypes = PropTypes.propTypes;
Alerts.defaultProps = PropTypes.defaultProps;
