import { CreateStatusFailure } from '../mutation/CreateStatusFailure';
import { client } from '../../../../Apollo';

const createStatusFailureOrder = (data) => new Promise((resolve, reject) => {
  client
    .mutate({
      mutation: CreateStatusFailure,
      variables: {
        failure_order_id: data.failureOrderId,
        failure_movement_id: data.failureMovementId,
        failure_reason: data.failureReason,
        failure_note: data.failureNote,
      },
    })
    .then((r) => {
      resolve(r.data.createStatusFailureOrderRepair);
    })
    .catch((e) => {
      console.log(e.message, 'erro ao criar');
      reject(e.message);
    });
});

export default createStatusFailureOrder;
