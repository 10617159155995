import OrdersBridge from '@/components/orders/ListOrdersVivo/OrdersBridge';
import {
  Box,
  Container, Paper, Typography,
} from '@material-ui/core';
import { loadCSS } from 'fg-loadcss';
import { useSelector } from 'react-redux';
import React from 'react';
import GetPartnerOrder from '../graphql/GetPartnerOrders';

const Home = () => {
  const storeData = useSelector((state) => state.VivoStoreReducer);
  React.useEffect(() => {
    const node = loadCSS(
      'https://use.fontawesome.com/releases/v5.12.0/css/all.css',
      document.querySelector('#font-awesome-css'),
    );

    return () => {
      node.parentNode.removeChild(node);
    };
  }, []);
  return (
    <Container maxWidth="lg">
      <Paper elevation={0}>
        <Box p={3}>
          <Typography variant="h6" align="center">
            Ordens de serviço
          </Typography>
          <Typography variant="subtitle1" align="center">
            Loja: {storeData.name}
          </Typography>
          <OrdersBridge query={GetPartnerOrder} />
        </Box>
      </Paper>
    </Container>
  );
};

export default Home;
