import React from 'react';
import MarkOrderAsReceived from '@/pages/partner/graphql/MarkOrderAsReceived';
import { useMutation } from '@apollo/client';
import {
  add, parse, format, parseISO,
} from 'date-fns';
import {
  Box,
  Divider,
  Grid,
  Icon,
  IconButton,
  CircularProgress,
  Button,
  Zoom,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import AssignmentIcon from '@material-ui/icons/Assignment';
import { useSelector } from 'react-redux';

import AlertCancel from './components/AlertCancel';
import Alerts from './components/Alert';
import Dialogo from './components/Dialogo';
import DialogoWarranty from './components/DialogoWarranty';
import useStyles from './style';
import getMovementsAndStatus from './graphql/schema/GetStatusAndMovements';
import getEmployeeById from './graphql/schema/GetEmployee';
import createStatusFailureOrder from './graphql/schema/CreateStatusFailure';
import createMovimentationsAndStatusOrder from './graphql/schema/CreateMovimentationsAndStatusOrder';

const OrderLine = ({ orderData }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <>
      {isMobile ? (
        <GridInformation data={orderData} />
      ) : (
        <TableInformation data={orderData} />
      )}
    </>
  );
};
const MarkAsReceivedButton = ({ orderData, ...props }) => {
  const [markAsReceived, { data, loading }] = useMutation(MarkOrderAsReceived, {
    variables: { id: orderData.id },
  });
  if (orderData.status.slug !== 'recebido-quiosque') return null;
  const handleClick = () => {
    markAsReceived();
  };
  return (
    <div {...props}>
      {loading ? (
        'Carregando'
      ) : (
        <Tooltip title="Sinalizar que cliente recebeu o aparelho">
          <IconButton onClick={handleClick}>
            <Icon
              className="fas fa-user-check"
              style={{ width: 'auto' }}
              color="secondary"
            />
          </IconButton>
        </Tooltip>
      )}
    </div>
  );
};
const CreatedBy = ({ orderData }) => {
  const [tradeInUser, setTradeInUser] = React.useState({ name: '' });
  React.useEffect(() => {
    if (orderData.tradeInUserId === '-1') return setTradeInUser({ name: 'Simulação' });
    if (orderData.tradeInUserId && orderData.tradeInUserId !== '-1') {
      getEmployeeById(orderData.tradeInUserId).then((dataUser) => setTradeInUser(dataUser));
    }
    return setTradeInUser({ name: '' });
  }, [orderData.tradeInUserId]);
  return (
    <>
      {tradeInUser.name}
    </>
  );
};

const TableInformation = ({ data: orderData }) => {
  const classes = useStyles();
  const customerData = useSelector((state) => state.PartnerStoreReducer);
  const { employee } = customerData;
  const statusName = 'Cancelado';
  const data = { statusName, orderId: orderData.id };
  const [isLoading, setLoading] = React.useState(false);
  const [isLoadingWarranty, setWarrantyLoading] = React.useState(false);
  const [valid, setIsValid] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [openWarrantyDialog, setOpenWarrantyDialog] = React.useState(false);
  const [failureNote, setFailureNote] = React.useState(null);
  const [failureChange, setFailureChange] = React.useState(false);
  const [showAlert, setShowAlert] = React.useState(null);
  const [alertMessage, setMessageAlert] = React.useState(false);
  const [alertSeverity, setSeverityAlert] = React.useState(false);
  const [alertDuration, setDurationAlert] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClickOpenWarranty = () => {
    setOpenWarrantyDialog(true);
  };
  const handleCloseWarranty = () => {
    setOpenWarrantyDialog(false);
  };
  const handleClose = () => {
    setOpen(false);
    setFailureChange(false);
  };
  const createStatusFailureData = (dataFailure) => {
    createStatusFailureOrder(dataFailure)
      .then(() => {
        setIsValid(true);
      })
      .catch((e) => {
        setIsValid(false);
      })
      .finally(() => setLoading(false));
  };
  const createMovimentationsAndStatusOrderData = (dataCreate) => {
    createMovimentationsAndStatusOrder(dataCreate)
      .then((r) => {
        createStatusFailureData({
          failureOrderId: orderData.id,
          failureMovementId: r.createMovimentationsOrderRepair.id,
          failureReason: 'Cliente cancelou através do site',
          failureNote,
        });
      })
      .catch((e) => {
        setIsValid(false);
        setLoading(false);
      });
  };
  const getStatusAndMovementsData = (allData) => {
    getMovementsAndStatus(allData)
      .then((r) => {
        const lastIndex = r.getReparofoneMovimentations.length - 1;
        createMovimentationsAndStatusOrderData({
          id: orderData.id,
          statusOrderId: r.getReparofoneOrderStatus.id,
          orderId: orderData.id,
          statusId: r.getReparofoneOrderStatus.id,
          previousStatusId:
            r.getReparofoneMovimentations[lastIndex].newStatus.id,
          userId: 0,
        });
      })
      .catch((e) => {
        setIsValid(false);
        setLoading(false);
      });
  };
  const handleCancelOrder = () => {
    if (failureNote) {
      setFailureChange(false);
      handleClose();
      setLoading(true);
      return getStatusAndMovementsData(data);
    }
    return setFailureChange(true);
  };
  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowAlert(null);
  };
  const handleAlert = (message, severity, openAlert, duration) => {
    setMessageAlert(message);
    setSeverityAlert(severity);
    setShowAlert(openAlert);
    setDurationAlert(duration);
  };
  const parsedDate = parse(orderData.createdAt, 'yyyy-MM-dd HH:mm:ss', new Date());

  const handleViewOrderDetails = () => document.location.assign(
    `${window.location.protocol}//${document.location.host}/partners/servicerepair/orders/${orderData.id}`,
  );

  return (
    <>
      <Alerts
        open={showAlert}
        handleClose={handleCloseAlert}
        message={alertMessage}
        severity={alertSeverity}
        duration={alertDuration}
      />
      <Dialogo
        cancelOrder={handleCancelOrder}
        open={open}
        order={orderData}
        setFailureNote={setFailureNote}
        clickClose={handleClose}
        setFailureChange={setFailureChange}
        failureChange={failureChange}
      />
      <DialogoWarranty
        open={openWarrantyDialog}
        order={orderData}
        clickClose={handleCloseWarranty}
        handleAlert={handleAlert}
        setWarrantyLoading={setWarrantyLoading}
      />
      <AlertCancel valid={valid} setIsValid={setIsValid} />
      <TableRow>
        <TableCell>{orderData.id}</TableCell>
        <TableCell>{orderData.customer.fullName}</TableCell>
        <TableCell>{orderData.product.name}</TableCell>
        <TableCell>{orderData.status.name}</TableCell>
        <TableCell>
          {JSON.parse(orderData.informedDefects).join(', ')}
        </TableCell>
        <TableCell>
          {JSON.parse(orderData.repairedDefects).join(', ')}
        </TableCell>
        <TableCell>
          {format(parseISO(orderData.createdAt), 'dd/MM/Y kk:mm')}
        </TableCell>
        <TableCell>
          {format(parseISO(orderData.lastMovement.date), 'dd/MM/Y kk:mm')}
        </TableCell>
        <TableCell>
          <CreatedBy orderData={orderData} />
        </TableCell>
        <TableCell>
          <MarkAsReceivedButton orderData={orderData} />
        </TableCell>
        <TableCell>
          <div className={classes.actions}>
            <Tooltip
              classes={classes.tooltip}
              arrow
              placement="right"
              TransitionComponent={Zoom}
              title={
              orderData
              && (orderData.status.name === 'Pendente Processamento'
                || orderData.status.name === 'Pendente Retirada')
                ? 'Cancelar a Ordem de Reparo'
                : 'Não é mais possível cancelar essa OS'
            }
            >
              <span style={{ paddingRight: '5px' }}>
                <IconButton
                  size="small"
                  onClick={handleClickOpen}
                  disabled={
                  !(
                    orderData
                    && (orderData.status.name === 'Pendente Processamento'
                      || orderData.status.name === 'Pendente Retirada')
                  ) || isLoading
                }
                  variant="contained"
                  color="secondary"
                  className={
                    !(
                      orderData
                    && (orderData.status.name === 'Pendente Processamento'
                      || orderData.status.name === 'Pendente Retirada')
                    ) || isLoading ? classes.buttonDisabled : classes.buttonCancel
}
                >
                  {isLoading ? (
                    <CircularProgress size={22} className={classes.buttonProgress} />
                  ) : (
                    <DeleteIcon fontSize="inherit" />
                  )}
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip
              classes={classes.tooltip}
              arrow
              placement="right"
              TransitionComponent={Zoom}
              title={
              orderData
              && (orderData.status.name === 'Concluído' && add(parsedDate, { days: 90 }) >= new Date()
                  && orderData.isCreatedWarranty === null)
                ? 'Criar Ordem em Garantia'
                : 'Não é possível criar essa OS na Garantia'
            }
            >
              <span style={{ paddingRight: '5px' }}>
                <IconButton
                  size="small"
                  onClick={handleClickOpenWarranty}
                  disabled={
                  !(
                    orderData
                    && (orderData.status.name === 'Concluído' && add(parsedDate, { days: 90 }) >= new Date()
                  && orderData.isCreatedWarranty === null)
                  ) || isLoadingWarranty
                }
                  variant="contained"
                  color="secondary"
                  className={
                    !(
                      orderData
                    && ((orderData.status.name === 'Concluído' && add(parsedDate, { days: 90 }) >= new Date()
                  && orderData.isCreatedWarranty === null))
                    ) || isLoadingWarranty ? classes.buttonDisabled : classes.buttonWarranty
                  }
                >
                  {isLoadingWarranty ? (
                    <CircularProgress size={22} className={classes.buttonProgress} />
                  ) : (
                    <AddCircleIcon fontSize="inherit" />
                  )}
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip
              classes={classes.tooltip}
              arrow
              placement="right"
              TransitionComponent={Zoom}
              title={`Ver Ordem ${orderData.id}`}
            >
              <span>
                <IconButton
                  size="small"
                  onClick={handleViewOrderDetails}
                  variant="contained"
                  color="secondary"
                  className={classes.buttonDetails}
                >
                  <AssignmentIcon fontSize="inherit" />
                </IconButton>
              </span>
            </Tooltip>
          </div>
        </TableCell>
      </TableRow>
    </>
  );
};
const GridInformation = ({ data: orderData }) => {
  const classes = useStyles();
  const customerData = useSelector((state) => state.PartnerStoreReducer);
  const { employee } = customerData;
  const { tradeInUserId } = orderData;
  const statusName = 'Cancelado';
  const data = { statusName, orderId: orderData.id };
  const [isLoading, setLoading] = React.useState(false);
  const [valid, setIsValid] = React.useState(null);
  const [tradeInUser, setTradeInUser] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [failureNote, setFailureNote] = React.useState(null);
  const [failureChange, setFailureChange] = React.useState(false);
  const [showAlert, setShowAlert] = React.useState(null);
  const [alertMessage, setMessageAlert] = React.useState(false);
  const [alertSeverity, setSeverityAlert] = React.useState(false);
  const [alertDuration, setDurationAlert] = React.useState(false);
  const [isLoadingWarranty, setWarrantyLoading] = React.useState(false);
  const [openWarrantyDialog, setOpenWarrantyDialog] = React.useState(false);
  const parsedDate = parse(orderData.createdAt, 'yyyy-MM-dd HH:mm:ss', new Date());

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleButtonClick = () => {
    handleClickOpen();
  };
  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowAlert(null);
  };
  const handleAlert = (message, severity, openAlert, duration) => {
    setMessageAlert(message);
    setSeverityAlert(severity);
    setShowAlert(openAlert);
    setDurationAlert(duration);
  };
  const handleClickOpenWarranty = () => {
    setOpenWarrantyDialog(true);
  };
  const handleCloseWarranty = () => {
    setOpenWarrantyDialog(false);
  };
  const handleViewOrderDetails = () => document.location.assign(
    `${window.location.protocol}//${document.location.host}/partners/servicerepair/orders/${orderData.id}`,
  );

  React.useEffect(() => {
    if (tradeInUserId) {
      getEmployeeById(tradeInUserId).then((dataUser) => setTradeInUser(dataUser));
    }
    return setTradeInUser(null);
  }, [tradeInUserId]);

  const createStatusFailureData = (dataFailure) => {
    createStatusFailureOrder(dataFailure)
      .then(() => {
        setIsValid(true);
      })
      .catch((e) => {
        setIsValid(false);
      })
      .finally(() => setLoading(false));
  };
  const createMovimentationsAndStatusOrderData = (dataCreate) => {
    createMovimentationsAndStatusOrder(dataCreate)
      .then((r) => {
        createStatusFailureData({
          failureOrderId: orderData.id,
          failureMovementId: r.createMovimentationsOrderRepair.id,
          failureReason: 'Parceiro cancelou através do site',
          failureNote,
        });
      })
      .catch((e) => {
        setIsValid(false);
        setLoading(false);
      });
  };
  const getStatusAndMovementsData = (allData) => {
    getMovementsAndStatus(allData)
      .then((r) => {
        const lastIndex = r.getReparofoneMovimentations.length - 1;
        createMovimentationsAndStatusOrderData({
          id: orderData.id,
          statusOrderId: r.getReparofoneOrderStatus.id,
          orderId: orderData.id,
          statusId: r.getReparofoneOrderStatus.id,
          previousStatusId:
            r.getReparofoneMovimentations[lastIndex].newStatus.id,
          userId: 0,
        });
      })
      .catch((e) => {
        setIsValid(false);
        setLoading(false);
      });
  };
  const handleCancelOrder = () => {
    if (failureNote) {
      setFailureChange(false);
      handleClose();
      setLoading(true);
      getStatusAndMovementsData(data);
    }
    return setFailureChange(true);
  };
  return (
    <>
      <Dialogo
        cancelOrder={handleCancelOrder}
        open={open}
        order={orderData}
        setFailureNote={setFailureNote}
        clickClose={handleClose}
        setFailureChange={setFailureChange}
        failureChange={failureChange}
      />
      <AlertCancel valid={valid} setIsValid={setIsValid} />
      <Alerts
        open={showAlert}
        handleClose={handleCloseAlert}
        message={alertMessage}
        severity={alertSeverity}
        duration={alertDuration}
      />
      <DialogoWarranty
        open={openWarrantyDialog}
        order={orderData}
        clickClose={handleCloseWarranty}
        handleAlert={handleAlert}
        setWarrantyLoading={setWarrantyLoading}
      />
      <Grid container direction="column" style={{ position: 'relative' }}>
        <MarkAsReceivedButton
          orderData={orderData}
          style={{ position: 'absolute', bottom: 0, right: 0 }}
        />
        <Grid item>
          <Typography variant="h6">{`Ordem #${orderData.id}`}</Typography>
        </Grid>
        <Grid item>
          <Typography variant="body1">{orderData.customer.fullName}</Typography>
        </Grid>
        <Grid item>
          <Typography variant="body1">{orderData.product.name}</Typography>
        </Grid>
        <Grid item>
          <Typography variant="body1">{orderData.status.name}</Typography>
        </Grid>
        <Grid item>
          <Typography variant="body1">
            {JSON.parse(orderData.informedDefects).join(', ')}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body1">
            {JSON.parse(orderData.repairedDefects).join(', ')}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body1">
            {format(parseISO(orderData.createdAt), 'dd/MM/Y kk:mm')}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body1">
            {format(parseISO(orderData.lastMovement.date), 'dd/MM/Y kk:mm')}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body1">
            Criado por:
            {' '}
            {tradeInUser ? tradeInUser.name : ''}
          </Typography>
        </Grid>
        <Grid item>
          <Tooltip
            classes={classes.tooltip}
            arrow
            TransitionComponent={Zoom}
            placement="right"
            title={
              orderData
              && (orderData.status.name === 'Pendente Processamento'
                || orderData.status.name === 'Pendente Retirada')
                ? 'Cancelar OS de Reparo'
                : 'Não é mais possivel fazer essa ação'
            }
          >
            <span>
              <Button
                onClick={handleButtonClick}
                size="small"
                style={{ marginTop: 10 }}
                disabled={
                  !(
                    orderData
                    && (orderData.status.name === 'Pendente Processamento'
                      || orderData.status.name === 'Pendente Retirada')
                  ) || isLoading
                }
                variant="contained"
                color="secondary"
                className={classes.buttonCancel}
              >
                {isLoading ? (
                  <CircularProgress size={20} className={classes.buttonProgressMobile} />
                ) : (
                  'Cancelar'
                )}
              </Button>
            </span>
          </Tooltip>
        </Grid>
        <Grid item>
          <Tooltip
            classes={classes.tooltip}
            arrow
            TransitionComponent={Zoom}
            placement="right"
            title={
              orderData
              && (orderData.status.name === 'Concluído'
              && add(parsedDate, { days: 90 }) >= new Date()
              && orderData.isCreatedWarranty === null)
                ? 'Criar Ordem em Garantia'
                : 'Não é mais possivel criar essa Ordem em Garantia'
            }
          >
            <span>
              <Button
                onClick={handleClickOpenWarranty}
                disabled={
                  !(
                    orderData
                    && (orderData.status.name === 'Concluído' && add(parsedDate, { days: 90 }) >= new Date()
                  && orderData.isCreatedWarranty === null)
                  ) || isLoadingWarranty
                }
                size="small"
                style={{ marginTop: 10 }}
                variant="contained"
                color="secondary"
                className={
                    !(
                      orderData
                    && ((orderData.status.name === 'Concluído' && add(parsedDate, { days: 90 }) >= new Date()
                  && orderData.isCreatedWarranty === null))
                    ) || isLoadingWarranty ? classes.buttonDisabled : classes.buttonWarranty
                  }
              >
                {isLoadingWarranty ? (
                  <CircularProgress size={20} className={classes.buttonProgressMobile} />
                ) : (
                  'Garantia'
                )}
              </Button>
            </span>
          </Tooltip>
        </Grid>
        <Grid item>
          <Tooltip
            classes={classes.tooltip}
            arrow
            TransitionComponent={Zoom}
            placement="right"
            title={`Ver Ordem ${orderData.id}`}
          >
            <span>
              <Button
                onClick={handleViewOrderDetails}
                size="small"
                style={{ marginTop: 10 }}
                variant="contained"
                color="secondary"
                className={classes.buttonDetails}
              >
                {`Ver Ordem ${orderData.id}`}
              </Button>
            </span>
          </Tooltip>
        </Grid>
      </Grid>
      <Box p={3}>
        <Divider />
      </Box>
    </>
  );
};
export default OrderLine;
