import P from 'prop-types';

export const TextInputFormikPropTypes = {
  handleChange: P.func.isRequired,
  handleBlur: P.func.isRequired,
  errors: P.string,
  value: P.string.isRequired,
  typeInput: P.oneOf(['filled', 'standard', 'outlined']).isRequired,
  disabled: P.bool,
};

export const TextInputFormikDefaultProps = {
  errors: '',
  value: '',
  disabled: false,
};

export default { TextInputFormikPropTypes, TextInputFormikDefaultProps };
