import CalculateRepairCost from '@/pages/neworderpartner/utils/CalculateRepairCost';
import { setTransactionAmount } from '../actions';

export default function calculateTotalBudgetAmount() {
  return async (dispatch, getState) => {
    const {
      customer: { delivery, collect },
      device: { selectedDefects },
    } = getState().NewOrderReducer;

    const transactionAmount = await CalculateRepairCost(
      selectedDefects,
      collect.zipCode,
      delivery.zipCode,
    );
    if (!transactionAmount) return Promise.reject();
    dispatch(setTransactionAmount(transactionAmount));

    return Promise.resolve(transactionAmount);
  };
}
