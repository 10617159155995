import { makeStyles } from '@material-ui/core/';
import { green } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '20px 0',
  },
  textField: {
    margin: '10px 0 12px 0',
  },
  optionTitle: {
    fontSize: '1.1rem',
    fontWeight: 500,
  },
  optionDescription: {
    fontSize: '0.9rem',
  },
  optionBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '100%',
  },
  divider: {
    width: '100%',
  },
}));

export default useStyles;
