import { makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
  title: {
    flex: '1 1 100%',
    fontSize: '1.7rem',
    textAlign: 'center',
  },
}));

export default styles;
