import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles(({ spacing }) => ({
  gridContainer: {
    padding: `${spacing(1)}px 0`,
    '& .gridItem': {
      textAlign: 'center',
      display: 'flex',
      alignItems: 'center',
    },
  },
  cardColor: {
    width: '100%',
    height: 40,
    borderRadius: 8,
  },
  cardAction: {
    color: 'darkslategrey',
    fontSize: 14,
    padding: 0,
  },
  colorView: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 40,
    width: 40,
    marginRight: 10,
    borderRight: '1px solid lightgrey',
  },
  colorSharpTag: {
    color: '#ffffffda',
    textShadow: '1px 1px 0px #00000045',
    fontSize: 21,
    lineHeight: '1.2rem',
    fontWeight: 700,
    background: '#ffffff4d',
    width: 29,
    height: 29,
    borderRadius: '50%',
    padding: 4,
  },
}));
