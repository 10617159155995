import React from "react";

import { Typography } from "@material-ui/core";
import { HashLink as Link } from "react-router-hash-link";

import { Button } from "semantic-ui-react";

import ReactGA from "react-ga";

import "./Banner.css";

const handleCTA = () => {
  if (process.env.NODE_ENV === "production") {
    ReactGA.event({
      category: "Interactivity",
      action: "bannerButtonClicked",
      label: "Clicou no botão do Banner",
    });
  }
};

const Banner = () => (
  <div id="home" className="banner">
    <div className="banner-container">
      <Typography variant="h1" align="left" className="text titulo-banner">
        Em parceria com a Trocafone, repare seu smartphone na maior assistência
        técnica multimarcas do Brasil!
      </Typography>
      <Typography variant="h2" align="left" className="subtitulo-banner">
        Faça seu orçamento grátis, online e instantâneo.
      </Typography>
      <Link to="#do-estimate">
        <Button
          content="Solicitar Orçamento"
          icon="dollar"
          labelPosition="right"
          className="button-banner"
          onClick={handleCTA}
        />
      </Link>
    </div>
  </div>
);

export default Banner;
