// MATERIAL-UI
import { makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
  storeNameButton: {
    padding: 0,
    color: '#FFAA49',
    '&:hover': {
      color: '#dd9442',
    },
  },
  listItem: {
    minHeight: 'auto',
  },
  storeName: {
    fontSize: '1rem',
    color: '#FFAA49',
    margin: 0,
    '&:hover': {
      color: '#dd9442',
    },
  },
  addressName: {
    fontSize: '0.8rem',
  },
  kilometers: {
    fontSize: '0.7rem',
    fontWeight: '700',
  },
  loadingStores: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '30px',
  },
  gridGroup: {
    marginTop: 1,
    marginBottom: 1,
  },
  fixedSizeList: {
    '&::-webkit-scrollbar': {
      width: 5,
      '&-track': {
        background: '#f1f1f1',
        borderRadius: '10px',
      },
      '&-thumb': {
        background: theme.palette.grey[400],
        '&:hover': {
          background: theme.palette.grey[600],
        },
      },
    },
  },
  header: {
    fontFamily: 'Roboto',
    fontWeight: '400',
    color: '#333',
    fontSize: '18px',
    paddingTop: '10px',
    margin: 0,
  },
}));

export default styles;
