import { gql } from '@apollo/client';

export const getMyRepairOrders = gql`
  query getMyRepairOrders($cursor: String) {
    MeCustomer {
      id
      customer_first_name
      customer_last_name
      orders(
        orderBy: [{ column: ID, order: DESC }]
        first: 10
        after: $cursor
      ) {
        edges {
          node {
            id
            order_imei
            product {
              product_name
            }
            status {
              id
              lay_name
              OOW_sequence
            }
            collect {
              id
              collect_name
              need_schedule
            }
            movements {
              oldStatus {
                lay_name
              }
              newStatus {
                lay_name
              }
              movement_updated_at
            }
            order_pre_price
            order_created_at
            order_updated_at
            meta(
              where: {
                OR: [
                  { column: META_KEY, value: "informed_phone_defects" }
                  { column: META_KEY, value: "informed_phone_obs" }
                  { column: META_KEY, value: "link_for_order_payment" }
                ]
              }
            ) {
              id
              meta_key
              meta_value
            }
            collectSchedule(
              where: { column: IS_COLLECTED, value: false }
              orderBy: { column: SCHEDULED_DATE, order: DESC }
            ) {
              scheduled_date
            }
          }
        }
        pageInfo {
          endCursor
          hasNextPage
          total
        }
      }
    }
    getRepairOrderStatuses(
      orderBy: [{ column: O_O_W_SEQUENCE, order: ASC }]
      where: { column: O_O_W_SEQUENCE, operator: IS_NOT_NULL }
    ) {
      lay_name
      icon
      OOW_sequence
    }
  }
`;

export const getRepairOrderById = gql`
  query($orderId: ID!) {
    customerRepairOrderById(id: $orderId) {
      id
      order_imei
      isPaid
      product {
        product_name
      }
      informed_product_name
      addresses {
        id
        address_type
        addressable_type
        addressable_id
        zip_code
        street
        street_number
        complement
        reference
        neighborhood
        state
        city
      }
      status {
        id
        lay_name
        status_slug
        OOW_sequence
      }
      collect {
        id
        collect_name
        need_schedule
      }
      movements {
        oldStatus {
          lay_name
        }
        newStatus {
          lay_name
        }
        movement_updated_at
      }
      addresses {
        id
        zip_code
      }
      order_pre_price
      order_created_at
      order_updated_at
      meta(
        where: {
          OR: [
            { column: META_KEY, value: "informed_phone_defects" }
            { column: META_KEY, value: "informed_phone_obs" }
            { column: META_KEY, value: "link_for_order_payment" }
            { column: META_KEY, value: "trackingCodeOutbound" }
            { column: META_KEY, value: "collect_price" }
          ]
        }
      ) {
        id
        meta_key
        meta_value
      }
      collectSchedule(
        where: { column: IS_COLLECTED, value: false }
        orderBy: { column: SCHEDULED_DATE, order: DESC }
      ) {
        scheduled_date
      }
      lastEstimateImages{
        file_name
        url
      }
      estimates(orderBy: { column: ID, order: DESC }) {
        estimate_price
        phone_imei
        labor
        revision_obs
        revisions {
          id
          phone_status
          estimate_status
          total_price
          defect {
            defect_name
            lay_name
          }
          parts {
            part_name
          }
        }
        image_files_name
      }
    }
    getRepairOrderStatuses(
      orderBy: [{ column: O_O_W_SEQUENCE, order: ASC }]
      where: { column: O_O_W_SEQUENCE, operator: IS_NOT_NULL }
    ) {
      lay_name
      icon
      OOW_sequence
    }
  }
`;

export const repairDecisionCustomer = gql`
  mutation repairDecisionCustomer(
    $revisionId: ID!
    $estimate_status: ReparafoneRevisionStatusOptions!
  ) {
    repairDecisionCustomer(
      input: { id: $revisionId, estimate_status: $estimate_status }
    ) {
      id
      estimate_status
      total_price
    }
  }
`;

export const confirmEstimateApproval = gql`
  mutation confirmEstimateApproval(
    $orderId: ID!
    $estimate_decision: EstimateApprovalCustomerOptions!
  ) {
    EstimateApprovalCustomer(
      input: { id: $orderId, estimate_decision: $estimate_decision }
    ) {
      id
    }
  }
`;

export const requestRecoverPasswordCode = gql`
  mutation ($customerEmail: String!){
    CustomerRequestRecoverPassword(email:$customerEmail){
      id
      customer_email
    }
  }
`;

export const validateRecoverPasswordToken = gql`
  mutation ($email:String!, $token:String!){
    ValidateRecoverPasswordToken(email:$email, token:$token){
      id
      customer_email
    }
  }
`;

export const ChangePasswordMutation = gql`
  mutation ChangePassword($email:String, $newPassword:String!, $token:String){
    CustomerChangePassword(input:{email:$email, newPassword:$newPassword, token:$token}){
      id
      password_changed
    }
  }
`;

export const GetCustomerInformation = gql`
  query{
    MeCustomer{
      id
      customer_first_name
      customer_last_name
      customer_email
      customer_cpf
      customer_rg
      customer_phone
      customer_landline
      password_changed
    }
  }
`;

export const GetDefects = gql`
  query($first: Int, $cursor: String, $filter: Mixed){
    getReparafoneDefect(first:$first, after:$cursor, where: {AND:[{column:DEFECT_DESCRIPTION, operator:NEQ, value:""}, {column: ID, operator: IN, value: $filter}]}){
      edges{
        node{
       		id
          defect_name
          defect_description
          lay_name   
        }
      }
      pageInfo{
        hasNextPage
        endCursor
        lastPage
        total
      }
    }
  }
`;

export const EditCustomerInfo = gql`
  mutation($input: EditMeCustomerInput!){
    EditMeCustomer(input: $input){
      id
      customer_first_name
      customer_last_name
      customer_email
      customer_cpf
      customer_rg
      customer_phone
      customer_landline
    }
  }
`;
export default getMyRepairOrders;
