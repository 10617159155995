import client from '@/Apollo';
import { GetDiscount } from '../query/GetDiscount';

export default function discountCoupon(arrayData, setDisabledTextField, setErrorAlert) {
  return new Promise((resolve, reject) => {
    client
      .query({
        query: GetDiscount,
        variables: { input: arrayData },
      })
      .then(
        ({ data: { ReparafoneCouponDiscount } }) => {
          resolve(ReparafoneCouponDiscount);
        },
      )
      .catch((e) => {
        setDisabledTextField(false);
        setErrorAlert(true);
      });
  });
}
