import React from 'react';
import * as P from 'prop-types';
import Box from '@material-ui/core/Box';
import RadioGroup from '@material-ui/core/RadioGroup';
import RadioOptionPayment from './RadioOptionPayment';

export default function RadioGroupPayment({
  methodSelect,
  handleRadioChange,
  options,
}) {
  return (
    <Box py={1} px={0.25} maxWidth={668} width="100%">
      <RadioGroup name="methodPayment" value={methodSelect} onChange={handleRadioChange}>
        {
          options.map(({
            component, value, label, icon,
          }) => (
            <RadioOptionPayment
              key={label}
              icon={icon}
              value={value}
              label={label}
              selected={methodSelect}
            >
              {component}
            </RadioOptionPayment>
          ))
        }
      </RadioGroup>
    </Box>
  );
}

RadioGroupPayment.propTypes = {
  methodSelect: P.string,
  handleRadioChange: P.func.isRequired,
  options: P.arrayOf(P.shape({
    icon: P.oneOfType([P.string, P.node]),
    value: P.string.isRequired,
    label: P.string.isRequired,
    component: P.node.isRequired,
  })).isRequired,
};

RadioGroupPayment.defaultProps = {
  methodSelect: '',
};
