/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import * as P from 'prop-types';
import { Box, Grid } from '@material-ui/core';

function InlineGridInput({
  handleChange, handleBlur, handleFocus, value, error, col, Component, disabled, ...props
}) {
  return (
    <Grid item xs={12} sm={col}>
      <Box px={1.2}>
        <Component
          typeInput="standard"
          disabled={disabled}
          handleChange={handleChange}
          handleFocus={handleFocus}
          handleBlur={handleBlur}
          value={value}
          error={error}
          {...props}
        />
      </Box>
    </Grid>
  );
}

const cols = Array(12).fill().map((_, index) => index + 1);

InlineGridInput.propTypes = {
  col: P.oneOf(cols.concat('auto')),
  Component: P.oneOfType([P.func, P.element]).isRequired,
  handleChange: P.func.isRequired,
  handleBlur: P.func.isRequired,
  handleFocus: P.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  value: P.any,
  error: P.string,
  disabled: P.bool,
};

InlineGridInput.defaultProps = {
  col: 6,
  value: '',
  error: '',
  disabled: false,
};

export default React.memo(InlineGridInput);
