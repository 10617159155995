import React from 'react';
import {
  CircularProgress,
  Icon, InputAdornment, TextField,
} from '@material-ui/core';
import ReactInputMask from 'react-input-mask';
import clsx from 'clsx';
import { InputZipCodePropTypes, InputZipCodeDefaultProps } from './PropTypes';
import InputZipCodeRules from './Rules';
import useStyle from '../../../../../../style';

function InputZipCode({
  handleBlur, errors, value, typeInput, disabled, isLoading,
  setDisabledButton, setFieldValue,
}) {
  const classes = useStyle();
  return (
    <>
      <ReactInputMask
        mask="99999-999"
        value={value}
        onChange={(e) => {
          if (e.target.value) {
            setDisabledButton(false);
            return setFieldValue('zipCode', e.target.value);
          }
          return setDisabledButton(true);
        }}
        onBlur={handleBlur}
        disabled={disabled || isLoading}
        maskPlaceholder={null}
      >
        <TextField
          fullWidth
          name="zipCode"
          label="CEP para Coleta"
          variant={typeInput}
          color="primary"
          style={{ marginRight: '5px' }}
          helperText={errors}
          error={!!errors}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Icon className={clsx(['fas', 'fa-map-marked-alt', classes.inputIcon])} color="action" />
              </InputAdornment>
            ),
            endAdornment: (
              <>
                { isLoading && (
                  <InputAdornment position="end">
                    <CircularProgress size={20} />
                  </InputAdornment>
                )}
              </>
            ),
          }}
        />
      </ReactInputMask>
    </>
  );
}

InputZipCode.propTypes = InputZipCodePropTypes;
InputZipCode.defaultProps = InputZipCodeDefaultProps;
InputZipCode.rules = InputZipCodeRules;

export default InputZipCode;
