import React from 'react';
import {
  InputAdornment, TextField,
} from '@material-ui/core';
import ReactInputMask from 'react-input-mask';
import clsx from 'clsx';
import RecentActorsIcon from '@material-ui/icons/RecentActors';
import { propTypes, defaultProps } from './PropTypes';
import rules from './Rules';
import useStyle from './style';

function InputCpf({
  handleChange, handleBlur, errors, value, typeInput, disabled,
}) {
  const classes = useStyle();
  return (
    <ReactInputMask
      mask="999.999.999-99"
      value={value}
      onChange={(event, valueCpf) => {
        if (event) {
          handleChange('customer_cpf', event.target.value);
        }
      }}
      onBlur={handleBlur}
      disabled={disabled}
      maskPlaceholder={null}
    >
      <TextField
        fullWidth
        className={classes.textField}
        disabled={disabled}
        value={value}
        id="customer_cpf"
        name="customer_cpf"
        label="CPF"
        size="small"
        variant={typeInput}
        color="primary"
        helperText={errors}
        error={!!errors}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <RecentActorsIcon color={!disabled ? 'primary' : 'action'} />
            </InputAdornment>
          ),
        }}
      />
    </ReactInputMask>
  );
}

InputCpf.propTypes = propTypes;
InputCpf.defaultProps = defaultProps;
InputCpf.rules = rules;

export default InputCpf;
