import gql from 'graphql-tag';

const GetOrderCoupon = gql`
  query GetOrderCoupon($order_id: ID!) {
    ReparafoneGetCouponsByOrder(order_id: $order_id) {
      order_id
      coupon_id
    }
  }
`;

export default GetOrderCoupon;
