import React from 'react';
import {
  useTheme, useMediaQuery, Grid, Typography,
  Paper,
} from '@material-ui/core';
import { head } from 'lodash';
import clsx from 'clsx';
import { Skeleton } from '@material-ui/lab';
import OrderInfo from '../OrderInfo';
import RevisionsTable from '../estimate/RevisionsTable';
import CarouselStrap from '../../../../../utils/images/CarouselStrap';
import { useStyles } from './style';

const EstimateImages = (props) => {
  const { images } = props;
  const imagesSrc = images.map((image) => ({
    ...image,
    src: image.url,
  }));
  return (
    <>
      <CarouselStrap images={images} />
    </>
  );
};
const RevisionInfo = ({ order }) => {
  const theme = useTheme();
  const classes = useStyles();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const lastEstimate = order && order.estimates && head(order.estimates);
  const lastEstimateImages = order && order.lastEstimateImages
  return (
    <>
      <Grid container direction="column" spacing={isMobile ? 4 : 2}>
        <Paper className={clsx(classes.paper, classes.paperRevision)}>
          <Grid item className={classes.lastRevision}>
            <OrderInfo label="Imei" value={order.order_imei} />
          </Grid>
          <Grid item className={classes.lastRevision}>
            <OrderInfo
              label="Produto"
              value={order && order.product.product_name}
            />
          </Grid>
          <Grid item className={classes.lastRevision}>
            <OrderInfo
              label="Observação do técnico"
              value={lastEstimate && lastEstimate.revision_obs}
            />
          </Grid>

          <Grid item xs={12} sm={4} spacing={3} className={classes.lastRevision}>
            {lastEstimate && lastEstimate.image_files_name ? (
              <Typography variant="subtitle2" style={{ color: '#383838', textAlign: 'center' }}>Anexos da ordem</Typography>
            ) : (
              <Skeleton />
            )}
          </Grid>
          <Grid container item>
            <Grid container direction="row" item>
              <Grid item xs={12} sm={4} spacing={3}>
                {lastEstimateImages ? (
                  <EstimateImages images={lastEstimateImages} />
                ) : (
                  <Skeleton />
                )}
              </Grid>
            </Grid>
          </Grid>
        </Paper>
        <Grid item style={{ marginBottom: '2rem' }}>
          <div style={{ marginTop: '1rem' }}>
            <RevisionsTable
              order={order}
              estimate={lastEstimate}
              rawRevisions={lastEstimate && lastEstimate.revisions}
            />
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default RevisionInfo;
