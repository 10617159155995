import React from 'react';
import {
  Grid, Typography, Divider, Button,
} from '@material-ui/core';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import { connect } from 'react-redux';
import Alerts from '../components/Alert';
import {
  startFetching,
  stopFetching,
} from '../../../redux/actions/auth/AuthActions';
import validateToken from '../graphql/schemas/ValidateRecover';

const RequestTokenShape = Yup.object().shape({
  token: Yup.string().required(
    'Digite o código que foi enviado para o seu email.',
  ),
});

const RequestToken = (props) => {
  const {
    history, location, startFetching, stopFetching,
  } = props;
  const [error, setError] = React.useState(false);
  const [showAlert, setShowAlert] = React.useState(null);
  const [alertMessage, setMessageAlert] = React.useState(false);
  const [alertSeverity, setSeverityAlert] = React.useState(false);
  const [alertDuration, setDurationAlert] = React.useState(false);
  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowAlert(null);
  };
  const handleAlert = (message, severity, openAlert, duration) => {
    setMessageAlert(message);
    setSeverityAlert(severity);
    setShowAlert(openAlert);
    setDurationAlert(duration);
  };
  const onSubmitForm = ({ token }, { setSubmitting }) => {
    startFetching();
    validateToken({ email: location.state.email, token })
      .then((r) => history.push('/partners/recover/password/change', {
        email: r.email,
        token,
      }))
      .catch((e) => {
        console.log(e, 'token errado');
        setError(true);
        handleAlert(
          'Código inválido! Por gentileza cheque novamente seu e-mail.',
          'error',
          true,
          7000,
        );
      })
      .finally(() => { setSubmitting(false); stopFetching(); });
  };
  return (
    <>
      <Alerts
        open={showAlert}
        handleClose={handleCloseAlert}
        message={alertMessage}
        severity={alertSeverity}
        duration={alertDuration}
      />
      <Grid container direction="row">
        <Grid item xs={12}>
          <Typography variant="subtitle1">Validar código</Typography>
        </Grid>
      </Grid>
      <Divider style={{ marginTop: '0.5rem', marginBottom: '0.5rem' }} />
      <Formik
        initialValues={{
          token: '',
        }}
        validationSchema={RequestTokenShape}
        onSubmit={onSubmitForm}
      >
        {({
          errors, touched, isSubmitting, dirty,
        }) => (
          <Form>
            <Grid container direction="column" spacing={4}>
              <Grid item>
                <Typography variant="body2">
                  Você recebeu no seu e-mail um código que deve ser inserido no
                  campo abaixo para prosseguir com a recuperação de sua senha.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Field
                  disabled={isSubmitting}
                  component={TextField}
                  fullWidth
                  label="Código"
                  name="token"
                  variant="outlined"
                  error={!!(touched.token && errors.token) || !!error}
                  helperText={(touched.token && errors.token) || error}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  disabled={isSubmitting || !dirty}
                  type="submit"
                  color="secondary"
                  variant="contained"
                  fullWidth
                  title="Confirmar"
                >
                  Confirmar
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};
const mapStateToProps = (state) => {
  const { AuthReducer } = state;
  const { isFetching } = AuthReducer;
  return {
    isFetching,
  };
};
export default connect(mapStateToProps, { startFetching, stopFetching })(
  RequestToken,
);
