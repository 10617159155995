import { makeStyles } from '@material-ui/core/';

const styles = makeStyles(({ spacing, breakpoints }) => ({
  title: {
    fontSize: '30px',
  },
  box: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    [breakpoints.down('xs')]: {
      padding: `${spacing(2)}px ${spacing(0.6)}px`,
    },
  },
  container: {
    [breakpoints.down('xs')]: {
      padding: `${spacing(0)}px ${spacing(0.6)}px`,
    },
  },
}));

export default styles;
