import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  setStep,
  middlewares,
  setDeliveryData,
  setCollectModalities,
} from '@redux/actions/NewOrderActions';

const mapStateToProps = ({
  NewOrderReducer: { activeStep: step, orderData, customer },
}) => ({
  $state: {
    step,
    orderData,
    customer,
  },
});

const mapDispatchToProps = (dispatch) => ({
  $actions: bindActionCreators(
    {
      setStep,
      createOrder: middlewares.createOrder,
      setDeliveryData,
      setCollectModalities,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps);
