import gql from 'graphql-tag';

export const GetPartnerOrders = gql`
query ReparofonePartnerOrders($storeId: String, $first: Int, $page: Int!, $searchLike: Mixed, $search:Mixed){
  ReparofonePartnerOrders(order_store_id: $storeId, first: $first, page: $page, where: {
      OR: [
        {
          column:ID, operator:EQ, value: $search
        },
        {
          HAS: {
            relation: "product"
            condition: { column: PRODUCT_NAME, operator:LIKE, value: $searchLike }
          }
        }
        {
          HAS: {
            relation: "customer"
            condition: {
              column: CUSTOMER_FIRST_NAME
              operator: LIKE
              value: $searchLike
            }
          }
        }
      ]
    }){
    data{
      id
      imei: order_imei
      informedDefects
      repairedDefects
      customer{
        fullName
        email: customer_email
        phone: customer_phone
        cpf: customer_cpf
      }
      product{
        id
        name: product_name
        color: product_color
        brand: product_brand
        model{
          id
          name
          brand
        }
        defects{
          id
          name
          description
          maxPrice
          isAble
          parts{
            part_name
            part_type
          }
        }
      }
      status{
        name: status_name
        slug: status_slug
      }
      lastMovement{
        date: movement_updated_at
      }
      addresses{
        zipCode: zip_code
        state
        city
        neighborhood
        street
        number: street_number
        complement
        addressType: address_type
      }
      createdAt: order_created_at
      branchId: order_branch_id
      storeId: order_store_id
      tradeInUserId: order_trade_in_user_id
      isCreatedWarranty: is_created_warranty
    },
    paginatorInfo{
      count
      currentPage
      total
      lastPage
      perPage
    }
  }
}

`;

export default GetPartnerOrders;
