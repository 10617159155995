import client from '@/Apollo';
import { pick } from 'lodash';
import ReactGA from 'react-ga';
import CreateOrderWithCollectDate from '../../graphql/order/CreateOrderWithCollectDate';
import { setOrderData } from '../actions';

const mapDataToSmartprod = (currState) => {
  const {
    NewRepairReducer: {
      device,
      customer,
      billingAddress,
      coupons,
      lead,
      origin_store,
      origin_user,
      collect,
      delivery,
    },
    PartnerStoreReducer: {
      branch: { branch_name, branch_slug },
    },
  } = currState;
  const [day, month, year] = collect.schedule_collect
    .match(/[0-9]+/g)
    .map((num) => parseInt(num, 10));
  const [deliveryDay, deliveryMonth, deliveryYear] = delivery.schedule_delivery
    .match(/[0-9]+/g)
    .map((num) => parseInt(num, 10));
  return {
    current_order_id: '',
    order_flux_type: 'fora-de-garantia',
    order_warranty: 0,
    order_warranty_type: 'fora-de-garantia',
    order_imei: device.imei ?? '',
    order_branch_id: origin_user.branch_id ?? '',
    coupons: coupons.length > 0 ? coupons[0].code : null,
    order_branch_slug: branch_slug || 'reparafone',
    order_store_id: origin_store.id,
    defects: device.selectedDefects.map((def) => pick(def, ['id'])),
    observation: device.defectComment ?? '',
    order_product_id: device.product.id,
    order_lead_id: lead.id,
    order_trade_in_user_id: '',
    origin_user: origin_user.id,
    origin_store: origin_store.id,
    conditions: device.conditions.map(({ id, condition }) => ({
      id,
      condition,
    })),
    collect: {
      date: [day, month, year].join('/'),
    },
    delivery_date_limit:
      [deliveryDay, deliveryMonth, deliveryYear].join('/') ?? '',
    collect_type: collect.chosenModality.collect_slug,
    delivery_type: delivery.modalities[0].delivery.delivery_slug,
    customer: {
      customer_first_name: customer.name.split(' ')[0],
      customer_last_name: customer.name.split(' ').splice(1).join(' '),
      customer_email: customer.email,
      customer_phone: customer.phone.replace(/[^0-9]/gm, ''),
      customer_landline: customer.phone.replace(/[^0-9]/gm, ''),
      customer_cpf: customer.cpf.replace(/[^0-9]/gm, ''),
      customer_rg: '',
    },
    address: {
      collect: {
        zip_code: customer.collect.zipCode.replace(/[^0-9]/gm, ''),
        state: customer.collect.state,
        city: customer.collect.city,
        neighborhood: customer.collect.neighborhood,
        street: customer.collect.street,
        street_number: customer.collect.number,
        complement: customer.collect.complement || '',
      },
      delivery: {
        zip_code: customer.delivery.zipCode.replace(/[^0-9]/gm, ''),
        state: customer.delivery.state,
        city: customer.delivery.city,
        neighborhood: customer.delivery.neighborhood,
        street: customer.delivery.street,
        street_number: customer.delivery.number,
        complement: customer.delivery.complement || '',
      },
      charge: {
        zip_code: billingAddress.zipCode.replace(/[^0-9]/gm, ''),
        state: billingAddress.state,
        city: billingAddress.city,
        neighborhood: billingAddress.neighborhood,
        street: billingAddress.street,
        street_number: billingAddress.number,
        complement: billingAddress.complement || '',
      },
    },
  };
};

export default function createOrder(
  showAlert,
  setSubmitting,
  handleResponse,
  totalBudget,
) {
  return async (dispatch, getState) => new Promise((resolve, reject) => {
    const data = mapDataToSmartprod(getState());
    const branchName = getState().PartnerStoreReducer.branch.branch_name;
    client
      .mutate({
        mutation: CreateOrderWithCollectDate,
        variables: { input: data },
      })
      .then(({ data: { ReparafoneCreateOrder } }) => {
        if (process.env.NODE_ENV === 'production') {
          ReactGA.event({
            category: branchName ? `createOrder${branchName}` : 'createOrder',
            action: 'Step 6',
            label: 'Ordem Criada',
            value: totalBudget / 100,
          });
        }
        dispatch(setOrderData(ReparafoneCreateOrder));
        showAlert({
          severity: 'success',
          message: 'Sua ordem foi criada com sucesso! 🎉',
        });
        resolve({ status: true });
      })
      .catch((errors) => {
        console.log(errors, 'erro ao criar');
        const errorInfo = {
          category: errors?.graphQLErrors[0].extensions.category,
          reason: errors?.graphQLErrors[0].extensions.reason,
        };
        const errorMessage = handleResponse(errorInfo);
        showAlert({ severity: 'error', message: errorMessage });
        // eslint-disable-next-line prefer-promise-reject-errors
        reject({ status: false, errors });
      })
      .finally(() => setSubmitting(false));
  });
}
