import React from 'react';

import { Header, Button } from 'semantic-ui-react';

import { HashLink as Link } from 'react-router-hash-link';
import Cards from './Cards';
import Coleta from '../../../images/Funcionamento/delivery.png';
import Reparo from '../../../images/Funcionamento/smartphone.png';
import Pagamento from '../../../images/Funcionamento/cartao-de-credito.png';
import Entrega from '../../../images/Funcionamento/entregador.png';
import Comissao from '../../../images/Funcionamento/bolsa-de-dinheiro.png';
import './ComoFunciona.css';

const Funcionamento = () => (
  <div id="how-it-works-franch" className="como-funciona-franchisee">
    <div className="como-funciona-franchisee-cards">
      <div className="como-funciona-franchisee-cards-intern">
        <Header
          className="descricao-funcionamento"
          content="        Surgimos diante da necessidade de reparos para celulares que
        apresentavam problemas técnicos ou que sofreram acidentes danificando
        peças, em especial a tela. Trabalhamos para que nossos clientes
        economizem tempo e dinheiro, oferecendo ótimos preços, pré orçamentos
        online, logística de entrega e coleta e 3 meses de garantia para o
        reparo realizado."
        />
        <Header
          className="subdescricao-funcionamento"
          content="        Ofereça todas essas vantagens para seus clientes e ganhe uma comissão em cima de cada orçamento cadastrado e aprovado, depois é só deixar que nós faremos todo o restante:"
        />
        <Cards
          image={Coleta}
          step="passo 1"
          title="Coleta"
          text="Buscamos os aparelhos no seu estabelecimento"
        />
        <Cards
          image={Reparo}
          step="passo 2"
          title="Reparo"
          text="Efetuamos todos os reparos solicitados"
        />
        <Cards
          image={Pagamento}
          title="Pagamento"
          text="Solicitamos o pagamento ao cliente"
          step="passo 3"
        />
        <Cards
          image={Entrega}
          title="Entrega"
          step="passo 4"
          text="Expedimos o reparado para a entrega no endereço cadastrado"
        />
        <Cards
          image={Comissao}
          title="Comissão"
          step="passo 5"
          text="Efetuamos o pagamento da sua comissão referente ao reparo realizado."
        />
      </div>
    </div>
  </div>
);

export default Funcionamento;
