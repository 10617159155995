import { makeStyles } from '@material-ui/core/';
import { green, blue } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
  buttonProgress: {
    color: green[500],
  },
  buttonProgressMobile: {
    color: green[500],
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
  },
  arrow: {
    color: theme.palette.common.black,
  },
  buttonCancel: {
    backgroundColor: theme.palette.common.white,
    color: 'red',
    border: '1px solid red',
    '&:hover': {
      border: '1px solid red',
      backgroundColor: theme.palette.common.white,
    },
  },
  buttonDisabled: {
    backgroundColor: theme.palette.common.white,
    color: 'grey',
    border: '1px solid grey',
    '&:hover': {
      border: '1px solid grey',
      backgroundColor: theme.palette.common.white,
    },
  },
  buttonWarranty: {
    backgroundColor: theme.palette.common.white,
    color: green[500],
    border: `1px solid ${green[500]}`,
    '&:hover': {
      border: `1px solid ${green[500]}`,
      backgroundColor: theme.palette.common.white,
    },
  },
  buttonDetails: {
    backgroundColor: theme.palette.common.white,
    color: blue[500],
    border: `1px solid ${blue[500]}`,
    '&:hover': {
      border: `1px solid ${blue[500]}`,
      backgroundColor: theme.palette.common.white,
    },
  },
  actions: {
    display: 'flex',
  },
}));

export default useStyles;
