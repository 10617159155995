import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles(({ typography, breakpoints }) => ({
  menu: {
    color: '#333333',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: 'Milliard Book',
    [breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  buttonOrcamento: {
    '& button.ui.button': {
      textTransform: 'uppercase',
      color: '#ffffff',
    },
  },
  buttonOrcamentoCarrefour: {
    '& button.ui.button': {
      fontFamily: 'Milliard Book',
      background: '#1752CC',
    },
  },
  buttonOrcamentoReparofone: {
    '& button.ui.button': {
      fontFamily: 'Roboto',
      background: '#FEAA48',
    },
  },
}));

export default styles;
