import { makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
  title: {
    flex: '1 1 100%',
    fontSize: '2rem',
  },
  button: {
    margin: theme.spacing(1),
    color: '#ffffff',
    padding: '5px 0',
  },
  alert: {
    margin: '8px',
  },
}));

export default styles;
