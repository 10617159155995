import P from 'prop-types';
import StatePropTypes from '../../../../redux/reducers/NewOrderReducer/PropTypes';

export const StepperContentPropTypes = {
  $state: P.shape({
    activeStep: StatePropTypes.activeStep,
  }).isRequired,
};

export const StepperContentDefaultProps = {};

export default { StepperContentPropTypes, StepperContentDefaultProps };
