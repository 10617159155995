/* eslint-disable camelcase */
// LODASH
import { isEmpty } from 'lodash';

export const cpfFormatter = (value) => {
  if (isEmpty(value)) return null;
  const cpfFormatado = value.replace(/(\d{3})?(\d{3})?(\d{3})?(\d{2})/, (matchDaRegex, grupo1, grupo2, grupo3, grupo4) => `***.***.${grupo3}-${grupo4}`);
  return cpfFormatado;
};

export const phoneFormatter = (value) => {
  if (isEmpty(value)) return null;
  const phoneFormatted = value.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
  return phoneFormatted;
};

export const landlineFormatter = (value) => {
  if (isEmpty(value)) return null;
  const landlineFormatted = value.replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2-$3');
  return landlineFormatted;
};
