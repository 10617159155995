import React from 'react';
// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { setCustomer } from '@/redux/actions/NewRepairActions';
// FORMIK && YUP
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';
// REACT ROUTER
import { useParams, useHistory } from 'react-router-dom';
// COMPONENTS
import {
  Grid,
  Button,
  CircularProgress,
  Paper,
  Typography,
} from '@material-ui/core';
import Breadcrumb from './components/Breadcrumb';
import Alerts from '../components/Alert';
// GRAPHQL
import validateTokenEmail from '../graphql/schema/ValidateEmailToken';
import editCustomerInfo from '../graphql/schema/EditCustomerInfo';
import getCustomerInfo from '../graphql/schema/GetCustomerInfo';
// STYLES
import style from './style';

const ValidateTokenSchema = Yup.object().shape({
  email_token_code: Yup.string().required('Por favor, insira o código!'),
});

const ValidateEmailToken = ({ location }) => {
  const classes = style();
  const { emailAddress } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const customerData = useSelector((state) => state.NewRepairReducer);
  const { customer } = customerData;
  const { email } = customer;
  const [loadingData, setLoadingData] = React.useState(false);
  const [showAlert, setShowAlert] = React.useState(null);
  const [alertMessage, setMessageAlert] = React.useState(false);
  const [alertSeverity, setSeverityAlert] = React.useState(false);
  const [alertDuration, setDurationAlert] = React.useState(false);
  React.useEffect(() => {
    if (!email) {
      setLoadingData(true);
      getCustomerInfo()
        .then((data) => {
          const dataCustomer = {
            id: data.id,
            email: data.customer_email,
            name: data.customer_first_name,
            lastName: data.customer_last_name,
            phone: data.customer_phone,
            cpf: data.customer_cpf,
          };
          dispatch(setCustomer(dataCustomer));
        })
        .finally(() => setLoadingData(false));
    }
  }, [dispatch]);
  const handleAlert = (message, severity, openAlert, duration) => {
    setMessageAlert(message);
    setSeverityAlert(severity);
    setShowAlert(openAlert);
    setDurationAlert(duration);
  };
  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowAlert(null);
  };
  const onSubmitForm = ({ email_token_code }, { setSubmitting }) => validateTokenEmail({ email, token: email_token_code })
    .then((r) => {
      if (!r.errors) {
        return editCustomerInfo({
          input: { customer_email: emailAddress, id: customer.id },
        }).then(() => {
          dispatch(setCustomer({ ...customer, email: emailAddress }));
          handleAlert(
            'Código confirmado. E-mail alterado com sucesso!',
            'success',
            true,
            6000,
          );
          setTimeout(
            () => history.push('/customer/myprofile'),
            6000,
          );
          return setSubmitting(false);
        });
      }
      if (r.errors) {
        handleAlert(
          'O código informado é inválido ou está expirado!',
          'warning',
          true,
          3000,
        );
      }
    })
    .catch(() => handleAlert('Houve um erro ao validar o código!', 'error', true, 3000));
  React.useEffect(() => {
    if (location) {
      handleAlert(
        'Enviamos um código para seu novo e-mail!',
        'success',
        true,
        8000,
      );
    }
  }, [location]);
  return (
    <>
      <Breadcrumb title="Código de confirmação" />
      <Alerts
        open={showAlert}
        handleClose={handleCloseAlert}
        message={alertMessage}
        severity={alertSeverity}
        duration={alertDuration}
      />
      <Formik
        initialValues={{
          email_token_code: '',
        }}
        validationSchema={ValidateTokenSchema}
        onSubmit={onSubmitForm}
      >
        {({
          errors, touched, isSubmitting, dirty,
        }) => (
          <Form>
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="center"
              className={classes.margin}
            >
              <Paper className={classes.paper}>
                <Grid item xs={12}>
                  <Field
                    type="text"
                    disabled={isSubmitting || loadingData}
                    component={TextField}
                    fullWidth
                    className={classes.margin}
                    label="Código"
                    name="email_token_code"
                    error={
                      !!touched.email_token_code && !!errors.email_token_code
                    }
                    helperText={
                      touched.email_token_code && errors.email_token_code
                    }
                    variant="outlined"
                  />
                </Grid>
                <Typography variant="caption" display="block" gutterBottom>
                  Enviamos uma confirmação para seu novo e-mail para que a
                  alteração seja realizada.
                </Typography>
                <Grid item xs={12}>
                  <Button
                    disabled={isSubmitting || !dirty}
                    type="submit"
                    color="secondary"
                    variant="contained"
                    fullWidth
                    className={classes.margin}
                    title="Confirmar"
                    endIcon={isSubmitting && <CircularProgress size="1.2rem" />}
                  >
                    Confirmar
                  </Button>
                </Grid>
              </Paper>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default ValidateEmailToken;
