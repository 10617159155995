import React from 'react';
// MATERIAL-UI
import {
  Box, Container, Paper, Typography,
} from '@material-ui/core';
// LOAD CSS
import { loadCSS } from 'fg-loadcss';
// COMPONENTS
import OrderDetails from '../../orderdetails';
// PROP-TYPES
import PropTypes from './PropTypes';
// STYLES
import styles from './styles';

const Home = ({ order }) => {
  const classes = styles();
  React.useEffect(() => {
    const node = loadCSS(
      'https://use.fontawesome.com/releases/v5.12.0/css/all.css',
      document.querySelector('#font-awesome-css'),
    );
    return () => {
      node.parentNode.removeChild(node);
    };
  }, []);
  return (
    <Container maxWidth="md" className={classes.container}>
      <Paper elevation={0}>
        <Box p={3} className={classes.box}>
          <Typography className={classes.title} variant="h1" align="center">
            Ordem de Serviço - {order.id}
          </Typography>
          <OrderDetails order={order} />
        </Box>
      </Paper>
    </Container>
  );
};

export default Home;

Home.propTypes = PropTypes.propTypes;
Home.defaultProps = PropTypes.defaultProps;
