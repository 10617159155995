import client from '@/Apollo';
import GetTradeInUserInformation from '../query/GetTradeInUser';

export default function getTradeInUser() {
  return new Promise((resolve, reject) => {
    client
      .query({
        query: GetTradeInUserInformation,
      })
      .then(
        (response) => {
          resolve(response.data.MeTradeInUser);
        },
      )
      .catch((error) => {
        console.log(error.message, 'erro ao buscar');
        reject(error);
      });
  });
}
