import { makeStyles } from '@material-ui/core';
import { green } from '@material-ui/core/colors';

export const useStyles = makeStyles((themeStyle) => ({
  paper: {
    padding: themeStyle.spacing(2),
    textAlign: 'center',
    color: themeStyle.palette.text.secondary,
    width: '100%',
  },
  marginBt: { marginBottom: '30px' },
  title: { color: '#383838' },
}));

export default useStyles;
