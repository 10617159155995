import React from 'react';
// ANALYTICS
import ReactGA from 'react-ga';
// ROUTERS
import { useParams } from 'react-router-dom';
// MATERIAL-UI
import {
  CircularProgress,
  Typography,
  Collapse,
} from '@material-ui/core/';
// REDUX
import { useDispatch, useSelector } from 'react-redux';
import {
  setOriginUser,
  setOriginStore,
} from '@/redux/actions/NewRepairActions';
import { setStore, setBranch } from '@/redux/actions/PartnerStoreActions';
// GRAPHQL
import insertUserAndStore from './graphql/schema/GetUserAndStore';
import getBranch from './graphql/schema/GetBranch';
// COMPONENTS
import OnlinePartnerPage from '../../pages/onlinepartner';
// STYLES
import styles from './style';

const OnlinePartnerRoutes = (() => {
  const classes = styles();
  const [loadingInfo, setLoadingInfo] = React.useState(false);
  const [message, setMessage] = React.useState(null);
  const { id } = useParams();
  const partnerData = useSelector((state) => state.PartnerStoreReducer);
  const { branch } = partnerData;
  const dispatch = useDispatch();
  const GetUserAndStore = (branchID) => {
    insertUserAndStore(branchID).then((data) => {
      dispatch(setOriginUser(data.ReparafoneGetTradeInUserByBranchId));
      dispatch(setOriginStore(data.ReparafoneGetStoreByBranchId));
      dispatch(setStore(data.ReparafoneGetStoreByBranchId));
    });
  };
  const GetBranch = () => {
    getBranch(id)
      .then((r) => {
        if (!r.show_white_label) {
          setMessage(
            'Parceiro desabilitado! Por favor, verifique o acesso.',
          );
          if (process.env.NODE_ENV === 'production') {
            ReactGA.event({
              category: `ErrorLanding${branch.branch_name}`,
              action: 'Erro',
              label: 'Parceiro desabilitado',
              value: 0,
            });
          }
          return setLoadingInfo('message');
        }
        if (!r) {
          setMessage(
            'Parceiro não cadastrado! Por favor, verifique se colocou a URL corretamente.',
          );
          if (process.env.NODE_ENV === 'production') {
            ReactGA.event({
              category: `ErrorLanding${branch.branch_name}`,
              action: 'Erro',
              label: 'Parceiro não cadastrado',
              value: 0,
            });
          }
          return setLoadingInfo('message');
        }
        dispatch(setBranch(r));
        GetUserAndStore(r.id);
        return setLoadingInfo('content');
      })
      .catch((e) => {
        setMessage(
          'Houve um erro ao autenticar o parceiro. Tente novamente mais tarde.',
        );
        if (process.env.NODE_ENV === 'production') {
          ReactGA.event({
            category: `ErrorLanding${branch.branch_name}`,
            action: 'Erro',
            label: 'Erro ao autenticar',
            value: 0,
          });
        }
        console.log(e);
        return setLoadingInfo('message');
      });
  };
  React.useEffect(() => {
    setLoadingInfo('loading');
    GetBranch();
  }, []);
  return (
    <>
      <Collapse
        in={loadingInfo === 'content'}
        mountOnEnter
        unmountOnExit
      >
        <OnlinePartnerPage />
      </Collapse>
      <Collapse
        className={classes.fade}
        in={loadingInfo === 'loading'}
        mountOnEnter
        unmountOnExit
      >
        <div className={classes.root}>
          <CircularProgress />
        </div>
      </Collapse>
      <Collapse
        className={classes.fade}
        in={loadingInfo === 'message'}
        mountOnEnter
        unmountOnExit
      >
        <div className={classes.root}>
          <Typography
            style={{ fontSize: '20px' }}
            variant="h1"
            component="div"
            alignCenter
          >
            {message}
          </Typography>
        </div>
      </Collapse>
    </>
  );
});

export default OnlinePartnerRoutes;
