import React from 'react';
// SEMANTIC
import { Button } from 'semantic-ui-react';
// REDUX
import { useSelector } from 'react-redux';
// CLSX
import clsx from 'clsx';
// COMPONENTS
import Buttons from './components/Button';
import BottomNavigation from './components/BottomNavigation';
// STYLES
import styles from './style';

const Menu = () => {
  const classes = styles();
  const partnerData = useSelector((state) => state.PartnerStoreReducer);
  const { branch } = partnerData;
  const { branch_name } = branch;
  return (
    <>
      <div className={classes.menu}>
        <Buttons label="Nosso Método" url="#how-it-works" />
        <Buttons label="Dúvidas" url="#answers" />
        <Buttons
          url="#do-estimate"
          component={Button}
          className={clsx(classes.buttonOrcamento, {
            [classes.buttonOrcamentoCarrefour]: branch_name === 'Carrefour',
            [classes.buttonOrcamentoReparofone]: branch_name === 'Limelocker',
          })}
          ButtonProps={{
            icon: 'dollar',
            labelPosition: 'right',
            content: 'Orçamento',
          }}
        />
      </div>
      <BottomNavigation />
    </>
  );
};

export default Menu;
