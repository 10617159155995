import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles(() => ({
  buttonStep: {
    font: 'inherit',
    background: 'inherit',
    border: 'none',
    '&.active': {
      cursor: 'pointer',
      '&:hover, &:focus': {
        textDecoration: 'underline',
      },
    },
  },
  icon: {
    height: 16,
  },
}));
