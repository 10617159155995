import React from 'react';
import {
  Box, Typography, Grid, Divider,
} from '@material-ui/core';
import PriceInformation from './components/PriceInformation';

const OrderInfo = ({ order }) => (
  <>
    <Typography variant="h6">Ordem de Serviço</Typography>
    <Box>
      <Grid container direction="column" spacing={1}>
        <Grid item>
          <Typography variant="body1">
            {`Aparelho: ${order.product.model.designName}`}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body1">
            {`Reparos: ${JSON.parse(order.isRepaired ? order.repairedDefects : order.informedDefects).join(', ')}`}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="h6">Valor</Typography>
        </Grid>
        <Grid item>
          <PriceInformation title="Subtotal" price={order.order_price} />
        </Grid>
        {order.totalPaid > 0 && (
        <Grid item>
          <PriceInformation title="Pagamento realizado" price={order.totalPaid} deduction />
        </Grid>
        )}
        <Divider />
        <Grid item>
          <PriceInformation title="Total a pagar" price={order.debt} bold />
        </Grid>
      </Grid>
    </Box>
  </>
);

export default OrderInfo;
