import { StepConnector, withStyles } from '@material-ui/core';

const CustomStepConnector = withStyles(({ palette: { primary } }) => ({
  alternativeLabel: {
    top: 15,
  },
  active: {
    '& $line': {
      backgroundImage:
        `linear-gradient( 95deg, ${primary.dark}, ${primary.light} )`,
    },
  },
  completed: {
    '& $line': {
      backgroundImage:
        `linear-gradient( 95deg, ${primary.dark}, ${primary.light} )`,
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: '#eaeaf0',
    borderRadius: 1,
  },
}))(StepConnector);

export default CustomStepConnector;
