import React from 'react';
import { loadCSS } from 'fg-loadcss';
import FormStepper from './components/FormStepper';

function Form() {
  React.useEffect(() => {
    const node = loadCSS(
      'https://use.fontawesome.com/releases/v5.12.0/css/all.css',
      document.querySelector('#font-awesome-css'),
    );
    return () => {
      node.parentNode.removeChild(node);
    };
  }, []);
  return (
    <>
      <FormStepper />
    </>
  );
}

export default Form;
