import React from 'react';
import {
  Snackbar,
  Backdrop,
  CircularProgress,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import styles from './style';

const Alerts = ({
  open, handleClose, loadBackdrop, message, severity, duration,
}) => {
  const classes = styles();
  return (
    <>
      <Backdrop className={classes.backdrop} open={loadBackdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbar open={open} autoHideDuration={duration} onClose={handleClose}>
        <Alert onClose={handleClose} variant="filled" severity={severity}>
          {message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default Alerts;
