import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles(({ typography, breakpoints }) => ({
  buttonMenu: {
    padding: '0 10px',
    textDecoration: 'none',
    color: '#333333',
    fontSize: '16px',
    overflow: 'hidden',
    outline: 'none',
    textTransform: 'uppercase',
    letterSpacing: '1px',
    fontWeight: 400,
    textShadow: '0 0 1px rgb(255 255 255 / 30%)',
    transition: 'all 0.5s',
    [breakpoints.up('sm')]: {
      '&:hover:before': {
        transform: 'scaleX(0.85)',
      },
      '&:focus:before': {
        transform: 'scaleX(0.85)',
      },
      '&:before': {
        left: 0,
        bottom: 0,
        width: '100%',
        height: '2px',
        background: '#393285',
        transform: 'scaleX(0)',
        content: '',
        position: 'absolute',
        transition: 'transform 0.5s ease',
      },
      '&:after': {
        content: '',
        position: 'absolute',
        transition: 'transform 0.5s ease',
      },
    },
  },
  buttonMenuCarrefour: {
    '&:hover': {
      color: '#1752CC',
    },
    '&:focus': {
      color: '#1752CC',
    },
  },
  buttonMenuReparofone: {
    '&:hover': {
      color: '#393285',
    },
    '&:focus': {
      color: '#393285',
    },
  },
}));

export default styles;
