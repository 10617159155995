import { Box, Container, Paper, Typography } from '@material-ui/core';
import React from 'react';

const PartnerTOS = ({ terms }) => (
  <Container maxWidth="md">
    <Paper elevation={1}>
      <Box p={3}>
        <Typography variant="body1" align="center">
          {terms}
        </Typography>
      </Box>
    </Paper>
  </Container>
);
export default PartnerTOS;
