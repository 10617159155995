import * as P from 'prop-types';
import { F } from 'ramda';

export const propTypes = {
  title: P.string.isRequired,
  desc: P.string,
  handleChange: P.func.isRequired,
  handleBlur: P.func.isRequired,
  values: P.shape({
    zipCode: P.string,
    state: P.string,
    city: P.string,
    neighborhood: P.string,
    street: P.string,
    number: P.string,
    complement: P.string,
  }),
  touched: P.shape({
    zipCode: P.bool,
    state: P.bool,
    city: P.bool,
    neighborhood: P.bool,
    street: P.bool,
    number: P.bool,
    complement: P.bool,
  }),
  errors: P.shape({
    zipCode: P.string,
    state: P.string,
    city: P.string,
    neighborhood: P.string,
    street: P.string,
    number: P.string,
    complement: P.string,
  }),
  autoFillLabel: P.string,
  autoFill: P.func,
  isEditable: P.bool,
  setValues: P.func.isRequired,
  defaultShow: P.bool,
};

export const defaultProps = {
  desc: '',
  isEditable: true,
  autoFill: null,
  autoFillLabel: 'Auto completar',
  isDisabledField: F,
  touched: {},
  errors: {},
  defaultShow: false,
};

export default {
  propTypes, defaultProps,
};
