import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setSelectedDefect, setLead } from '@redux/actions/NewOrderActions';

const mapStateToProps = ({ NewOrderReducer, VivoStoreReducer }) => ({
  $state: {
    customer: NewOrderReducer.customer,
    estimate: NewOrderReducer.transactionAmount,
    product: NewOrderReducer.device.product,
    selectedDefects: NewOrderReducer.device.selectedDefects,
    deliveryZipcode: NewOrderReducer.customer.delivery.zipCode,
    collectZipcode: NewOrderReducer.customer.collect.zipCode,
    partner: VivoStoreReducer,
  },
});

const mapDispatchToProps = (dispatch) => ({
  $actions: bindActionCreators({ setSelectedDefect, setLead }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
