/* eslint-disable react/jsx-no-duplicate-props */
import React from 'react';
import * as Yup from 'yup';
import * as P from 'prop-types';
import { omit } from 'ramda';
import {
  FormControl, FormHelperText, InputLabel, MenuItem, Select,
} from '@material-ui/core';
import { propTypes, defaultProps } from '../Utils/TextFieldPropTypes';

export default function InputInstallments({
  handleChange, disabled, selected,
  error, typeInput, options,
}) {
  const selectRef = React.useRef();
  const handleSelectChange = ({ target }) => {
    const value = options.find((opt) => opt.value === target.value);
    handleChange({ currentTarget: selectRef.current, value });
  };

  return (
    <div>
      <select
        hidden
        id="installments"
        name="installments"
        data-checkout="installments"
        ref={selectRef}
      >
        {
          options.map((opt) => (
            <option key={opt.value} value={opt.value}>{opt.text}</option>
          ))
        }
      </select>
      <FormControl
        fullWidth
        type="text"
        size="small"
        variant={typeInput}
        error={!!error}
        disabled={!options.length || disabled}
      >
        <InputLabel>Parcelas</InputLabel>
        <Select
          onChange={handleSelectChange}
          value={selected?.value ?? ''}
        >
          {
            options.map((opt) => (
              <MenuItem key={opt.value} value={opt.value}>{opt.text}</MenuItem>
            ))
          }
        </Select>
        {!!error && (
          <FormHelperText>{error}</FormHelperText>
        )}
      </FormControl>
    </div>
  );
}

InputInstallments.propTypes = {
  ...omit(
    ['handleBlur', 'value'],
    propTypes,
  ),
  selected: P.shape({
    value: P.oneOfType([P.string, P.number]),
    text: P.string,
  }),
  options: P.arrayOf(P.shape({
    value: P.oneOfType([P.string, P.number]).isRequired,
    text: P.string.isRequired,
  })),
};
InputInstallments.defaultProps = defaultProps;
InputInstallments.rules = Yup.string().required('Este campo é obrigatório');
