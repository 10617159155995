import * as P from 'prop-types';

const propTypes = {
  order: P.arrayOf(P.shape({
    customer: P.arrayOf(P.shape({
      fullName: P.string,
      cpf: P.string.isRequired,
      email: P.string.isRequired,
      landline: P.string.isRequired,
      phone: P.string.isRequired,
    })).isRequired,
    addresses: P.arrayOf(P.shape({
      addressType: P.string.isRequired,
      city: P.string.isRequired,
      complement: P.string,
      neighborhood: P.string.isRequired,
      number: P.string.isRequired,
      state: P.string.isRequired,
      street: P.string.isRequired,
      zipCode: P.string.isRequired,
    })).isRequired,
  })).isRequired,
};

export default {
  propTypes,
  defaultProps: {},
};
