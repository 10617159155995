import React from 'react';
import {
  Route,
  Redirect,
} from 'react-router-dom';
import { connect } from 'react-redux';
import { useLazyQuery } from '@apollo/client';
import ModalChangePassword from '../../pages/user/auth/ModalChangePassword';
import { GetCustomerInformation } from '../../utils/GraphQL';
import { setAuthenticated } from '../../redux/actions/auth/AuthActions';

function LoggedInRoute({ children, ...rest }) {
  const { dispatch, isAuthenticated } = rest;
  const [getUser, { data, error }] = useLazyQuery(GetCustomerInformation);
  React.useEffect(() => {
    if (isAuthenticated) {
      getUser({
        errorPolicy: 'all',
      });
    }
  }, [isAuthenticated]);
  React.useEffect(() => {
    if (error) dispatch(setAuthenticated(false));
  }, [error]);
  return (
    <Route
      {...rest}
      render={
        ({ location }) => (isAuthenticated ? (
          <>
            {children}
            <ModalChangePassword isOpen={data && !data.MeCustomer.password_changed} passwordChanged={data && !data.MeCustomer.password_changed} />
          </>
        ) : (<Redirect to={{ pathname: '/login', state: { from: location } }} />))
}
    />
  );
}
function mapStateToProps(state) {
  const { AuthReducer } = state;
  const { isAuthenticated, user } = AuthReducer;
  return {
    isAuthenticated,
    user,
  };
}
export default connect(mapStateToProps)(LoggedInRoute);
