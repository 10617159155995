import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles(({ palette: { grey } }) => ({
  listModels: {
    padding: 2,
    '&::-webkit-scrollbar': {
      width: 5,
      '&-track': {
        background: '#f1f1f1',
      },
      '&-thumb': {
        background: grey[400],
        '&:hover': {
          background: grey[600],
        },
      },
    },
  },
  gridGroup: {
    marginTop: 1,
    marginBottom: 1,
  },
}));
