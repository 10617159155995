import client from '@/Apollo';
import { GetEmployeeByNameWithStoreByName } from '../query/GetUser';

const insertUser = () => new Promise((resolve, reject) => {
  const employeeName = 'Reparofone Online - Quiosque';
  const storeName = 'REPAROFONE ONLINE';
  client
    .query({
      query: GetEmployeeByNameWithStoreByName,
      variables: { employeeName, storeName },
    })
    .then((response) => resolve(response.data))
    .catch((e) => {
      console.log(e.message, 'erro ao inserir user online');
      reject(e.message);
    });
});

export default insertUser;
