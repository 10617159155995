import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setStep, setAfterRepairPayment } from '@redux/actions/NewOrderActions';

const mapStateToProps = ({ NewOrderReducer }) => {
  const { activeStep } = NewOrderReducer;
  return {
    $state: {
      step: activeStep,
    },
  };
};

const mapDispatchToProps = (dispatch) => ({
  $actions: bindActionCreators(
    {
      setStep,
      setAfterRepairPayment,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
});
