import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '650px',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

export default function SimpleAccordion() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>
            Como funciona o pagamento de comissão ao parceiro?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>Fazemos repasses mensais do comissionamento.</Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className={classes.heading}>
            Como funciona a coleta/entrega (prazo, sistema, etc)
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            O parceiro receberá um acesso à nossa plataforma onde poderá fazer
            as vendas e acompanhar as ordens de reparo abertas. No sistema todo
            o prazo e o preço de cada reparo será informado de forma
            transparente. Se o cliente optar por recolher o aparelho na loja
            após o reparo, você deverá informar o sucesso da entrega na nossa
            plataforma.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className={classes.heading}>
            É necessário um CNPJ para ser um parceiro?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>Sim.</Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className={classes.heading}>
            Como funciona o pagamento do cliente?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Contamos com as seguintes formas de pagamento: Via Boleto, Débito
            pelo Mercado Pago e também Cartão de Crédito com parcelamento em até
            12x SEM JUROS. Será enviado link do mercado pago contendo o valor e
            ordem de serviço referente ao Reparo.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
