import React from 'react';
import {
  Dialog, DialogContent, DialogContentText, DialogTitle,
  DialogActions, Button,
} from '@material-ui/core';

export default function ModelNeoAssist({ open, handleClose }) {
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        scroll="paper"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="md"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <iframe src="https://trocafone.neoassist.com/?th=fixaNeof" style={{ border: 0 }} width="100%" height="430px" title="NeoAssist - Trocafone" />
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ paddingRight: '30px' }}>
          <Button onClick={handleClose} style={{ color: '#3D6378' }}>
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
