import { Paper } from '@material-ui/core';
import React from 'react';
import style from './style';
import StepperContent from '../StepperContent';
import StepperHeader from '../StepperHeader';

function FormStepper() {
  const classes = style();
  return (
    <div id="top-content" className={classes.backgroundForm}>
      <Paper id="topContent" className="form" elevation={0}>
        <StepperHeader />
        <StepperContent />
      </Paper>
    </div>
  );
}

export default FormStepper;
