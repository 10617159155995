import React from 'react';
import {
  Stepper, withStyles, Step, StepLabel, StepConnector, makeStyles, Icon,
} from '@material-ui/core';
import { Check } from '@material-ui/icons';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { snakeCase } from 'lodash';
import { red } from '@material-ui/core/colors';

const ConnectorStyle = {
  alternativeLabel: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  active: {
    '& .MuiStepConnector-line': {
      borderColor: (props) => (props.isValid ? '#3c3584' : red[500]),
    },
  },
  completed: {
    '& .MuiStepConnector-line': {
      borderColor: (props) => (props.isValid ? '#3c3584' : red[500]),
    },

  },
  line: {
    borderColor: '#b4b4b9',
    borderTopWidth: 3,
    borderRadius: 1,

  },
  lineVertical: {
    borderLeftStyle: 'none',
  },
};
const QontoConnector = withStyles(ConnectorStyle)(({ classes, isValid, ...other }) => (
  <StepConnector classes={classes} {...other} />
));

const useQontoStepIconStyles = makeStyles({
  root: {
    color: '#b4b4b9',
    display: 'flex',
    height: 22,
    alignItems: 'center',
  },
  active: {
    color: (props) => (props.isValidIndex ? '#3c3584' : red[500]),
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
  completed: {
    color: (props) => (props.isValidIndex ? '#3c3584' : red[500]),
    zIndex: 1,
    fontSize: 18,
  },
});
const QontoStepIcon = (icon, isValidIndex) => {
  const classes = useQontoStepIconStyles({ isValidIndex });
  return (props) => {
    const { active, completed } = props;
    const defaultIcon = completed ? (
      <Check
        className={classes.completed}
      />
    ) : <div className={classes.circle} />;
    return (
      <div
        className={clsx(classes.root, {
          [classes.active]: active,
        })}
      >
        {
          icon
            ? <div className={completed ? classes.completed : ''}><Icon>{snakeCase(icon)}</Icon></div>
            : defaultIcon
}
      </div>
    );
  };
};

const Timeline = (props) => {
  const { items, currentIndex, ...otherProps } = props;
  const isValidIndex = !Number.isNaN(currentIndex);
  return (
    <Stepper
      alternativeLabel
      activeStep={isValidIndex ? currentIndex : items.length}
      connector={<QontoConnector isValid={isValidIndex} />}
      style={{ background: 'transparent' }}
      {...otherProps}
    >
      {items.map(({ label, icon }) => (
        <Step key={label}>
          <StepLabel StepIconComponent={QontoStepIcon(icon, isValidIndex)}>{label}</StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};
Timeline.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    icon: PropTypes.string,
  })).isRequired,
  currentIndex: PropTypes.number,
};
Timeline.defaultProps = {
  currentIndex: 0,
};
export default Timeline;
