import React from 'react';
import * as P from 'prop-types';
import BrazillianCurrency from '@/utils/NationalCurrency';
import useStyle from '../Utils/Style';

export default function LabelTaxDelivery({ title, value }) {
  const valueParsed = value ? BrazillianCurrency(value).format() : 'Grátis';
  const classes = useStyle();

  return (
    <div className={classes.containerLabel}>
      <div className={classes.labelItem}>
        <div className={classes.containerTitle}>{title}</div>
        <div className={classes.containerValue}>{valueParsed}</div>
      </div>
    </div>
  );
}

LabelTaxDelivery.propTypes = {
  title: P.string.isRequired,
  value: P.number.isRequired,
};
