export const ADD_STORES_DATA = 'ADD_STORES_DATA';
export const ADD_CURRENT_STORES = 'ADD_CURRENT_STORES';

const initialState = {
  stores_data: [],
  current_store: [],
};

export default function AllStoresReducer(state = initialState, action) {
  const { payload } = action;
  const finalState = (() => {
    switch (action.type) {
      case ADD_STORES_DATA:
        return {
          ...state,
          stores_data: [...state.stores_data, ...payload],
        };
      case ADD_CURRENT_STORES:
        return {
          ...state,
          current_store: { ...state.current_store, ...payload },
        };
      default:
        return state;
    }
  })();
  return finalState;
}
