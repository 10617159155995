const urlParams = new URLSearchParams(window.location.search)
export default {
  id: urlParams.get('store'),
  encryptedId: urlParams.get('store'),
  name: undefined,
  address: {
    zipCode: undefined,
    state: undefined,
    city: undefined,
    neighborhood: undefined,
    street: undefined,
    number: undefined,
    complement: undefined,
  },
  employee: {
    id: urlParams.get('user'),
    encryptedId: urlParams.get('user'),
    name: undefined,
  },
};
