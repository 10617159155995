/* eslint-disable react/jsx-no-duplicate-props */
import React from 'react';
import * as Yup from 'yup';
import * as P from 'prop-types';
import reactTriggerChange from 'react-trigger-change';
import {
  CircularProgress, Icon, InputAdornment, TextField,
} from '@material-ui/core';
import ReactInputMask from 'react-input-mask';
import { propTypes, defaultProps } from '../Utils/TextFieldPropTypes';
import useStyle from '../../../../../style';

export default function InputNumber({
  handleChange, handleBlur, handleFocus,
  value, error, typeInput, disabled, isLoading,
}) {
  const inputRef = React.useRef(null);
  const classes = useStyle();

  React.useEffect(() => {
    reactTriggerChange(inputRef.current);
  }, []);

  return (
    <div>
      <ReactInputMask
        mask="9999 9999 9999 9999"
        placeholder="0000 0000 0000 0000"
        value={value}
        onBlur={handleBlur}
        onFocus={handleFocus}
        disabled={disabled}
        onChange={handleChange}
        maskPlaceholder={null}
      >
        <TextField
          fullWidth
          size="small"
          autoComplete="off"
          label="Número do cartão"
          spellCheck={false}
          variant={typeInput}
          inputRef={inputRef}
          error={!!error}
          helperText={error || null}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Icon className={classes.inputIcon}>credit_card</Icon>
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="start">
                {isLoading && <CircularProgress size="1.2rem" />}
              </InputAdornment>
            ),
          }}
        />
      </ReactInputMask>
      <input
        id="cardNumber"
        type="hidden"
        data-checkout="cardNumber"
        defaultValue={String(value).replace(/[^0-9]/gm, '')}
      />
    </div>
  );
}

InputNumber.propTypes = {
  ...propTypes,
  isLoading: P.bool.isRequired,
};
InputNumber.defaultProps = defaultProps;
InputNumber.rules = Yup
  .string()
  .matches(/\d{4} \d{4} \d{4} \d{4}/gm, 'Formato inválido')
  .required('Este campo é obrigatório');
