import React from 'react';
// MATERIAL-UI
import { Paper, Typography } from '@material-ui/core';
// REDUX
import { useSelector } from 'react-redux';
// CLSX
import clsx from 'clsx';
// COMPONENTS
import StepperContent from '../StepperContent';
import StepperHeader from '../StepperHeader';
// STYLES
import style from './style';

function FormStepper() {
  const classes = style();
  const partnerData = useSelector((state) => state.PartnerStoreReducer);
  const { branch } = partnerData;
  const { branch_name } = branch;
  return (
    <div className={classes.backgroundForm}>
      <Typography
        className={clsx(classes.header, {
          [classes.headerCarrefour]: branch_name === 'Carrefour',
          [classes.headerReparofone]: branch_name === 'Limelocker',
        })}
        variant="h2"
      >
        Faça sozinho seu Orçamento agora
      </Typography>
      <Paper id="top-content" className="form" elevation={0}>
        <div id="topContent" className={classes.topContent} />
        <StepperHeader />
        <StepperContent />
      </Paper>
    </div>
  );
}

export default FormStepper;
