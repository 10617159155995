import React from 'react';
import { Icon, InputAdornment, TextField } from '@material-ui/core';
import clsx from 'clsx';
import { InputEmailPropTypes, InputEmailDefaultProps } from './PropTypes';
import InputEmailRules from './Rules';
import useStyle from '../../../../style';

export default function InputEmail(props) {
  const {
    handleChange, handleBlur, errors, value, typeInput, disabled, id,
  } = props;
  const classes = useStyle();
  return (
    <TextField
      fullWidth
      name="email"
      label="E-mail do cliente"
      id={id}
      variant={typeInput}
      color="primary"
      value={value}
      onChange={handleChange}
      onBlur={handleBlur}
      helperText={errors}
      error={!!errors}
      disabled={disabled}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Icon className={clsx(['fas', 'fa-envelope', classes.inputIcon])} color="action" />
          </InputAdornment>
        ),
      }}
    />
  );
}

InputEmail.propTypes = InputEmailPropTypes;
InputEmail.defaultProps = InputEmailDefaultProps;
InputEmail.rules = InputEmailRules;
