import mercadoPagoMessages from './Messages/MercadoPago';

const defaultMessage = 'Houve um erro ao criar a ordem de serviço, por favor, tente novamente mais tarde';
const handleResponse = (errorMessage) => {
  let response = defaultMessage;
  if (errorMessage.category === 'payment') {
    response = mercadoPagoMessages
      .find(({ statusDetail }) => statusDetail === errorMessage.reason)?.message;
  }
  return response || defaultMessage;
};

export default handleResponse;
