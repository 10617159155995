import client from '@/Apollo';
import GetDefect from '../query/GetDefect';

export default function getDefectByName(name) {
  return new Promise((resolve, reject) => {
    client
      .query({
        query: GetDefect,
        variables: { defect_name: name },
      })
      .then((response) => resolve(response.data.getDefectByName))
      .catch((error) => {
        console.log(error.message, 'erro ao buscar');
        reject(error);
      });
  });
}
