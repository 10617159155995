import React from 'react';
import * as P from 'prop-types';
import { Box, CircularProgress, makeStyles } from '@material-ui/core';
import clsx from 'clsx';

const customStyle = makeStyles(({ zIndex }) => ({
  loadingContent: {
    zIndex: -50,
    opacity: 0,
    display: 'flex',
    position: 'absolute',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'all .4s ease-in-out',
    '&.active': {
      zIndex: zIndex.drawer + 1,
      opacity: 1,
    },
  },
}));

export default function LoadingContent({ isLoading, children }) {
  const classes = customStyle();
  return (
    <Box position="relative">
      <Box className={clsx([classes.loadingContent, { active: isLoading }])}>
        {isLoading ? <CircularProgress size="1.2rem" /> : null}
      </Box>
      <fieldset
        disabled={isLoading}
        style={{
          border: 'none',
          padding: 0,
          margin: 0,
        }}
      >
        {children}
      </fieldset>
    </Box>
  );
}

LoadingContent.propTypes = {
  isLoading: P.bool.isRequired,
  children: P.oneOfType([P.node, P.string, P.number]),
};

LoadingContent.defaultProps = {
  children: null,
};
