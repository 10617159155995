import React from 'react';
import {
  BottomNavigation, BottomNavigationAction, Icon, makeStyles,
} from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import { snakeCase, indexOf } from 'lodash';
import MenuItems from './MenuItems';

const useStyles = makeStyles(
  (theme) => ({
    stickToBottom: {
      [theme.breakpoints.up('sm')]: {
        display: 'none',
      },
      width: '100%',
      position: 'fixed',
      bottom: 0,
    },
  }),
);

const BottomNav = () => {
  const classes = useStyles();
  const [activeTab, setActiveTab] = React.useState(0);
  const handleChange = (e, value) => {
    setActiveTab(value);
  };
  return (
    <BottomNavigation
      className={classes.stickToBottom}
      value={activeTab}
      onChange={handleChange}
      showLabels
    >
      {MenuItems.filter((r) => !!~indexOf(r.appearIn, 'bottom')).map(({
        label, to, icon,
      }) => (
        <BottomNavigationAction
          key={label}
          label={label}
          component={NavLink}
          to={to}
          icon={<Icon>{snakeCase(icon)}</Icon>}
        />
      ))}
    </BottomNavigation>
  );
};

export default BottomNav;
