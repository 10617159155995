import { client } from '@/Apollo';
import requestRecoverPasswordCode from '../mutation/RequestRecover';

const requestTokenGeneration = (data) => new Promise((resolve, reject) => {
  client
    .mutate({
      mutation: requestRecoverPasswordCode,
      variables: { ...data },
    })
    .then((r) => {
      resolve(r.data.PartnerRequestRecoverPassword);
    })
    .catch((e) => {
      console.log(e.message, 'erro ao criar token');
      reject(e.message);
    });
});

export default requestTokenGeneration;
