import React from 'react';

import { Card, Icon, Image } from 'semantic-ui-react';

import './ComoFunciona.css';

const Cards = (props) => {
  const { image, step, title, text } = props;

  return (
    <Card>
      <Image
        className="image-card"
        src={image}
        alt="Reparofone - Trocafone"
        wrapped
        ui={false}
      />
      <Card.Content>
        <Card.Header>{title}</Card.Header>
        <Card.Description>{text}</Card.Description>
      </Card.Content>
      <Card.Content extra>
        <Icon name="arrow circle right" />
        {step}
      </Card.Content>
    </Card>
  );
};

export default Cards;
