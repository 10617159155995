import { Snackbar } from '@material-ui/core';
import React from 'react';
import { Alert } from '@material-ui/lab';

const AlertCancel = ({
  valid, setIsValid,
}) => {
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setIsValid(null);
  };

  return (
    <div>
      <Snackbar open={valid} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} variant="filled" severity="success">
          Ordem cancelada com sucesso!
        </Alert>
      </Snackbar>
      <Snackbar open={valid === false} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} variant="filled" severity="error">
          Ocorreu um erro ao cancelar a OS! Tente novamente ou atualize a página.
        </Alert>
      </Snackbar>
    </div>
  );
};
export default AlertCancel;
