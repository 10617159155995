import gql from 'graphql-tag';

export const GetEmployeeById = gql`
	query GetEmployeeById ($id: String!){
		ReparofoneGetEncryptedUser(key:$id){
			id
			name
			enabled
		}
	}
`;

export const GetEmployeeByIdWithStoreById = gql`
	query GetEmployeeByIdWithStoreById ($employeeId: String!, $storeId:String! ){
			ReparofoneGetEncryptedUser(key: $employeeId){
				id
				name
				enabled
				token
			}
			ReparofoneGetEncryptedStore(key: $storeId){
				id
				name
				enabled
				addresses{
					id
					zipCode: zip_code
					state
					city
					neighborhood
					street
					number: street_number
					complement
				}
			}
		}
`;

export default GetEmployeeById;
