import React from 'react';
import { Box, Grid } from '@material-ui/core';
// COMPONENTS
import Title from './Components/Title';
import InlineGridInfo from './Components/InlineGridInfo';
// FUNCTIONS
import connect from './ConnectComponentRedux';
import getLabelAddress from './Utils/GetLabelAddress';
import { propTypes, defaultProps } from './PropTypes';

function PaymentData({ $state }) {
  const { payment, billingAddress: address } = $state;

  const creditNumber = (v) => v.replace(/^.+([0-9]{4})$/, '**** **** **** $1');

  return (
    <Box py={1}>
      <Title />
      <Grid container spacing={1}>
        <InlineGridInfo title="CPF do pagador:" value={payment.data.cpf} />
        <InlineGridInfo title="Nome do pagador:" value={payment.data.name} />
        <InlineGridInfo title="Cartão de Crédito:" value={payment.data.number} formatValue={creditNumber} />
        <InlineGridInfo title="Parcelas:" value={payment.data.installmentsText} />
        <InlineGridInfo title="Endereço de cobrança:" value={getLabelAddress(address)} />
      </Grid>
    </Box>
  );
}

PaymentData.propTypes = propTypes;
PaymentData.defaultProps = defaultProps;

export default connect(PaymentData);
