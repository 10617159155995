import { styled, Typography } from '@material-ui/core';
import React from 'react';

const Header = ({ title, description, className }) => (
  <div className={className}>
    <Typography variant="h4">{title}</Typography>
    <Typography variant="subtitle1">{description}</Typography>
  </div>
);

export default styled(({ color, ...other }) => <Header {...other} />)({
  backgroundColor: ({ color }) => color,
  padding: '2.5rem',
  color: 'white',
  textAlign: 'center',
});
