import { makeStyles } from '@material-ui/core';
import { green } from '@material-ui/core/colors';

export const useStyles = makeStyles((themeStyle) => ({
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    left: '8.5%',
    marginTop: 4,
  },
  buttonProgressMobile: {
    color: green[500],
    position: 'absolute',
    left: '16%',
    marginTop: 3.5,
  },
  tooltip: {
    backgroundColor: themeStyle.palette.common.black,
  },
  arrow: {
    color: themeStyle.palette.common.black,
  },
  buttonCancel: {
    backgroundColor: themeStyle.palette.common.white,
    color: 'red',
    border: '1px solid red',
    '&:hover': {
      border: '1px solid red',
      backgroundColor: themeStyle.palette.common.white,
    },
  },
  paper: {
    padding: themeStyle.spacing(2),
    textAlign: 'center',
    color: themeStyle.palette.text.secondary,
    width: '60%',
    margin: `${themeStyle.spacing(3)}px auto`,
    [themeStyle.breakpoints.down('sm')]: {
      marginBottom: '80px',
      width: '100%',
    },
  },
  paperRevision: {
    [themeStyle.breakpoints.down('sm')]: {
      marginBottom: '0px',
      width: '100%',
    },
  },
  lastRevision: { padding: themeStyle.spacing(2) },
}));

export default useStyles;
