import gql from 'graphql-tag';

export const GetEmployeeByNameWithStoreByName = gql`
  query GetEmployeeByNameWithStoreByName(
    $employeeName: String!
    $storeName: String!
  ) {
    ReparafoneGetTradeInUserByName(name: $employeeName) {
      id
      name
      email
      branch_id
    }
    ReparafoneGetStoreByName(name: $storeName) {
      id
      name
    }
  }
`;

export default GetEmployeeByNameWithStoreByName;
