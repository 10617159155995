import client from '@/Apollo';
import { getModelsByBrandQuery } from '../graphql/query/GetModels';

export default function getModelsByBrand(brand) {
  return new Promise((resolve, reject) => {
    client.query({ query: getModelsByBrandQuery, variables: { brand } })
      .then(({ data: { models } }) => {
        resolve(models);
      }).catch(() => {
        reject();
      });
  });
}
