import gql from 'graphql-tag';

export const UpdateStatusOrder = gql`
  mutation UpdateStatusOrder($id: ID!, $order_status_id: ID!) {
    updateStatusOrderRepair(
      input: { id: $id, order_status_id: $order_status_id }
    ) {
      id
      status {
        id
        status_name
        status_slug
        lay_name
      }
    }


  }
`;

export const CreateMovimentationsOrder = gql`
  mutation CreateMovimentationsOrder(
    $movement_order_id: ID!
    $movement_status_id: ID!
    $movement_previous_status: ID!
    $movement_user_id: ID!
  ) {
    createMovimentationsOrderRepair(
      input: {
        movement_order_id: $movement_order_id
        movement_status_id: $movement_status_id
        movement_previous_status: $movement_previous_status
        movement_user_id: $movement_user_id
      }
    ) {
      id
      movement_order_id
    }
  }
`;

export const CreateMovimentationsAndUpdateStatusOrder = gql`
  mutation CreateMovimentationsAndUpdateStatusOrder(
    $id: ID!
    $order_status_id: ID!
    $movement_order_id: ID!
    $movement_status_id: ID!
    $movement_previous_status: ID!
    $movement_user_id: ID!
  ) {
    createMovimentationsOrderRepair(
      input: {
        movement_order_id: $movement_order_id
        movement_status_id: $movement_status_id
        movement_previous_status: $movement_previous_status
        movement_user_id: $movement_user_id
      }
    ) {
      id
      movement_order_id
    }

  updateStatusOrderRepair(
      input: { id: $id, order_status_id: $order_status_id }
    ) {
      id
      status {
        id
        status_name
        status_slug
        lay_name
      }
    }
  }
`;

export default UpdateStatusOrder;
