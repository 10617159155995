import P from 'prop-types';

export const SUBMITTING = {
  BACK: 'BACK',
  NEXT: 'NEXT',
};

export const StepperButtonsPropTypes = {
  backStep: P.func,
  nextStep: P.func,
  nextText: P.string,
  isSubmitting: P.oneOf(Object.keys(SUBMITTING)),
};

export const StepperButtonsDefaultProp = {
  backStep: null,
  nextStep: null,
  nextText: 'Próximo',
  isSubmitting: null,
};

export default {
  StepperButtonsPropTypes,
  StepperButtonsDefaultProp,
};
