import React from 'react';
// MATERIAL-UI
import {
  Box, Typography, List, Icon,
} from '@material-ui/core';
// ICONS
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import BuildIcon from '@material-ui/icons/Build';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
// PROP-TYPES
import PropTypes from './PropTypes';
// COMPONENTS
import { InfoData } from '../Common';
// UTILS
import {
  revisionDateFormatter,
  totalEstimateFormatter,
  usedPartsPriceFormatter,
  imeiFormatter,
  laborPriceFormatter,
} from './utils';
// STYLES
import styles from './styles';

const Revision = ({ order }) => {
  const classes = styles();
  const [revisionDate, setRevisionDate] = React.useState(null);
  const [totalEstimate, setTotalEstimate] = React.useState(null);
  const [partsPrice, setPartsPrice] = React.useState(null);
  const [imei, setImei] = React.useState(null);
  const [labor, setLabor] = React.useState(null);
  React.useEffect(() => {
    setImei(imeiFormatter(order));
    setLabor(laborPriceFormatter(order.estimates));
    setPartsPrice(usedPartsPriceFormatter(order.estimates));
    setTotalEstimate(totalEstimateFormatter(order));
    setRevisionDate(revisionDateFormatter(order.estimates));
  }, []);
  return (
    <Box className={classes.box}>
      <Typography className={classes.title} variant="h2" align="left">
        Dados da Revisão
      </Typography>
      <List className={classes.list}>
        <InfoData
          title="IMEI do Aparelho"
          info={imei}
          icon={<Icon className="fas fa-barcode" style={{ fontSize: '2.5rem' }} />}
        />
        <InfoData
          title="Produto"
          info={order.product.product_name}
          icon={<PhoneIphoneIcon className={classes.icon} />}
        />
        <InfoData
          title="Mão de Obra"
          info={labor}
          icon={<BuildIcon className={classes.icon} />}
        />
        <InfoData
          title="Valor das Peças"
          info={partsPrice}
          icon={<AttachMoneyIcon className={classes.icon} />}
        />
        <InfoData
          title="Valor Total do Orçamento"
          info={totalEstimate}
          icon={<MonetizationOnIcon className={classes.icon} />}
        />
        <InfoData
          title="Data da Revisão"
          info={revisionDate}
          icon={<EventAvailableIcon className={classes.icon} />}
        />
      </List>
    </Box>
  );
};

export default Revision;

Revision.propTypes = PropTypes.propTypes;
Revision.defaultProps = PropTypes.defaultProps;
